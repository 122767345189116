<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";

export default {
  name: "pay_point",
  components: {navigationBar},
  data(){
    return{
      ctime:0,
      total_integral:0,
    }
  },
  methods:{
    chooseUrl(){
      this.$router.push({
        path:'point_home',
        replace:true
      })
    },
  },
  mounted() {
    this.ctime = JSON.parse(this.$route.query.data).ctime
    this.total_integral = JSON.parse(this.$route.query.data).total_integral
  }
}
</script>

<template>
<div class="container">
  <div class="area">
    <navigationBar :title="'兑换结果'" class="navigation"></navigationBar>
    <div class="success">
      <span class="iconfont icon-Frame15"></span>
      <span class="text">兑换成功</span>
    </div>
    <div class="form">
      <div class="line">
        <div class="label">消耗积分</div>
        <div class="value">{{ total_integral }}</div>
      </div>
      <div class="line">
        <div class="label">支付方式</div>
        <div class="value">积分兑换</div>
      </div>
      <div class="line">
        <div class="label">兑换时间</div>
        <div class="value">{{ctime}}</div>
      </div>
    </div>
    <div class="botmBtn">
      <div class="btn" @click="chooseUrl">返回首页</div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.success{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  color: #08B909;
  .iconfont{
    font-size:48px ;
  }
  .text{
    margin-top: 25px;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
  }
}
.form{
  margin: 10px 16px;
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  .line{
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }
}
.botmBtn{
  position: fixed;
  bottom: 5%;
  width: 100%;
}
.btn{
  border-radius: 39px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  color: #1b1b1b;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 15px;
  margin: 0 16px;
  background-color: #fff;
}
</style>