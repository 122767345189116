<template>
  <div class="container userinfo">
    <div class="area">
      <navigationBar :title="'用户信息'" class="navigation"></navigationBar>
      <div class="card">
        <el-upload
            class="uploader"
            action
            :http-request="uploadFile"
            ref="upload"
            :limit="problemLimit"
            :on-remove="handleRemove"
            :file-list="problemFiles"
            :on-exceed="handleExceed"
            :before-upload="beforeUpload"
            :show-file-list="false"
            :headers="headers"
            :on-change="fileChange2"
            multiple
            name="22"
        >
          <div class="line" @click="setAvatar">
            <div class="left">
              修改头像
            </div>
            <div class="right">
              <img class="avatar" :src="userinfo.avatar" alt="">
              <span class="iconfont icon-Frame-4"></span>
            </div>
          </div>
        </el-upload>


        <div class="line" @click="setNickName">
          <div class="left">修改昵称</div>
          <div class="right">
            {{ userinfo.nickname }}
            <span class="iconfont icon-Frame-4"></span>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="line" @click="serPssword">
          <div class="left">修改密码</div>
          <div class="right">
            <span class="iconfont icon-Frame-4"></span>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="line" @click='checkUserAgreement'>
          <div class="left">用户协议</div>
          <div class="right">
            <span class="iconfont icon-Frame-4"></span>
          </div>
        </div>
        <div class="line" @click="privacyAgreement">
          <div class="left">隐私协议</div>
          <div class="right">
            <span class="iconfont icon-Frame-4"></span>
          </div>
        </div>
        <div class="line">
          <div class="left">版本号</div>
          <div class="right">
            V1.0.0
            <span class="iconfont icon-Frame-4"></span>
          </div>
        </div>
      </div>
      <div class="bottomBtn">
<!--        <div class="toggle btn" @click="toggle">切换账号</div>-->
        <div class="exit btn" @click="exitLogin">退出登录</div>
      </div>
    </div>
  </div>
</template>
<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {logout, upload_file, upload_files, user_deit} from "@/utils/api";
import Compressor from "compressorjs";
import {Toast} from "vant";

export default {
  name: "userInfo",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    navigationBar
  },
  data(){
    return{
      userinfo:{},

      problemFiles:[],
      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["png", "jpg", "jpeg",],
      problemLimit:1,
    }
  },
  methods: {
    uploadUser(avatar){
      return new Promise((resolve, reject)=> {
        user_deit(avatar,this.$store.state.user.nickname).then((res) => {
          resolve(res)
        }).catch(err=>{
          reject(err)
        })
      })

    },

    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)

        }


      })
    },
    async beforeUpload(files) {
      console.log(files)
      let file = await this.yasuo(files)
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        if (files.type.startsWith('image/')) {   //图片
          //计算文件的大小
          const isLt5M = file.size / 1024 / 1024 < 1; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            return false;
          }
        } else if (files.type.startsWith('video/')) {//视频
          const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
     uploadFile(item) {
      // console.log(JSON.stringify(item))
      if (item.file.type.startsWith('image/')) {
        upload_file(item.file).then(async res => {
          if (res.data.status == 200) {
            if (item.filename == 22) {
              // console.log(res.data.data.url)
              let data = await this.uploadUser(res.data.data.url)
              if(data.data.status == 200) {
                let user = this.$store.state.user
                user.avatar = res.data.data.url
                this.$store.commit("updataUser", user);
                Toast('修改成功')
                this.problemFiles = []
              }
            }
            // this.userUpload.push({tempFilePath:res.data.data.saveurl,fileType:'image'})
          }
        })
      } else if (item.file.type.startsWith('video/')) {
        upload_files(item.file).then(res => {
          if (res.data.status == 200) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 22) {
              this.problemLoad.push(res.data.data.url)
            }
          }
        })
      }
    },
    //上传成功后的回调
    handleSuccess() {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.handleClose();
    },
    fileChange2(file, problemFiles) {
      let list = []
      problemFiles.forEach(item => {
        const FileExt = item.name.replace(/.+\./, "").toLowerCase();
        if (this.fileType.includes(FileExt)) {
          list.push(item)
          return true;
        } else {
          return false;
        }
      })
      this.problemFiles = list;
    },
    async handleRemove() {
    },
    handleClose(i) {
      this.yanshoufileList.splice(i, 1); //删除上传的文件
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },






    checkUserAgreement() {
      console.log('用户协议')
    },
    privacyAgreement() {
      console.log('隐私')
    },
    serPssword() {
      this.$router.push({
        name: 'setDetails',
        params:{type:1,aaa:1},
      })
    },
    setNickName() {
      this.$router.push({
        name: 'setDetails',
        params:{nickName:this.$store.state.user.nickname,type:2,aaa:2},
      })
    },
    setAvatar() {

      console.log('头像')
    },
    toggle() {
      console.log('切换账号')
    },
    exitLogin() {
      logout().then(res=>{
        if(res.data.status == 200){
          sessionStorage.removeItem('userInfo')
          sessionStorage.removeItem('token')
          this.$store.commit('outLogin')
        }
      })
    },
  },
  mounted() {
    this.userinfo = this.$store.state.user
    console.log(this.userinfo)
    console.log(this.$store.state.user)
  }
}
</script>


<style scoped lang="scss">
container {
  background: #F8F8F8;
}

.area {
  background: #F8F8F8;
  min-height: 100vh;
}

.bottomBtn {
  position: absolute;
  bottom: 10px;
  left: 0%;
  width: 100%;

  .btn {
    background: #FFFFFF;
    margin: 10px 16px;
    border-radius: 10px;
    padding: 13px;
    font-weight: 400;
    font-size: 15px;
    color: #1B1B1B;
    line-height: 24px;
  }
}

.navigation {
  background: white !important;
}

.card {
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  margin: 10px 16px;
  padding: 0 10px;

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F7F7F7;
    padding: 16px 0;
    font-family: PingFang SC, PingFang SC;

    .left {
      font-weight: 400;
      font-size: 15px;
      color: #1B1B1B;
      line-height: 24px;
    }

    .right {
      display: flex;
      align-items: center;
      color: #999999;
      font-size: 14px;

      .iconfont {
        font-size: 24px;
        color: #B8B8B8;
      }

      .avatar {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .line:nth-last-of-type(1) {
    border-bottom: 1px solid white;
  }
}


</style>
<style>
.userinfo .el-upload--text{
  border:none;
  height: auto;
}
</style>