<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import { kefu_list} from "@/utils/api";
import {Toast} from "vant";
import {getSeekerDetail,createCollection,del_collection} from "@/api/recruitment"
export default {
  name: "biographical_notes",
  components: {navigationBar},
  data() {
    return {
      collect: false,
      obj: {},
    }
  },
  methods: {
    add_collection() {
      console.log(this.collect)
      if (this.collect) {
        //取消
        del_collection(this.obj.collection.id, 1).then(res => {
          if (res.data.status == 200) {
            Toast('已取消')
            this.collect = false
          } else {
            Toast(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        //添加
        createCollection(this.obj.id, 1).then(res => {
          if (res.data.status == 200) {
            Toast('收藏成功')
            if(!this.obj.collection){
              let obj = {
                id:res.data.data
              }
              this.obj.collection = obj
            }else{
              this.obj.collection.id = res.data.data
            }
            this.collect = true
          } else {
            Toast(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    getObj(item) {
      getSeekerDetail(item.uid).then(res => {
        if (res.data.status == 200) {
          let obj = res.data.data
          if (obj.collection) {
            this.collect = true
          } else {
            this.collect = false
          }
          this.obj = obj
        }
      })
    },

    chatMessage(){
      kefu_list(this.$store.state.token,this.obj.uid,4).then(res=>{
        if(res.data.status == true){
          let obj = res.data.data
          let list = obj.filter(item=>{return item.type == 4})
          console.log(list,obj)
          console.log(list.find(item=>{return item.anotherid == this.obj.uid&&item.userid == this.$store.state.user.id}))

          let messageObj = {}
          if(list.find(item=>{return item.anotherid == this.obj.uid&&item.userid == this.$store.state.user.id})){
            messageObj = list.find(item=>{return item.anotherid == this.obj.uid&&item.userid == this.$store.state.user.id})
          }else{
            if(list.find(item=>{return item.anotherid == this.$store.state.user.id &&item.userid == this.obj.uid})){
              messageObj = list.find(item=>{return item.anotherid == this.$store.state.user.id &&item.userid == this.obj.uid})
              messageObj.anotherid =  obj.id
              messageObj.userid = this.$store.state.user.id
            }
          }
          this.$store.commit('updataChatDetail',messageObj)
          this.$router.push({
            name: 'chat_details',
          })

        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },

    mobile(){
      console.log('电话')
    },

  },
  mounted() {
    this.getObj(JSON.parse(this.$route.query.item))
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="''" class="navigationBar">
        <template #search>
          <div class="rightFun">
            <img @click="add_collection" :src="$store.state.imageHost+'/img/Collect11.png'" alt="" class="icon"
                 v-if="!collect">
            <img @click="add_collection" :src="$store.state.imageHost+'/img/Collect22.png'" alt="" class="icon"
                 v-if="collect">
<!--            <img :src="$store.state.imageHost+'/img/share_invite.png'" alt="" class="icon">-->
            <img :src="$store.state.imageHost+'/img/report_invite.png'" alt="" class="icon">
          </div>
        </template>
      </navigationBar>
      <div class="inner">
        <div class="userInfo">
          <div class="leftInfo">
            <div class="linee"><span class="name text">{{ obj.user_name }}</span> <span
                class="text" v-if="obj.userjobstatus">{{ obj.userjobstatus.job_name }}</span> <span
                class="text"><span class="iconfont icon-xiangqing-gongling"> {{
                obj.workingyears.job_name
              }}</span></span><span
                class="text">{{ obj.user_age }}岁</span><span class="text" v-if="obj.degrees.code">{{
                obj.degrees.job_name ? obj.degrees.job_name : '暂未填写'
              }}</span><span class="text" v-if="obj.degrees.code">{{ obj.school }}</span>
            </div>
          </div>
          <div class="rightImg">
            <el-image
                class="avatar"
                :src="$store.state.imageHost+obj.user_avatar"
                :preview-src-list="[$store.state.imageHost+obj.user_avatar]">
            </el-image>
            <div class="tag">已认证</div>
          </div>
        </div>
        <div class="gangweiBox">
          <div class="text">期望岗位：{{ obj.industry.industry_name }}</div>
          <div class="text">全职</div>
          <div class="text">{{ Number(obj.salary_min) }}k~{{ Number(obj.salary_max) }}k</div>
        </div>
        <div class="youshi line_box">
          <div class="title">
            个人优势
          </div>
          <div class="details" v-html="obj.advantage"></div>
        </div>

        <div class="gongzuojingli line_box">
          <div class="title">
            工作经历
          </div>
          <div class="details" v-html="obj.work_experience"></div>
        </div>

        <div class="gongzuojingli line_box">
          <div class="title">
            项目经历
          </div>
          <div class="details" v-html="obj.project_experience"></div>
        </div>


        <div class="jiaoyu line_box">
          <div class="title">
            教育经历
          </div>

          <div class="cad">
            <div class="toptext">
              <div class="left">{{ obj.school }}</div>
              <div class="right">{{ obj.graduation_time }}</div>
            </div>
            <div class="bot">
              <span class="text">{{ obj.degrees.job_name }}</span>
              <span class="text">{{ obj.profession }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomBox">
       <div class="btn mobile" v-if="obj&&$store.state.user.job_standing == 2" @click="mobile">
         <span class="iconfont icon-dianhua2"></span>打电话
       </div>
        <div class="btn chat" @click="chatMessage"><span class="iconfont icon-Frame5"></span>聊一聊</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.rightFun {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .iconfont {
    font-size: 24px;
    color: #666666;
    margin-left: 20px;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-left: 20px;
    object-fit: cover;
  }
}

.inner {
  padding-bottom: 100px;

  .userInfo {
    display: flex;
    padding: 10px 16px 0px 16px;

    .leftInfo {
      flex: 1;
      text-align: left;
      padding-right: 10px;
      color: #666666;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;

      .name {
        color: #1b1b1b;
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
      }

      .text {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }

    .rightImg {
      position: relative;

      .avatar {
        width: 62px;
        height: 62px;
        border-radius: 50%;
      }

      .tag {
        position: absolute;
        gap: 10px;
        padding: 2px 6px;
        background: #fff4e9;
        color: #ff9f4d;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        bottom: 0;
        left: 0%;
        transform: translate(25%, 0);
      }
    }
  }

  .gangweiBox {
    display: flex;
    margin-right: 15px;
    margin-left: 15px;
    border-bottom: 1px solid #F6F6F6;
    padding-bottom: 20px;

    .text {
      margin-right: 10px;
      color: #666666;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
    }
  }

  .line_box {
    text-align: left;
    margin: 10px 16px 10px 16px;
    border-bottom: 1px solid #F6F6F6;
    padding-bottom: 20px;

    .title {
      color: #1b1b1b;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      padding: 10px 0;
    }

    .details {
      color: #666666;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
    }

    .cad {
      color: #666666;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      .toptext {
        display: flex;
        justify-content: space-between;
      }
      .bot{
        margin-top: 5px;
        .text{
          margin-right: 10px;
        }
      }

    }

  }

}

.bottomBox {
  background: #fff;
  position: fixed;
  padding: 20px 0px;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  box-shadow: 0px -0.5px 2px #99999926;
  .btn {
    cursor: pointer;
    color: #fff;
    padding: 12px 13px;
    border-radius: 12px;
    background: #fc223b;
    margin: 0 auto;
    vertical-align: middle;
    .iconfont {
      font-size: 22px;
      vertical-align: middle;
    }
  }
  .mobile{
    flex-shrink: 0;
    border-radius: 12px;
    background: #0393f5;
    width: 25%;
    margin-right: 15px;
    margin-left: 16px;
  }
  .chat{
    flex: 1;
    margin-right: 16px;
  }
}

</style>