import { render, staticRenderFns } from "./chat_details.vue?vue&type=template&id=22e86e2e&scoped=true"
import script from "./chat_details.vue?vue&type=script&lang=js"
export * from "./chat_details.vue?vue&type=script&lang=js"
import style0 from "./chat_details.vue?vue&type=style&index=0&id=22e86e2e&prod&scoped=true&lang=scss"
import style1 from "./chat_details.vue?vue&type=style&index=1&id=22e86e2e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22e86e2e",
  null
  
)

export default component.exports