<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {getCardCateTag, getGiftCardList} from "@/api/gift_card";
import {_throttle, getCustomer} from "@/utils/tool";
import {Toast} from "vant";
import {getUserInfo} from "@/utils/api";

export default {
  name: "gift_card_home",
  components: {navigationBar},
  data(){
    return{
      activeKey:0,
      leftLi:[],
      list:[],
      page:1,
      limit:10,

      hasMore: true,//是否可以继续滚动
      loadLock: true,//是否展示loding
    }
  },
  mounted() {
    this.getLftLi()
  },
  watch:{

  },
  methods:{
    getLftLi(){
      getCardCateTag().then(res=>{
        if(res.data.status == 200){
          this.leftLi = res.data.data
          this.leftLi.unshift({
            cate_tag_name: "全部",
            id: 0,
            status: 1,
          })
          this.leftLi.forEach((item)=>{
            item.page = 1
            item.limit = 10
          })
          this.getBodyLi()
        }
      })
    },
    getBodyLi(){
      getGiftCardList(this.leftLi[this.activeKey].page,this.leftLi[this.activeKey].limit,this.leftLi[this.activeKey].id).then(res=>{
        if(res.data.status == 200){
          let list = res.data.data.data
          list.forEach(item=>{
            if(item.type == 1){
              item.yuan = item.price.split('.')[0]
              item.mao = item.price.split('.')[1]
            }
          })
          if(res.data.data.count < this.leftLi[this.activeKey].limit){
            this.hasMore = false
          }
          this.loadLock = false
          this.list = list
        }
      })
    },
    containerSroll(event){
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      //   console.log(scrollStep, this.oldScrollTop);
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const {scrollHeight, clientHeight, scrollTop} = event.target;
        // console.log(scrollHeight - (scrollTop + clientHeight));
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          // console.log((scrollHeight - (scrollTop + clientHeight)) / clientHeight);
          if (this.hasMore) {
            this.loadLock = true
            this.qingqiu(this);
          }
        }
      }
    },
    qingqiu:_throttle(async function () {
      this.leftLi[this.activeKey].limit += 10
      this.getBodyLi()
    },200),
    onChange(){
      this.list = []
      this.getBodyLi()
    },
    godetails(item){
      console.log(item)
      this.$router.push({
        path:'/gift_card_details',
        query: {id:item.id}
      })
    },
    goOrder(){
      if (!this.$store.state.token) {
        Toast('请登录')
        return
      }
      this.$router.push({
        name: "order_list",
        params: {id: 0},
      })
    },
    my_card(){
      if (!this.$store.state.token) {
        Toast('请登录')
        return
      }
      this.$router.push({
        name: "my_card_list",
      })
    },

    kefu(){
      if (!this.$store.state.token) {
        Toast('您还未登录，请登录')
        return
      }
      getUserInfo().then(async res => {
        if(res.data.status == 200) {
          let userInfo = res.data.data
          let url = `/chat`;
          let obj = {

          }
          getCustomer(userInfo,url,obj,1)
        }else{
          Toast(res.data.msg)
        }
      })
    },
  },
}
</script>

<template>
<div class="container" id="gift_card_home" @scroll="containerSroll">
  <div class="area">
    <navigationBar :title="'礼品卡'" class="navigation"></navigationBar>
    <div class="inner">
        <van-sidebar v-model="activeKey"  @change="onChange">
          <van-sidebar-item v-for="(item,index) in leftLi" :key="index" :title="item.cate_tag_name" />
        </van-sidebar>
      <div class="rightBody">
        <div class="ul">
          <div class="li"  v-for="(item,index) in list" :key="index" @click="godetails(item)">
            <div class="card">
              <img class="card_img" :src="`${item.img}`" alt="">
              <div class="name">{{item.title}}</div>
<!--              {{item.type}} = 1礼品卡 2用户自定义金额礼品卡-->
              <div class="price" v-if="item.type == 1">
                  <div class="unit">￥</div>
                  <div class="yuan">{{item.yuan}}</div>.
                  <div class="mao">{{item.mao}}</div>
              </div>
              <div class="custom" v-if="item.type == 2">
                <div class="unit">￥自定义金额</div>
              </div>
            </div>
          </div>
          <div class="loader-box">
            <div id="loader" v-if="loadLock"></div>
            <div id="nothing" v-if="!loadLock&&!hasMore">没有更多了~</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="btn" @click="goOrder">
        <span class="iconfont icon-dingdan"></span><span class="text">订单</span>
      </div>
      <div class="btn center_btn" @click="my_card"><span class="text">我的礼品卡</span></div>
      <div class="btn" @click="kefu">
        <span class="iconfont icon-kefu2"></span><span class="text">客服</span>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.container{
  height: 100vh;
  min-height: 100vh;
}
.area{
  min-height: 100vh;
}
.inner{
  display: flex;
  width: 100%;
  overflow: hidden;
}
.van-sidebar{
  min-width: 80px;
}
.rightBody{
  flex: 1;
  width: 0;
  overflow: hidden;
  .ul{
    .li{
      width: 100%;
      .card{
        margin: 0px 16px 10px 10px;
        border-radius: 10px;
        background: #ffffff;
        box-shadow: 0px 0px 1.6px #66666640;
        text-align: left;
        .card_img{
          width: 100%;
          height: 167px;
          border-radius: 10px;
          object-fit: cover;
        }
        .price{
          padding: 10px 14px;
          color: #FD2A39;
          display: flex;
          align-items: baseline;
          font-weight: 600;
          line-height: 24px;
          font-size: 20px;
          .unit,.mao{
            font-size: 14px;
          }
        }
        .name{
          color: #1b1b1b;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          white-space: nowrap;
          overflow: hidden;
          padding: 0 14px;
          text-overflow: ellipsis;
        }
        .custom{
          font-size: 14px;
          padding: 10px 14px;
          color: #FD2A39;


        }
      }
    }
  }
}
.bottom{
  border-radius: 38px;
  background: #fffffff5;
  box-shadow: 0px 0px 2px #66666640;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 85%;
  padding: 10px;
  display: flex;
  .btn{
    flex: 1;
    .iconfont{
      font-size: 22px;
      margin-right: 4px;
      vertical-align: middle;
      color: #868789;
    }
    .text{
      color: #868789;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .center_btn{
    .text{
      border-left: 1px solid #d9d9d9;;
      border-right: 1px solid #d9d9d9;;
      width: 100%;
      display: inline-block;
    }
  }
}
</style>
<style>
#gift_card_home .van-sidebar-item--select::before{
  height: 100%;
}
</style>