<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {Toast} from "vant";
import {getCompanyList,update_company_acquiesce} from "@/api/recruitment"

export default {
  name: "corporation_manage",
  components: {navigationBar},
  data() {
    return {
      disable: false,
      list:[],
      result:[],
      checkedAll:false,
      choosetype:false,
      chooseFirm:{},
    }
  },
  methods: {
    edit() {
      this.disable = !this.disable
    },
    allclick(){
      if(this.checkedAll){
        this.result = this.list.map(item=>{return item.id})
      }else{
        this.result = []
      }
    },
    getList() {
      getCompanyList().then(res => {
        if (res.data.status == 200) {
          let list = res.data.data.data
          this.list = list
        } else {
          Toast(res.data.msg)
        }
      })
    },
    beforeclose(){

    },
    checkboxClick(){
      if(!this.disable){
        this.$refs.checkboxGroup.toggleAll()
      }
    },
    closeCollect(){
      if(this.result.length<=0){
        Toast('请选择')
        return
      }



    },

    checkedit(item){
      if(this.disable)return
      this.$router.push({
        path:'invite_Company_certification',
        query:{item:JSON.stringify(item)}
      })

    },
    addE(){
      this.$router.push({
        path:'invite_Company_certification',
      })
    },
    chooseFirmFun(item){
      if(this.choosetype){
        if(item.id == this.chooseFirm.id)return
        if(item.audit_state!=1){
          Toast('该企业未审核')
          return;
        }
        if(item.status == 2){
          Toast('该企业已禁用')
          return;
        }
        this.chooseFirm = item
        update_company_acquiesce(item.id)
        this.$router.go(-1)
      }
    },
  },
  mounted() {
    if(this.$route.query.type){
      this.choosetype = true
      this.chooseFirm = JSON.parse(this.$route.query.item)
    }
    this.getList()
  },
  watch:{
    disable(n){
      if(n){
        this.$refs.swipeCell.forEach((item)=>{
          item.open('left')
        })
      }else{
        this.$refs.swipeCell.forEach((item)=>{
          item.close('left')
        })
      }
    },
    result:{
      handler(n){
        if(n.length >= this.list.map(item=>{return item.id}).length){
          this.checkedAll = true
        }else{
          this.checkedAll = false
        }
      },
      deep:true
    }
  },

}
</script>

<template>
  <div class="container corporation_manage ">
    <div class="area">
      <navigationBar :title="'企业管理'" class="navigationBar">
        <template #search v-if="!choosetype">
          <div class="edit" @click="edit">{{ disable ? '取消' : '管理' }}</div>
        </template>
      </navigationBar>
      <div class="ul">
        <div class="li" v-for="(item,index) in list" :key="index" @click="chooseFirmFun(item)">
          <van-checkbox-group v-model="result" ref="checkboxGroup"  v-if="item">
            <van-swipe-cell class="van-swipe-cell" ref="swipeCell"  :before-close="beforeclose" disabled  :stop-propagation="true">
              <template #left>
                <van-checkbox :name="item.id" @click="checkboxClick" checked-color="#FC223B">
                </van-checkbox>
              </template>
              <div class="card" >
                <div class="left">
                  <div class="title">
                    <div class="leftText">{{ item.name }}</div>
                  </div>
                  <div class="name" >
                    <div class="is_attestation" v-if="item.audit_state == 1">已认证</div>
                  </div>
                </div>
                <div class="address" @click="checkedit(item)" v-if="item.audit_state == 1&&!choosetype">
                  <span class="iconfont icon-changyongyu-xiugai"></span>
                </div>
                <div class="nameText" v-if="item.audit_state == 0&&!choosetype">审核中</div>
                <div class="radioBox"  v-if="choosetype" :class="chooseFirm.id==item.id?'radio1':'radio2'">
                  <span class="iconfont icon-duigou" v-if="chooseFirm.id==item.id"></span>
                </div>
              </div>
            </van-swipe-cell>
          </van-checkbox-group>
        </div>
        <div class="addE" v-if="!disable" @click="addE">
          <span class="iconfont icon-fabu"></span>
          新增企业认证
        </div>
      </div>
      <div class="bootomBox" v-if="disable">
        <div class="inne">
          <div class="left">
            <van-checkbox  v-model="checkedAll" checked-color="#ee0a24" @click="allclick">全选</van-checkbox>
          </div>
          <div class="rightBtn" @click="closeCollect">
            删除企业
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
.radioBox{
  width: 16px;
  height: 16px;
  border: 1px solid #666666;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .iconfont{
    font-size: 11px!important;
    color: #fff !important;
  }
}
.radio1{
  background: #FC223B;
  border: 1px solid #FC223B;
  color: #fff;
}
.area{
  height: 100vh;
  background: #f4f6fa;
}
.bootomBox{
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  .inne{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .rightBtn{
      width: fit-content;
      height: fit-content;
      display: inline-flex;
      place-content: center;
      place-items: center;
      gap: 10px;
      padding: 11px 20px;
      border-radius: 34px;
      background: #fc223b;
      color: #ffffff;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
.navigationBar {
  position: relative;
  background: #fff;
  .edit {
    position: absolute;
    right: 20px;
  }
}
.card{
  display: flex;
  margin: 10px 16px;
  background: #ffffff;
  padding: 10px;
  justify-content: space-between;
  border-radius: 10px;
  align-items: center;
  .left{
    display: flex;
    align-items: center;
    .leftText{
      color: #1a1a1a;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
    .name{
      display: flex;
      align-items: center;
      margin-left: 5px;
      .is_attestation{
        display: inline-flex;
        place-content: center;
        place-items: center;
        gap: 10px;
        flex-shrink: 0;
        padding: 2px 6px;
        border-radius: 5px;
        border: 1px solid #0393f5;
        color: #0393f5;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
  .iconfont{
    font-size: 24px;
    color: #666666;
  }
  .nameText{
    color: #1a1a1a;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }
}
.addE{
  border-radius: 28px;
  background: #ffffff;
  box-shadow: 0px 2px 4px #b2c5e466;
  display: inline-block;
  padding: 12px 16px;
  margin-top: 20px;
  color: #0393f5;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  .iconfont{
    margin-right: 4px;
    line-height: 20px;
  }
}
</style>
<style>
.corporation_manage .van-swipe-cell__left{
  display: flex;
  align-items: center;
  padding-left: 10px;
}
</style>