<template>
  <div class="bottomFun">
    <!-- 用户底部 -->
    <div v-if="identity==1" class="inners">
      <div class="left" v-if="chooseIndex != 3">
        <a href="tel:13933837143">
          <div class="fun" @click="kefu">
            <span class="iconfont icon-kefu2"></span>
            客服
          </div>
        </a>
        <div class="fun" @click="order(1)">
          <span class="iconfont icon-gongju"></span>
          正在快修
          <van-icon class="icon" :badge="zhengzaiNum" v-if="zhengzaiNum>0"/>
        </div>
        <div class="fun" @click="order(2)">
          <span class="iconfont icon-Frame18"></span>
          快修记录
        </div>
      </div>
      <div class="left" v-if="chooseIndex == 3">
        <van-checkbox
            v-model="checked"
            checked-color="#FC223B"
            class="checkBox"
            icon-size="17"
        >
          <template #default>
            <span class="text">匿名（延迟7日后发布评论）</span>
          </template>
        </van-checkbox>
      </div>
      <div class="rightBnt" :class="chooseIndex == 3 ? 'rightBntTwo' : ''">


        <div class="yuyueBox" v-if="chooseIndex == 0&&(!obj.pay_status||obj.pay_status)" @click="userSubscribe">
          <div class="left">￥<span class="yuan">{{ type ? 0 : 1 }}</span></div>
          <div class="right">{{ (obj.pay_status==1&&obj.status==1)?'去支付':
              obj.status==3?'已取消':'预约勘察' }}</div>
        </div>



        <div class="yuyueBox" v-if="chooseIndex == 1&&identity == 1">
          <div class="left">￥<span class="yuan">{{ obj.goods_amount }}</span></div>
          <div class="right" v-if="obj.pay_status==6" @click="userPayTwo">订单支付</div>
          <div class="right" v-else>已支付</div>
        </div>


        <div class="yuyueBox" v-if="chooseIndex == 2">
          <div class="left text" @click="userQuestion">整改提交</div>
          <div class="right" @click="yanshouBtn">验收合格</div>
        </div>
        <div class="suibmit" v-if="chooseIndex == 3" @click="submitEvaluate">提交评价</div>







      </div>
    </div>
    <div v-if="identity==2" class="inners kanchaBot">
      <div class="left">
        <div class="fun" @click="home">
          <span class="iconfont icon-shouye1"></span>
          首页
        </div>
        <a :href="'tel:'+13213074005" class="telA">
          <div class="kefu" @click="kefu">
            <span class="iconfont icon-Frame14"></span>
            客服
          </div>
        </a>
      </div>
      <div class="right">
        <div class="btn" :class="obj.authority_user_status==1?'kanchaRightBox':''">
          <div class="btnBox" v-if="obj.authority_user_status!=1">
            <div class="priceBox ">
              <span class="symbol">￥</span><span class="price">{{ allPrice }}</span>
            </div>
            <div class="button" @click='settlement'>{{ payText }}</div>
          </div>
        </div>
        <div class="btnBox Door" v-if="obj.authority_user_status==1" @click="kanchaDoor">
          上门打卡
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {get_no_order} from "@/utils/api";
import {Toast} from "vant";

export default {
  components: {},
  props: {
    chooseIndex: {
      type: [Number],
    },
    identity: {
      type: [Number, String],
      default: 1,
    },
    obj: {
      type: [String, Number, Object],
    },
    obj1: {
      type: [String, Number, Object],
    },
    maintenance_price: {
      type: [String, Number, Object],
    },
  },
  data() {
    return {
      checked: true,
      zhengzaiNum: 0,
      type:'',
    };
  },
  watch: {},
  computed: {
    allPrice() {
      return (Number(this.$parent.maintenance_price)).toFixed(2)
    },
    payText() {
      let str = ''
      if (this.obj) {
        if (this.identity == 1) {
          if (this.obj.pay_status == 1 || this.obj.pay_status == 6) {
            str = '订单支付'
          } else if (this.obj.pay_status == 2) {
            str = '支付成功'
          }
          if (this.obj.items.status == 2) {
            str = '已支付'
          }
          if (this.obj.items.status == 1) {
            str = '提交订单'
            if (this.obj.payment_time) {
              str = '已支付'
            }
          }
          if (this.obj.confirm == 2) {
            str = '已完成'
          }
        }
        if (this.identity == 2) {
          if (this.obj.items.status == 2) {
            str = '修改订单'
          }
          if (this.obj.items.status == 1) {
            str = '提交订单'
            if (this.obj.payment_time) {
              str = '已支付'
            }
          }
          if (this.obj.confirm == 2) {
            str = '已完成'
          }
        }

      } else {
        str = '上门打卡'
      }
      return str
    },
  },
  methods: {
    settlement() {

      this.$emit('submitOrder',this.payText);
      //订单支付
    },
    kanchaDoor() {
      this.$emit('employeeDoor');
    },
    userSubscribe() {
      this.$emit('userSubscribe');
    },
    userPayTwo(){
      this.$emit('userPayTwo');
    },
    userQuestion(){
      this.$emit('userQuestion');
    },
    yanshouBtn(){
      this.$emit('yanshouBtn');
    },
    submitEvaluate(){
      this.$emit('submitEvaluate');
    },
    order(e) {
      let obj = {
        title: "",
        type: e,
      };
      if (e == 1) {
        obj.title = "正在快修";
      }
      if (e == 2) {
        obj.title = "快修记录";
      }
      this.$router.push({
        path: "/service_order",
        query: {title:obj.title,type:e},
      });
    },
    kefu() {
    },
    home() {
      this.$router.go(-1)
    },
    getNum() {
      get_no_order(this.$store.state.token, 1, 10).then((res) => {
        if (res.data.status == true) {
          console.log(res.data.data.count)
          this.zhengzaiNum = res.data.data.count
          this.zhengzaiNum = this.zhengzaiNum > 99 ? '99+' : this.zhengzaiNum
        } else {
          Toast(res.data.msg)
        }

      }).catch(err => {
        console.log(err)
      })
    },
  },
  created() {
  },
  mounted() {
    // this.getNum()
    if(this.$route.query.type){
      this.type = this.$route.query.type
    }
  },
};
</script>
<style lang="scss" scoped>
.bottomFun {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  padding: 8px 0;
  z-index: 20;
  text-align: left;

  .inners {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .left {
    display: flex;
    margin-left: 16px;

    .telA {
      .kefu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #666666;
        margin: 0 5px;

        .iconfont {
          font-size: 32px;
          color: #286CFB;
        }
      }
    }

    .fun {
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 24px;
      text-align: center;
      margin-right: 5%;
      white-space: nowrap;
      width: 50px;
      position: relative;

      .icon {
        position: absolute;
        right: 0;
        top: 0;
      }

      .iconfont {
        font-size: 32px;
        color: #286CFB;
      }
    }

    .fun {
      margin: 0 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #666666;

      .iconfont {
        font-size: 24px;
        color: #286CFB;
      }

    }

    .fun:nth-last-of-type(1) {
      margin-right: 0;
    }
  }

  .iconfont {
    //color: #FC223B;
    color: #333333;
    font-size: 24px;
  }
}

.rightBnt {
  margin-right: 16px;
  width: 60%;
}

.rightBntTwo {
  width: 30%;

  .suibmit {
    background: #FC223B;
    border-radius: 32px 32px 32px 32px;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 24px;
    padding: 10px 0;
    text-align: center;
  }
}

.yuyueBox {
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  justify-content: flex-end;

  .left {
    color: #fd2a39;
    font-size: 14px;
    background: #ffffff;
    box-shadow: inset 0px 0px 4px 0px rgba(249, 100, 34, 0.5);
    border-radius: 32px 0px 0px 32px;
    padding: 10px 0;
    width: 50%;
    text-align: center;
    justify-content: center;
    max-width: 100px;
    min-width: 70px;

    .yuan {
      display: inline;
      font-size: 20px;
    }
  }

  .right {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 24px;
    background: #FC223B;
    border-radius: 0px 32px 32px 0px;
    padding: 10px 0;
    width: 50%;
    text-align: center;
    max-width: 100px;
    min-width: 70px;
  }

  .text {
    color: #FC223B;
  }
}

.checkBox {
  .text {
    font-weight: 400;
    font-size: 13px;
    color: #666666;
    line-height: 24px;
    text-align: center;
  }
}

.kanchaBot {

  .right {
    margin-right: 16px;
    width: 50%;
    .Door {
      box-shadow: inset 0px 0px 8px 0px #5199E1;
      justify-content: center;
      color: #4e76fa;
      font-size: 14px;
      padding: 10px 40px;
      border-radius: 50px;
      text-align: center;
    }

    .btn{
      //width: 50%;


      .btnBox{
        display: flex;
        border-radius: 40px;
        overflow: hidden;

        .priceBox{
          width: 50%;
          background: #FFFDFC;
          padding: 8px;
          text-align: center;
          border-radius: 32px 0px 0px 32px;
          box-shadow: inset 0px 0px 4px 0px #5199E1;
          overflow: scroll;
          .symbol  {
            color: #F74440;
          }
          .price {
            font-size: 18px;
            font-weight: 800;
            color: #F74440;
          }

        }
        .button{
          width: 50%;
          background: #4e76fa;
          color: white;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>