<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";

export default {
  name: "aboutUs",
  components: {NavigationBar}
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'关于我们'" class="navigationBar"></navigationBar>

      <div class="body">
        <div class="logoImg">
          <img src="" alt="" class="logo">
        </div>
        <div class="appname">
          尚百街
        </div>
        <div class="versions">
          尚百街版本 1.0
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container{
  height: 100vh;
  .area{
    height: 100vh;
  }
}
.body{

  .logoImg{
    width: 100%;
    margin: 32px 0;
    .logo{
      width: 60px;
      height: 60px;
      background: red;
      border-radius: 6px;
    }
  }
  .appname{
    color: #1b1b1b;
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
  }
  .versions{
    color: #1b1b1b;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-top: 8px;
  }
}
</style>