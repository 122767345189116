<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
// import {vip_order, vipOrder} from "@/utils/api";
import {Toast} from "vant";
import loding from "@/components/common/loding/loding.vue";
import {_throttle, Sub} from "@/utils/tool";
import {cardIndex, overdueTime} from "@/api/vip"
import {card_create} from "@/utils/api";

export default {
  name: "vip_page",
  components: {
    navigationBar,
    loding
  },
  data() {
    return {
      economize: this.$store.state.is_vip.cut_amount?this.$store.state.is_vip.cut_amount:0,
      privilegelI: [
        {
          title: '免起送费',
          text: '无需凑价免费送',
          type: '商品类覆盖',
          icon: '/img/0c5bf7969b70e2c4a5b129eec033321.png',
        },
        {
          title: '专享减免',
          text: '无门槛立享减免',
          type: '商品类覆盖',
          icon: '/img/544ea2b7659cf57550d54711ef9e684.png',
        },
      ],
      tabsList: [
        {name: '免费配送',},
        {name: '商品优惠',},
        {name: '免起送费',},
      ],
      is_vip: this.$store.state.is_vip?this.$store.state.is_vip.is_vip:false,
      btnText: '立即开通',
      priceList: [{}, {}, {}],
      priceIndex: 0,

      vip_type: 5,
      loding: false,

      vip:{},

      memberType: [],
      userInfo: {},
      memberRights: [],
      memberExplain: [],
      memberCoupons: [],
      isGetFree: null,
      svip:{},
      memberEndTime: '',
      checked:false,
      tabs:1,
      ulWidth:0,
      pay_type:'weixin', //微信：weixin，余额：yue，线下：offile/*余额支付yue;积分支付'integral';线下支付'offline';支付宝'alipay';现金支付'cash';*/

    }
  },

  computed: {
    activeVip() {
      return this.memberType[this.priceIndex]
    },
    vipTime() {
      let data = new Date(this.$store.state.vipTime * 1000)
      let y = data.getFullYear()
      let m = data.getMonth() + 1
      let d = data.getDate()
      m = m < 10 ? '0' + m : m
      d = d < 10 ? '0' + d : d
      return this.$store.state.vipTime ? y + '-' + m + '-' + d : 0
    },
    daysNums(){
      if(this.$store.state.is_vip.is_vip){
        let nums = 0
        if((new Date(this.vipTime).getTime() - new Date().getTime())>0){
         return  Math.round(((new Date(this.vipTime).getTime() - new Date().getTime())/1000/60/60/24))
        }else{
          nums = 0
        }

        return nums
      }else{
        return 0
      }
    },
  },
  watch: {
    memberType(n){
      console.log(n)
      n.forEach(item=>{
        item.price_nums = Number(item.price)
        item.pre_price_nums = Number(item.pre_price)
      })
    },
  },
  methods: {
    Sub,
    // getExpirationDate(level) {
    //   const now = new Date();
    //   const daysToAdd = {
    //     '7': '一周',
    //     '30': '月',
    //     '90': '季',
    //     '360': '年',
    //   }[level];
    //   console.log(daysToAdd)
    //   now.setDate(now.getDate() + daysToAdd);
    //   const year = now.getFullYear();
    //   const month = now.toLocaleDateString('zh-CN', { month: 'short' }); // 月份需要根据实际需求进行格式化
    //   const season = Math.floor(month / 3) + 1; // 假设一季度是3个月，根据实际调整
    //   return `${year}年${month} ${season}季`;
    // },
    vip_order() {
      cardIndex().then(res => {
        if (res.data.status == 200) {
          this.vip = res.data.data
          const {
            is_get_free,
            member_coupons,
            member_explain,
            member_rights,
            member_type
          } = res.data.data;
          this.isGetFree = is_get_free;
          this.userInfo = is_get_free.user_info;
          this.memberRights = member_rights;
          this.memberType = member_type;
          this.memberCoupons = member_coupons;
          this.memberExplain = member_explain;
          if (is_get_free.is_record) {
            this.memberType = this.memberType.filter(item => item.type !== 'free');
          }
          this.totalPrice = parseFloat(this.memberType[0].pre_price);
          this.type = this.memberType[0].type;
          this.totalPrice = parseFloat(this.memberType[0].pre_price);
          this.svip = this.memberType[0];
          overdueTime(this.svip.type,this.svip.vip_day).then(res=>{
            this.memberEndTime = res.data.data;
          })


        } else {
          Toast(res.data.msg)
        }

      }).catch(err => {
        console.log(err)
      })
    },
    submit() {
      if(!this.checked) {
        Toast('请阅读并同意会员协议')
        return
      }
      this.loding = true
      this.create()
    },
    create: _throttle(function () {
      this.loding = false
      //type 0：免费领取会员 ，1：购买会员 ，2：卡密领取会员 ，3：线下付款
      let from = 'weixin'
      // client_type:0,//客户端类型 1:PC,2:安卓,3:10S,4:H5,5:小程序,6:微信内置H5,7:IPAD
      if(this.$store.state.client_type == 0 || this.$store.state.client_type == 1 || this.$store.state.client_type == 4){
        from = 'h5'
      }
      if(this.$store.state.client_type == 2||this.$store.state.client_type == 3||this.$store.state.client_type == 6){
        from = 'weixin'
      }
      if(this.$store.state.client_type == 5){
        from = 'routine'
      }


      let quitUrl = ''
      card_create(this.pay_type,1,this.tabs==2?'':from,this.activeVip.id,this.tabs==2?this.activeVip.pre_price:this.activeVip.integral_price,quitUrl,this.tabs==2?1:'').then(res => {
        if(res.data.status == 200){
          console.log(res.data.data)
          let obj = JSON.parse(JSON.stringify(this.activeVip))
          obj.result = res.data.data
          console.log(this.tabs)
          if(this.tabs==1){
            this.$router.push({
              path:"/pay",
              query:{vip_data:JSON.stringify(obj),backStep:2,type:""}
            })
          }
          if(this.tabs == 2){
            Toast(res.data.msg)
            // if(res.data.status == 200){
            //   Toast(res.data.msg)
            //   //
            // }else{
            //   //
            // }
          }
        }
        if (res.data.status == 400){
          Toast(res.data.msg)
        }
      })




      // vipOrder(this.$store.state.token, this.vip_type, this.activeVip.id, 1, this.activeVip.vip_type).then(res => {
      //   if (res.data.status == true) {
      //     console.log(res.data.data)
      //     this.$router.push({
      //       name: "pay",
      //       params: {data: res.data.data, backStep: 2, type: ""}
      //     })
      //   } else {
      //     Toast(res.data.msg)
      //   }
      // }).catch(err => {
      //   console.log(err)
      // })
    }, 300),
    choose_Vip(index) {
      this.priceIndex = index
      // console.log(this.$refs)
      if (index >= 1) {
        this.$refs.innerli[index - 1].scrollIntoView({block: 'end', behavior: 'smooth', inline: "start"})
      } else {
        // this.$refs.innerScroll.scrollLeft = 0
        this.$refs.innerli[0].scrollIntoView({block: 'end', behavior: 'smooth', inline: "start"})
      }
    },
    goPoint(){
      console.log('积分商城')
    },

    chooseTabs(e){
      this.tabs = e
      if(e == 1){
        this.pay_type = 'weixin'
      }
      if(e == 2){
        this.pay_type = 'integral'
      }
    },
  },
  mounted() {
    this.vip_order()
    this.ulWidth = this.$refs.ul.offsetWidth;

  },
}
</script>

<template>
  <div class="container vippage">
    <div class="area">
      <navigationBar :title="'会员中心'" class="navigation"></navigationBar>
      <div class="body">
        <loding v-if="loding"></loding>
        <div class="top">
          <div class="left">
            <div class="userinfo">
              <div class="avatarBox"><img :src="$store.state.user.avatar" alt="" class="avatar">
              </div>
              <div class="info">
                <div class="nickname">
                  <span class="text">{{ $store.state.user.nickname }}</span>
                  <img class="vip" src="../../../../public/image/vip.png" alt="" v-if="$store.state.is_vip.is_vip">
                </div>
                <div class="isvip">
                  {{ is_vip ? `有效期至 ${vipTime}` : '暂未开通会员' }}
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="economize">
                <div class="nums">{{ $store.state.user.integral }}</div>
                <div class="text">我的积分</div>
              </div>
<!--              <div class="daysNums">-->
<!--                <div class="nums">{{ daysNums }}</div>-->
<!--                <div class="text">会员天数</div>-->
<!--              </div>-->
            </div>
          </div>
          <div class="right">
            <img src="../../../../public/image/vipBadge.png" alt="" class="vipBadge">
            <div class="btn">
              <span class="text" @click="goPoint">积分商城 <span class="iconfont icon-Frame-4"></span></span>
            </div>
          </div>
        </div>
        <div class="banner">
          <div class="head">
            <div class="text">分享规则</div>
            <div class="text">分享记录</div>
          </div>
          <div class="bannerImg" :style="`background-image: url('${$store.state.imageHost}/img/vip_swiper.png')`">
          </div>
        </div>
        <div class="inner">
          <div class="everyBox">
            <div class="topbox">
              <div class="oneline"><img :src="`${$store.state.imageHost}/img/vip_title_icon.png`" alt="" class="icon">
                <span class="text">开通百街会员</span></div>
              <div class="tabbar" ref="ul">
                  <div class="every" :class="tabs == 1?'chooseTabs':''" @click="chooseTabs(1)">购买开通</div>
                  <div class="every" :class="tabs == 2?'chooseTabs':''" @click="chooseTabs(2)">积分兑换</div>
                <div class="line" :style="'transform: translateX('+(((((ulWidth/5)/2).toFixed(0)*(tabs))+(((ulWidth/5)/2).toFixed(0)*tabs))-10)+'px) ;transition-duration: 0.3s;'"></div>
              </div>
            </div>
            <div class="priceList" ref="innerScroll">
              <div class="priceItem" ref="innerli" v-for="(item,index) in memberType" :key="index"
                   :class="priceIndex == index?'choosePrice':''" @click="choose_Vip(index)">
                <div class="economize" v-if="tabs == 1">立省 {{Sub(item.price, item.pre_price)}}元</div>
                <div class="outtitle">
                  <span class="title">{{item.title}}</span>
                  <span class="days">{{item.vip_day}}天</span>
                </div>
                <div class="priceBox">
                  <span class="price" v-if="tabs == 1">￥<span class="num">{{ item.pre_price_nums}}</span></span>
                  <span class="integral_price" v-if="tabs == 2"><span class="num">{{ item.integral_price}}</span><span class="text">积分</span></span>
                  <span class="ot_price" v-if="tabs == 1">￥{{ item.price_nums }}</span>
                </div>
                <div class="bottom" v-if="tabs == 1">
                  <span class="pointNums">{{item.integral_price}}</span>积分兑换
                </div>
              </div>
            </div>
            <div class="bottomBox">
              <div class="inn">
                <div class="btn" @click="submit">
                  {{ is_vip ? '立即续费' : `立即开通` }}
                </div>
                <div class="agreement"><van-checkbox  v-model="checked" checked-color="#ee0a24" icon-size="14px">开通代表已阅读</van-checkbox><a class="agree" href="">《会员服务协议》</a></div>
              </div>
            </div>
            <div class="topbox">
              <div class="left">
                <div class="title">会员专属福利</div>
              </div>
            </div>
            <div class="privilege">
              <div class="every" v-for="(item,index) in privilegelI" :key="index">
                <div class="left">
                  <div class="title">{{ item.title }}</div>
                  <div class="text">{{ item.text }}</div>
                  <div class="type">{{ item.type }}</div>
                </div>
                <div class="right">
                  <img class="icon" :src="$store.state.imageHost+item.icon" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.banner{
  background-color: #fff;
  padding: 10px;
  .head{
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
    .text{
      margin-left: 10px;
      color: #1b1b1b;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      text-decoration-line: underline;
    }
  }
  .bannerImg{
    width: 100%;
    margin-top: -30px;
    height: 107px;
    background-size: 100% 100%;
  }

}
.container, .area {
  height: 100vh;
  background: linear-gradient(108deg, #FFF5E1 0%, #FCE9BE 49%, #FCE9BE 100%);
  padding-bottom: 0;
}

.area {
  position: relative;
  min-height: 100vh;
}

.navigation {
  background: transparent;
  position: relative;
  z-index: 2;
}

.body {
  position: relative;
  z-index: 1;

  .top {
    background: linear-gradient(90deg, #FEE1A9 0%, #FFD58E 100%);
    display: flex;
    border-radius: 16px;
    margin: 10px 16px;
    justify-content: space-between;
    padding: 20px 12px 24px 12px;

    .left, .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .left {
      width: 68%;

      .userinfo {
        display: flex;

        .avatarBox {
          width: 46px;
          height: 46px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;

          .avatar {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .info {
          text-align: left;
          width: 74%;

          .nickname {
            .text {
              display: inline-block;
              vertical-align: middle;
              max-width: 69%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-weight: 500;
              font-size: 15px;
              color: #6E451E;
              line-height: 20px;
              text-align: left;
            }

            .vip {
              width: 44px;
              object-fit: cover;
              vertical-align: middle;
              margin-left: 7px;
            }

          }

          .isvip {
            font-weight: 300;
            font-size: 11px;
            color: #68421E;
            line-height: 20px;
            text-align: left;
          }
        }

      }

      .bottom {
        display: flex;

        .economize, .daysNums {
          margin-left: 16px;
          max-width: 45%;

          .nums {
            width: 100%;
            overflow: auto;
            white-space: nowrap;
            font-weight: 500;
            font-size: 18px;
            color: #684623;
            line-height: 20px;
          }
          .text {
            font-weight: 400;
            font-size: 12px;
            color: #A58343;
            line-height: 20px;
            text-align: left;
          }
        }
        .economize::-webkit-scrollbar, .daysNums::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .right {

      .vipBadge {
        width: 108px;
        height: 108px;
      }
      .btn {
        border-radius: 6px;
        padding: 5px;
        background: #6e451e;

        .text {
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          text-align: left;
          //background-image: linear-gradient(to top, #fec163,#de4313); /* 线性渐变背景，方向向上 */
          -webkit-background-clip: text; /* 背景被裁剪成文字的前景色 */
          -webkit-text-fill-color: transparent; /* 文字填充颜色变透明*/
          background-image: linear-gradient(358.5398342341906deg, #F6DDBE 0%, #FFEED2 100%);
          .iconfont{
            vertical-align: middle;
            font-size: 24px;
            margin-left: -5px;
            margin-right: -10px;
          }
        }

      }
    }
  }
  .inner {
    background: #ffffff;
    //background: red;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    min-height: 70vh;

    .everyBox {
      .topbox {
        margin: 20px 16px 0 16px;
        text-align: left;
        .oneline{
          display: flex;
          align-items: center;
          .icon{
            font-size: 24px;

          }
        }
        .tabbar{
          display: flex;
          position: relative;
          margin-bottom: 10px;
          .every{
            width: 20%;
            color: #1b1b1b;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            text-align: left;
          }
          .chooseTabs{
            color: #1b1b1b;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
          }
          .line{
            position: absolute;
            height: 2px;
            width: 15%;
            background: #1b1b1b;
            left: -17%;
            bottom: -25%;
          }
        }

        .left {
          .title {
            font-weight: 500;
            font-size: 17px;
            color: #1B1B1B;
            line-height: 24px;
            text-align: left;
          }

          .details {
            font-weight: 400;
            font-size: 13px;
            color: #9B9B9B;
            line-height: 24px;
          }
        }

        .rightBtn {
          background: #3B3013;
          border-radius: 29px;
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 24px;
          padding: 4px 10px;
          //transform: rotate(90deg);
          display: flex;
          align-items: center;

          .iconfont {
            display: block;
            color: #fff;
            transform: rotate(90deg);
          }
        }
      }

      .priceList {
        display: flex;
        margin-left: 16px;
        overflow-x: scroll;
        padding-top: 16px;

        .priceItem {
          position: relative;
          border-radius: 10px 10px 10px 10px;
          margin-right: 10px;
          min-width: 128px;
          background: #f8f8f8;
          .economize{
            position: absolute;
            border-radius: 10px 10px 10px 0px;
            background: linear-gradient(90deg, #fea233 0%, #fec502 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-weight: 300;
            font-size: 12px;
            line-height: 17px;
            padding: 2px 5px;
            top: 0;
            left: 0;
            transform: translate(0,-50%);
          }
          .outtitle{
            margin:12px 12px 0;
            text-align: left;
            .title{
              color: #6e451e;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
            }
            .days{
              color: #b99d7f;
              font-weight: 400;
              font-size: 10px;
              line-height: 24px;
              margin-left: 5px;
            }
          }

          .topbox {
            margin: 0;

            .time {
              color: #666666;
              font-size: 16px;
            }
          }

          .original_price {
            font-weight: 400;
            font-size: 11px;
            color: #A2A5AC;
            line-height: 16px;
            text-align: left;
            font-style: normal;
            text-decoration-line: line-through;
          }

          .price {
            text-align: left;
            font-weight: bold;
            font-size: 20px;
            color: #666666;
            line-height: 24px;
            font-style: normal;
            margin-top: 18px;

            .num {
              font-size: 20px;
              color: #666666;
            }
          }
          .bottom{
            border-radius: 0px 0px 10px 10px;
            background: #262626;
            color: #ffffff;
            font-weight: 400;
            font-size: 10px;
            padding: 4px 0;
            .pointNums{
              color: #ffeb98;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
      .priceBox{
        text-align: left;
        margin: 10px;
        .price{
          display: inline-block;
        }
        .ot_price{
          margin-left: 6px;
          color: #b99d7f;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-decoration: line-through;
          //text-decoration-line: strikethrough;
        }
        .text{
          color: #7f7f7f;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
        }
        .integral_price{
          margin-top: 40px;
          color: #666666;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          display: inline-block;
          .num{
            margin-right: 3px;
            display: inline-block;
          }
        }
      }

      .priceList::-webkit-scrollbar {
        display: none;
      }

      .choosePrice {
        border: 2px solid #ff8d4a;
        background: linear-gradient(104.11deg, #fff0d2 0%, #ffe5df 116.52%)!important;
        .time {
          color: #B6771A !important;
        }

        .price {
          color: #B6771A !important;

          .num {
            color: #B6771A !important;
            font-size: 24px !important;
          }
        }
        .integral_price{
          color: #6E451E;
        }
      }
    }

    .privilege {
      display: flex;
      margin: 10px 0px 0 16px;
      white-space: nowrap;
      overflow-x: scroll;

      .every {
        //width: 50%;
        display: flex;
        align-items: center;
        background: #FCE8C7;
        box-shadow: inset 2px 2px 4px 0px rgba(255, 255, 255, 0.4);
        border-radius: 10px 10px 10px 10px;
        margin: 0 3px;
        padding: 10px;
      }

      .every:nth-last-of-type(1) {
        margin-right: 16px;
      }

      .left {
        margin-right: 14px;

        .title {
          font-weight: 500;
          font-size: 15px;
          color: #492F0A;
          line-height: 24px;
          text-align: left;
        }

        .text {
          font-weight: 400;
          font-size: 12px;
          color: #642C10;
          line-height: 18px;
          text-align: left;
        }

        .type {
          font-weight: 400;
          font-size: 10px;
          color: #BA9A7D;
          line-height: 15px;
          text-align: left;
        }
      }

      .right {
        .icon {
          width: 50px;
          height: 50px;
        }
      }
    }

    .privilege::-webkit-scrollbar {
      display: none;
    }
  }
}

.bottomBox {
  margin-top: 10px;
  .inn {
    margin: 0 16px;
  }

  .btn {
    background: linear-gradient(90deg, #FCDEB4 0%, #FDC18D 100%);
    box-shadow: inset 0px 4px 4px 0px rgba(255, 255, 255, 0.58);
    border-radius: 29px 29px 29px 29px;
    border: 1px solid #FFDCBE;
    color: #642C10;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 11px;
  }

  .agreement {
    font-weight: 400;
    font-size: 11px;
    color: #A2A5AC;
    line-height: 16px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .agree {
      color: #FC223B;
    }
  }
}

</style>
<style>
.vippage .van-tab {
  font-weight: 500;
  font-size: 17px;
  color: #999999;
  line-height: 24px;
}

.vippage .van-tab--active {
  color: #3B3013;
}

.vippage .van-tabs__nav--line .van-tabs__line {
  background: #3B3013;
  width: 22px;
  height: 5px;
}
</style>