<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import Compressor from "compressorjs";
import {
  upload_file,
  upload_files
} from "@/utils/api";
import MaskDiv from "@/components/common/invite_job/online/mask/mask.vue";
import ExpectJob from "@/components/common/invite_job/expect_job/expect_job.vue";
import {Toast} from "vant";
import {getParentIdNew} from "@/utils/tool";

import {up_seeker_status, getIndustryList, getJobCateList, getSeekerDetail, updateSeeker,createSeeker} from "@/api/recruitment"

export default {
  name: "online",
  components: {ExpectJob, MaskDiv, navigationBar},
  data() {
    return {
      Resume: {
        name: '',
        age: '',
        avatar: '',
        seeking: '',//求职状态
        seeking_id: '',//求职状态ID
        preponderance: '',//个人优势
        work_experience: '',//工作经历
        project_experience: '',//项目经历
        education_experience: '',//教育经历
        Working_years: '',//工作年限
        Job_hunting_type: '',//求职类型
        Working_city: '',//工作城市
        Desired_position: '',//期望岗位
        Expected_salary: '',//期望薪资
      },
      avatarFiles: [],
      avatarLimit: 1,
      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["png", "jpg", "jpeg"],

      is_published: false,
      choosePositionObj: {},
      rules: {
        name: [{required: true, message: '请输入姓名', trigger: 'blur'},],
        age: [{required: true, message: '请输入年龄', trigger: 'blur'},],
        avatar: [{required: true, message: '请上传头像', trigger: 'blur'},],
        seeking: [{required: true, message: '请填写求职状态', trigger: 'blur'},],
        preponderance: [{required: true, message: '请填写个人优势', trigger: 'blur'},],
        Working_years: [{required: true, message: '请选择工作年限', trigger: 'blur'},],
        Job_hunting_type: [{required: true, message: '请选择求职类型', trigger: 'blur'},],
        Working_city: [{required: true, message: '请选择工作城市', trigger: 'blur'},],
        Desired_position: [{required: true, message: '请选择期望岗位', trigger: 'blur'},],
        Expected_salary: [{required: true, message: '请选择期望薪资', trigger: 'blur'},],
      },

      maskType: '',
      chooseValue: '',

      expect_job_show: false,

      disable:false,

      pop_show: false,
      pop_type: "",
      // 职状态 1在职不考虑 2离职 3月内 4考虑
      seekingList: [
        // {text: '在职·暂不考虑', id: 1,},
        // {text: '离职·随时到岗', id: 2,},
        // {text: '在职·月内到岗', id: 3,},
        // {text: '在职·考虑机会', id: 4,},
      ],
      Working_yearsList: [
        // {text: '1年经验以内',},
        // {text: '1年经验',},
        // {text: '2年经验',},
        // {text: '3年经验',},
        // {text: '4年经验',},
        // {text: '5年经验',},
        // {text: '6年经验',},
        // {text: '7年经验',},
        // {text: '8年经验',},
        // {text: '9年经验',},
        // {text: '10年经验',},
        // {text: '10年经验以上',},
      ],
      backgroundListPro:[],
      // 职位类型（如1全职、2兼职等）
      Job_hunting_typeList: [
        {text: '全职',type:1},
        {text: '兼职',type:2},
      ],
      moneyList: [
        {values: []},
        {values: []},
      ],

      educationFrom: {},

      address_li: this.$store.state.allAddress,
    }
  },
  methods: {
    onsubmit(formName) {
      console.log(this.choosePositionObj)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('yes')
          if(this.Resume.id){
            this.updateForm()
          }else {
            this.submitForm()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)
        }


      })
    },
    async beforeUpload(files) {
      console.log(files)
      let file = await this.yasuo(files)
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        if (files.type.startsWith('image/')) {   //图片
          //计算文件的大小
          const isLt5M = file.size / 1024 / 1024 < 1; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            return false;
          }
        } else if (files.type.startsWith('video/')) {//视频
          const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
    uploadFile(item) {
      // console.log(JSON.stringify(item))
      this.$message("文件上传中........");
      if (item.file.type.startsWith('image/')) {
        upload_file(item.file).then(res => {
          if (res.data.status == 200) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 'avatar') {
              this.Resume.avatar = res.data.data.url
            }
            this.handleClose(0)
          }
        })
      } else if (item.file.type.startsWith('video/')) {
        upload_files(item.file).then(res => {
          if (res.data.status == 200) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 11) {
              this.kanchaUpload.push({tempFilePath: res.data.data.url, fileType: 'video'})
            }
            if (item.filename == 22) {
              this.DoorUpload.push({tempFilePath: res.data.data.url, fileType: 'video'})
            }
            console.log(res.data.data)
            // this.userUpload
            // this.userUpload.push({tempFilePath:res.data.data.url,fileType:'video'})
          }
        })
      }
    },
    handleSuccess() {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.handleClose();
    },
    fileChange(file, infomationFiles) {
      this.avatarFiles = infomationFiles;
    },
    async handleRemove() {
    },
    handleClose(i) {
      this.avatarFiles.splice(i, 1); //删除上传的文件
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },
    closeMask() {
      this.maskType = ''
    },
    openMask(e) {
      if(this.disable == true)return
      this.maskType = e
      this.chooseValue = ''
      this.chooseValue = this.Resume[e]
    },
    submitMask(e) {
      if (this.maskType == 'preponderance') {
        this.Resume.preponderance = e
      }
      if (this.maskType == 'work_experience') {
        this.Resume.work_experience = e
      }
      if (this.maskType == 'project_experience') {
        this.Resume.project_experience = e
      }
      if (this.maskType == 'education_experience') {
        let str = ''
        for (let key in e) {
          if (e[key]&&key!='education_id') {
            str += (String(e[key]) + ' · ')
          }
        }
        this.Resume.education_experience = str.slice(0, str.length - 2)
        this.educationFrom = e
        console.log(this.educationFrom)
      }

      this.closeMask()
    },
    openPop(e) {
      if(this.disable == true)return
      this.pop_type = e
      this.pop_show = true
    },
    closePop() {
      this.pop_type = ''
      this.pop_show = false
    },
    save() {
      if (this.pop_type == 'seeking') {
        this.Resume.seeking = this.$refs.seeking.getValues()[0].text
        this.Resume.seeking_id = this.$refs.seeking.getValues()[0].id_d
      }
      if (this.pop_type == 'Working_years') {
        console.log(this.$refs.Working_years.getValues()[0])
        this.Resume.Working_years = this.$refs.Working_years.getValues()[0].text
        this.Resume.Working_years_id = this.$refs.Working_years.getValues()[0].id
      }
      if (this.pop_type == 'Job_hunting_type') {
        this.Resume.Job_hunting_type = this.$refs.Job_hunting_type.getValues()[0].text
        this.Resume.Job_hunting_type_id = this.$refs.Job_hunting_type.getValues()[0].type
      }
      if (this.pop_type == 'Working_city') {
        this.chooseAddress = this.$refs.Working_city.getValues()
        console.log(this.chooseAddress)
        this.Resume.Working_city = String(this.chooseAddress.map(item => {
          return item.name
        }))
      }
      if (this.pop_type == 'Expected_salary') {
        console.log(this.$refs.Expected_salary.getValues()[0])
        this.Resume.Expected_salary = this.$refs.Expected_salary.getValues()[0] + '-' + this.$refs.Expected_salary.getValues()[1]
      }
      this.closePop()
    },
    moneyChange(picker) {
      picker.setColumnValues(1, this.recentMoney.slice((this.$refs.Expected_salary.getIndexes()[0]) + 1, -1));
    },

    closeExpoect(e) {
      this.expect_job_show = e
    },
    choosePosition(e) {
      if(this.disable == true)return

      this.choosePositionObj = e
      this.Resume.Desired_position = e.industry_name
      this.expect_job_show = false
    },


    submitForm() {
      // name: '',
      //     age: '',
      //     avatar: '',
      //     seeking: '',//求职状态
      //     preponderance: '',//个人优势
      //     work_experience: '',//工作经历
      //     project_experience: '',//项目经历
      //     education_experience: '',//教育经历
      //     Working_years: '',//工作年限
      //     Job_hunting_type: '',//求职类型
      //     Working_city: '',//工作城市
      //     Desired_position: '',//期望岗位
      //     Expected_salary: '',//期望薪资
      createSeeker(
          this.Resume.name,
          this.Resume.age,
          this.Resume.Job_hunting_type_id,
          this.choosePositionObj.id,
          (this.Resume.Expected_salary.split('-')[0]).replace('k', ''),
          (this.Resume.Expected_salary.split('-')[1]).replace('k', ''),
          this.chooseAddress[0].id,
          this.chooseAddress[1].id,
          this.chooseAddress[2].id,
          this.chooseAddress[0].name,
          this.chooseAddress[1].name,
          this.chooseAddress[2].name,
          this.educationFrom.school ? this.educationFrom.school : '',
          this.educationFrom.education_id ? this.educationFrom.education_id : '',
          this.educationFrom.profession ? this.educationFrom.profession : '',
          this.educationFrom.time ? this.educationFrom.time : '',
          this.Resume.seeking_id,
          this.Resume.preponderance,
          this.Resume.project_experience,
          this.Resume.work_experience,
          this.Resume.Working_years_id,
          this.Resume.avatar,
      ).then(res => {
        if (res.data.status == 200) {
          Toast(res.data.msg)
          this.$router.go(-1)
        } else {
          Toast(res.data.msg)
        }
      })
    },
    updateForm() {
      updateSeeker(
          this.Resume.name,
          this.Resume.age,
          this.Resume.Job_hunting_type_id,
          this.choosePositionObj.id,
          this.Resume.Expected_salary.split('-')[0].replace('k', ''),
          this.Resume.Expected_salary.split('-')[1].replace('k', ''),
          this.chooseAddress[0].id,
          this.chooseAddress[1].id,
          this.chooseAddress[2].id,
          this.chooseAddress[0].name,
          this.chooseAddress[1].name,
          this.chooseAddress[2].name,
          this.educationFrom.school ? this.educationFrom.school : '',
          this.educationFrom.education_id ? this.educationFrom.education_id : '',
          this.educationFrom.profession ? this.educationFrom.profession : '',
          this.educationFrom.time ? this.educationFrom.time : '',
          this.Resume.seeking_id,
          this.Resume.preponderance,
          this.Resume.project_experience,
          this.Resume.work_experience,
          this.Resume.Working_years_id,
          this.Resume.avatar,
          this.Resume.id,
      ).then(res => {
        if (res.data.status == 200) {
          Toast('保存成功')
          this.disable = true
          this.findSeeker()
        } else {
          Toast(res.data.msg)
        }
      })
    },


    async findSeeker() {
      const PositionList = await getIndustryList()
      getSeekerDetail(this.$store.state.user.uid).then(async res => {
        let yearsList = await getJobCateList(this.$store.state.token)
        if (res.data.status == 200) {
          if (res.data.status == 200) {
            let obj = res.data.data
            this.disable = true
            this.is_published = true
            //学历
            this.educationFrom = {
              educationText: yearsList.data.data[2].find(item=>{return item.code == obj.degree})?yearsList.data.data[2].find(item=>{return item.code == obj.degree}).job_name:'',
              education_id: obj.degree,
              profession: obj.profession,
              school: obj.school,
              time: obj.graduation_time,
            }
            let str = ''
            for (let key in this.educationFrom) {
              if (this.educationFrom[key]&&key!='education_id') {
                str += (String(this.educationFrom[key]) + ' · ')
              }
            }
            //地址
            this.chooseAddress = [
              {name:obj.province_name,id:obj.province_id},
              {name:obj.city_name,id:obj.city_id},
              {name:obj.district_name,id:obj.district_id},
            ]
            //岗位
            this.choosePositionObj = getParentIdNew(PositionList.data.data,'id',obj.industry_id,'data')
            this.Resume = {
              id: obj.id,
              status: obj.status,
              name: obj.user_name,
              age: obj.user_age,
              avatar: obj.user_avatar,
              // seeking: obj.user_job_status,//求职状态
              seeking: yearsList.data.data[3].find(item=>{return item.code == obj.user_job_status}).job_name,//求职状态
              seeking_id: yearsList.data.data[3].find(item=>{return item.code == obj.user_job_status}).code,//求职状态
              preponderance: obj.advantage,//个人优势
              work_experience: obj.work_experience,//工作经历
              project_experience: obj.project_experience,//项目经历
              education_experience: str.slice(0, str.length - 2),//教育经历
              Working_years:  yearsList.data.data[1].find(item=>{return item.id == obj.working_years})?yearsList.data.data[1].find(item=>{return item.id == obj.working_years}).job_name:'',//工作年限
              Working_years_id: obj.working_years,//工作年限
              Job_hunting_type_id: obj.job_type,//求职类型    职位类型（如1全职、2兼职等）
              Job_hunting_type: obj.job_type==1?'全职':'兼职',//求职类型    职位类型（如1全职、2兼职等）
              Working_city: String(this.chooseAddress.map(item => {
                return item.name
              })),//工作城市
              Desired_position: this.choosePositionObj.industry_name,//期望岗位
              Expected_salary:Number(obj.salary_min)+'k'+'-'+Number(obj.salary_max)+'k',//期望薪资
            }
          }
        } else {
          //
        }
      }).catch(err => {
        console.log(err)
      })
    },
    edit(){
      this.disable = false
      this.is_published = false
    },
    down(){
      up_seeker_status(this.Resume.id).then(res=>{
        console.log(res)
        if(res.data.status==200){
          if(this.Resume.status == 1){
            this.Resume.status = 2
          }else{
            this.Resume.status = 1
          }
        }
      }).catch(err=>{
        console.log(err)
      })
    }
  },
  computed: {
    recentMoney() {
      let money = [];
      for (let i = 0; i <= 25; i++) {
        money.push((i + 1) + 'k');
      }
      return money;
    }
  },
  async mounted() {
    this.moneyList[0].values = this.recentMoney.slice(0,-2)
    this.moneyList[1].values = this.recentMoney.slice(1)
    this.findSeeker()
    let datae = await getJobCateList(this.$store.state.token)
    let seekingList = []
    let Working_yearsList = []
    let backgroundListPro = []
    seekingList = datae.data.data[3]
    Working_yearsList = datae.data.data[1]
    backgroundListPro = datae.data.data[2]
    seekingList.forEach(item => {
      item.text = item.job_name
      item.id_d = item.code
    })
    Working_yearsList.forEach(item => {

      item.text = item.job_name
    })
    backgroundListPro.forEach(item => {
      item.text = item.job_name
    })
    this.seekingList = seekingList
    this.Working_yearsList = Working_yearsList


    this.backgroundListPro = backgroundListPro
  }
}
</script>

<template>
  <div class="container online_resume">
    <MaskDiv @closePop="closeMask"
             @submit="submitMask"
             class="popmask"
             v-if="maskType"
             :maskType="maskType"
             :educationFromc="educationFrom"
             :chooseValue="chooseValue"
    >
    </MaskDiv>
    <ExpectJob
        class="expect_job"
        @closePop="closeExpoect"
        @choosePosition="choosePosition"
        :invalidObj="choosePositionObj"
        v-if="expect_job_show"
    ></ExpectJob>
    <div class="area">
      <navigationBar :title="'在线简历'" class="navigationBar">
        <template #search v-if="disable&&(Resume.status==1||Resume.status==2)">
          <div class="edit" @click="edit">编辑</div>
        </template>
      </navigationBar>
      <el-form :model="Resume" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">
        <van-cell class="cell" size="large" label="">
          <template #title>
            <span class="custom-title">姓名<span class="essential">*</span></span>
          </template>
          <template #label>
            <el-form-item label="" prop="name">
              <input class="input" type="text" placeholder="请填写个人信息" v-model="Resume.name" :disabled="disable">
            </el-form-item>
          </template>
        </van-cell>
        <van-cell class="cell" size="large" label="">
          <template #title>
            <span class="custom-title">年龄<span class="essential">*</span></span>
          </template>
          <template #label>
            <el-form-item label="" prop="age">
              <input class="input" type="number" placeholder="请填写个人年龄" v-model="Resume.age"
                     oninput="if(value.length>3)value=value.slice(0,3)" :disabled="disable">
            </el-form-item>
          </template>
        </van-cell>
        <van-cell class="cell avatar" size="large">
          <template #title>
            <span class="custom-title">添加求职头像<span class="essential">*</span></span>
          </template>
          <template #right-icon>
            <el-form-item label="" prop="avatar" v-if="!disable">
              <div class="">
                <el-upload
                    class="avatar-uploader"
                    action
                    :http-request="uploadFile"
                    ref="upload"
                    :limit="avatarLimit"
                    :on-remove="handleRemove"
                    :file-list="avatarFiles"
                    :on-exceed="handleExceed"
                    :before-upload="beforeUpload"
                    :show-file-list="false"
                    :headers="headers"
                    :on-change="fileChange"
                    multiple
                    name="avatar"
                >
                  <div v-if="!Resume.avatar" style="width: 100%;height: 100%">
                    <div class="innerBox">
                      <span class="iconfont icon-xiangji"></span>
                      <div class="text">上传</div>
                      <!--                    <img :src="$store.state.imageHost+'/img/Group_1538%402x.png'" alt="" class="image">-->
                    </div>
                  </div>
                  <img :src="Resume.avatar" alt="" class="showImg" v-if="Resume.avatar">
                </el-upload>
                <div v-if="is_published&&Resume.avatar" style="position: relative" class="showImgBox">
                  <el-image
                      class="imageShow"
                      :src="Resume.avatar"
                      :preview-src-list="[Resume.avatar]"
                  >
                  </el-image>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="" prop="avatar" v-if="disable">
              <div class="">
                <div v-if="is_published&&Resume.avatar" style="position: relative" class="showImgBox">
                  <el-image
                      class="imageShow"
                      :src="Resume.avatar"
                      :preview-src-list="[Resume.avatar]"
                  >
                  </el-image>
                </div>
              </div>
            </el-form-item>
          </template>
        </van-cell>
        <van-cell class="cell selectCell" size="large" label="" @click="openPop('seeking')">
          <template #title>
            <span class="custom-title">求职状态<span class="essential">*</span></span>
          </template>
          <template #label>
            <el-form-item label="" prop="seeking">
              <span class="text" :class="Resume.seeking ? '' : 'placeholder'">{{
                  Resume.seeking ? Resume.seeking : '求职状态'
                }}</span>
            </el-form-item>
            <span class="iconfont icon-Frame-4"></span>
          </template>
        </van-cell>
        <van-cell class="cell" size="large" label="" @click="openMask('preponderance')">
          <template #title>
            <span class="custom-title">个人优势<span class="essential">*</span></span>
          </template>
          <template #label>
            <el-form-item label="" prop="preponderance">
              <span class="text" :class="Resume.preponderance ? '' : 'placeholder'" v-html="Resume.preponderance?Resume.preponderance:'请介绍个人优势'"></span>
            </el-form-item>
          </template>
          <template #right-icon>
            <span class="iconfont icon-changyongyu-xiugai"></span>
          </template>
        </van-cell>

        <van-cell class="cell" size="large" label="" @click="openMask('work_experience')">
          <template #title>
            <span class="custom-title">工作经历</span>
          </template>
          <template #label>
            <el-form-item label="" prop="work_experience">
               <span class="text" :class="Resume.work_experience ? '' : 'placeholder'" v-html="
                   Resume.work_experience ? Resume.work_experience : '请介绍个人的工作经历'
                 "></span>
            </el-form-item>
          </template>
          <template #right-icon>
            <span class="iconfont icon-changyongyu-xiugai"></span>
          </template>
        </van-cell>
        <van-cell class="cell" size="large" label="" @click="openMask('project_experience')">
          <template #title>
            <span class="custom-title">项目经历</span>
          </template>
          <template #label>
            <el-form-item label="" prop="project_experience">
            <span class="text" :class="Resume.project_experience ? '' : 'placeholder'" v-html="
                Resume.project_experience ? Resume.project_experience : '请介绍个人项目经验'
              "></span>
            </el-form-item>
          </template>
          <template #right-icon>
            <span class="iconfont icon-changyongyu-xiugai"></span>
          </template>
        </van-cell>
        <van-cell class="cell selectCell" size="large" label="" @click="openMask('education_experience')">
          <template #title>
            <span class="custom-title">教育经历</span>
          </template>
          <template #label>
            <el-form-item label="" prop="education_experience">
              <span class="text" :class="Resume.education_experience ? '' : 'placeholder'">{{
                  Resume.education_experience ? Resume.education_experience : '前往个人教育经历'
                }}</span>
            </el-form-item>
            <span class="iconfont icon-Frame-4"></span>
          </template>
        </van-cell>

        <van-cell class="cell selectCell" size="large" label="" @click="openPop('Working_years')">
          <template #title>
            <span class="custom-title">工作年限<span class="essential">*</span></span>
          </template>
          <template #label>
            <el-form-item label="" prop="Working_years">
          <span class="text" :class="Resume.Working_years ? '' : 'placeholder'">
<!--            {{Resume.Working_years?Resume.Working_years:'请选择'}}-->
            {{ Resume.Working_years ? Resume.Working_years : '请选择' }}
          </span>
            </el-form-item>
            <span class="iconfont icon-Frame-4"></span>
          </template>
        </van-cell>
        <van-cell class="cell selectCell" size="large" label="" @click="openPop('Job_hunting_type')">
          <template #title>
            <span class="custom-title">求职类型<span class="essential">*</span></span>
          </template>
          <template #label>
            <el-form-item label="" prop="Job_hunting_type">
            <span class="text" :class="Resume.Job_hunting_type ? '' : 'placeholder'">{{
                Resume.Job_hunting_type ? Resume.Job_hunting_type : '请选择'
              }}</span>
            </el-form-item>
            <span class="iconfont icon-Frame-4"></span>
          </template>
        </van-cell>
        <van-cell class="cell selectCell" size="large" label="" @click="openPop('Working_city')">
          <template #title>
            <span class="custom-title">工作城市<span class="essential">*</span></span>
          </template>
          <template #label>
            <el-form-item label="" prop="Working_city">
            <span class="text" :class="Resume.Working_city ? '' : 'placeholder'">{{
                Resume.Working_city ? Resume.Working_city : '请选择'
              }}</span>
            </el-form-item>
            <span class="iconfont icon-Frame-4"></span>
          </template>
        </van-cell>
        <van-cell class="cell selectCell" size="large" label="" @click="()=>{if(disable ==true)return;expect_job_show = true}">
          <template #title>
            <span class="custom-title">期望岗位<span class="essential">*</span></span>
          </template>
          <template #label>
            <el-form-item label="" prop="Desired_position">
          <span class="text" :class="Resume.Desired_position ? '' : 'placeholder'">{{
              Resume.Desired_position ? Resume.Desired_position : '请选择'
            }}</span>
            </el-form-item>
            <span class="iconfont icon-Frame-4"></span>
          </template>
        </van-cell>

        <van-cell class="cell selectCell" size="large" label="" @click="openPop('Expected_salary')">
          <template #title>
            <span class="custom-title">期望薪资<span class="essential">*</span></span>
          </template>
          <template #label>
            <el-form-item label="" prop="Expected_salary">
            <span class="text" :class="Resume.Expected_salary ? '' : 'placeholder'">{{
                Resume.Expected_salary ? Resume.Expected_salary : '请选择'
              }}</span>
            </el-form-item>

            <span class="iconfont icon-Frame-4"></span>
          </template>
        </van-cell>


        <div class="bottomBox">
          <div class="btn" @click="onsubmit('ruleForm')" v-if="!Resume.id">发布简历</div>
          <div class="btn" v-if="Resume.status == 1&&disable" @click="down()">下架简历</div>
          <div class="btn" v-if="Resume.status == 2&&disable" @click="down()">上架简历</div>
          <div class="btn" v-if="(Resume.status == 2||Resume.status == 1)&&!disable" @click="onsubmit('ruleForm')">保存简历</div>
        </div>
      </el-form>


      <van-popup v-model="pop_show" position="bottom">
        <div class="pop_inner">
          <van-picker
              v-if="pop_type == 'seeking'"
              ref="seeking"
              :columns="seekingList"
          />
          <van-picker
              v-if="pop_type == 'Working_years'"
              ref="Working_years"
              :columns="Working_yearsList"
          />
          <van-picker
              v-if="pop_type == 'Job_hunting_type'"
              ref="Job_hunting_type"
              :columns="Job_hunting_typeList"
          />
          <van-picker
              v-if="pop_type == 'Working_city'"
              ref="Working_city"
              value-key="name"
              :columns="address_li"
          />
          <van-picker
              v-if="pop_type == 'Expected_salary'"
              ref="Expected_salary"
              :columns="moneyList"
              @change="moneyChange"
          />
        </div>
        <div class="pop_bottom">
          <div class="close btn" @click="closePop">取消</div>
          <div class="save btn" @click="save">确定</div>
        </div>
      </van-popup>
      <div class="dianpian"></div>

    </div>

  </div>
</template>

<style scoped lang="scss">
input:disabled{
  background-color: #fff;
}
.navigationBar{
  position: relative;
  .edit{
    position: absolute;
    right: 20px;
  }
}
.pop_bottom {
  background: #fff;
  padding: 30px;
  display: flex;
  justify-content: space-around;

  .btn {
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 40px;
    border-radius: 10px;
    background: #f0f0f0;
    color: #1b1b1b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .save {
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 40px;
    border-radius: 10px;
    background: #05c160;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

}

.popmask, .expect_job {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: #fff;
  left: 0;
  top: 0;
}

.avatar-uploader {
  width: 76px;
  height: 76px;
  border-radius: 50px;

  .innerBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .iconfont {
      font-size: 24px;
      margin-bottom: 0px;
    }
  }

  .showImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.showImgBox {
  position: relative;

  .iconfont {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.imageShow {
  width: 76px;
  height: 76px;
  border-radius: 50px;
  object-fit: cover;
}

.container {
  .area {
    min-height: 100vh;
  }
}

.van-cell__title {
  text-align: left;
  width: 80%;
}

.van-cell__value {
  width: 10%;
  -webkit-box-flex: initial;
  -webkit-flex: initial;
  flex: initial;
}

.van-cell__label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: 10px;
  color: #1b1b1b;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 22px;
}
.van-cell__label {

  .text{
    display: inline-block;
    display: -webkit-box;
    /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
    -webkit-box-orient:vertical;
    /*要显示的行数*/
    -webkit-line-clamp:2;
    /* 溢出部分隐藏 */
    overflow:hidden;
  }
}

.el-form-item__content {
  font-size: 16px;
  line-height: 22px;
}

.el-form-item {
  margin-bottom: 20px;
}

.cell {
  border-bottom: 1px solid var(--房产描边F6F6F6, #f6f6f6);
  padding: 20px 0 0 0;
  margin: 0 16px;
  width: initial;

  .custom-title {
    color: #1b1b1b;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    .essential {
      color: #fc223b;
    }
  }

  .placeholder {
    color: #c7c7c7;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .input {
    border: none;
    color: #1b1b1b;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .input::placeholder {
    color: #c7c7c7;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .iconfont {
    font-size: 24px;
    color: #666666;
    margin-bottom: 20px;
  }
}

.avatar {
  .van-cell__value {
    width: 40%
  }

  .van-cell__title {
    width: 50%;
  }
}

.selectCell {
  .van-cell__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    vertical-align: middle;
  }

  .text {
    vertical-align: middle;
    color: #1b1b1b;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}

.placeholder {
  color: #c7c7c7 !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.bottomBox {
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
  width: 100%;

  .btn {
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 12px 13px;
    border-radius: 12px;
    background: #0393f5;
    margin: 15px 16px;
    text-align: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>


<style>
.online_resume .el-form-item__content {
  font-size: 16px;
  line-height: 22px;
}
</style>