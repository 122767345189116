import request from "@/utils/request";
import store from "../store/index";
const newDevHost = store.state.newDevHost;
/*
* 首页 - 获取中部区域 - 分类列表
* */
export function getCategory(){
    return request.get(`${newDevHost}/api/getCategory`)
}
/*
* 首页 - top区域 -获取轮播图
* */
export function getConfigImage(show_position,client_type){
    return request.get(`${newDevHost}/api/getConfigImage?show_position=${show_position}&client_type=${client_type}`)
}
/*
* 首页 - 商品列表
* */

export function getCateProductList(page,limit,category_id,product_name) {
    return request.get(`${newDevHost}/api/getCateProductList?page=${page}&limit=${limit}&category_id=${category_id}&product_name=${product_name}`)
}
/*
* 首页 - 闲置好物商品列表
* */
export function getPeripProductList(page,limit) {
    return request.get(`${newDevHost}/api/getPeripProductList?page=${page}&limit=${limit}`)
}
/*
* 首页 - 根据店铺闲置分类 - 获取店铺列表
* */
export function getCategoryShopList(category_id,word,limit,page) {
    return request.get(`${newDevHost}/api/getCategoryShopList?category_id=${category_id}&word=${word}&limit=${limit}&page=${page}`)
}
/*
* 获取回收/搬家入住列表
* */
export function getRecoveryShopList(type){
    return request.get(`${newDevHost}/api/getRecoveryShopList?type=${type}`)
}
/*
* 首页 - 店铺闲置 - 店铺详情
* */
export function getProductShopDetail(id){
    return request.get(`${newDevHost}/api/getProductShopDetail?id=${id}`)
}
/*
* 首页 - 搜索 - 商品详情
* */
export function getProductDetail(id){
    return request.get(`${newDevHost}/api/getProductDetail?id=${id}`)
}
/*
* 校验是否已报价
* */
export function checkIsQuote(product_id) {
    return request.get(`${newDevHost}/api/checkIsQuote?product_id=${product_id}`)
}
/*
* 收藏
* */
export function recovercollect(product_id,is_collect){
    return request.get(`${newDevHost}/api/recovercollect?product_id=${product_id}&is_collect=${is_collect}`)
}
/*
* 用户报价
* */
export function quote(product_id,quote_price,shop_id) {
    return request.get(`${newDevHost}/api/quote?product_id=${product_id}&quote_price=${quote_price}&shop_id=${shop_id}`)
}
/*
* 用户报价
* */
export function getFreeMarkList(){
    return request.get(`${newDevHost}/api/getFreeMarkList`);
}
/*
* 个人中心 - 获取数量
* */
export function getListCount() {
    return request.get(`${newDevHost}/api/getListCount`);
}
/*
* 获取个人实名认证
* */
export function getRegAuthentDetail() {
    return request.get(`${newDevHost}/api/getRegAuthentDetail`);
}
/*
* 我发布的 - 全部商品
* */
export function getMyPublishList(limit,page,is_sale){
    return request.get(`${newDevHost}/api/getMyPublishList?limit=${limit}&page=${page}&is_sale=${is_sale}`);
}
/*
* 我的发布 - 商品删除
* */
export function delPublish(data) {
    return request.post(`${newDevHost}/api/delPublish`,data);
}
/*
* 我的发布 - 商品上下架
* */
export function enablePublish(data){
    return request.post(`${newDevHost}/api/enablePublish`,data);
}
/*
* 获取用户店铺
* */
export function getUserShop(){
    return request.get(`${newDevHost}/api/getUserShop`);
}
/*
* 首页 - 闲置发布 - 单品发布
* */
export function publish(
    product_name,//商品名称
    category_id,//分类
    images,//图片
    price,//售价
    origin_price,//商品原价
    brand,//品牌
    warranty,//质保
    product_mode,//型号
    use_date,//使用日期
    is_invoice,//是否有发票 0 -无，1-有
    is_sale,//是否预售 1是 2否
    pre_sale_date,//预售时间
    address,//取件地址
    detail_address,//详细地址
    product_desc,//简介
    train_time,//交易时间
    id,
){
    let obj = {
        product_name,
        category_id,
        images,
        price,
        origin_price,
        brand,
        warranty,
        product_mode,
        use_date,
        is_invoice,
        is_sale,
        pre_sale_date,
        address,
        detail_address,
        product_desc,train_time,id,};
    return request.post(`${newDevHost}/api/publish`, obj)
}
/*
* 我的发布 - 商品编辑
* */
export function updatePublish(
    product_name,//商品名称
    category_id,//分类
    images,//图片
    price,//售价
    origin_price,//商品原价
    brand,//品牌
    warranty,//质保
    product_mode,//型号
    use_date,//使用日期
    is_invoice,//是否有发票 0 -无，1-有
    is_sale,//是否预售 1是 2否
    pre_sale_date,//预售时间
    address,//取件地址
    detail_address,//详细地址
    product_desc,//简介
    train_time,//交易时间
    id,
){
    let obj = {
        product_name,
        category_id,
        images,
        price,
        origin_price,
        brand,
        warranty,
        product_mode,
        use_date,
        is_invoice,
        is_sale,
        pre_sale_date,
        address,
        detail_address,
        product_desc,train_time,id,};
    return request.post(`${newDevHost}/api/updatePublish`, obj)
}
/*
* 我卖出的 - 全部商品列表
* */
export function getMySaleList(status,page,limit) {
    return request.get(`${newDevHost}/api/getMySaleList?status=${status}&page=${page}&limit=${limit}`);
}
/*
* 我卖出的 - 待取货详情
* */
export function getMySaleDetail(quote_id) {
    return request.get(`${newDevHost}/api/getMySaleDetail?quote_id=${quote_id}`)
}
/*
* - 我卖出的 - 列表同意按钮
* */
export function enableQuote(quote_id,product_id,is_agree){
    let data = {
        quote_id,
        product_id,
        is_agree,
    }
    return request.post(`${newDevHost}/api/enableQuote`, data)
}

/*
* 闲置转让  我的收藏商品列表
* */
export function collectList(is_sale,page,limit){
    return request.get(`${newDevHost}/api/collectList?is_sale=${is_sale}&page=${page}&limit=${limit}`);
}
/*
* 个人中心 - 常见问题 - 获取分类类型
* */
export function getFeedBackData(){
    return request.get(`${newDevHost}/api/getFeedBackData`);
}
/*
* 个人中心 - 常见问题 - 问题详情页面
* */
export function getFeedBackItem(f_id){
    return request.get(`${newDevHost}/api/getFeedBackItem?f_id=${f_id}`);
}
/*
* 个人中心 - 获取问题反馈分类
*  类型 1问题类型 2举报类型 4回收入驻类型 5搬家入驻类型  6发布店铺转让获取店铺类型 7发布办公转租获取写字楼类型 8发布办公转租获取写字楼装修 9店铺标签 10办公转租押金押方式 11办公转租押金付方式 12起/免租期 13办公转租标签
* */
export function getQuestionCate(type) {
    return request.get(`${newDevHost}/api/getQuestionCate?type=${type}`);
}
/*
* 个人中心 - 问题反馈提交
* */
export function createQuestion(cate_id='',desc='',img='') {
    let data = {
        cate_id,
        desc,
        img,
    }
    return request.post(`${newDevHost}/api/createQuestion`,data)
}
/*
* 回收/搬家入驻
* */
export function createRecovery(reg_type,city_id,area_id,recovery_type,type){
    let data = {reg_type,city_id,area_id,recovery_type,type}
    return request.post(`${newDevHost}/api/createRecovery`,data);
}
/*
* 新增回收/搬家入驻类型
* */
export function createRecoveryShopType(recovery_type,type) {
    let data = {recovery_type,type}
    return request.post(`${newDevHost}/api/createRecoveryShopType`,data)
}
/*
* 我买到的 - 全部商品列表
* */
export function getBuyList(is_all,page,limit) {
    return request.get(`${newDevHost}/api/getBuyList?is_all=${is_all}&page=${page}&limit=${limit}`);
}
/*
* 我买到的 - 用户取消交易
* */
export function cancelBuyQuote(quote_id,product_id) {
    let data = {quote_id,product_id}
    return request.post(`${newDevHost}/api/cancelBuyQuote`,data)
}
/*
* 我买到的 - 确认收货
* */
export function enableBuyQuote(quote_id,product_id) {
    let data = {
        quote_id,
        product_id,
    }
    return request.post(`${newDevHost}/api/enableBuyQuote`, data)
}
/*
* 我买到的 - 删除订单
* */
export function delBuyQuote(quote_id,product_id){
    let data = {quote_id,product_id}
    return request.post(`${newDevHost}/api/delBuyQuote`,data)
}
/*
* 实名认证提交
* */
export function regAuthentication(valid_type,name,idcard,img){
    let obj = {valid_type,name,idcard,img}
    return request.post(`${newDevHost}/api/regAuthentication`,obj)
}
/*
* 获取所有地区
* */
export function getAllArea() {
    return request.get(`${newDevHost}/api/getAllArea`)
}
/*
* 闲置发布 - 发布店铺
* */
export function publishShop(
    category_id,img,title,area,
    address,shop_type,insdustry,
    measure,rent,lease_term,transfer_cost,
    desc,plege,symbol,use,is_agree,number,
    tag,type,area_id,city_id,origin_id,logo,info,shop_user_img
){
    let data = {shop_user_img,logo,info,area_id,city_id,origin_id,type,number,use,is_agree,symbol,plege,transfer_cost,lease_term,insdustry,category_id,img,title,area,address,shop_type,measure,rent,desc,tag}
    return request.post(`${newDevHost}/api/publishShop`,data)
}
/*
* 首页 - 发布店铺/办公转租 - 获取行业数据类型列表
* */
export function getIndustryConfigList() {
    return request.get(`${newDevHost}/api/getIndustryConfigList`);
}
/*
* 首页 - 店铺闲置 - 店铺详情
* */
export function PlategetProductdetail(id) {
    return request.get(`${newDevHost}/api/PlategetProductdetail?id=${id}`);
}
/*
* 个人商家主页详情
* */
export function getUserShopDetail(user_id,category_id) {
    return request.get(`${newDevHost}/api/getUserShopDetail?user_id=${user_id}&category_id=${category_id}`);
}

