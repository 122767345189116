<script>

import {getProductDetails, service_record, upload_file} from "@/utils/api";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import {HTTP_REQUEST_URL} from "@/config/app";
import Bus from "@/utils/Bus";
import Compressor from "compressorjs";
import emojiList from "@/utils/emoji"
import Swiper from "swiper";
import {Toast} from "vant";

const chunk = function (arr, num) {
  num = num * 1 || 1;
  var ret = [];
  arr.forEach(function (item, i) {
    if (i % num === 0) {
      ret.push([]);
    }
    ret[ret.length - 1].push(item);
  });
  return ret;
};
export default {
  name: "chat",
  components: {ElImageViewer},
  data() {
    return {
      fileLimit: 1,//图片最大上传数量
      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["png", "jpg", "jpeg",],
      fileList: [],
      isSwiper: false,
      con: '',

      productId: 0,
      productInfo: {},

      orderId: 0,
      orderInfo: {},

      chatList: [],
      limit: 15,
      uidTo: 0,
      toUid: 0,
      myUid: 0,
      httpUrl: '',
      maxlength: 200,
      emojiGroup: chunk(emojiList, 21),
      ViewerShow: false,
      ViewList: [],


      loading: false,
      loaded: false,
      isScroll: false,

    }
  },
  methods: {
    // 滚动到顶部
    scrollToTop() {
      if (this.isScroll) {
        this.loading = true;
        this.uidTo = this.chatList[0].id;
        this.isScroll = false;
        setTimeout(() => {
          this.getMessage();
        }, 800);
      }
    },
    scrollFun(event) {
      if (event.target.scrollTop <= 20) {
        this.scrollToTop()
      }
    },
    uploadImg() {

    },
    // 表情点击
    addEmoji(item) {
      let val = `[${item}]`;
      this.con += val;
    },
    // 发送消息
    sendText() {
      console.log(123)
      if (!this.isSend || this.con.trim() === '') {
        return this.$util.Tips({
          title: '请输入内容'
        });
      }
      this.sendMsg(this.con, 1);
      this.con = '';
    },
    getMessage() {
      service_record(this.uidTo, this.limit, this.toUid).then(res => {
        if (res.data.status == 200) {
          if (window.initWebsocket) {
            window.initWebsocket()
            this.$nextTick(() => {
              this.receiveMessage()
            })
          }
          let selector = '';
          if (res.data.data.serviceList.length) {
            if (this.uidTo == 0) {
              selector = `msg-${res.data.data.serviceList[res.data.data.serviceList.length - 1].id}`;
            } else {
              selector = `msg-${this.chatList[0].id}`;
            }
            // let arr = [];
            // var sH = 0;
            this.titleName = res.data.data.nickname;
            this.toUid = res.data.data.uid;
            res.data.data.serviceList.forEach(el => {
              el._add_time = el._add_time.substring(0, el._add_time.length - 3);
              if (el.msn_type == 1 || el.msn_type == 2) {
                el.msn = this.replace_em(el.msn);
              }
            });
            this.loading = false;

            this.chatList = [...res.data.data.serviceList, ...this.chatList];
            this.$nextTick(() => {
              this.setPageScrollTo(selector)
              this.isScroll = res.data.data.serviceList.length >= this.limit;
            })


          }
        }
        if(res.data.status == 400){
          Toast(res.data.msg)
          this.loading = false;
          this.isScroll = false
          this.$router.push({path:'/feedback'})
        }
      }).catch(err => {
        console.log(err)
        Toast(err.data.msg)
        this.loading = false;
        this.isScroll = false
        // 跳转客服离线

      })
    },
    // 聊天表情转换
    replace_em(str) {
      str = str.replace(/\[em-([\s\S]*?)\]/g, "<span class='em em-$1' style='background-image:url(" + this
          .httpUrl + ")'></span>");
      return str;
    },


    // 设置页面滚动位置
    setPageScrollTo(selector) {
      try {
        if (this.chatList.length) {
          this.$nextTick(() => {
            console.log(selector)
            console.log(this.$refs[selector][0].offsetTop)
            // let top = this.$refs.scrollView.scrollHeight
            // let offsetHeight = this.$refs.scrollView.offsetHeight
            // console.log(top,offsetHeight)

            this.$refs.scrollView.scrollTop = this.$refs[selector][0].offsetTop
          })

          // let view = uni
          //     .createSelectorQuery()
          //     .in(this)
          //     .select(selector);
          // view.boundingClientRect(res => {
          //   this.scrollTop = parseFloat(res.top) - 60;
          // }).exec();
        }
      } catch (e) {
        console.log(e);
      }
    },
    focus(e) {
      this.blurIndex = this.$refs.messageInput.$el.children[0].selectionStart;
      console.log(e.target)
      e.target.setSelectionRange(this.cursorIndex, this.cursorIndex);
    },
    blur() {
      this.cursorIndex = this.$refs.messageInput.$el.children[0].selectionStart
    },


    // enter
    keyDown(e) {
      if (e.ctrlKey && e.keyCode == 13) {
        //用户点击了ctrl+enter触发
        if (this.con.length <= this.maxlength) {
          this.con += "\n";
        }
        this.$nextTick(() => {
          setTimeout(() => {
            const textarea = document.getElementById("kefu_message");
            textarea.scrollTop = textarea.scrollHeight;
          }, 13);
        });
      } else {
        if (!this.con.trim()) {
          this.con = "";
          // console.log(this.content)
        } else {
          // 发送消息
          this.sendMsg(this.con, 1)
          this.con = ''
        }
        if (e != undefined) {
          e.preventDefault(); // 阻止浏览器默认的敲击回车换行的方法
        }
      }
    },
    // ws发送
    sendMsg(msn, type) {
      let msg = {
        data: {
          msn,
          type,
          to_uid: this.toUid
        },
        form_type: 3,
        type: "chat",
      }
      this.$store.state.websocket.send(JSON.stringify(msg));
      this.showInput = false;
    },
    sendMsgBtn() {
      if (!this.con.trim()) {
        this.con = "";
        // console.log(this.content)
      } else {
        this.sendMsg(this.con, 1)
        this.con = ''
      }
    },


    // 发送商品
    sendProduct() {
      this.sendMsg(this.productId, 5);
      this.productId = 0;
      this.productInfo = {};
    },

    //接收消息
    receiveMessage() {
      Bus.$on("websocket", (msg) => {
        console.log(JSON.parse(msg.content.data))
        let data = JSON.parse(msg.content.data)
        if (data.data && data.data._add_time) {
          if (data.data.msn_type == 1) {
            data.data.msn = this.replace_em(data.data.msn);
          }
          data.data._add_time = data.data._add_time.substring(0, data.data._add_time.length - 3);
          this.chatList.push(data.data);
          this.$nextTick(() => {
            this.height();
          });
        }
      })
    },
    // 滚动到底部
    height() {
      this.$nextTick(() => {
        let top = this.$refs.scrollView.scrollHeight
        let offsetHeight = this.$refs.scrollView.offsetHeight
        this.$refs.scrollView.scrollTop = top - offsetHeight
      })
      // let self = this;
      // var scrollTop = 0;
      // let info = uni.createSelectorQuery().select('.chat');
      // setTimeout(res => {
      //   info.boundingClientRect(function(data) {
      //     //data - 各种参数
      //     scrollTop = data.height;
      //     if (self.active) {
      //       self.scrollTop = parseInt(scrollTop) + 500;
      //     } else {
      //       self.scrollTop = parseInt(scrollTop) + 100;
      //     }
      //   }).exec();
      // }, 200);
    },


    async handleRemove() {
    },
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },
    fileChange(file, fileList) {
      this.fileList = fileList;
    },
    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)

        }


      })
    },

    async beforeUpload(files) {
      let file = await this.yasuo(files)
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        if (files.type.startsWith('image/')) {   //图片
          //计算文件的大小
          const isLt5M = file.size / 1024 / 1024 < 1; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            return false;
          }
        } else if (files.type.startsWith('video/')) {//视频
          const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },

    uploadFile(item) {
      console.log(item)
      // this.$message("文件上传中........");
      if (item.file.type.startsWith('image/')) {
        console.log('图片')
        console.log(item.file)
        upload_file(item.file).then(res => {
          if (res.data.status == 200) {
            this.$message.close()
            // this.$message.success("上传成功");
            this.fileList.splice(0, 1); //删除上传的文件\
            console.log(res.data.data.url)
            // item.data.data.image = res.data.data.url

            this.sendMsg(res.data.data.url, 3);
          }
        })
      }
      // else if (item.file.type.startsWith('video/')) {
      //   upload_files(item.file).then(res => {
      //     if (res.data.status == true) {
      //       this.$message.close()
      //       this.$message.success("上传成功");
      //       console.log(res.data.data)
      //     }
      //   })
      // }
    },
    openSwiper() {
      this.isSwiper = !this.isSwiper
      if (this.isSwiper) {
        this.$nextTick(() => {
          this.swiper = new Swiper(".swiper", {
            pagination: {
              el: ".swiper-pagination",
            },
            loop: false,
            autoplay: false,
          });
        })
      }
    },

    getproductInfo() {
      if (!this.productId) return;
      getProductDetails(this.productId).then(res => {
        if (res.data.status == 200) {
          this.productInfo = res.data.data.storeInfo
        }
      })
    },
    getOrderInfo() {
      if (!this.orderId) return;

    },
    // 商品信息
    goProduct(item) {
      this.$router.push({
        path: '/water_details',
        query: {goods: item.msn}
      })
    },

    previewImage(n) {
      const image = new Image()
      let list = [JSON.parse(JSON.stringify(n))]
      console.log(list)
      const star = list.splice(0)
      const remain = list.splice(0, 0);
      this.ViewList = star.concat(remain);
      this.ViewerShow = true

      image.src = (n)
      image.onerror = function () {
        console.log('图片加载失败')
      }
    },
    closeViewer() {
      this.ViewerShow = false
    },

  },
  computed: {
    isSend() {
      if (this.con.length == 0) {
        return false;
      } else {
        return true;
      }
    },
    records() {
      return this.chatList.map((item, index) => {
        if (index) {
          if (item.add_time - this.chatList[index - 1].add_time >= 300) {
            item.show = true;
          } else {
            item.show = false;
          }
        } else {
          item.show = true;
        }
        return item;
      });
    }
  },
  created() {
    this.httpUrl = `${HTTP_REQUEST_URL}/statics/images/look.png`;
  },
  mounted() {
    this.productId = parseInt(this.$route.query.productId) || 0;
    this.orderId = parseInt(this.$route.query.orderId) || 0;
    this.getproductInfo()
    this.getOrderInfo()

    this.toUid = this.$route.query.to_uid || '';
    this.myUid = this.$store.state.user.uid;

    this.getMessage()



  },
  beforeDestroy() {
    Bus.$off('websocket')
    window.websocketclose()
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <!--    <navigationBar title="" class="navigation"></navigationBar>-->
      <div class="scrollView">
        <div class="broadcast-details-order">
          <!-- 商品信息 -->
          <div class="broadcast-details_box" v-if="productId && productInfo.id">
            <div class="broadcast_details_img">
              <img class="goods-img" :src="productInfo.image" alt="">
            </div>
            <div class="broadcast_details_picBox">
              <div class="broadcast_details_tit" v-text="productInfo.store_name"></div>
              <div class="bottom acea-row row-between">
                <div class="broadcast_details_pic">
                  ￥{{ productInfo.price }}
                  <span class="broadcast_details_pic_num"
                        v-if="productInfo.ot_price">￥{{ productInfo.ot_price }}</span>
                </div>
                <div class="broadcast_details_btn" @click="sendProduct">发送客服</div>
              </div>
            </div>
          </div>
          <!-- 订单信息 -->
          <div></div>
        </div>
        <div class="chat-scroll-box">
          <div class="scroll-view" ref="scrollView" @scroll="scrollFun">
            <div class="Loads acea-row row-center-wrapper" v-if="loading && !loaded" style="margin-top: .2rem;">
              <div v-if="loading">
                <div class="iconfont icon-jiazai loading acea-row row-center-wrapper"></div>
                <slot name="loading"></slot>
                正在加载中
              </div>
              <div v-else>
                <slot name="load"></slot>
                上拉加载更多
              </div>
            </div>

            <div :class="'msg-'+item.id" v-for="(item,index) in records" :key="index" :ref="'msg-'+item.id">
              <div class="day-box" v-if="item.show">{{ item._add_time }}</div>
              <div class="chat-item" :class="{ 'right-box': item.uid == myUid }">
                <img class="avatar" :src="item.avatar" mode=""/>
                <!-- 消息 -->
                <div class="msg-box" v-if="item.msn_type <= 2" v-html="item.msn"></div>
                <!-- 图片 -->
                <div class="img-box" v-if="item.msn_type == 3">
                  <img :src="item.msn" mode="widthFix" @click="previewImage(item.msn)"/>
                </div>
                <!-- 商品 -->
                <div class="product-box" v-if="item.msn_type == 5" @click="goProduct(item)">
                  <img v-if="Array.isArray(item.productInfo)" :src="require('/public/image/product-off.png')"
                       class="product-off" alt="">
                  <!--                <image   mode="aspectFit" ></image>-->
                  <template v-else>
                    <img
                        :src="item.productInfo.cartInfo?item.productInfo.cartInfo.image:item.productInfo.image"
                    />
                    <div class="info">
                      <div class="price">
                        <span class="unit">￥</span>{{
                          item.productInfo.cartInfo ? item.productInfo.cartInfo.price : item.productInfo.price
                        }}
                      </div>
                      <div class="name line2">{{ item.productInfo.store_name }}</div>
                    </div>
                  </template>
                </div>
                <!-- 订单 -->
                <!--            <div class="order-box"-->
                <!--                  v-if="(item.msn_type == 6 || item.msn_type == 7) && item.orderInfo.cartInfo"-->
                <!--                  @click="goOrder(item)">-->
                <!--              <view class="title">订单号: {{ item.orderInfo.order_id }}</view>-->
                <!--              <view class="info">-->
                <!--                <image-->
                <!--                    :src="item.orderInfo.cartInfo[0].productInfo.attrInfo?item.orderInfo.cartInfo[0].productInfo.attrInfo.image:item.orderInfo.cartInfo[0].productInfo.image">-->
                <!--                </image>-->
                <!--                <view class="product-info">-->
                <!--                  <view class="name line2">{{ item.orderInfo.cartInfo[0].productInfo.store_name }}-->
                <!--                  </view>-->
                <!--                  <view class="price">-->
                <!--                    ¥{{ item.orderInfo.cartInfo[0].productInfo.attrInfo?item.orderInfo.cartInfo[0].productInfo.attrInfo.price:item.orderInfo.cartInfo[0].productInfo.price }}-->
                <!--                  </view>-->
                <!--                </view>-->
                <!--              </view>-->
                <!--            </div>-->
              </div>

            </div>
          </div>
        </div>

        <div class="bottomBox">
          <div class="footer-box">
            <el-upload
                class="avatar-uploader"
                action
                :http-request="uploadFile"
                ref="upload"
                :limit="fileLimit"
                :on-remove="handleRemove"
                :file-list="fileList"
                :on-exceed="handleExceed"
                :before-upload="beforeUpload"
                :show-file-list="false"
                :headers="headers"
                :on-change="fileChange"
                multiple
                :data="{ index: 0, data: 'message' }"
            >
              <van-icon name="add-o" class="words" @click="uploadImg"/>
            </el-upload>

            <div class="input-box">
              <!--          <div class="flex-1 pl-20 h-full fs-28 text&#45;&#45;w111-999 flex-y-center">请输入内容</div>-->
              <el-input
                  ref="messageInput"
                  type="textarea"
                  autosize
                  placeholder="请输入内容~"
                  v-model="con"
                  class="textarea"
                  :maxlength="maxlength"
                  :max="maxlength"
                  @focus.stop.prevent="focus($event)"
                  @blur.stop.prevent="blur($event)"
                  id="kefu_message"
                  @keydown.enter.native="keyDown"></el-input>

              <!--            <input type="text" placeholder="请输入内容" v-model="con" confirm-type="send" @confirm="sendText" />-->
              <span class="icon-ic_send" :class="{ isSend: isSend }" @click="sendMsgBtn">发送</span>
            </div>
            <van-icon name="smile-o" class="emoji" @click="openSwiper"/>
          </div>
        </div>
        <div class="banner slider-banner" v-show="isSwiper && httpUrl">
          <div class="swiper">
            <div class="swiper-wrapper">
              <div
                  v-for="(emojiList, index) in emojiGroup" :key="index"
                  class="swiper-slide"
              >
                <i class="em" :class="emoji" :style="'background-image:url('+ httpUrl +')'"
                   v-for="emoji in emojiList" :key="emoji" @click="addEmoji(emoji)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="ViewerShow">
      <ElImageViewer :on-close="closeViewer" :url-list="ViewList"></ElImageViewer>
    </template>
  </div>
</template>

<style scoped lang="scss">
.broadcast-details_box {
  display: flex;
  background: #fff;
  border-radius: 6px;
  padding: 12px;
}

.broadcast_details_img {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.broadcast_details_img .goods-img {
  width: 100%;
  height: 100%;
}

.broadcast_details_picBox {
  width: 75%;
  margin-left: 12px;

  .bottom {
    margin-top: 6px;
  }
}

.broadcast_details_tit {
  font-size: 16px;
  color: #333333;
  height: 33px;
  font-weight: 800;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left !important;
}

.broadcast_details_pic {
  font-size: 18px;
  color: #FF448F;
  text-align: left;
}

.broadcast_details_pic_num {
  text-decoration: line-through;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 0.1rem;
}

.broadcast_details_btn {
  width: 65px;
  height: 25px;
  background: #FF448F;
  opacity: 1;
  border-radius: 54px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 25px;
}

.broadcast-details_num {
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: #000000;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  padding: 0 12px;
}

.swiper-slide {
  // background: red;
  background-size: cover;
  background-position: center center;
  width: 100%;
  text-align: left;
}

.swiper {
  margin: 0px 0px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;

  .img {
    width: 100%;
  }

  // 分页器
  /* 两种都可以 */
  .swiper-pagination-total {
    font-size: 12px;
  }

  .swiper-pagination {
    background: rgba(51, 51, 51, 0.75);
    width: auto;
    // right: 10px;
    border-radius: 64px;
    padding: 0 5px;
    color: white;
    line-height: 24px;
    font-size: 13px;
  }

  // 分页器
  .pagination {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 10px;
    z-index: 3;
    margin: 0;
  }
}

.area {
  height: 100vh;
}

.area::-webkit-scrollbar {
  display: none;
}

.container {
  height: 100vh;
}

.scrollView {
  display: flex;
  flex-direction: column;
  background: #f0f1f2;
  height: 100vh;
}

.char-scroll-box {
  flex: 1;
}

.footer-box {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: rgba(0, 0, 0, 0.8);
  background: #f5f5f5;
  min-height: auto;
  // height: calc(100rpx+ constant(safe-area-inset-bottom)); ///兼容 IOS<11.2/
  // height: calc(100rpx + env(safe-area-inset-bottom)); ///兼容 IOS>11.2/


  .words .icon-ic_picture {
    font-size: 25px;
  }

  .words, .emoji {
    font-size: 25px;
  }

  .input-box {
    display: flex;
    align-items: center;
    flex: 1;
    min-height: 32px;
    padding-right: 2.5px;
    margin-left: 9px;
    margin-right: 9px;
    background-color: #fff;
    border-radius: 16px;

    input, .el-textarea {
      flex: 1;
      padding-left: 10px;
      height: 100%;
      font-size: 14px;
      font-weight: normal;
      font-size: 16px;
    }


    .icon-ic_send {
      color: #ccc;
      font-weight: normal;
      margin-left: 15px;
      margin-right: 15px;
    }

    .isSend {
      color: #fd2a39;
    }
  }

  .input-box::-webkit-scrollbar {
    display: none;
  }

  .emoji .icon-ic_expression {
    margin-left: 9px;
    font-size: 25px;
  }

  input, .el-textarea {
    border: none;
    background-color: transparent;
  }

  .more .icon-gengduozhankai {
    margin-left: 9px;
    font-size: 25px;
  }
}


.chat-scroll-box {
  flex: 1;
  padding: 0 15px 0;
  overflow: hidden;

  .scroll-view {
    height: 100%;
    overflow: scroll;
  }

  .scroll-view::-webkit-scrollbar {
    display: none;
  }

  .chat-item {
    display: flex;
    margin-bottom: 8px;
    padding-bottom: 10px;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .msg-box {
      display: flex;
      align-items: center;
      max-width: 226px;
      margin-left: 11px;
      padding: 5px 12px;
      background: #fff;
      border-radius: 7px;
      word-break: break-all;
    }

    .img-box {
      width: 135px;
      margin-left: 11px;

      img {
        width: 135px;
      }
    }

    .product-box {
      width: 226px;
      margin-left: 11px;
      background-color: #fff;
      border-radius: 7px;
      overflow: hidden;

      img {
        width: 226px;
      }

      .info {
        padding: 8px 13px;
        text-align: left;

        .price {
          font-size: 20px;
          color: #fd2a39;

          .unit {
            font-size: 16px;
          }

          text {
            font-size: 14px;
          }
        }

        .line2 {
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden
        }
      }
    }

    .order-box {
      width: 226px;
      margin-left: 11px;
      background-color: #fff;
      border-radius: 7px;

      .title {
        padding: 7px 10px;
        font-size: 13px;
        color: #282828;
        border-bottom: 1px solid #eceff8;
      }

      .info {
        display: flex;
        padding: 10px;

        img {
          width: 62px;
          height: 62px;
          border-radius: 3px;
        }

        .product-info {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 8px;

          .name {
            font-size: 13px;
          }

          .price {
            font-size: 15px;
            //color: var(--view-priceColor);
          }
        }
      }
    }

    &.right-box {
      flex-direction: row-reverse;

      .msg-box {
        margin-left: 0;
        margin-right: 11px;
        background-color: #9cec60;
      }

      .img-box {
        margin-left: 0;
        margin-right: 11px;
      }

      .product-box {
        margin-left: 0;
        margin-right: 11px;
      }

      .order-box {
        margin-left: 0;
        margin-right: 11px;
      }
    }

    .em {
      margin: 0;
    }
  }
}

.day-box {
  font-size: 14px;
  color: #999;
  text-align: center;
  margin-bottom: 18px;
}

.msgItem {
  &:first-child {
    .day-box {
      padding-top: 15px;
    }
  }
}

.avatar-uploader {
  border: none !important;
  width: auto !important;
  height: auto !important;
  line-height: 10px;
}

.em {
  display: inline-block;
  width: 28px;
  height: 28px;
  margin: 22px 0 0 28px /* background-size: 4100%!important; background-position: 2.5% 62.5%!important; */
;
}
</style>
<style>
.em {
  display: inline-block;
  height: 1em;
  width: 1em;
  overflow: hidden;
  line-height: 18px;
  font-size: 22px;
  vertical-align: middle;
  margin-top: -4px;
  color: transparent !important;
  background-size: 4100%;
}

.scrollView .el-textarea::-webkit-scrollbar, .scrollView textarea::-webkit-scrollbar {
  display: none;
}

.scrollView textarea {
  background: transparent !important;
}

.scrollView .el-upload {
  display: inline;
  border: none !important;
}

.scrollView .el-upload--picture-card:hover, .scrollView .el-upload:focus {
  color: #97a8be !important;
}
</style>