<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {Dialog, Toast} from "vant";
import Compressor from "compressorjs";
import {
  upload_file,
  upload_files
} from "@/utils/api";
import {getQuestionCate,getCategory,publishShop,getIndustryConfigList,} from "@/api/idle"
export default {
  name: "ShopTransfer",
  components: {NavigationBar},
  data(){
    return{
      choose:{},
      ruleForm: {
        name: '',//发布分类
        title: '',//发布房源标题
        shop_name: '',//店铺名称
        shop_id: '',//店铺id
        address: '',//区域
        address_ids:{
          area_id:'',//省id
          city_id:'',//市id
          rigin_id:'',//区id
        },//区域id
        address_details: '',//详细地址
        roomNum:'',//房号               ===办公    4
        type: '',//类型
        type_id: '',//类型 id
        profession:'', // 经营行业       ===商铺 3
        profession_id:'', // 经营行业id  ===商铺 3
        fitment:'', // 写字楼装修        ===办公    4
        area: '',//建筑面积
        usage: '',//使用率              ===办公    4
        guaranty: '',//押付方式          ===办公    4
        Minimum: '',//起租              ===办公    4
        free: '',//免租                 ===办公    4
        rent:'',//租金
        Remaining:'',//剩余租期            ===商铺  3
        AssignmentFee:'',//转让费      ===商铺   3
        introduce:'',//房源介绍
      },
      rules: {
        name: [
          {required: true, message: '请选择分类', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        title: [
          {required: true, message: '请输入房源标题', trigger: 'blur'},
          {min: 10, max: 22, message: '长度在 10 到 22 个字符', trigger: 'blur'}
        ],
        address: [
          {required: true, message: '请选择区域', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        address_details: [
          {required: true, message: '请输入详细地址', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        type: [
          {required: true, message: '请选择类型', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        area: [
          {required: true, message: '请输入建筑面积', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        rent: [
          {required: true, message: '请输入租金', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        introduce: [
          {required: true, message: '请输入房源介绍', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],

      },

      guarantee:'',
      payrent:'',



      infomationLoad: [],
      infomationFiles: [],
      imfomationLimit: 6,
      logoLimit:1,
      logoFiles:[],
      logoUpload:[],
      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["png", "jpg", "jpeg", 'mp4', 'mp3'],
      typecolumns:[],
      defaultTypeIdex:0,
      checked:false,


      popShow:false,
      popType:'',
      address_li:this.$store.state.allAddress,
      chooseAddress:[],
      houseTypeList:[],
      professionList:[],
      facilitiesList:[],
      fitmentList:[],

      guaranteeList:[],
      payrentList:[],
      MinimumList:[],
    }
  },
  mounted() {
    // id== 3商铺   id==4办公
    this.choose = JSON.parse(this.$route.query.choose)

    //
    // getUserShop(this.$store.state.token).then(res=>{
    //   console.log(res)
    // }).catch(err=>{
    //   console.log(err)
    // })
    this.getCategory()

    // this.getfacilities()
    this.getIndustr()
    this.guarantee = '押一'
    this.payrent = '付一'
  },
  methods:{
    async submitForm(formName) {
      // console.log(await getRegAuthshop(this.$store.state.token))
      if(!this.$store.state.token){
        Dialog.confirm({
          title: '您还未登录，请先登录',
          confirmButtonText:'去登录'
        })
            .then(() => {
              // on confirm
              this.$router.push('/login')
            })
            .catch(() => {
              // on cancel
            });
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid){
          let list = this.facilitiesList.filter(item => {return item.choose === true})
          if(this.logoUpload.length<=0){
            Toast('请上传店铺logo')
            return
          }
          if(this.infomationLoad.length<=0){
            Toast('请上传照片')
            return
          }
          if(list.length<=0){
            Toast('请选择标签')
            return
          }
          if(this.checked === false){
            Toast('请阅读并同意协议')
          }
          //     name: '',//发布分类
          //     title: '',//发布房源标题
          //     address: '',//区域
          //     address_details: '',//详细地址
          //     roomNum:'',//房号               ===办公    4
          //     type: '',//类型
          //     type_id: '',//类型 id
          //     profession:'', // 经营行业       ===商铺 3
          //     profession_id:'', // 经营行业id  ===商铺 3
          //     fitment:'', // 写字楼装修        ===办公    4
          //     area: '',//建筑面积
          //     usage: '',//使用率              ===办公    4
          //     guaranty: '',//押付方式          ===办公    4 this.guarantee+'+'+this.payrent
          //     Minimum: '',//起租              ===办公    4
          //     free: '',//免租                 ===办公    4
          //     rent:'',//租金
          //     Remaining:'',//剩余租期            ===商铺  3
          //     AssignmentFee:'',//转让费      ===商铺   3
          //     introduce:'',//房源介绍
          publishShop(
              this.choose.id,
              JSON.stringify(this.infomationLoad),
              this.ruleForm.title,
              this.ruleForm.address,
              this.ruleForm.address_details,
              this.ruleForm.type,
              this.choose.id==3?this.ruleForm.profession:this.choose.id==4?this.ruleForm.fitment:'',
              this.ruleForm.area,
              this.ruleForm.rent,
              this.choose.id===3?this.ruleForm.Remaining:this.choose.id==4?this.ruleForm.free:'',
              this.choose.id==3?this.ruleForm.AssignmentFee:this.choose.id==4?this.ruleForm.Minimum:'',
              this.ruleForm.introduce,
              this.guarantee,
              this.payrent,
              this.ruleForm.usage,
              this.is_agree,
              this.ruleForm.roomNum,
              String((this.facilitiesList.filter(item => {return item.choose == true})).map(item=>{return item.id})),
              this.choose.id==3?1:this.choose.id==4?2:'',
              this.ruleForm.address_ids.area_id,
              this.ruleForm.address_ids.city_id,
              this.ruleForm.address_ids.rigin_id,
              this.logoUpload[0],
              '',
              '',
          ).then(res=>{
            if(res.data.status == 200){
              Toast('提交成功')
              this.$router.go(-1)
            }else {
              Toast(res.data.msg)
            }
          }).catch(err=>{
            console.log(err)
          })
        }else{
          console.log('error submit!!');
          return false;
        }
      });


          },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    async getIndustr(){
//       3店铺  4 办公
            if(this.choose.id == 3){
              let professionList = (await getIndustryConfigList).data.data
              professionList.forEach(item=>{
                delete item.children
              })
              this.houseTypeList = (await getQuestionCate(6)).data.data
              this.professionList = professionList
              let facilitiesList = (await getQuestionCate(9)).data.data
              facilitiesList.forEach(item=>{
                item.choose = false
              })
              this.facilitiesList = facilitiesList
            }
            if(this.choose.id == 4){
              this.houseTypeList = (await getQuestionCate(7)).data.data
              this.fitmentList = (await getQuestionCate(8)).data.data
              this.guaranteeList = (await getQuestionCate(10)).data.data
              this.payrentList = (await getQuestionCate(11)).data.data
              this.MinimumList = (await getQuestionCate(12)).data.data
              let facilitiesList = (await getQuestionCate(13)).data.data
              facilitiesList.forEach(item=>{
                item.choose = false
              })
              this.facilitiesList = facilitiesList
            }
    },




    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)

        }


      })
    },
    async beforeUpload(files) {
      console.log(files)
      let file = await this.yasuo(files)
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        if (files.type.startsWith('image/')) {   //图片
          //计算文件的大小
          const isLt5M = file.size / 1024 / 1024 < 1; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            return false;
          }
        } else if (files.type.startsWith('video/')) {//视频
          const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
    uploadFile(item) {
      // console.log(JSON.stringify(item))
      this.$message("文件上传中........");
      if (item.file.type.startsWith('image/')) {
        upload_file(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 22) {
              this.infomationLoad.push(res.data.data.url)
            }
            if(item.filename == 'logo'){
              // this.logoUpload[0] = res.data.data.url
              console.log(this.logoUpload)
              if(this.logoUpload.length<=0){
                this.logoUpload.push(res.data.data.url)
              }else{
                this.logoUpload = []
                this.logoUpload.push(res.data.data.url)
              }
              this.logoFiles = []
            }
            // this.userUpload.push({tempFilePath:res.data.data.url,fileType:'image'})
          }
        })
      } else if (item.file.type.startsWith('video/')) {
        upload_files(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 11) {
              this.kanchaUpload.push(res.data.data.url)
            }
            if (item.filename == 22) {
              this.DoorUpload.push(res.data.data.url)
            }
          }
        })
      }
    },
    //上传成功后的回调
    handleSuccess() {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.handleClose();
    },
    fileChange2(file, infomationFiles) {
      let list = []
      infomationFiles.forEach(item => {
        const FileExt = item.name.replace(/.+\./, "").toLowerCase();
        if (this.fileType.includes(FileExt)) {
          list.push(item)
          return true;
        } else {
          return false;
        }
      })
      this.infomationFiles = list;
    },
    fileChange3(file, logoFiles) {
      let list = []
      logoFiles.forEach(item => {
        const FileExt = item.name.replace(/.+\./, "").toLowerCase();
        if (this.fileType.includes(FileExt)) {
          list.push(item)
          return true;
        } else {
          return false;
        }
      })
      console.log(list)
      this.logoFiles = list;
    },
    async handleRemove() {
    },
    handleClose(i) {
      this.yanshoufileList.splice(i, 1); //删除上传的文件
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
    },
    deleteImg(list, index, fileList) {
      list.splice(index, 1)
      fileList.splice(index, 1)
    },


    getCategory() {
      getCategory().then(res => {
        if (res.data.status == 200) {
          let list = res.data.data
          this.typecolumns = list.filter(item => {return item.id ==3 ||item.id == 4})
          this.defaultTypeIdex = this.typecolumns.findIndex(item => {return item.id == this.choose.id})
          this.ruleForm.name = this.typecolumns[this.defaultTypeIdex].cate_name
        }
      }).catch(err => {
        console.log(err)
      })
    },

    choose_type() {
      this.popShow = true
      this.popType = 'classification'
    },
    choose_address() {
      this.popShow = true
      this.popType = 'choose_address_P'
    },
    choose_genre(){
      this.popShow = true
      this.popType = 'choose_choose_genre_P'
    },
    choose_profession(){
      this.popShow = true
      this.popType = 'choose_profession_P'
    },
    choose_fitment(){
      this.popShow = true
      this.popType = 'choose_fitment_P'
    },
    choose_guarantee(){
      this.popShow = true
      this.popType = 'choose_guarantee_P'
    },
    choose_payrent(){
      this.popShow = true
      this.popType = 'choose_payrent_P'
    },
    choose_Minimum(){
      console.log(123456)
      this.popShow = true
      this.popType = 'choose_Minimum_P'
    },
    choose_free(){
      this.popShow = true
      this.popType = 'choose_free_P'
    },


    chooseFacilities(item){
      item.choose = !item.choose;
    },

    closePop(){},
    savePop(){
      if (this.popType == 'classification') {
          this.ruleForm.name = (this.$refs.classification.getValues())[0].cate_name
          this.defaultTypeIdex = (this.$refs.classification.getIndexes())[0]
      }
      if(this.popType == 'choose_address_P'){
        this.chooseAddress = this.$refs.choose_address.getValues()
        this.ruleForm.address = String(this.chooseAddress.map(item=>{return item.name}))
        this.ruleForm.address_ids.area_id = ((this.$refs.choose_address.getValues())[0]).id
        this.ruleForm.address_ids.city_id = ((this.$refs.choose_address.getValues())[1]).id
        this.ruleForm.address_ids.rigin_id = ((this.$refs.choose_address.getValues())[2]).id
      }
      if(this.popType == 'choose_choose_genre_P'){
        this.ruleForm.type = (this.$refs.choose_genre.getValues())[0].cate_name
        this.ruleForm.type_id = (this.$refs.choose_genre.getValues())[0].id
      }
      if(this.popType == 'choose_profession_P'){
        this.ruleForm.profession = (this.$refs.choose_profession.getValues())[0].name
        this.profession_id = (this.$refs.choose_profession.getValues())[0].id
      }
      if(this.popType == 'choose_fitment_P'){
        this.ruleForm.fitment = (this.$refs.choose_fitment.getValues())[0].cate_name
      }

      if(this.popType == 'choose_guarantee_P'){
        this.guarantee = (this.$refs.choose_guarantee.getValues())[0].cate_name
      }
      if(this.popType == 'choose_payrent_P'){
        this.payrent = (this.$refs.choose_payrent.getValues())[0].cate_name
      }
      if(this.popType == 'choose_Minimum_P'){
        this.ruleForm.Minimum = (this.$refs.choose_Minimum.getValues())[0].cate_name
      }
      if(this.popType == 'choose_free_P'){
        this.ruleForm.free = (this.$refs.choose_free.getValues())[0].cate_name
      }







      this.popShow = false
    },
  },
  computed: {
    infomationsrcList() {
      let list = []
      JSON.parse(JSON.stringify(this.infomationLoad)).forEach(item => {
        list.push(this.$store.state.imageHost + item)
      })
      return list
    },

    guaranty(){
      return this.guarantee+'+'+this.payrent
    },
    is_agree(){
      let num = -1
      if(this.checked == true){num = 1}
      if(this.checked == false){num = 2}
      return num
    },
  },
  watch:{
    choose: {
      handler(n) {
        let keyValues = Object.entries(this.rules)
        if(n.id == 3){
          keyValues.splice(keyValues.findIndex(item=>{return item[0] == 'title'}),0, ["shop_name",[ {required: true, message: '请输入店铺名称', trigger: 'blur'},]]); // insert key value at the index you want like 1.
          keyValues.push(["Remaining",[ {required: true, message: '请输入剩余租期', trigger: 'blur'}]])
          keyValues.push(["AssignmentFee",[ {required: true, message: '请输入转让费用', trigger: 'blur'}]])
          keyValues.splice(keyValues.findIndex(item=>{return item[0] == 'area'}),0, ["profession",[ {required: true, message: '请选择经营行业', trigger: 'blur'},]]); // insert key value at the index you want like 1.
        }
        if(n.id == 4){
          keyValues.splice(keyValues.findIndex(item=>{return item[0] == 'rent'}),0, ["free",[ {required: true, message: '请选择免租期', trigger: 'blur'},]]); // insert key value at the index you want like 1.
          keyValues.splice(keyValues.findIndex(item=>{return item[0] == 'free'}),0, ["Minimum",[ {required: true, message: '请选择起租期', trigger: 'blur'},]]); // insert key value at the index you want like 1.
          keyValues.splice(keyValues.findIndex(item=>{return item[0] == 'Minimum'}),0, ["guaranty",[ {required: true, message: '请选择押付方式', trigger: 'blur'},]]); // insert key value at the index you want like 1.
          keyValues.splice(keyValues.findIndex(item=>{return item[0] == 'guaranty'}),0, ["usage",[ {required: true, message: '请输入使用率', trigger: 'blur'},]]); // insert key value at the index you want like 1.
          keyValues.splice(keyValues.findIndex(item=>{return item[0] == 'type'}),0, ["roomNum",[ {required: true, message: '请输入楼层房号', trigger: 'blur'},]]); // insert key value at the index you want like 1.
          keyValues.splice(keyValues.findIndex(item=>{return item[0] == 'area'}),0, ["fitment",[ {required: true, message: '请选择写字楼装修', trigger: 'blur'},]]); // insert key value at the index you want like 1.
        }
        // console.log(keyValues)
        this.rules = Object.fromEntries(keyValues)
        this.$nextTick(() => {
          this.$refs['ruleForm'].resetFields();
        })
      },
      deep: true
    },
    guaranty(n){
      console.log(n)
      this.ruleForm.guaranty = n
    },
  },
  popShow(n) {
    if (n == false) {
      this.popType = ''
    }
  },
}
</script>

<template>
<div class="container shopTransfer">
  <div class="area">
    <navigationBar :title="'发闲置'" class="navigationBar"></navigationBar>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="95px" class="demo-ruleForm">
      <div class="card" @click.stop="choose_type">
        <el-form-item label="选择分类" prop="name" @click.stop="choose_type">
          <div class="inn" @click.stop="choose_type">
            <el-input v-model="ruleForm.name" placeholder="请选择"></el-input>
            <span class="iconfont icon-Frame-4"></span>
            <div style="width: 100%;height: 20px;background-color: transparent;position:absolute;"></div>
          </div>
        </el-form-item>
      </div>

      <div class="shopAuthentication">
      <div v-if="choose.id==3">
        <div class="cont" >
          <div class="title">店铺认证<span class="text">（如发布店铺闲置宝贝，务必填写完整信息）</span></div>
<!--          <div class="line">-->
<!--            <div class="left">店铺名称</div>-->
<!--            <input type="text" class="right" placeholder="请填写" v-model="ruleForm.shop_name">-->
<!--          </div>-->
          <el-form-item label="店铺名称" prop="shop_name">
            <div class="inn">
              <el-input v-model="ruleForm.shop_name" type="text" placeholder="请输入" max="22" maxlength="22"></el-input>
            </div>
          </el-form-item>
          <div class="title" style="border-bottom: none">店铺LOGO</div>
          <div class="uploadBox">
            <el-upload
                class="avatar-uploader"
                action
                :http-request="uploadFile"
                ref="upload"
                :limit="logoLimit"
                :on-remove="handleRemove"
                :file-list="logoFiles"
                :on-exceed="handleExceed"
                :before-upload="beforeUpload"
                :show-file-list="false"
                :headers="headers"
                :on-change="fileChange3"
                multiple
                name="logo"
            >
              <div v-if="logoUpload.length<=0" class="inn">
                <span class="iconfont icon-xiangji"></span>
              </div>
              <img :src="$store.state.imageHost+logoUpload" alt="" class="showImg" v-if="logoUpload">
            </el-upload>
          </div>

        </div>
      </div>
     </div>


      <div class="card cardTwo inputBox">
        <div class="imgTitle">上传照片（{{infomationLoad.length}}/{{imfomationLimit}}）</div>
        <div class="img_box">
          <div class="img_list">
            <div class="evert li" v-for="(item,index) in infomationLoad" :key="index">
              <el-image
                  class="avatar"
                  fit="cover"
                  :src="$store.state.imageHost+item"
                  :preview-src-list="infomationsrcList">
              </el-image>
              <van-icon name="clear" class="icon" @click.stop="deleteImg(infomationLoad,index,infomationFiles)"/>
            </div>
            <el-upload
                v-if="infomationLoad.length<=5"
                class="avatar-uploader"
                action
                :http-request="uploadFile"
                ref="upload"
                :limit="imfomationLimit"
                :on-remove="handleRemove"
                :file-list="infomationFiles"
                :on-exceed="handleExceed"
                :before-upload="beforeUpload"
                :show-file-list="false"
                :headers="headers"
                :on-change="fileChange2"
                multiple
                name="22"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
      </div>
      <div class="outTitle">
        请填写房源相关信息
      </div>
      <div class="card lastBox">
        <el-form-item label="房源标题" prop="title">
          <div class="inn">
            <el-input v-model="ruleForm.title" type="text" placeholder="请输入" max="22" maxlength="22"></el-input>
          </div>
        </el-form-item>


        <el-form-item label="区域" prop="address" @click="choose_address">
          <div class="inn" @click="choose_address">
            <el-input v-model="ruleForm.address" placeholder="请选择"></el-input>
            <span class="iconfont icon-Frame-4"></span>
            <div style="width: 100%;height: 20px;background-color: transparent;position:absolute;"></div>
          </div>
        </el-form-item>
        <el-form-item :label="choose.id==3?'商铺地址':'写字楼地址'" prop="address_details">
          <div class="inn">
            <el-input v-model="ruleForm.address_details" type="text" placeholder="请输入" maxlength="30"></el-input>
          </div>
        </el-form-item>
        <el-form-item :label="'楼层房号'" prop="roomNum" v-if="choose.id==4">
          <div class="inn">
            <el-input v-model="ruleForm.roomNum" type="text" placeholder="请输入" maxlength="30"></el-input>
          </div>
        </el-form-item>
        <el-form-item :label="choose.id==3?'商铺类型':'写字楼类型'" prop="type" @click="choose_genre">
          <div class="inn" @click="choose_genre">
            <el-input v-model="ruleForm.type" placeholder="请选择"></el-input>
            <span class="iconfont icon-Frame-4"></span>
            <div style="width: 100%;height: 20px;background-color: transparent;position:absolute;"></div>
          </div>
        </el-form-item>
        <el-form-item label="经营行业" prop="profession" @click="choose_profession" v-if="choose.id==3">
          <div class="inn" @click="choose_profession">
            <el-input v-model="ruleForm.profession" placeholder="请选择"></el-input>
            <span class="iconfont icon-Frame-4"></span>
            <div style="width: 100%;height: 20px;background-color: transparent;position:absolute;"></div>
          </div>
        </el-form-item>
        <el-form-item label="写字楼装修" prop="fitment" @click="choose_fitment" v-if="choose.id==4">
          <div class="inn" @click="choose_fitment">
            <el-input v-model="ruleForm.fitment" placeholder="请选择"></el-input>
            <span class="iconfont icon-Frame-4"></span>
            <div style="width: 100%;height: 20px;background-color: transparent;position:absolute;"></div>
          </div>
        </el-form-item>
        <el-form-item :label="'建筑面积'" prop="area">
          <div class="inn">
            <el-input v-model="ruleForm.area" type="number" placeholder="请输入" maxlength="7" oninput="if(value.length>8)value=value.slice(0,8)"></el-input>
            <span class="unit">㎡</span>
          </div>
        </el-form-item>

        <el-form-item :label="'使用率'" prop="usage" v-if="choose.id==4">
          <div class="inn">
            <el-input v-model="ruleForm.usage" type="text" placeholder="请输入" maxlength="4"></el-input>
            <span class="unit">%</span>
          </div>
        </el-form-item>
        <el-form-item :label="'押付方式'" prop="guaranty" v-if="choose.id==4">

          <div class="inn outBlock">
            <div class="block" @click="choose_guarantee"><span class="blockTitle">{{guarantee}}</span><span class="iconfont icon-Frame-4"></span></div>
            <div class="block" @click="choose_payrent"><span class="blockTitle">{{payrent}}</span><span class="iconfont icon-Frame-4"></span></div>
            <!--            <el-input v-model="ruleForm.guaranty" type="text" placeholder="请输入"></el-input>-->
          </div>
        </el-form-item>
        <el-form-item :label="'起租期'" prop="Minimum" v-if="choose.id==4">
          <div class="inn">
            <div class="outBlock" @click="choose_Minimum">
              <div class="block"><span class="blockTitle">{{ruleForm.Minimum?ruleForm.Minimum:'请选择'}}</span><span class="iconfont icon-Frame-4"></span></div>
            </div>
            <!--            <el-input v-model="ruleForm.guaranty" type="text" placeholder="请输入"></el-input>-->
          </div>
        </el-form-item>
        <el-form-item :label="'免租期'" prop="free" v-if="choose.id==4">
          <div class="inn">
            <div class="outBlock">
              <div class="block" @click="choose_free"><span class="blockTitle">{{ruleForm.free?ruleForm.free:'请选择'}}</span><span class="iconfont icon-Frame-4"></span></div>
            </div>
            <!--            <el-input v-model="ruleForm.guaranty" type="text" placeholder="请输入"></el-input>-->
          </div>
        </el-form-item>







        <el-form-item :label="'租金'" prop="rent">
          <div class="inn">
            <el-input v-model="ruleForm.rent" type="number" placeholder="请输入" oninput="if(value.length>8)value=value.slice(0,8)"></el-input>
            <span class="unit">元/月</span>
          </div>
        </el-form-item>


        <el-form-item :label="'剩余租期'" prop="Remaining" v-if="choose.id==3">
          <div class="inn">
            <el-input v-model="ruleForm.Remaining" type="number" placeholder="请输入" oninput="if(value.length>5)value=value.slice(0,5)"></el-input>
            <span class="unit">月</span>
          </div>
        </el-form-item>
        <el-form-item :label="'转让费'" prop="AssignmentFee" v-if="choose.id==3">
          <div class="inn">
            <el-input v-model="ruleForm.AssignmentFee" type="number" placeholder="请输入" oninput="if(value.length>10)value=value.slice(0,10)"></el-input>
            <span class="unit">元</span>
          </div>
        </el-form-item>





      </div>

      <div class="card textareaBox">
        <el-form-item label="房源介绍" prop="introduce">
          <textarea name="" id="" cols="30" rows="10" v-model="ruleForm.introduce" class="textarea" maxlength="200"></textarea>
        </el-form-item>
      </div>



      <div class="facilities">
        <div class="ul">
          <div class="li" v-for="(item,index) in facilitiesList" @click="chooseFacilities(item)" :key="index" :class="item.choose?'chooseFacilities':''">
              {{item.cate_name}}
          </div>
        </div>
        <div style="height: 100px;width: 100%"></div>

      </div>

      <div class="bottomBtn">
        <div class="agreementBpox">
          <van-checkbox v-model="checked" checked-color="#fc223b">
            <template #default>
              <div class="checkboxText">阅读并同意<a href="">《用户协议》</a>及<a href="">《隐私政策》</a></div>
            </template>
          </van-checkbox>
        </div>
        <!--            <el-button @click="resetForm('ruleForm')">重置</el-button>-->
        <div @click="submitForm('ruleForm')" class="oneSubmit">提交</div>
      </div>
    </el-form>

    <van-popup v-model="popShow" round closeable position="bottom" class="popup">
      <div class="classification" v-if="popType=='classification'">
        <van-picker
            :default-index="defaultTypeIdex"
            :columns="typecolumns"
            value-key="cate_name"
            ref="classification"
        />
      </div>

      <div class="choose_address_P" v-if="popType == 'choose_address_P'">
        <van-picker  :columns="address_li" value-key="name" ref="choose_address"/>
      </div>
      <div class="choose_choose_genre_P" v-if="popType=='choose_choose_genre_P'">
        <van-picker :columns="houseTypeList" value-key="cate_name" ref="choose_genre" />
      </div>
      <div class="choose_profession_P" v-if="popType=='choose_profession_P'">
        <van-picker :columns="professionList" value-key="name"  ref="choose_profession"></van-picker>
      </div>
      <div class="choose_fitment_P" v-if="popType == 'choose_fitment_P'">
        <van-picker :columns="fitmentList" value-key="cate_name" ref="choose_fitment"></van-picker>
      </div>

      <div class="choose_guarantee_P" v-if="popType == 'choose_guarantee_P'">
        <van-picker :columns="guaranteeList" value-key="cate_name" ref="choose_guarantee"></van-picker>
      </div>
      <div class="choose_payrent_P" v-if="popType == 'choose_payrent_P'">
        <van-picker :columns="payrentList" value-key="cate_name" ref="choose_payrent"></van-picker>
      </div>

      <div class="choose_Minimum_P" v-if="popType == 'choose_Minimum_P'">
        <van-picker :columns="MinimumList" value-key="cate_name" ref="choose_Minimum"></van-picker>
      </div>
      <div class="choose_free_P" v-if="popType == 'choose_free_P'">
        <van-picker :columns="MinimumList" value-key="cate_name" ref="choose_free"></van-picker>
      </div>

      <div class="bottomBox">
        <div class="close btn" @click="closePop">取消</div>
        <div class="save btn" @click="savePop">确定</div>
      </div>
    </van-popup>

  </div>
</div>
</template>

<style scoped lang="scss">
.popup {


  .bottomBox {
    display: flex;
    background: #fff;
    padding: 10px 60px;
    justify-content: space-around;

    .btn {
      place-content: center;
      place-items: center;
      gap: 10px;
      flex-shrink: 0;
      padding: 8px 40px;
      border-radius: 10px;
      background: #f0f0f0;
      color: #1b1b1b;
      font-weight: 500;
      font-size: 16px;
    }

    .save {
      background: #05C160;
      color: #fff;
    }
  }
}
.bottomBtn{
  max-width: 750px;
}
.container {
  background-color: #F9F9F9;
  height: 100vh;
  text-align: left;
  margin-bottom: 100px;
}
.el-form {
  //margin: 0 16px;
}
.el-form-item {
  margin-bottom: 0;

}
.is-error {
  margin-bottom: 22px !important;
}
.card {
  border-radius: 10px;
  background: #ffffff;
  margin: 0 16px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.shopAuthentication {
  background: #fff;
  flex: 1;
  border-radius: 10px 10px 0 0;
  padding-top: 10px;
  text-align: left;
  margin: 10px 16px;
  padding-bottom: 10px;
  border-radius: 10px;
  .cont{
    padding: 10px;
  }
  .title {
    color: #1b1b1b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid var(--房产描边F6F6F6, #f6f6f6);
    padding: 10px 0px;
    margin-bottom: 10px;
    .text {
      color: #c7c7c7;
      font-size: 12px;
    }
  }

  .line {
    display: flex;
    margin: 0 16px;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid var(--房产描边F6F6F6, #f6f6f6);

    .left {
      color: #666666;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .right {
      color: #1b1b1b;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding-left: 10px;
      border: none;
      flex: 1;
    }
    .right::placeholder{
      color: #c7c7c7;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .uploadBox{
    margin: 10px 0px;
    .inn{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .iconfont{
        font-size: 25px;
      }

    }
  }
}

.cardTwo {
  padding-left: 10px;
}
.inputBox {
  .top {
    border-bottom: 1px solid #E9E9E9;

    .title {
      color: #1b1b1b;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      border: none;
      width: 100%;
      padding: 0 0 10px 0;
    }


  }

  .body {

    .el-textarea {

      .el-textarea__inner {
        padding: 5px 0px;

      }
    }
  }

}
.inn {
  .el-input__inner {
    border: none;
    height: 20px;
    line-height: 20px;
  }

  .iconfont {
    width: 20px;
    font-size: 28px;
    color: #999999;
  }
  .unit{
    white-space: nowrap;
    text-align: right;
    color: #1b1b1b;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .el-input.is-disabled .el-input__inner {
    background-color: #fff;
  }


}
.outBlock{
  display: flex;
  .block{
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    color: #c7c7c7;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-left: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 10px;
    margin-right: 16px;
    margin-left: 5px;
    .title{
    }
  }
}

.inputBox {
  .body {
    .el-textarea {
      .el-textarea__inner {
        padding: 5px 0px;
        background-color: #fff !important;
        color: #000000;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
      }
    }
  }

  .el-form-item__content {
    margin-left: 0 !important;
  }
}
.bottomBtn {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px 0;
  justify-content: center;
  box-shadow: 0px -0.5px 2px #99999926;
  .oneSubmit {
    border-radius: 10px;
    background-color: #fc223b;
    color: #fff;
    text-align: center;
    line-height: 45px;
    padding-left: 40%;
    padding-right: 40%;
    margin-left: 16px;
    margin-right: 16px;
  }
  .agreementBpox{
    margin: 10px 16px;
    .checkboxText{
      color: #666666;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      a{
        color: #F96422;
      }
    }
  }
}
.img_box {
  margin-top: 10px;

  .title {
    text-align: left;
    font-size: 12px;
    color: #999999;
  }

  .img_list {
    margin-top: 4px;
    display: flex;
    flex-wrap: wrap;

    .li {
      width: 97px;
      height: 97px;
      margin-right: 5px;
      position: relative;
      border-radius: 10px;
      margin-top: 15px;

      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      .icon {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(20%, -50%);
        font-size: 20px;
      }
    }

    .addImg {
      border: 1px dashed #999999;
      border-radius: 10px;
      position: relative;

    }

    .addImg:after,
    .addImg:before {
      position: absolute;
      content: '';
      display: block;
      width: 30%;
      height: 2px;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }

    .addImg:before {
      width: 2px;
      height: 30%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }
  }

}
.avatar-uploader {
  margin-top: 15px;
  width: 97px;
  height: 97px;
  line-height: 97px;
}

.outTitle{
  color: #1b1b1b;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 10px 16px;
}
.lastBox {
  .el-form-item {
    margin-bottom: 20px;
  }

  .el-form-item:nth-last-of-type(1) {
    margin-bottom: 0px;
  }
}

.textareaBox{
  padding-right: 0;
  padding-left: 0;
  .el-form-item{
    display: flex;
    flex-direction: column;
    .el-form-item__content{
      //background-color: red;
    }
  }
  .textarea{
    border: none;
    width: 90%;
    margin: 10px 16px 0 16px;
    max-height: 80px;
  }

}

.facilities{
  background-color: #fff;
  .ul{
    margin: 10px 16px;
    display: flex;
    flex-wrap: wrap;
    .li{
      margin-top: 10px;
      width: 20%;
      margin-right: 2.5%;
      margin-left: 2.5%;
      text-align: center;
      display: inline-flex;
      place-content: center;
      place-items: center;
      gap: 10px;
      flex-shrink: 0;
      padding: 4px 0;
      border-radius: 30px;
      background: #f8f8f8;
    }
    .chooseFacilities{
      background: #fc223b;
      color: #ffffff;
    }
  }
}
.showImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>





<style>

.shopTransfer .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: '*';
  color: #F56C6C;
  margin-left: 4px;
}

.shopTransfer .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  display: none;
}

.shopTransfer {
  .el-form-item__label {
    color: #1b1b1b;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .el-form-item__content {
    line-height: 20px;
  }

  .inn {
    display: flex;
    align-items: center;
    position: relative;
    .el-input__inner {
      border: none;
      height: 20px;
      line-height: 20px;
    }

    .iconfont {
      width: 20px;
      font-size: 28px;
      color: #999999;
    }

    .el-input.is-disabled .el-input__inner {
      background-color: #fff;
    }
  }

  .inputBox {
    .body {
      .el-textarea {
        .el-textarea__inner {
          padding: 5px 0px;
          background-color: #fff !important;
          color: #000000;
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
        }
      }
    }

    .el-form-item__content {
      margin-left: 0 !important;
    }
  }

  .el-radio-group {
    padding: 0 20px;
  }

  .el-form-item__label {
    text-align: left;
  }

  .priceBox {
    .el-form-item {
      .el-form-item__content {
        .inne {
        }

        .el-input {
          width: 90%;

          .el-input__inner {
            text-align: right;
          }
        }
      }
    }
  }

  .bottomBtn {
    .el-form-item {
      width: 100%;
    }

    .el-form-item__content {
      margin-left: 0 !important;
      display: flex !important;
      width: 100%;
    }
  }
}


.shopTransfer .textareaBox .el-form-item .el-form-item__content{
    margin-left:0!important;
}
.shopTransfer .textareaBox .el-form-item .el-form-item__label {
  width: 100%!important;
  padding-right: 0!important;
  padding-bottom: 10px;
  border-bottom: 1px solid #f6f6f6;
  padding-left: 16px;
}
</style>