<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {getMySaleDetail} from "@/api/idle";
import {isValidJSON} from "@/utils/tool";

export default {
  name: "sellDetails",
  components: {navigationBar},
  data(){
    return{
      obj:{},
    }
  },
  methods:{
    godetails(){
      console.log('详情')
    },
  },
  mounted() {
    console.log(this.$route.query)
    if(this.$route.query.id){
      getMySaleDetail(this.$route.query.id).then(res=>{
        if(res.data.status == 200){
          this.obj = res.data.data
          if (this.obj.images) {
            if (isValidJSON(this.obj.images)) {
              this.obj.showImg = JSON.parse(this.obj.images)[0]
            } else {
              this.obj.showImg = this.obj.images.split(',')[0]
            }
            if (typeof this.obj.showImg == 'string') {
              if (!this.obj.showImg.startsWith('https:')) {
                this.obj.showImg = this.$store.state.imageHost + this.obj.showImg
              } else {
                this.obj.showImg = this.obj.showImg
              }
            } else {
              this.obj.showImg = this.$store.state.imageHost + this.obj.showImg.tempFilePath
            }
          }
        }
      })
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="''" class="navigationBar"></navigationBar>
      <div class="topNav">
        <div class="navInner">
          <div class="textBox">
            <!--          动态-->
            <div class="title">等待买家取货~</div>
            <div class="text">交易已形成，尽快主动联系买家上门取货</div>
            <!--          动态-->
          </div>
        </div>
      </div>

      <div class="goodsBox">
        <div class="every" @click.stop="godetails">
          <div class="leftImg">
            <img :src="obj.showImg" alt="" class="leftImg">
          </div>
          <div class="rightInfo">
            <div class="name">{{obj.product_name}}</div>
            <div class="price">
              <div class="unit">￥</div>
              {{ obj.price }}
            </div>
          </div>
        </div>
      </div>

<!--      <div class="bottomBntBox">-->
<!--        <div class="Btn">-->
<!--          在线聊-->
<!--        </div>-->
<!--        <div class="Btn nocofrim">-->
<!--          订单完成-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>

</template>

<style scoped lang="scss">
.area{
  position: relative;
  .navigationBar{
    position: relative;
    z-index: 3;
  }
  .topNav{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;

    .navInner{
      width: 100%;
      min-height: 150px;
      background-image: url("https://picture.bjbjkj.top/sellDetailsTopNav.png");
      background-repeat: no-repeat;
      background-size: 178px 89px;
      background-position: 100% 50%;
      display: flex;
      align-items: flex-end;
    }

    .textBox{
      text-align: left;
      margin-left: 16px;
      .title{
        color: #1b1b1b;
        font-weight: 500;
        font-size: 22px;
        line-height: 33px;
      }
      .text{
        color: #1b1b1b;
        font-weight: 400;
        font-size: 13px;
        line-height: 19.5px;
      }
    }
  }
}

.goodsBox{
  background: #F4F6FA;
  min-height: 75vh;
  margin-top: 100px;
  padding-top: 1px;
  .every{
    display: flex;
    margin: 10px 16px;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    .leftImg{
      width: 74px;
      height: 74px;
      border-radius: 10px;
      object-fit: cover;
    }
    .rightInfo{
      padding-left: 10px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name{
        color: #1b1b1b;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        display: -webkit-box;
        /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
        -webkit-box-orient:vertical;
        /*要显示的行数*/
        -webkit-line-clamp:2;
        /* 溢出部分隐藏 */
        overflow:hidden;
      }
      .price{
        display: flex;
        color: #fd2a39;
        font-weight: 500;
        line-height: 24px;
        font-size: 16px;
        .unit{
          font-size: 12px;
        }
      }
    }
  }
}
.bottomBntBox{
  position: fixed;
  width: 100%;
  transform: translate(-50%,0);
  left: 50%;
  bottom: 0;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  padding-bottom: 14px;
  padding-top: 14px;
  .Btn{
    width: fit-content;
    height: fit-content;
    display: inline-flex;
    place-content: center;
    place-items: center;
    gap: 4px;
    padding: 6px 10px;
    border-radius: 25px;
    border: 0.5px solid #999999;
    margin-left: 10px;
    color: #999999;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .nocofrim{
    border: none;
    background: #c7c7c7;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>