<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {PlategetProductdetail} from "@/api/idle";
import {Toast} from "vant";
import {isValidJSON} from "@/utils/tool";
import Swiper from "swiper";

export default {
  name: "idle_house_details",
  components: {NavigationBar},
  data(){
    return{
      chooseId:this.$route.query.item,
      shop:{},
      swiperLi:[],

      swiper:{},
    }
  },
  methods:{
    getDetails(){
      PlategetProductdetail(this.chooseId).then(res=>{
        if(res.data.status == 200){
          this.shop = res.data.data
          if(isValidJSON(this.shop.img)){
            this.swiperLi = JSON.parse(this.shop.img)
            this.shop.imgLi = JSON.parse(this.shop.img)
            this.shop.tagLi = this.shop.tag.split(',')
          }
          this.$nextTick(()=>{
            this.swiper = new Swiper(".swiper", {
              pagination: {
                el: ".swiper-pagination",
                type: "fraction",
              },
              loop: false,
              autoplay: {
                delay: 3000,
                disableOnInteraction: false,
              },
            });
          })
          console.log(this.shop)
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
  },
  mounted() {
    this.getDetails()
     new Swiper(".swiper");

  },
  computed:{
    srcList(){
      let list = []
      this.swiperLi.forEach(item=>{
        list.push(this.$store.state.imageHost+item)
      })
      return list
    }
  },
}
</script>

<template>
<div class="container">
  <div class="area">
    <navigationBar :title="''" class="navigationBar"></navigationBar>
    <div class="swiper" >
      <div class="swiper-wrapper">
        <div
            v-for="(item,index) in shop.imgLi" :key="index"
            class="swiper-slide"
        >
          <el-image
              fit="cover"
              style="width: 100%;height: 100%"
              :src="$store.state.imageHost+item"
              :preview-src-list="srcList">
          </el-image>
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="pagination">
        <div class="swiper-pagination"></div>
      </div>
    </div>

    <div class="inner">

      <div class="houseTitle borderBottom">
        {{shop.title}}
      </div>
      <div class="priceAndArea borderBottom">
        <div class="price priceAndAreaItem">
          <div class="top">
            <span class="Num">{{shop.shop[0].rent}}</span><span class="Unit">元/月</span>
          </div>
          <div class="botoom">租金</div>
        </div>
        <div class="price priceAndAreaItem">
          <div class="top">
            <span class="Num">{{shop.measure}}</span><span class="Unit">㎡</span>
          </div>
          <div class="botoom">面积</div>
        </div>
        <div class="price priceAndAreaItem" v-if="shop.type == 1">
          <div class="top">
            <span class="text">{{shop.plege}}{{shop.symbol}}</span>
          </div>
          <div class="botoom">押付方式</div>
        </div>
        <div class="price priceAndAreaItem" v-if="shop.type == 2">
          <div class="top">
            <span class="Num">{{shop.transfer_cost}}</span><span class="Unit">元</span>
          </div>
          <div class="botoom">转让费</div>
        </div>
      </div>

      <div class="infoOne borderBottom">

        <div class="line">
          区域：{{shop.area}}
        </div>
        <div class="lineBox">
          <div class="left">类型：{{shop.shop_type}}</div>
<!--          <div class="right" v-if="shop.type == 2">行业：{{shop.insdustry}}</div>-->
        </div>
        <div class="line" v-if="shop.type == 1">
          行业：{{shop.insdustry}}
        </div>
        <div class="line">
          剩余租期：{{typeof shop.lease_term == 'string'?shop.lease_term:`${shop.lease_term}个月`}}
        </div>

        <div class="lineBox" v-if="shop.type == 2">
          <div class="left">免租期：{{shop.lease_term}}</div>
          <div class="right">起租期：{{shop.transfer_cost}}</div>
        </div>
        <div class="lineBox" v-if="shop.type == 2">
          <div class="left">使用率：{{shop.use}}%</div>
          <div class="right">装修：{{shop.insdustry}}</div>
        </div>

        <div class="line">
          地址：{{shop.area}}
        </div>




      </div>


      <div class="infoTwo borderBottom housetag">
        <div class="right">
          <div class="rightItem" v-for="(item,index) in shop.tagLi" :key="index">
            {{item}}
          </div>
        </div>
      </div>


      <div class="introduceBox">
        <div class="title">房源介绍</div>
        <div class="Innercontent">
          {{shop.desc}}
        </div>
      </div>

      <div style="width: 100%;height: 100px"></div>

      <div class="bottomBtnBox">
        <a :href="`tel:131`"><div class="Btn"><span class="iconfont icon-dianhua3"></span>电话联系</div></a>
      </div>



    </div>


  </div>
</div>
</template>

<style scoped lang="scss">
.navigationBar{
  position: fixed;
  background-color: transparent;
  z-index: 2;
}
.container{
  height: 100vh;
}
.area{
  height: 100vh;
}
.swiper-slide{
  //background-color: red;
}
.swiper-slide {
  // background: red;
  height: 372px;
  background-size: cover;
  background-position: center center;
}
.swiper {
  overflow: hidden;
  position: relative;
  min-height: 372px;

  .swiper-slide {
    max-height: 372px;
  }
  .img {
    width: 100%;
  }

  // 分页器
  /* 两种都可以 */
  .swiper-pagination-total {
    font-size: 12px;
  }

  .swiper-pagination {
    background: rgba(51, 51, 51, 0.75);
    width: auto;
    // right: 10px;
    border-radius: 64px;
    padding: 0 5px;
    color: white;
    line-height: 24px;
    font-size: 13px;
  }

  // 分页器
  .pagination {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 10px;
    z-index: 3;
    margin: 0;
  }
}


.infoTwo {
  display: flex;
  align-items: baseline;
  .right{
    width: 100%;
    display: flex;
  }
}
.inner{
  margin: 10px 16px;
  .houseTitle{
    text-align: justify;
    color: #1b1b1b;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: -webkit-box;
    /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
    -webkit-box-orient:vertical;
    /*要显示的行数*/
    -webkit-line-clamp:3;
    /* 溢出部分隐藏 */
    overflow:hidden;
  }
  .priceAndArea{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0;
  }
  .price{
      width: 33%;
      text-align: center;
      margin: 5px 0;
      .top{
        color: #fc223b;
        font-weight: 500;
        font-size: 22px;
        line-height: 24px;
        white-space: nowrap;
        white-space: nowrap;
        width: 100%;
        overflow: scroll;
        .Unit{
          font-size: 12px;
        }
      }
      .top::-webkit-scrollbar{
        display: none;
      }
      .botoom{
        color: #666666;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin-top: 4px;
      }
    }
  .infoOne{
    text-align: left;
    .line {
      font-size: 15px;
      color: #666666;
      width: 85%;
      overflow-wrap: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin-bottom: 8px;
      font-weight: 400;
      line-height: 24px;
    }
    .lineBox{
      display: flex;
      margin-bottom: 8px;
      color: #1b1b1b;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      .left,.right{
        width: 50%;
        font-size: 15px;
        color: #666666;
      }
    }
  }
  .housetag{
    overflow: scroll;
    padding: 20rpx 0;
    margin: 14px 0;
    padding-bottom: 14px;
    .right{
      flex-wrap: nowrap;
      .rightItem{
        display: inline-flex;
        place-content: center;
        place-items: center;
        gap: 10px;
        flex-shrink: 0;
        padding: 4px 13px;
        border-radius: 25px;
        background: #f8f8f8;
        margin-right: 10px;
        color: #1b1b1b;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  .housetag::-webkit-scrollbar{
    display: none;
  }
}
.borderBottom{
  border-bottom: 1px solid #f6f6f6;
}
.introduceBox{
  text-align: left;
  .title{
    color: #1b1b1b;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
  .Innercontent{
    margin-top: 10px;
    color: #666666;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
  }
}
.bottomBtnBox{
  position: fixed;
  padding-bottom: 10px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%,0);
  background: #fff;
  width: 100%;
  text-align: right;
  padding-top: 10px;
  box-shadow: 0px -0.5px 2px #99999926;

  .Btn{
    display: inline-block;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 12px 20px;
    border-radius: 34px;
    background: #fc223b;
    max-width: 138px;
    margin-right: 16px;
    vertical-align: middle;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    .iconfont{
      font-size: 20.1px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}

</style>