<template>
  <div>
    <div class="container">
      <div class="area">
        <navigationBar
            :title="'购物车'"
            :backBtn="false"
            class="navigation"
        ></navigationBar>
        <div class="innerArea">
          <van-dropdown-menu>
            <van-dropdown-item v-model="value1" :options="option1"/>
          </van-dropdown-menu>
        </div>

        <div class="body">
          <div class="out" v-for="(item, index) in list" :key="'1'+index">
            <div class="title">
              <div class="leftCheck">
                <van-checkbox
                    v-model="item[2].checked"
                    class="radioGroup"
                    @change="outChange(item)"
                    icon-size="24"
                >
                  <template #icon="props">
                    <span class="iconfont icon-Frame-2" v-if="props.checked"></span>
                    <span class="iconfont icon-a-Frame18713" v-if="!props.checked"></span>
                  </template>
                  <template #default></template>
                </van-checkbox>
              </div>
              <div class="right">
                <img
                    class="img"
                    :src="item[2].seller.avatar"
                    alt=""
                />
                <div class="name">{{ item[2].seller.name }}</div>
                <div class="iconfont icon-Frame-4"></div>
              </div>
            </div>
            <div
                class="inne"
                v-for="(item2, index2) in item[1]"
                :key="index2"
            >
              <div class="leftCheck">
                <van-checkbox
                    v-model="item2.checked"
                    class="radioGroup"
                    @change="innerChange(item2, item2.checked, item, index)"
                >
                  <template #icon="props">
                    <span class="iconfont icon-Frame-2" v-if="props.checked"></span>
                    <span class="iconfont icon-a-Frame18713" v-if="!props.checked"></span>
                  </template>
                  <template #default></template>
                </van-checkbox>
              </div>
              <div class="rightInfo">
                <goods
                    :width="75"
                    :brand="item2.productInfo"
                    :item2="item2"
                    :name="item2.productInfo.store_name"
                    :size="item2.productInfo.attrInfo.suk"
                    :weight="item2.productInfo.attrInfo.weight"
                ></goods>
              </div>
            </div>
          </div>
          <div class="noCar" v-if="list.length<=0">
            <img :src="$store.state.imageHost+'/img/home_car_Mask.png'" alt="" class="noCarImg">
            <span class="text">还没添加宝贝，等你来挑选</span>
          </div>
        </div>
        <div class="bottomSubmitLine" :style="'bottom: ' + bottom + 'px;'">
          <div class="inner" style="position: relative">
            <van-submit-bar
                button-color="#FC223B"
                :price="allPrice"
                button-text="提交订单"
                @submit="onSubmit()"
            >
              <van-checkbox v-model="allFlag" class="radioGroup" @click="changeAll">
                <!--                <template #icon="props">-->
                <!--                  <div class="radio" :class="props.checked ? 'choose' : ''"></div>-->
                <!--                </template>-->
                <template #icon="props">
                  <span class="iconfont icon-Frame-2" v-if="props.checked"></span>
                  <span class="iconfont icon-a-Frame18713" v-if="!props.checked"></span>
                </template>
                <template #default>全选</template>
              </van-checkbox>
            </van-submit-bar>
          </div>
        </div>
        <tabbar :active="1" ref="tabbar"></tabbar>
      </div>

      <van-popup
          v-model="carShow"
          closeable
          close-icon="close"
          position="bottom"
      >
        <div class="vanPopBox">
          <div class="title">请分开结算以下店铺</div>
          <div class="vanbody">
            <div class="li" v-for="(item, index) in list" :key="'1'+index">
              <div class="shopName">
                <div class="imgBox">
                  <img
                      class="img"
                      :src="item[2].seller.avatar"
                      alt=""
                  />
                </div>
                <div class="name">{{ item[2].seller.name }}</div>
                <div class="iconfont icon-Frame-4"></div>
              </div>
              <div class="goodsNmae">
                <div
                    class="text"
                    v-for="(item2, index2) in item[1]"
                    :key="'2'+index2"
                >
                  {{ item2.productInfo.store_name }}
                </div>
              </div>
              <div class="scrollBox">
                <div
                    class="goodItem"
                    v-for="(item2, index2) in item[1]"
                    :key="index2"
                >
                  <img
                      class="img"
                      :src="item2.productInfo.image"
                      alt=""
                  />
                </div>
              </div>
              <div class="goodsNumsPrice">
                <div class="left">共{{ item[2].seller.goodsNums }}件商品</div>
                <div class="right">
                  <div class="left">合计￥</div>
                  <div class="price">{{ item[2].seller.goodsPrice }}</div>
                </div>
              </div>
              <div class="buttonBox">
                <div class="btn" @click="submit(item)">去结算</div>
              </div>
            </div>
          </div>
        </div>
      </van-popup>

    </div>
  </div>
</template>

<script>
import tabbar from "@/components/common/tabbar/home_tabbar.vue";
import navigationBar from "../../navigation-bar/navigation-bar.vue";
import Vue from "vue";
import {SubmitBar} from "vant";
import goods from "../building_materials/goods/goods.vue";
import {getCartList} from "@/utils/api";

Vue.use(SubmitBar);
export default {
  components: {
    tabbar,
    navigationBar,
    goods,
  },
  props: {},

  data() {
    return {
      option1: [
        {text: "全部", value: 0},
      ],
      value1: 0,
      checked: false,
      list: [],
      list2: [],
      bottom: 0,
      allPrice: 0,
      carShow: false,
      allFlag: true,
    };
  },
  watch: {
    list: {
      handler() {
        let allPrice = 0;
        this.list.forEach((item) => {
          item[1].forEach((item2) => {
            if (item2.checked) {
              allPrice+=Number((item2.truePrice)) * 100 * item2.cart_num
            }
          })
          item[1] = item[1].filter((item2) => {return item2.cart_num >=1})
        });
        this.allPrice = allPrice;
      },
      deep: true,
    },
    allFlag(n) {
      if (n) {
        this.list2.forEach((item) => {
          item.checked = true
        })
      }
    },
  },
  computed: {},
  methods: {
    submit(item){
        let allPirce = {
          mao: item[2].seller.goodsPrice.split(',')[1],
          yuan: item[2].seller.goodsPrice.split(',')[0],
        }
        this.$router.push({
          name: 'createOrderSecond',
          params: {list: item[1], allPrice: allPirce},
        })
    },
    customGroup(array, callBack) {
      const arr = Array.from([...array]);
      const tempObj = {};
      arr.map((mp) => (tempObj[callBack(mp)] = []));
      Object.keys(tempObj).forEach(
          (key) =>
              (tempObj[key] = [
                ...tempObj[key],
                ...arr.filter((el) => callBack(el) === key),
              ])
      );
      return {...tempObj};
    },
    onSubmit() {
      this.carShow = true;
    },
    changeAll() {
      if (!this.allFlag) {
        this.list2.forEach((item) => {
          item.checked = false
        })
      }
    },

    innerChange(item, checked, list) {
      item.checked = checked;
      list[2].checked = list[1].every((item2) => item2.checked);
      this.allFlag = this.list.every((item2) => item2[2].checked);
    },
    outChange(item) {
      if (!item[1].some((item2) => item2.checked == true)) {
        item[1].forEach((element) => {
          element.checked = false;
        });
      }
      if (item[2].checked && !item[1].every((item2) => item2.checked)) {
        item[1].forEach((element) => {
          element.checked = true;
        });
      }
      // console.log(item.checked,item.list.some(item2=>item2.checked),item.list.every(item2=>item2.checked));

      if (
          !item[2].checked &&
          item[1].some((item2) => item2.checked) &&
          item[1].every((item2) => item2.checked)
      ) {
        item[1].forEach((element) => {
          element.checked = false;
        });
      }
    },

    async getList() {
      if (this.$store.state.token) {
        let waterLi = (await getCartList(1)).data.data
        let supermarketLi = (await getCartList(2)).data.data
        let list = waterLi.concat(supermarketLi)
        list.forEach(item=>{
          if (!item.productInfo.yuan) {
            if (item.productInfo.is_vip == 0) {
              // item.productInfo.yuan = item.productInfo.price.split('.')[0]
              // item.productInfo.mao = item.productInfo.price.split('.')[1]
              item.productInfo.yuan = item.truePrice.toFixed(2).split('.')[0]
              item.productInfo.mao = item.truePrice.toFixed(2).split('.')[1]
            } else {
              // item.productInfo.yuan = (this.$store.state.is_vip.is_vip ? item.productInfo.vip_price : item.productInfo.price).split('.')[0]
              // item.productInfo.mao = (this.$store.state.is_vip.is_vip ? item.productInfo.vip_price : item.productInfo.price).split('.')[1]
              item.productInfo.yuan = item.truePrice.toFixed(2).split('.')[0]
              item.productInfo.mao = item.truePrice.toFixed(2).split('.')[1]
            }
          }
        })
        list = Object.entries(
            this.customGroup(list, ({cat}) => cat)
        );
        let option1 = [
          { text: '全部商品', value: 0 },
        ]
        list.forEach((item) => {
          option1.push({
            text: item[1][0].cat==1?'水站':'超市',
            value: item[1].find((itema) => {
              return itema.seller_info_name;
            }),
          });
          let list = Object.entries(
              this.customGroup(item[1], ({ cat }) => cat)
          )[0][1]
          list.forEach(item=>{
            item.checked = true
          })
          item.push({
            list ,
            seller: {
              name: item[1][0].cat==1?'水站':'超市',
              id:item[1][0].cat,
              avatar:this.$store.state.config.site_logo,
              goodsPrice:item[1].reduce((total, itemcc) =>
                      total += (itemcc.truePrice * itemcc.cart_num)
                  , 0).toFixed(2),
              goodsNums:item[1].reduce((total, itemcc) =>
                      total +=  itemcc.cart_num
                  , 0),
            },
            type: item[1].filter((itema) => {
              return itema.seller_info_name;
            }),
            checked:true,
          });
        });
        this.list = list;
        this.option1 = option1;
        console.log(this.list)
        this.$nextTick(() => {
          this.bottom =
              this.$refs.tabbar.$refs.homeTabbar.offsetHeight +
              (this.$refs.tabbar.$refs.appInstallBox?this.$refs.tabbar.$refs.appInstallBox.offsetHeight:0);
        });
        // getCartAllType(this.$store.state.token, 1).then(res => {
        //   if (res.data.status) {
        //     let list = res.data.data.list
        //     let list2 = res.data.data.list
        //     list = list.filter(item => {
        //       return item.id && item.seller_info
        //     })
        //     list2 = list2.filter(item => {
        //       return item.id && item.seller_info
        //     })
        //     list.forEach(item => {
        //       console.log(item)
        //       console.log()
        //       if(item.type == 5){
        //         item.yuan = (this.$store.state.is_vip.is_vip?item.products.vip_price:item.products.price).split(".")[0]
        //         item.mao = (this.$store.state.is_vip.is_vip?item.products.vip_price:item.products.price).split(".")[1]
        //       }else{
        //         item.yuan = item.products.price.split(".")[0]
        //         item.mao = item.products.price.split(".")[1]
        //       }
        //       item.products.brand = {
        //         name:item.products.brand_name
        //       }
        //       if (item.seller_info) {
        //         item.sell_id = item.seller_info.id ? item.seller_info.id : ''
        //         item.sell_img = item.seller_info.legal_person_img ? item.seller_info.legal_person_img.split(',')[0] : item.type == 1 ? '/img/shuizhanlogo.png' : item.type == 5 ? '/img/chaoshilogo.png' : ''
        //         item.seller_info_name = item.seller_info.seller_name ?
        //             item.seller_info.seller_name:
        //             item.seller_info.shop_name
        //         item.seller_id = item.seller_id?item.seller_id:item.seller_info_name
        //       }
        //       if(item.products.sell_price){
        //         item.products.price = item.products.sell_price
        //         item.products.specifications = item.products.length+'*'+item.products.width+'*'+item.products.weight
        //         item.images = item.products.img.split(',')[0]
        //         item.products.images = item.products.img.split(',')[0]
        //       }
        //     })
        //     list = Object.entries(
        //         this.customGroup(list2, ({seller_id}) => seller_id)
        //     );

        //     console.log(list)

        //     this.list2 = list2;
        //     console.log(list)
        //     console.log(list2)
        //   } else {
        //     Toast(res.data.msg)
        //   }
        // }).catch(err => {
        //   console.log(err)
        // })
      }
    },
  },
  created() {
  },
  mounted() {

    this.getList()
  },
};
</script>
<style lang="scss" scoped>
.navigation {
}

.body {
  padding-bottom: 120px;

  .out {
    background: #fff;
    border-radius: 20px 20px 20px 20px;
    padding: 16px 15px;
    margin-bottom: 10px;

    .title {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;

      .right {
        display: flex;
        align-items: center;
        flex: 1;

        .img {
          width: 24px;
          height: 24px;
          border-radius: 5px;
          margin-right: 10px;
        }

        .name {
          font-weight: 500;
          font-size: 16px;
          color: #1b1b1b;
          line-height: 24px;
          text-align: left;
          width: 100%;
        }

        .iconfont {
          font-size: 24px;
        }
      }
    }

    .inne {margin-top: 10px;
      width: 100%;
      border-radius: 10px;
      padding: 0 6px;
      display: flex;
      align-items: center;

      .rightInfo {
        flex: 1;
      }
    }
  }
}

.container {
  background: #fafafa;
}

.area {
}

.innerArea {
  padding-right: 16px;
  padding-left: 16px;
  margin-bottom: 20px;
}

.radioGroup {
  display: flex;
  align-items: center;
  margin-right: 10px;

  .iconfont {
    font-size: 24px;
    color: #E8E8E8;
  }

  .icon-Frame-2 {
    color: #FC223B;
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    color: #1b1b1b;
    line-height: 20px;
    text-align: left;
  }

  .van-radio {
    margin-right: 10px;
  }
}

.bottomSubmitLine {
  max-width: 750px;
  position: fixed;
  width: 100%;
  background: red;
  left: 50%;
  transform: translate(-50%, 0);

  .inner {
    width: 100%;
    position: relative;
    //min-height: 100px;
  }
}

.vanPopBox {
  margin: 0 auto;
  text-align: left;

  .title {
    color: #000000;
    font-weight: 500;
    font-size: 18px;
    color: #1b1b1b;
    line-height: 24px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .vanbody {
    max-height: 70vh;
    overflow: scroll;
    padding-bottom: 80px;

    .li {
      background: #fff;
      padding: 10px;
      border-radius: 10px 10px 10px 10px;
      margin: 10px;

      .shopName {
        display: flex;
        align-items: center;

        .img {
          width: 24px;
          height: 24px;
          border-radius: 5px;
        }

        .name {
          padding-left: 10px;
        }

        .iconfont {
          font-size: 24px;
        }
      }

      .goodsNmae {
        margin-top: 10px;
        display: -webkit-box;
        /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
        -webkit-box-orient: vertical;
        /*要显示的行数*/
        -webkit-line-clamp: 2;
        /* 溢出部分隐藏 */
        overflow: hidden;

        .text {
          display: inline-block;
          margin-right: 10px;
          font-weight: 500;
          font-size: 14px;
          color: #1b1b1b;
          line-height: 24px;
          text-align: left;
          position: relative;
          white-space: nowrap;
        }

        .text::after {
          position: absolute;
          right: -5px;
          top: 50%;
          display: block;
          content: "";
          width: 1px;
          height: 10px;
          background: #1b1b1b;
          transform: translate(0, -50%);
          border-radius: 5px;
        }

        .text:nth-last-of-type(1)::after {
          display: none;
        }
      }

      .scrollBox {
        display: flex;

        .goodItem {
          .img {
            width: 50px;
            height: 50px;
            margin-right: 10px;
            border-radius: 10px;
            background: #f9f9f7;
          }
        }

        .goodItem:nth-last-of-type(1) {
          .img {
            margin-right: 0;
          }
        }
      }

      .goodsNumsPrice {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          color: #7f7f7f;
          font-size: 12px;
        }



        .left,
        .right {
          color: #666666;
          font-size: 12px;
        }

        .right {
          display: flex;
          align-items: center;
          max-width: 60%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .left{
            color: #1b1b1b;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
          .price{
            color: #1b1b1b;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
          }
        }
      }

      .buttonBox {
        text-align: right;
        margin-top: 10px;

        .btn {
          display: inline-block;
          background: #FC223B;
          border-radius: 36px 36px 36px 36px;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          line-height: 24px;
          text-align: center;
          padding: 5px 17px;
        }
      }
    }
  }
}

.noCar{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .noCarImg{
    width: 80%;
    margin-top: 50px;
  }
  .text{
    align-items: center;
    text-align: center;
    color: #7f7f7f;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
<style>
.van-checkbox__icon {
  font-size: 24px !important;
}

.van-popup {
  border-radius: 10px 10px 0 0;
  /* max-height: 80vh; */
}

.innerArea::v-deep(van-dropdown-menu__item) {
  display: inline-block !important;
  background-color: white;
  background-color: red;

  /* box-shadow: 0rpx 0rpx 4rpx 0rpx rgba(102, 102, 102, 0.25); */
  text-align: center;
  /* border-radius: 92rpx; */
}

.innerArea >>> .van-dropdown-menu__item {
  display: inline-block !important;
  background-color: white;
  background-color: red;
  /* box-shadow: 0rpx 0rpx 4rpx 0rpx rgba(102, 102, 102, 0.25); */
  text-align: center;
  /* border-radius: 92rpx; */
}

.van-dropdown-menu__item {
  /* background-color: white; */
  /* background-color: red; */
  /* box-shadow: 0px 0px 2px 0rpx rgba(102, 102, 102, 0.25); */
  /* text-align: center; */
  /* text-align: left; */
}

.van-dropdown-menu {
  text-align: left;
}

.van-dropdown-menu__bar {
  display: inline-block !important;
  height: auto !important;
  /* display: block!important; */
  /* height: auto!important; */
  /* background-color: #FAFAFA!important; */
  /* padding: 5px 10px; */
  border-radius: 46px;
}

.van-dropdown-menu__title {
  padding: 5px 25px 5px 10px !important;
  border-radius: 10px;
}

.van-dropdown-menu .van-dropdown-item {
  padding: 0 16px;
  transform: translate(-50%, 0);
  left: 50%;
  width: 95%;
  max-width: 720px;
}

.van-dropdown-menu__title::after {
  right: 10px !important;
}

.van-dropdown-item__content {
  border-radius: 0 0 20px 20px;
}

.van-ellipsis {
  font-weight: 400;
  font-size: 14px;
  color: #1b1b1b;
  line-height: 24px;
}

.van-submit-bar {
  position: absolute !important;
  padding-bottom: 10px!important;
}

.van-popup {
  background-color: #f4f4f4 !important;
  /* background-color: red!important; */
}
</style>