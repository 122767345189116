<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {Toast} from "vant";
import {job_Company_list} from "@/api/recruitment";

export default {
  name: "my_publishi",
  components: {navigationBar},
  data() {
    return {
      active: 0,
      tabsLi: [
        {text: '全部', list: [], page: 1, limit: 10, loading: false, noMore: false,},
        {text: '发布中', list: [], page: 1, limit: 10, loading: false, noMore: false,},
        {text: '已关闭', list: [], page: 1, limit: 10, loading: false, noMore: false,},
      ],
    }
  },
  methods: {
    getList() {
      job_Company_list(this.$store.state.nowCompany.id, this.tabsLi[this.active].page, this.tabsLi[this.active].limit,this.publish_status).then(res => {
        if (res.data.status == 200) {
          res.data.data.count
          console.log(res.data.data.data)
          let list = res.data.data.data
          list.forEach(item => {
            item.showImg = JSON.parse(item.images)[0]
            if (typeof item.showImg == 'string') {
              if (!item.showImg.startsWith('https:')) {
                item.showImg = this.$store.state.imageHost + item.showImg
              } else {
                item.showImg = item.showImg
              }
            } else {
              item.showImg = this.$store.state.imageHost + item.showImg.tempFilePath
            }
          })
          this.tabsLi[this.active].list = list
          // this.tabsLi[this.active].list = this.tabsLi[this.active].list.concat(list);
          console.log(this.tabsLi[this.active].list)
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    tabsChange() {
      this.getList()
    },

    load() {
      console.log('加载=========')
      this.tabsLi[this.active].loading = true
      if (this.tabsLi[this.active].noMore) {
        return
      }
      let page = this.tabsLi[this.active].page
      let limit = this.tabsLi[this.active].limit += 10
      this.$nextTick(()=>{
        job_Company_list(this.$store.state.nowCompany.id, page, limit,this.publish_status).then(res => {
          if (res.data.status == 200) {
            let maxlength = res.data.data.count
            let list = res.data.data.data
            list.forEach(item => {item
              // item.showImg = JSON.parse(item.images)[0]
              // if (typeof item.showImg == 'string') {
              //   if (!item.showImg.startsWith('https:')) {
              //     item.showImg = this.$store.state.imageHost + item.showImg
              //   } else {
              //     item.showImg = item.showImg
              //   }
              // } else {
              //   item.showImg = this.$store.state.imageHost + item.showImg.tempFilePath
              // }
            })
            this.tabsLi[this.active].list = list
            if (list.length >= maxlength) {
              this.tabsLi[this.active].noMore = true
              this.tabsLi[this.active].loading = false
            }
            this.tabsLi[this.active].loading = false
            this.tabsLi[this.active].maxlength = maxlength
          } else {
            Toast(res.data.msg)
          }
        })
      })


    },


    godetail(item) {
      this.$router.push({
        path:'/invite_details',
        query:{item:JSON.stringify(item)}
      })
    },
  },
  computed: {
    is_sale() {
      // 1出价中 2待取货 3已完成 4已取消
      let flag = this.active
      if (this.active == 0) {
        flag = ''
      }
      return flag
    },
    disabled() {
      return (this.active != -1 ? this.tabsLi[this.active].loading : true) || (this.active != -1 ? this.tabsLi[this.active].noMore : false)
    },
    noMore() {
      return this.tabsLi[this.active].list.length >= this.tabsLi[this.active >= 0 ? this.active : 0].maxlength
    },


    publish_status(){
      return this.active==0?'':this.active == 1?1:2
    },


  },
  mounted() {
    console.log(this.$store.state.nowCompany)
    // this.getList()
  },
}
</script>

<template>
  <div class="container myCollect">
    <div class="area">
      <navigationBar :title="'我的发布'" class="navigationBar"></navigationBar>
      <van-tabs v-model="active" animated class="vanTabs" @change="tabsChange">
        <!--        <van-tab v-for="(item,index) in tabsLi" :key="index" :title="item.text">-->
        <!--          <div class="ul" v-infinite-scroll="load"-->
        <!--               :infinite-scroll-disabled="disabled" :infinite-scroll-immediate="false">-->

        <!--            <p v-if="tabsLi[index].loading">加载中...</p>-->
        <!--            <p v-if="tabsLi[index].noMore">没有更多了</p>-->
        <!--          </div>-->
        <!--        </van-tab>-->


        <van-tab
            v-for="(item, index) in tabsLi"
            :title="item.name"
            :key="index"
        >
          <template #title>{{ item.text }}</template>
          <div class="ul" v-infinite-scroll="load"
               :infinite-scroll-disabled="disabled" :infinite-scroll-immediate="false">
            <div v-for="(itemc,indexcc) in tabsLi[index].list" :key="indexcc+'itemc'" class="li">
              <!--                  {{active}}-->
              <div class="item" @click="godetail(itemc)">
                <div class="topT">
                  <div class="left">{{itemc.company.name}}</div>
                  <div class="rightStatus" :class="itemc.status == 2?'closeRight':''">
                    {{itemc.status==0?'审核中':itemc.status==1?'发布中':itemc.status==2?'已关闭':''}}
                  </div>
                </div>
                <div class="addressB">
                  {{itemc.city_name}} {{itemc.district_name}}
                </div>
                <div class="btom">
                  <div class="leftT">
                    {{itemc.salary_min}}l~{{itemc.salary_max}}k
                  </div>
                  <div class="rightCorpor">
                    {{itemc.company.name}}
                  </div>
                </div>
              </div>

            </div>
            <p v-if="tabsLi[index].loading" style="margin-top: 15px">加载中...</p>
            <p v-if="tabsLi[index].noMore" style="margin-top: 15px">没有更多了</p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  background: #fff;
  height: 100vh;
}

.container::-webkit-scrollbar {
  display: none;
}

.area {
  height: 100vh;
}

.navigationBar {
  background: #fff;
}

.vanTabs .van-tabs__wrap {
  background: #fff;
}

.vanTabs {

  .ul {
    height: 100vh;
    overflow: scroll;

    .li {

      .item {
        background: #fff;
        margin: 0 16px;
        border-bottom: 1px solid #E6E6E6;
        padding-bottom: 15px;
        padding-top: 15px;
        .topT{
          display: flex;
          justify-content: space-between;
          .left{
            color: #1b1b1b;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
          }
          .rightStatus{
            color: #1b1b1b;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
          }
          .closeRight{
            color: #fc223b;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
          }
        }
        .addressB{
          text-align: left;
          margin-top: 8px;
          color: #1b1b1b;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        .btom{
          margin-top: 8px;
          display: flex;
          justify-content: space-between;
          .leftT{
            color: #fc223b;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
          }
          .rightCorpor{
            color: #c7c7c7;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .ul::-webkit-scrollbar {
    display: none;
  }
}
</style>
<style>

.myCollect .van-tab {
  flex: none;
  width: 20%;
  color: #1b1b1b;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

.myCollect .van-tab--active {
  text-align: center;
  color: #1b1b1b;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.myCollect .van-tabs--line .van-tabs__wrap {
  padding-bottom: 10px;
}
</style>