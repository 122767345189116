import { render, staticRenderFns } from "./corporation_list.vue?vue&type=template&id=124eaa47&scoped=true"
import script from "./corporation_list.vue?vue&type=script&lang=js"
export * from "./corporation_list.vue?vue&type=script&lang=js"
import style0 from "./corporation_list.vue?vue&type=style&index=0&id=124eaa47&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "124eaa47",
  null
  
)

export default component.exports