import { render, staticRenderFns } from "./corporation_manage.vue?vue&type=template&id=129ed686&scoped=true"
import script from "./corporation_manage.vue?vue&type=script&lang=js"
export * from "./corporation_manage.vue?vue&type=script&lang=js"
import style0 from "./corporation_manage.vue?vue&type=style&index=0&id=129ed686&prod&scoped=true&lang=scss"
import style1 from "./corporation_manage.vue?vue&type=style&index=1&id=129ed686&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "129ed686",
  null
  
)

export default component.exports