<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {sckill_list, seckill_index} from "@/api/flash_sale";

export default {
  name: "flash_sale_home",
  components: {navigationBar},
  data(){
    return{
      topImage:'',
      timeList: [],
      active: 5,
      intoindex:'',
      page:1,
      limit:10,
      sysHeight:0,
      list:[],
      status: 1,

    }
  },
  computed:{
    headerBg(){
      return 'url('+this.$store.state.imageHost+'/img/seckill_header.png'+')'
    },
  },
  methods:{
    getList(){
      sckill_list(this.timeList[this.active].id,this.page,this.limit).then(res=>{
        if(res.data.status == 200){
          let list = res.data.data
          list.forEach(item=>{
            item.price_yuan = item.price.toFixed(2).split('.')[0]
            item.price_mao = item.price.toFixed(2).split('.')[1]
          })
          this.list = list
          this.status = this.timeList[this.active].status
        }
      })
    },
    chooseTab(e){
      this.active = e
      this.getList()
    },
    godetails(item){
      console.log(item)
      this.$router.push({
        path: '/flash_details',
        query: {goods: item.id,type:1}
      })
    },

  },
  mounted() {
    this.sysHeight = this.$refs.navigation.$el.scrollHeight
    seckill_index().then(res=>{
      if(res.data.status == 200){
        this.topImage = res.data.data.lovely;
        this.timeList = res.data.data.seckillTime;
        this.active = res.data.data.seckillTimeIndex;
        this.$nextTick(()=>{
          this.intoindex = 'sort'+res.data.data.seckillTimeIndex
        })
        this.status = this.timeList[this.active].status
        this.getList()
      }
    })

  }
}
</script>

<template>
<div class="container">
  <div class="area">
    <navigationBar :title="''" class="navigation" ref="navigation"></navigationBar>
    <div class="top_bg" :style="{backgroundImage:headerBg,height: (sysHeight + 200)+ 'px'}"></div>
    <div class="contentc">
      <div class="header">
        <div class="head_tab">
          <div class="left btn" :class="active==0?'choose':''" @click="chooseTab(0)">
            <div class="time" v-if="timeList[0]"> {{timeList[0].start_time}} ~ {{timeList[0].end_time}}</div>
            <div class="state" :class="timeList[0].status == 1?'sating':''" v-if="timeList[0]">{{timeList[0].state}}</div>
            <div class="Angle">
              <div class="circle">
                <div class="inn"></div>
              </div>
            </div>
<!--            <img :src="$store.state.imageHost+'/img/seckill_header_icon1.png'" alt="" class="bg">-->
          </div>
          <div class="right btn" :class="active==1?'choose':''" @click="chooseTab(1)">
            <div class="time" v-if="timeList[1]"> {{timeList[1].start_time}} ~ {{timeList[1].end_time}}</div>
            <div class="state" :class="timeList[0].status == 1?'sating':''" v-if="timeList[1]">{{timeList[1].state}}</div>
            <div class="Angle"></div>
<!--            <img :src="$store.state.imageHost+'/img/seckill_header_icon2.png'" alt="" class="bg">-->
          </div>
        </div>
      </div>

      <div class="body">
        <div class="ul">
          <div class="li" v-for="(item,index) in list" :key="index">
            <div class="every" @click="godetails(item)">
              <div class="leftImg"><img :src="item.activity_image" alt="" class="image"></div>
              <div class="rightInfo">
                <div class="title">
                  {{item.title}}
                </div>
                <div class="bottomLine">
                  <div class="left">
                    <div class="price">
                      <div class="unit">￥</div>
                      <div class="yuan">{{ item.price_yuan }}</div>.
                      <div class="mao">{{ item.price_mao }}</div>
                      <div class="ot_price">￥{{item.ot_price}}</div>
                    </div>
                    <div class="percent" v-if="status == 1">
                      <van-progress  :show-pivot="false" color="linear-gradient(90deg, #ea3b28 0%, #fc722f 100%)" track-color="#faceca" :percentage="item.percent" /> 已抢{{item.percent}}%
                    </div>
                  </div>
                  <div class="rightBnt">

                    <img :src="$store.state.imageHost+'/img/sckill_btn_y.png'" alt="" class="img" v-if="status == 1">
                    <img :src="$store.state.imageHost+'/img/sckill_btn_n.png'" alt="" class="img" v-if="status==2||status==0">
                    <div class="text" v-if="status == 1" @click="godetails(item)">
                      限时抢
                    </div><div class="text" v-else-if="status == 2">
                      未开始
                    </div>
                    <div class="text" v-else>
                      已结束
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="none" v-if="!list.length">
            暂无秒杀商品，去看看其他商品吧～
          </div>
        </div>
      </div>


<!--      <img :src="$store.state.imageHost+'/img/seckill_header_icon1.png'" alt="">-->
<!--      <img :src="$store.state.imageHost+'/img/seckill_header_icon2.png'" alt="">-->
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.container{
  height: 100vh;
  position: relative;
}
.area{
  height: 100vh;
}
.navigation{
  position: absolute;
  z-index: 299;
}
.top_bg{
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 225px;
}


.contentc{
  margin-top: -35px;
  padding: 0px;
}
.header{
  position: relative;
}
.head_tab{
  display: flex;
  .btn{
    width: 0;
    flex: 1;
    position: relative;
    background: #f8f8f8;
    margin: 0 1.5px;
    border-radius: 10px;
    padding: 4px 0 7px 0;
    .bg{
      position: absolute;
      width: 135%;
      left: 0;
      top: 0;
    }
    .Angle{
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
    }
    .left{
      .Angle{
        right: 0;
        top: 0;
      }
    }
    .right{
      .Angle{
        right: 0;
        top: 0;
      }
    }

    .time{
      color: #1b1b1b;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .state{
      color: #999999;
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
    }
    .sating{
      display: inline-flex;
      place-content: center;
      place-items: center;
      gap: 10px;
      padding: 0px 15px;
      border-radius: 14px;
      background: #e93323;
      color: #ffffff;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
    }
  }
  .choose{
    background: #fff;
  }
}

.contentc::-webkit-scrollbar{
  display: none;
}

.body{
  padding: 16px;
  .none{

  }
  .every{
    display: flex;
    margin: 10px 0;
    .leftImg{
      width: 120px;
      height: 120px;
      .image{
        width: 100%;
        height: 100%;
      }
    }
    .rightInfo{
      flex: 1;
      width: 0;
      text-align: left;
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title{
        color: #1b1b1b;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        display: -webkit-box;
        /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
        -webkit-box-orient: vertical;
        /*要显示的行数*/
        -webkit-line-clamp: 2;
        /* 溢出部分隐藏 */
        overflow: hidden;
      }
      .ot_price{
        color: #7f7f7f;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-decoration: line-through;
        margin-left: 5px;
      }
      .bottomLine{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        .price{
          display: flex;
          align-items: baseline;
          color: #fd2a39;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          .yuan{
            font-size: 20px;
          }
        }
        .rightBnt{
          width: 92px;
          height: 42px;
          line-height: 54px;
          position: relative;
          .img{
            z-index: 1;
            width: 79px;
            height: 42px;
            position: absolute;
            left: 0;
            top: 0;
          }
          .text{
            position: relative;
            z-index: 2;
            text-align: center;
            color: #ffffff;
            font-weight: 500;
            font-size: 13px;
          }
        }
        .left{
          width: 59%;
        }
        .percent{
          display: flex;
          align-items: center;
          .van-progress{
            width: 50%;
            margin-right: 5px;
          }
          color: #fd2a39;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
  }
}


</style>