<template>
  <div class="app">
    <div class="innerarea">
      <div class="line border_bottom" @click="openTime">
        <div class="lable">预约时间</div>
        <div class="right" :class="kanchaTimeShow?'haveTime':''">
          {{ kanchaTimeShow ? kanchaTimeShow : '请您选择勘察时间' }}
          <!-- <input type="text" class="input" placeholder="请您选择勘察时间"> -->
          <van-icon name="arrow" icon class="icon" color="#C7C7C7" v-if="!this.otherIn"/>
        </div>
      </div>
      <div class="line" @click="openService">
        <div class="lable">快修项目</div>
        <div class="right" :class="userChooseWeixiu?'haveTime':''">
          {{ userChooseWeixiu ? userChooseWeixiu : '请您选择快修项目' }}
          <!-- <input type="text" class="input" placeholder="请您选择勘察时间"> -->
          <van-icon name="arrow" icon class="icon" color="#C7C7C7" v-if="!this.otherIn"/>
        </div>

      </div>
    </div>

    <div class="fun">
      <div class="order_typeBox">
        <van-radio-group v-model="radio" class="radionGourp">
          <van-radio :name="1" checked-color="#FC223B" icon-size='17' v-if="radio == 1">
            <template #default>
              <span class="text">质保外</span>
            </template>
          </van-radio>
          <van-radio :name="2" checked-color="#FC223B" icon-size='17' v-if="radio == 2">
            <template #default>
              <span class="text">快修质保</span>
            </template>
          </van-radio>
          <van-radio :name="3" checked-color="#FC223B" icon-size='17' v-if="radio == 3">
            <template #default>
              <span class="text">装修质保</span>
            </template>
          </van-radio>
        </van-radio-group>
      </div>
      <div class="my_guarantee" @click="goAcceptance">
        <img src="../../../../public/image/shandian.png" alt="" class="shandian" @click="goAcceptance">
        {{(identity==1||!identity)?'我的质保':identity==2?'质保查询':'我的质保'}}
      </div>
    </div>

<!--    <div class="VoiceOut">-->
<!--      <span class="description">不想打太多字？用语音描述快修项目</span>-->
<!--      <div>-->
<!--        <div class="voiceStarBtn">语音描述</div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="img_box">
      <div class="title">照片、视频{{ userUpload.length }}/{{ maxImg }}</div>
      <div class="img_list">
        <div  class="evert li" v-for="(item,index) in userUpload" :key="index+index" @click.stop="checkImg()">
          <el-image
              class="avatar"
              fit="cover"
              :src="$store.state.imageHost+item.tempFilePath"
              :preview-src-list="srcList">
          </el-image>
          <van-icon name="clear" class="icon" @click.stop="deleteImg(userUpload,index)" v-if="!otherIn"/>
        </div>
        <el-upload
            v-if="userUpload.length<=4&&!otherIn"
            class="avatar-uploader"
            action
            :http-request="uploadFile"
            ref="upload"
            :limit="fileLimit"
            :on-remove="handleRemove"
            :file-list="fileList"
            :on-exceed="handleExceed"
            :before-upload="beforeUpload"
            :show-file-list="false"
            :headers="headers"
            :on-change="fileChange"
        >
          <i  class="el-icon-plus avatar-uploader-icon" ></i>
        </el-upload>

      </div>
    </div>


    <div class="innerarea" @click="chooseAddress">
      <div class="line border_bottom">
        <div class="lable">联系人</div>
        <div class="right" :class="userInfo.name?'haveTime':''">
          {{ userInfo.name ? userInfo.name : '请选择联系人' }}
          <!-- <input type="text" class="input" placeholder="请您选择勘察时间"> -->
          <van-icon name="arrow" icon v-if="!this.otherIn"/>
        </div>

      </div>
      <div class="line">
        <div class="lable">联系电话</div>
        <div class="right" :class="userInfo.mobile?'haveTime':''">
          {{ userInfo.mobile ? userInfo.mobile : '请添加联系电话' }}
          <!-- <input type="text" class="input" placeholder="请您选择勘察时间"> -->
          <van-icon name="arrow" icon v-if="!this.otherIn"/>
        </div>

      </div>
      <div class="line">
        <div class="lable">快修地址</div>
        <div class="right" :class="userInfo.shipAddress?'haveTime':''">
          {{ userInfo.shipAddress ? userInfo.shipAddress : '请选择地址' }}
          <!-- <input type="text" class="input" placeholder="请您选择勘察时间"> -->
          <van-icon name="arrow" icon v-if="!this.otherIn"/>
        </div>

      </div>
    </div>
    <div class="agreement">
      <div class="title">用户须知</div>
      <div class='ul'>
        <div class="li" v-for="(item, index) in agreementLi" :key="index">{{ item.id }}. {{ item.text }}</div>
      </div>
    </div>
    <van-popup v-model="timeShow" round closeable position="bottom">
      <chooseTime :white="'white'" :type="'xiu'" :color="'#FC223B'" @childMessage=childMessage></chooseTime>
    </van-popup>
    <van-popup v-model="itemShow" round closeable position="bottom">
      <div class="itemBox">
        <div class="title">维修项目</div>
        <div class="input">

          <el-input type="textarea"
                    autosize
                    placeholder="项目可多选、若下方快修项目没有，可以手动输入~"
                    v-model="weixiuContent" class="textarea" maxlength="100"></el-input>
        </div>
        <div class="itemBody">
          <div v-for="(it,idx) in weixiutabbar" :key="idx" class="li" @click="chooseItem(idx)">
            <div class="itemBody_name">{{ it.name }}</div>
            <van-checkbox :value="it.choose" v-if="it.choose" checked-color="#FC223B"></van-checkbox>
          </div>
        </div>
        <div class="button" @click="saveItem">确定</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import chooseTime from "../choose_time/choose_time.vue"
import choose_time from "@/components/common/choose_time/choose_time.vue";
import { upload_file, upload_files} from "@/utils/api";
import Bus from "@/utils/Bus";
import Compressor from "compressorjs"

export default {
  components: {
    chooseTime
  },
  props: {
    maintenance_type:{
      type: [String,Number],
    },
    obj:{
      type: [String,Number,Object],
    },
    obj1:{
      type: [String,Number,Object],
    },
    identity:{
      type: [String,Number],
    },

  },
  data() {
    return {
      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["png", "jpg", "jpeg", 'mp4', 'mp3'],
      fileList: [],
      fileLimit: 5,
      maxImg: 5,
      agreementLi: [
        {id: 1, text: '预约勘察费用不退，不计入维修费用'},
        {id: 2, text: '用户验收通过后，享有30日的质保期'},
        {id: 3, text: '用户报修后，勘察员应及时反馈现场勘查情况与报修内容并配合用户完成维修服务'},
        {id: 4, text: '质保期内，非人为造成的、因维修师傅违反操作规范造成的二次维修免费'},
        {id: 5, text: '人为损坏或自然损坏不在免费质保范围内，用户需重新下单维修'},
        {id: 6, text: '快修内容如涉及物业维修手续，用户应协调配合办理相关手续'},
      ],
      timeShow: false,
      itemShow: false,

      weixiutabbar: [],
      // scoutLi:[],
      chooseKanchaObj: {},
      kanchaTime: '',
      kanchaTimeShow: '',
      weixiuContent: '',
      userChooseWeixiu: '',
      userInfo: {},//个人信息
      userUpload: [],//
      srcList:[],
      otherIn:false,
    };
  },
  watch: {
    userUpload:{
      handler(newVal){
        let list = []
        newVal.forEach(item=>{
          list.push(this.$store.state.imageHost+item.tempFilePath)
        })
        this.srcList = list
      },
      deep:true
    },
  },
  computed: {
    choose_time() {
      return choose_time
    },
    radio(){
      return this.maintenance_type
    },
  },
  methods: {
    chooseAddress(){
      if(this.otherIn){
        return
      }
      console.log('选择地址')
      this.$router.push({
        path:'/userAddress',
        query:{id:this.userInfo.addId},
      })
    },
    deleteImg(list,index){
      list.splice(index, 1)
      this.handleClose()
    },
    checkImg(){

    },
    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)

        }


      })
    },


    async beforeUpload(files) {
      let file = await this.yasuo(files)
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        if (files.type.startsWith('image/')) {   //图片
          //计算文件的大小
          const isLt5M = file.size / 1024 / 1024 < 1; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            return false;
          }
        } else if (files.type.startsWith('video/')) {//视频
          const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },


    uploadFile(item) {
      // console.log(JSON.stringify(item))
      this.$message("文件上传中........");
      if (item.file.type.startsWith('image/')){
        console.log('图片')
        console.log(item.file)
        upload_file(item.file).then(res=>{
          if(res.data.status == true){
            this.$message.close()
            this.$message.success("上传成功");
            this.userUpload.push({tempFilePath:res.data.data.url,fileType:'image'})
            // this.userUpload
          }
        })
      }else if (item.file.type.startsWith('video/')){
        upload_files(item.file).then(res=>{
          if(res.data.status == true){
            this.$message.close()
            this.$message.success("上传成功");
            console.log(res.data.data)
            // this.userUpload
            this.userUpload.push({tempFilePath:res.data.data.url,fileType:'video'})
          }
        })
      }
    },
    //上传成功后的回调
    handleSuccess() {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.handleClose();
    },
    fileChange(file, fileList) {
      let list = []
      fileList.forEach(item=>{
        const FileExt = item.name.replace(/.+\./, "").toLowerCase();
        if (this.fileType.includes(FileExt)) {
          list.push(item)
          return true;
        } else {
          return false;
        }
      })
      this.fileList = list;
    },
    async handleRemove() {
    },

    handleClose(i) {
      this.fileList.splice(i, 1); //删除上传的文件
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },
    uploadImg() {
      console.log('上传图片【')
    },
    openService() {
      if (this.otherIn)return;

      this.itemShow = true
    },
    openTime() {
      if (this.otherIn)return;
      this.timeShow = true
    },
    goAcceptance() {
      this.$router.push({
        path:'/my_acceptance',
        query:(this.obj&&this.identity!=1)?this.obj:''
      })
    },
    chooseItem(ide) {
      console.log(this.weixiutabbar[ide]);
      this.weixiutabbar[ide].choose = !this.weixiutabbar[ide].choose
    },
    saveItem() {
      let str = ''
      this.weixiutabbar.forEach(item => {
        if (item.choose) {
          str += (item.name + '、')
        }
      })
      str += this.weixiuContent
      if (str.endsWith('、')) {
        str = str.substr(0, str.length - 1)
      }
      this.userChooseWeixiu = str
      this.itemShow = false
    },
    childMessage(obj) {
      console.log(obj)
      console.log(obj.chooseKanchaObj)
      this.kanchaTime = obj.item.time,
          this.kanchaTimeShow = obj.item.time,
          this.chooseKanchaObj = obj.chooseKanchaObj,
          this.timeShow = false;
      let obj1 = {
        kancha: obj.chooseKanchaObj
      }
      this.$emit('yuangong', obj1);
    },
    initUserAddress() {
      Bus.$on("sendUserInfo", (msg) => {
        console.log(msg)
        console.log(this.$store.state.shipingAddressList[0])
        let obj = {
          ys: '39.932246,116.453546',
          name:this.$store.state.shipingAddressList[0].name,
          mobile: this.$store.state.shipingAddressList[0].mobile,
          addId:this.$store.state.shipingAddressList[0].id,
          shipAddress: this.$store.state.shipingAddressList[0].province_name+this.$store.state.shipingAddressList[0].city_name+this.$store.state.shipingAddressList[0].district_name+this.$store.state.shipingAddressList[0].details_name,
          address: this.$store.state.shipingAddressList[0].address,
        }
        console.log(obj,this.$store.state.shipingAddressList[0])
        this.userInfo = obj
      });
    },
  },
  created() {
  },
  mounted() {
    Bus.$emit("updataUserInfo", '1');
    // get_two_cat(this.$store.state.token, 2).then(res => {
    //   if (res.data.status) {
    //     let list = res.data.data
    //     list.forEach(item => {
    //       item.choose = false
    //     })
    //     this.weixiutabbar = list
    //   }
    // })
    if (this.$store.state.token) {
      this.initUserAddress()
    }
    this.$nextTick(()=>{
      if(this.obj.order_id){
        console.log(this.obj,'==========shoudao')

        this.otherIn = true
        if(this.$route.query.type == 'set'){
          this.otherIn = false
        }
        this.userInfo = {
          name:this.obj.ship_name,
          mobile: this.obj.ship_mobile,
          addId:this.obj.addId,
          shipAddress: this.obj.ship_address,
          ys: this.obj.ship_address_yx,
          address: this.obj.address,
        }
        console.log(this.obj)
        this.kanchaTimeShow = this.obj.yvyue_time

        this.kanchaTime = this.obj.yvyue_time
        this.kanchaTimeShow = this.obj.yvyue_time
        this.userChooseWeixiu = this.obj.items.title
        this.userUpload = JSON.parse(this.obj.items.files_user)






      }
    })
  },
  beforeDestroy() {
    Bus.$off("sendUserInfo");
  }
};
</script>
<style lang="scss" scoped>

.innerarea {
  margin-top: 10px;

  .line {
    display: flex;
    padding-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    margin-left: 8px;
    margin-right: 8px;
    padding: 10px 0;

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #C7C7C7;
      font-size: 14px;
      text-align: right;
      width: 80%;
    }

    .lable {
      color: #666666;
      font-size: 14px;
      line-height: 24px;
    }

    .haveTime {
      color: #1B1B1B !important;

      .iconf {
        color: #C7C7C7;
      }
    }

    .input {
    }
  }

  .border_bottom {
    border-bottom: 1px solid rgba(153, 153, 153, 0.15);
  }
}


.app {
  // background-color: red;
  padding: 0 16px;

  .border_bottom {
    border-bottom: 1px solid rgba(153, 153, 153, 0.15);
  }

  .fun {
    display: flex;
    text-align: right;
    margin-top: 15px;
    justify-content: space-between;
  }

  .my_guarantee {
    background-color: #FC223B;
    display: inline-block;
    color: #fff;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 0 50px 50px 0px;
    font-size: 14px;
    line-height: 24px;

    .shandian {
      width: 29px;
      position: absolute;
      left: 0;
      top: -5px;
      transform: translate(-80%, 0);
    }
  }

  .VoiceOut {
    margin-top: 15px;

    .description {
      color: #999999;
      font-size: 12px;
      line-height: 24px
    }

    .voiceStarBtn {
      box-shadow: inset 0px 0px 4px 0px rgba(249, 100, 34, 0.25);
      border-radius: 33px 33px 33px 33px;
      display: inline-block;
      color: #FC223B;
      font-size: 12px;
      padding: 8px 28px;
      margin-top: 6px;
    }

  }

  .img_box {
    margin-top: 10px;

    .title {
      text-align: left;
      font-size: 12px;
      color: #999999;
    }

    .img_list {
      margin-top: 4px;
      display: flex;
      .li {
        width: 65px;
        height: 65px;
        margin-right: 5px;
        position: relative;
        border-radius: 10px;
        .avatar{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
        .icon{
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(20%,-50%);
          font-size: 20px;
        }
      }

      .addImg {
        border: 1px dashed #999999;
        border-radius: 10px;
        position: relative;

      }

      .addImg:after,
      .addImg:before {
        position: absolute;
        content: '';
        display: block;
        width: 30%;
        height: 2px;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        background-color: #a6a6a6;
      }

      .addImg:before {
        width: 2px;
        height: 30%;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        background-color: #a6a6a6;
      }
    }

  }

}
.radionGourp {
  .text {
    font-weight: 400;
    font-size: 12px;
    color: #666666;
    line-height: 24px;
  }
}
.agreement {
  text-align: left;
  margin-top: 10px;

  .title {
    color: #1B1B1B;
    font-size: 14px;

  }

  .ul {
    margin-top: 5px;

    .li {
      color: #666666;
      font-size: 12px;
      line-height: 24px;
    }
  }
}

.itemBox {
  padding: 10px 0 10px 16px;
  text-align: left;
  height: 500px;
  text-align: left;

  .button {
    color: #fff;
    background-color: #FC223B;
    text-align: center;
    padding: 14px;
    border-radius: 10px 10px 10px 10px;
    margin-right: 16px;
  }

  .title {
    margin-top: 6px;
    font-size: 18px;
    color: #1a1a1a;
    margin-bottom: 16px;
  }


  .itemBody {
    // display: flex;
    height: 320px;
    overflow: scroll;
    margin-bottom: 20px;
    margin-top: 10px;
    border-top: 1px solid #F4F4F4;

    .li {
      display: flex;
      justify-content: space-between;
      padding: 15px 16px 15px 0;
      border-bottom: 1px solid rgba(153, 153, 153, 0.15);;

      .name {
        font-size: 16px;
        color: #1b1b1b !important;
      }
    }
  }
}

.timePop {
  height: 550px;
}
</style>
<style>
.avatar-uploader {
  border: 1px dashed #999999;
  border-radius: 10px;
  position: relative;
  width: 65px;
  height: 65px;
  overflow: hidden;

}

.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.avatar-uploader .el-upload:hover {
  border-color: #d9d9d9;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 100%;
  line-height: 65px !important;
  text-align: center;
}

.avatar {

}
.avatar-uploader .evert{
  width: 65px;
  height: 65px;
}
.avatar-uploader .avatar{
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.input {
  .textarea {
    border-radius: 10px;
    min-height: 15px;
    line-height: normal;
    font-size: 14px;
    width: 90%;

    .el-textarea__inner {
      background: #FFF6F2;
      border: 1px solid #FC223B;
    }

    .el-textarea__inner::-webkit-input-placeholder {
      color: #FC223B;
    }
  }
}

</style>