import request from "@/utils/request";
import store from "../store/index";
const newDevHost = store.state.newDevHost;

/*
* 会员卡主界面
* */
export function cardIndex(){
    return request.get(`${newDevHost}/api/user/member/card/index`)
}
/*
* 会员卡主界面
* */
export function overdueTime(member_type,vip_day) {
    return request.get(`${newDevHost}/api/user/member/overdue/time?member_type=${member_type}&vip_day=${vip_day}`)
}