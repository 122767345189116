<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {config} from "@/utils/api";

export default {
  name: "agreement",
  components: {NavigationBar},
  data() {
    return {
      title: this.$route.query.id == 10 ? '用户协议' : this.$route.query.id == 11?'隐私协议':'',
      content:''
    }
  },
  computed: {
  },
  mounted() {
    config().then(res => {
      if(res.data.status == true){
        const config = res.data.data
        this.$store.commit('updataConfig', config)
        if(this.$route.query.id == 10){
          this.content = config.find(e => {
            return e.key == 'user_xieyi'
          }).value
        }else if(this.$route.query.id == 11){
          this.content = config.find(e => {
            return e.key == 'yinsi_xieyi'
          }).value

        }
        console.log(this.content)
      }
    })
    // content:wx.getStorageSync('config').find(e=>{return e.key == 'user_xieyi'}).value
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigation-bar :title="title" :back-btn="false" class="navigation"></navigation-bar>
      <div class="text ql-editor inn" v-html="content"></div>
      <footer class="copyright">
        <p>Copyright © 2024 </p>
        <p>北京百街科技有限公司</p>
        <a href="http://beian.miit.gov.cn/publish/query/indexFirst.action" target="_blank">ICP备案/许可证号：京ICP备2023016119号</a>
      </footer>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .area{
    text-align: left;
  }
  .navigation{
    text-align: center;
  }
  .inn{
    margin: 0 16px;
  }
  .copyright{
    font-size: 12px;
    margin-top: 30px;
    color: #666666;
    text-align: center;
    a{
      color: #666666;
    }
  }
</style>