<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
// import {select_work} from "@/utils/api";
// import {Toast} from "vant";

export default {
  name: "performance",
  components: {NavigationBar},
  data() {
    return {
      nowDay:new Date().getMonth()+1+'月'+new Date().getDate()+'日',
      year:new Date().getFullYear()+'年'+(new Date().getMonth()+1)+'月',
      time:new Date().getFullYear()+'-'+((new Date().getMonth()+1)>10?new Date().getMonth()+1:'0'+(new Date().getMonth()+1)),
      list:[],
      show:false,
      currentDate: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()),
      minDate: new Date(new Date(new Date().getTime()-(31536000000*2)).getFullYear(),new Date().getMonth(),new Date().getDate()),
      maxDate: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()),
      formatter(type, value) {
        if (type === 'year') {
          return `${value}年`;
        }
        if (type === 'month') {
          return `${value}月`;
        }
        return value;
      },
    }
  },
  methods:{
    selectTime(){
      this.show = true
    },
    select_work(time){
      console.log(time)
      // select_work(this.$store.state.token,time).then(res=>{
      //   if(res.data.status==true){
      //     let list = res.data.data
      //     console.log(list)
      //     this.list = list
      //   }else{
      //     Toast(res.data.msg)
      //   }
      // }).catch(err=>{console.log(err)})
    },
    onDateCorfirm(e){
      let month = (new Date(e).getMonth()+1)>10?(new Date(e).getMonth()+1):'0'+(new Date(e).getMonth()+1)
      this.year = new Date(e).getFullYear()+'年'+(new Date(e).getMonth()+1)+'月'
      this.time = new Date(e).getFullYear()+'-'+month
      this.show = false
    },
    chaxun(){
      this.select_work(this.time)
    },
    onDateCancel(){this.show = false},
    godetails(item){
      console.log(item)
    },
  },
  mounted() {
    this.select_work(this.time)
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <NavigationBar :title="'绩效查询'"></NavigationBar>
      <div class="top">
        <div class="left" @click="selectTime">
          {{ year }}
        </div>
        <div class="right" @click="chaxun">查询</div>
      </div>
      <div class="jixiaoBox">
        <div class="head">
          <div class="time every">时间</div>
          <div class="content every">订单编号</div>
          <div class="ticheng every">绩效提成</div>
        </div>
        <div class="body">
          <div class="every " v-for="(item,index) in list" :key="index" @click="godetails(item)">
            <div class="chaxunBody time">{{item.times}}</div>
            <div class="chaxunBody content">{{item.order_id}}</div>
            <div class="chaxunBody ticheng">{{item.ticheng}}</div>
          </div>
          <div class="none" v-if="list.length<=0">暂无数据</div>
        </div>
      </div>
      <van-popup
          v-model="show"
          round
          position="bottom"
          custom-style="height: 50%"
          bind:close="onClose"
      >
        <van-datetime-picker
            type="year-month"
            :value="currentDate"
            bind:input="onInput"
            :min-date="minDate"
            :max-date="maxDate"
            :formatter="formatter"
            @confirm="onDateCorfirm"
            @cancel="onDateCancel"
        />
      </van-popup>
    </div>
  </div>
</template>

<style scoped lang="scss">
.top{
  margin: 0 16px;
  padding:5px  0px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left{
    color: #4E76FA;
    font-size: 14px;
    border: 1px solid #4E76FA;
    border-radius: 8px;
    padding: 4px 14px;
    background-color: #F1F6FD;
  }
  .right{
    background-color: #4E76FA;
    color: white;
    display: flex;
    align-items: center;
    font-size: 14px;
    border-radius: 8px;
    padding: 4px 14px;
  }
}
.jixiaoBox{
  margin: 10px 16px 0 16px;
  background-color: white;
  box-shadow: 0px 0px 2px 0px rgba(102,102,102,0.25);
  border-radius: 10px;
  padding: 10px 0 10px 10px;
  height: 90%;
  overflow: hidden;
  .head{
    display: flex;
    color: #444444;
    .every{
      font-size: 13px;
    }
  }
  .body{
    .every{
      display: flex;
      margin: 10px 0 0 0;
      border-radius: 8px;
      padding: 4px 0;
      align-items: center;
      .time{
        line-height: 20px;
      }
      .chaxunBody{
        font-size: 13px;
        color: #1b1b1b;
      }
    }
    .every:nth-child(odd) {
      background-color: #C9E4FF;
    }
    .every:nth-child(even) {
      background-color: #C9F2FF;
    }
    .none{
      text-align: center;
      font-size: 14px;
      color: #666666;
      padding: 20px 0;
    }
  }
  .time{
    width: 30%;
    text-align: center;
  }
  .content{
    width: 50%;
    text-align: center;
    padding: 0;
  }
  .ticheng{
    width: 25%;
    text-align: center;
  }
}
</style>