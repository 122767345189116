<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {getFeedBackItem} from "@/api/idle";
import {Toast} from "vant";

export default {
  name: "FAQdetails",
  components: {NavigationBar},
  data(){
    return{
      activeObj:{},
      question:[],
    }
  },
  mounted() {
    this.activeObj = this.$route.query
    getFeedBackItem(this.$route.query.id).then((res) => {
      if(res.data.status == 200){
        this.question = res.data.data
      }else{
        Toast(res.data.msg)
      }
    }).catch(err=>{
      console.log(err)
    })
  }
}
</script>

<template>
<div class="container">
  <div class="area">
    <navigationBar :title="''" class="navigationBar"></navigationBar>
    <div class="title">
      {{activeObj.list_item}}
    </div>
    <div class="question">
      <div class="ul">
        <div class="li" v-for="(item,index) in question" :key="index">
          <div class="li_title">{{item.content}}</div>
          <div class="li_text">{{item.list_item}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.container{
  min-height: 100vh;
}
.area{
  min-height: 100vh;
}
.title{
  text-align: left;
  color: #1b1b1b;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 10px 16px;
}
.question{
  margin: 10px 16px;
  text-align: left;
  .ul{

    .li{
      margin: 10px 0;
      text-align: justify;
      color: #666666;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      .li_title{
        font-size: 14px;
        margin-bottom: 6px;
      }
      .li_text{
        font-size: 13px;
      }
    }
  }
}
</style>