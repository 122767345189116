<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {Toast} from "vant";
import {createRecoveryShopType,createRecovery,getQuestionCate } from "@/api/idle"

export default {
  name: "MoveCheckIn",
  components: {NavigationBar},
  data() {
    return {
      problemLi: [],
      addshow: false,
      addValue:'',
      province:[],
      provinceShow:false,
      chooseProvince:[],
      defaultProvice:[0],

      city:[],
      cityShow:false,
      chooseCity:[],
      defaultCity:[],
    }
  },
  methods: {
    getTypes() {
      getQuestionCate( 5).then(res => {
        if (res.data.status == 200) {
          let list = res.data.data
          list.forEach(item => {
            item.choose = false
          })
          this.problemLi = list
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    chooseItem(item) {
      console.log(item)
      item.choose = !item.choose
    },
    addProblem() {
      this.addshow = true
    },
    submit() {
      if(!this.chooseCity[0]){
        Toast('请选择接单地区')
        return
      }
      let list = this.problemLi.filter(item => {return item.choose == true})
      if(list.length <= 0){
        Toast('请选择回收类型')
        return;
      }
      console.log(list)
      let str = String(list.map(item => {return item.id}))
      createRecovery('搬家师傅',this.chooseProvince[0].id,this.chooseCity[0].id,str,2).then(res=>{
        console.log(res)
        if(res.data.status == 200){
          Toast('提交成功')
          this.$router.go(-1)
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    addComfirm() {
      if(!this.addValue){
        Toast('请输入名称')
      }
      createRecoveryShopType(this.addValue, 5).then(res=>{
        if(res.data.status == 200){
          Toast('添加成功')
          this.addshow = false
          this.problemLi.push({
            cate_name: this.addValue,
            choose:false,
            create_time: null,
            delete_time: null,
            id:res.data.data,
            type:4,
            uid:this.$store.state.user.id,
            update_time:null,
          })
          this.addValue = ''
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    closeAddPop() {
      this.addshow = false
    },
    savePop(){
      if(this.provinceShow){
        this.defaultProvice = this.$refs.provincePicker.getIndexes()
        this.chooseProvince = this.$refs.provincePicker.getValues()
        this.provinceShow = false
      }
      if(this.cityShow) {
        this.defaultCity = this.$refs.cityPicker.getIndexes()
        this.chooseCity = this.$refs.cityPicker.getValues()
        this.cityShow = false
      }
    },
    openCity(){
      if(this.chooseProvince[0]){
        this.cityShow =true
      }else{
        Toast('请选择省区')
      }
    },
  },
  mounted() {
    this.getTypes()
    this.province = this.$store.state.allAddress.map(item=>{return {text:item.name,parent_id:item.parent_id,id:item.id}})
  },
  watch: {
    chooseProvince(n){
      let list = this.$store.state.allAddress.find(item=>{return item.id == n[0].id}).staa
      this.city = list.map(item=>{return {text:item.name,parent_id:item.parent_id,id:item.id}})
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'搬家入住'" class="navigationBar"></navigationBar>
      <div class="topImg">
        <img src="https://picture.bjbjkj.top/tp2024071803001500257731617.png" alt="" class="image">
      </div>
      <div class="line">
        <div class="title">
          选择注册类型
        </div>
        <div class="card">
          搬家师傅
        </div>
      </div>
      <div class="line">
        <div class="title">
          选择接单地区
        </div>
        <div class="topCard">
          <div class="card" @click="provinceShow = true">
            <div class="left">{{chooseProvince[0]?chooseProvince[0].text:'省'}}</div>
            <span class="iconfont icon-zhankai1"></span>
          </div>
          <van-popup  v-model="provinceShow" class="choose" position="bottom" round >
            <van-picker
                :columns="province"
                ref="provincePicker"
                :default-index="defaultProvice[0]"
            />
            <div class="bottomBox">
              <div class="close btn" @click="provinceShow = false">取消</div>
              <div class="save btn" @click="savePop">确定</div>
            </div>
          </van-popup>
          <div class="card"  @click="openCity">
            <div class="left">{{chooseCity[0]?chooseCity[0].text:'市'}}</div>
            <span class="iconfont icon-zhankai1"></span>
          </div>
          <van-popup  v-model="cityShow" class="choose" position="bottom" round >
            <van-picker
                :columns="city"
                ref="cityPicker"
                :default-index="defaultCity[0]"
            />
            <div class="bottomBox">
              <div class="close btn" @click="cityPicker = false">取消</div>
              <div class="save btn" @click="savePop">确定</div>
            </div>
          </van-popup>

        </div>
      </div>
      <div class="line">
        <div class="title">
          回收类型<span class="inneText">（可多选）</span>
        </div>
        <div class="problemLi">
          <div class="ul">
            <div class="li" v-for="(item,index) in problemLi" :key="index" :class="item.choose?'chooseLi':''"
                 @click="chooseItem(item)">
              {{ item.cate_name }}
            </div>
            <div class="addLi li" @click="addProblem">自定义</div>
          </div>
        </div>
        <div class="botmText">
          *自定义不可超过四个字
        </div>
      </div>
      <van-popup v-model="addshow" class="addProblem">
        <div class="titl">添加</div>
        <input type="text" maxlength="4" v-model="addValue" placeholder="自定义输入（不可超过四个字）">
        <div class="footer">
          <div class="Btn" @click="closeAddPop">取消</div>
          <div class="Btn comfirm" @click="addComfirm">确定</div>
        </div>
      </van-popup>
      <div class="botmBtn" @click="submit">提交审核</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  background: #f4f6fa;
  min-height: 100vh;
}

.navigationBar {
  background: #fff;

}

.topImg {
  width: 100%;
  max-height: 180px;
  margin-top: 10px;

  .image {
    width: 100%;
    max-height: 180px;
    object-fit: cover;
  }
}

.line {
  margin: 10px 16px;
  text-align: left;
  padding-top: 1px;
  padding-bottom: 1px;

  .title {
    color: #1b1b1b;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
  }

  .inneText {
    color: #c7c7c7;
    font-size: 12px;
    line-height: 17px;
  }

  .problemLi {
    margin: 10px 0;

    .ul {
      .li {
        display: inline-block;
        place-content: center;
        place-items: center;
        gap: 10px;
        flex-shrink: 0;
        padding: 4px 13px;
        border-radius: 30px;
        background: #ffffff;
        color: #1b1b1b;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-top: 10px;
        margin-right: 10px;
      }

      .chooseLi {
        color: #ffffff;
        background: #fd2a39;
      }
    }
  }

  .botmText {
    color: #c7c7c7;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
  }
}

.card {
  border-radius: 10px;
  background: #ffffff;
  padding: 10px;
  margin: 10px 0;
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.topCard {
  display: flex;
  justify-content: space-between;

  .card {
    width: 42%;
    display: flex;
    justify-content: space-between;
  }
}

.botmBtn {
  position: fixed;
  left: 50%;
  bottom: 40px;
  transform: translate(-50%, 0);
  border-radius: 10px;
  background: #fc223b;
  text-align: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 85%;
  padding: 13px;
}
.addProblem{
  width: 80%;
  border-radius: 10px;
  background: #fff;
  background-color: #fff!important;
  padding: 10px 10px 0;
  .title{
    text-align: center;
    color: #1b1b1b;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
  input{
    margin-top: 15px;
    border-radius: 10px;
    background: #f9f9f9;
    border: none;
    color: #1b1b1b;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 80%;
    padding: 10px;
  }
  input::placeholder{
    color: #c7c7c7;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px
  }
  .footer{
    display: flex;
    margin-top: 10px;
    .Btn{
      width: 50%;
      padding: 15px;
      border-top: 1px solid #99999926;
    }
    .comfirm{
      border-left: 1px solid #99999926;
      color: #FC223B;
    }
  }
}
.choose{
  .bottomBox {
    display: flex;
    background: #fff;
    padding: 10px 60px;
    justify-content: space-around;

    .btn {
      place-content: center;
      place-items: center;
      gap: 10px;
      flex-shrink: 0;
      padding: 8px 40px;
      border-radius: 10px;
      background: #f0f0f0;
      color: #1b1b1b;
      font-weight: 500;
      font-size: 16px;
    }

    .save {
      background: #05C160;
      color: #fff;
    }
  }
}
</style>