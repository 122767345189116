<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {feedBackPost, serviceFeedBack} from "@/utils/api";
import {Toast} from "vant";

export default {
  name: "feedback",
  components: {navigationBar},
  data(){
    return{
      name:'',
      phone:'',
      con:'',
      feedback:'',
      submitFlag:true,
    }
  },
  methods:{
    getInfo(){
      serviceFeedBack().then(res=>{
        this.feedback = res.data.data.feedback
      })
    },
    subMit(){
      console.log('submit')
      if(!this.submitFlag)return
      if(!this.name) {
        Toast('请填写姓名')
      }
      if(!this.phone || !(/^1(3|4|5|7|8|9|6)\d{9}$/i.test(this.phone))){
        Toast('请填写正确的手机号码')
      }
      if(!this.con) {
        Toast('请填写内容')
      }
      this.submitFlag = false
      feedBackPost(this.name,this.phone,this.con).then(res=>{
        console.log(res)
        if(res.data.status == 200){
          Toast({
            message: res.data.msg,
            onClose:()=>{
              this.$router.go(-2)
            }
          });
        }else{
          Toast(res.data.msg)
          this.submitFlag = true
        }
      })

    },
  },
  mounted() {
    this.getInfo()
  }
}
</script>

<template>
  <div class="feedback-wrapper">
    <div class="goPage">
      <navigationBar :title="''" class="navigation"></navigationBar>
    </div>
    <div class="head">
      <div class="left-wrapper">
        <div class="title">商城客服已离线</div>
        <div class="txt">{{feedback}}</div>
      </div>
      <div class="img-box"><img :src="require('/public/image/feed-icon.png')" mode="" /></div>
    </div>
    <div class="main">
      <div class="title">我要反馈</div>
      <div class="input-box">
        <input type="text" placeholder="请输入您的姓名" v-model="name" maxlength="140">
      </div>
      <div class="input-box">
        <input type="text" placeholder="请输入您的联系电话" v-model="phone" maxlength="140">
      </div>
      <div class="input-box">
        <textarea type="text" placeholder="请填写内容" v-model="con" maxlength="140"/>
      </div>
      <div class="sub_btn" @click="subMit">提交</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.navigation .iconfont{
  color: #fff;
}
.goPage{
  background-color:#3A3A3A;
  padding: 10px 0 0 10px;
  .icon-fanhui1{
    background-color:#fff;
    color:#333;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 10px
  }
}
.feedback-wrapper .head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 123px;
  padding: 0px 17px;
  background-color: #3a3a3a;
}
.head{
  .left-wrapper{
    width: 261px;
    color: #fff;
    font-size: 13px;
    text-align: left;
    .title{
      margin-bottom: 8px;
      font-size: 18px;
    }
    .txt{}
  }
  .img-box{
    img{
      width: 99px;
      height: 89px;
    }
  }
}
.main{
  margin-top: 9px;
  padding: 17px 17px 38px;
  background-color: #fff;
  text-align: left;
  .title{
    font-size: 17px;
    font-weight: 700;
  }
  .input-box{
    margin-top: 11px;
    input{
      display: block;
      width: 100%;
      height: 44px;
      background: #f5f5f5;
      font-size: 16px;
      padding-left: 11px;
      border: none;
    }
    textarea{
      display: block;
      width: 100%;
      height: 149px;
      padding: 11px;
      background: #f5f5f5;
      font-size: 16px;
      border: none;

    }
  }
  .sub_btn{
    margin-top: 74px;
    width: 100%;
    height: 49px;
    line-height: 49px;
    font-size: 17px;
    text-align: center;
    color: #fff;
    border-radius: 24px;
    background-color: #3875ea;
  }
}
</style>