<template>
  <div class="timePop" :class="type=='xiu'?'xiuPop':'songPop'" :style="allDays?'height:530px':''">
    <div class="title">选择时间</div>
    <div class="scroll" v-if="type=='xiu'">
      <div @click="chooseKancha(index)" class="item" v-for="(item,index) in kanchayuanList" :key="index"
           :class="chooseKanchaIndex==index?'active':''">
        勘察员：{{ item.name }}
      </div>
    </div>
    <div class="time_body">
      <div class="pop_head">
        <div class="date_box">
          <div class="date_every" v-for="(item, index) in dateList" :key="index"
               @click="selectDate(item, index)">
            <div class="selectLine" v-if="dataIndex == index"
                 :style="dataIndex == index ? 'background:' + proColor : ''"></div>
            <div class="date_top" :style="dataIndex == index ? 'color:' + proColor : ''">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>

      <div class="timeInBox1">
        <div v-for="(item, index) in allDays?WholeDay:timeList" :key="index" style="width: 86%;">
          <div class="select" v-if="(proType == 'song' || index != 0)"
               :class="nowHour >= item.disable ? 'noSelect' : ''" @click="selectTime(item, index)">
            <div class="timeButtonTop" v-if="index == 0"
                 :style="(timeIndex[1]?timeIndex[0]<=item.disable&&timeIndex[1]>=item.disable:timeIndex[0]==item.disable)
            &&nowSend ? 'color:' + proColor : ''">
              {{ item.text }}
            </div>
            <!-- proColor?'color:'+proColor:'color:red' -->
            <div class="timeButtonUp" v-if="index != 0"
                 :style="(timeIndex[1]?timeIndex[0]<=item.disable&&timeIndex[1]>=item.disable:timeIndex[0]==item.disable)
            &&nowSend ? 'color:' + proColor : ''">
              {{ item.time }}
<!--              {{ (timeIndex[1]?timeIndex[0]<=item.disable&&timeIndex[1]>=item.disable:timeIndex[0]==item.disable)-->
<!--            &&nowSend?'selectTime':''}}-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottomBtn" v-if="allDays" @click="okDays" :style="'background-color:'+proColor">
      确认
    </div>
  </div>
</template>

<script>
import { getNowTime} from "@/utils/api";
import {Toast} from "vant";

export default {
  components: {},
  props: {
    white: {
      type: [String],
      default: ''
    },// white  black
    type: [String],// song  xiu
    color: [String],// song  xiu
    allDays: {
      type: [String, Number, Boolean],
      default: false,
    },
  },
  data() {
    return {
      dateList: [{text: '今天', time: ''}, {text: '明天', time: ''}, {text: '后天', time: ''}],
      nowHour: 8,
      dataIndex: 0,
      timeIndex: [],
      nowSend: false,
      timeList: [
        {text: '立即预约', time: '半小时内到达', diab: 99, disable: 99},
        {text: '预约', time: '08:00-09:00', diab: 7, disable: 7},
        {text: '预约', time: '09:00-10:00', diab: 8, disable: 8},
        {text: '预约', time: '10:00-11:00', diab: 9, disable: 9},
        {text: '预约', time: '11:00-12:00', diab: 10, disable: 10},
        {text: '预约', time: '13:00-14:00', diab: 12, disable: 12},
        {text: '预约', time: '14:00-15:00', diab: 13, disable: 13},
        {text: '预约', time: '15:00-16:00', diab: 14, disable: 14},
        {text: '预约', time: '16:00-17:00', diab: 15, disable: 15},
        {text: '预约', time: '17:00-18:00', diab: 16, disable: 16},
        {text: '预约', time: '18:00-19:00', diab: 17, disable: 17},
        {text: '预约', time: '19:00-20:00', diab: 18, disable: 18},
      ],
      WholeDay: [
        {text: '', time: '', diab: 99, disable: 99},
        {text: '预约', time: '00:00-01:00', diab: 0, disable: 0},
        {text: '预约', time: '01:00-02:00', diab: 1, disable: 1},
        {text: '预约', time: '02:00-03:00', diab: 2, disable: 2},
        {text: '预约', time: '03:00-04:00', diab: 3, disable: 3},
        {text: '预约', time: '04:00-05:00', diab: 4, disable: 4},
        {text: '预约', time: '05:00-06:00', diab: 5, disable: 5},
        {text: '预约', time: '06:00-07:00', diab: 6, disable: 6},
        {text: '预约', time: '07:00-08:00', diab: 7, disable: 7},
        {text: '预约', time: '08:00-09:00', diab: 8, disable: 8},
        {text: '预约', time: '09:00-10:00', diab: 9, disable: 9},
        {text: '预约', time: '10:00-11:00', diab: 10, disable: 10},
        {text: '预约', time: '11:00-12:00', diab: 12, disable: 12},
        {text: '预约', time: '13:00-14:00', diab: 13, disable: 13},
        {text: '预约', time: '14:00-15:00', diab: 14, disable: 14},
        {text: '预约', time: '15:00-16:00', diab: 15, disable: 15},
        {text: '预约', time: '16:00-17:00', diab: 16, disable: 16},
        {text: '预约', time: '17:00-18:00', diab: 17, disable: 17},
        {text: '预约', time: '18:00-19:00', diab: 18, disable: 18},
        {text: '预约', time: '19:00-20:00', diab: 19, disable: 19},
        {text: '预约', time: '20:00-21:00', diab: 20, disable: 20},
        {text: '预约', time: '21:00-22:00', diab: 21, disable: 21},
        {text: '预约', time: '22:00-23:00', diab: 22, disable: 22},
        {text: '预约', time: '23:00-00:00', diab: 23, disable: 23},

      ],
      proWhite: this.white,
      proType: this.type,
      proColor: this.color,

      kanchayuanList: [],
      chooseKanchaIndex: 0,
      serverTime: 0,
    };
  },
  watch: {
    timeIndex(n) {
      if (this.allDays) {
        if (n.length > 2) {
          this.timeIndex = []
        }
        // if(this.timeIndex.length>=2){
        //   // this.timeIndex.sort(function (a, b) {
        //   //   return a - b;
        //   // })
        // }
      } else {
        if (n.length > 1) {
          this.timeIndex.shift()
        }
      }
      console.log(n)
    },
  },
  computed: {
    chooseKanchaObj() {
      return this.kanchayuanList[this.chooseKanchaIndex];
    }
  },
  methods: {
    okDays(){
      // let data
      // let time = []
      if(this.timeIndex.length<=0){
        Toast('请选择时间')
        return
      }
      if(this.timeIndex.length<=1){
        Toast('请选择结束时间')
        return
      }
      let str = ''
      this.timeIndex.forEach((item,index)=>{
        let now = new Date().getTime()
        let first
        let last
        let time
        time = this.WholeDay.find(e=>{return e.diab == item%24})
        if(item<23){
          first = new Date(now)
          let y = first.getFullYear()>10?first.getFullYear():'0'+first.getFullYear()
          let m = (first.getMonth()+1)>10?(first.getMonth()+1):'0'+(first.getMonth()+1)
          let d = first.getDate()>10?first.getDate():'0'+first.getDate()
          str +=y +'-'+ m +'-'+ d + ' '+time.time.split('-')[index]+','
        }else{
          last = new Date(now+(86400000*Math.floor(item/24)))
          let y = last.getFullYear()>10?last.getFullYear():'0'+last.getFullYear()
          let m = (last.getMonth()+1)>10?(last.getMonth()+1):'0'+(last.getMonth()+1)
          let d = last.getDate()>10?last.getDate():'0'+last.getDate()
          console.log()
          str +=y +'-'+ m +'-'+ d + ' '+time.time.split('-')[index]+','
        }
      })
      str = str.substring(str,str.length-1)
      console.log(str)
      let obj = {
        time: str,
      }
      this.sendParents(obj)
    },
    // 获取今天明天后天
    GetDateStr(AddDayCount, num) {
      var dd = new Date();
      dd.setDate(dd.getDate() + AddDayCount); // 获取AddDayCount天后的日期
      var m = dd.getMonth() + 1; // 获取当前月份的日期
      var y = dd.getFullYear()
      m = m.toString();
      if (m.length == 1) {
        m = '0' + m;
      }
      var d = dd.getDate();
      d = d.toString();
      if (d.length == 1) {
        d = '0' + d;
      }
      let r = m + '月' + d + '日'

      if (num == 1) {
        return r
      } else {
        return y + '-' + m + '-' + d
      }
    },
    GetDateStr1(AddDayCount) {
      var dd = new Date();
      dd.setDate(dd.getDate() + AddDayCount); // 获取AddDayCount天后的日期
      var m = dd.getMonth() + 1; // 获取当前月份的日期
      // let y = dd.getFullYear()
      var h = dd.getHours()
      if (h < 8) {
        dd.setDate(dd.getDate() - 1)
        m = dd.getMonth() + 1; // 获取当前月份的日期
        // y = dd.getFullYear()
        var d = dd.getDate();
        return m + '月' + d + '日'
      } else {
        m = m.toString();
        if (m.length == 1) {
          m = '0' + m;
        }
        let d = dd.getDate();
        d = d.toString();
        if (d.length == 1) {
          d = '0' + d;
        }
        let r = m + '月' + d + '日'
        return r
      }

    },
    nowSendFun1() {
      let list = this.allDays ? this.WholeDay : this.timeList
      let newArr = list.slice(1);
      if (this.dataIndex == 0) {
        // if (this.nowHour >= 7 && this.nowHour < 19) {
        //   // this.timeIndex[0] = list.findIndex(item => {
        //   //   return item.disable != 99 && item.disable > this.nowHour
        //   // })
        // } else {
        //   // this.timeIndex[0] = -1
        // }
      }
      let flag = newArr.some(e => {
        return e.disable > this.nowHour
      })
      this.nowSend = flag
    },
    selectTime(item, index) {
      let list = this.allDays ? this.WholeDay : this.timeList
      if (this.dataIndex != 0 && index == 0) return;
      if (this.nowHour <= 6) {
        if (index == 0) {
          return
        }
      }
      if (this.nowHour >= list[index].disable) {
        return
      }
      // console.log(item, index);


      if (!this.allDays) {
        this.timeIndex.push(item.disable)
        console.log(this.nowHour)
        this.$nextTick(() => {
          let data = this.GetDateStr(this.dataIndex, 0)
          let star
          let end
          if (this.timeIndex[0] == 0) {
            let myDate = new Date(this.serverTime * 1000);
            let hours = myDate.getHours(); //获取当前小时
            let minutes = myDate.getMinutes(); //获取当前分钟
            hours = hours < 10 ? ('0' + hours) : hours;
            minutes = minutes < 10 ? ('0' + minutes) : minutes;
            star = hours + ':' + minutes
            end = (hours + 1) + ':' + minutes
            if (this.nowHour == 12) {
              end = (hours + 2) + ':' + minutes
            }
          } else {
            console.log()
            star =list.find(e=>{return e.disable == this.timeIndex[0]}).time.split('-')[0]
            end = list.find(e=>{return e.disable == this.timeIndex[0]}).time.split('-')[1]
          }
          let obj = {
            time: data + ' ' + star + ',' + data + ' ' + end,
            timeshow: this.dateList[this.dataIndex].text + list.find(e=>{return e.disable == this.timeIndex[0]}).time,
          }
          if (this.type == 'xiu') {
            if (this.chooseKanchaObj) {
              this.sendParents(obj, index, this.chooseKanchaObj)
            }
          } else {
            this.sendParents(obj, index)
          }
        })

      } else {
        this.timeIndex.push(item.disable)
        this.timeIndex.sort(function (a, b) {return a - b;})
        this.$nextTick(() => {

        })
      }

    },
    selectDate(item, index) {
      let list = this.allDays ? this.WholeDay : this.timeList

      if (this.dataIndex == index) return;
      this.dataIndex = index
      list.forEach(item => {
        item.disable = item.diab + (this.dataIndex * 24)
      })
      this.nowSendFun1()
      console.log(this.dataIndex)
      if (this.dataIndex > 0) {
        if (this.timeIndex[0] == 0 | this.timeIndex[0] == -1) {
          this.timeIndex.push(0)
        }
      } else {
        if (this.timeIndex[0] != 0) {
          // this.timeIndex.push(list.find(value => value.disable != 99 & value.disable > this.nowHour).disable)
        } else {
          // this.timeIndex.push(0)
        }
      }

    },

    chooseKancha(index) {
      if (this.chooseKanchaIndex == index) return
      this.chooseKanchaIndex = index
    },

    sendParents(item, index = -1, chooseKanchaObj) {
      let obj = {
        item, index, chooseKanchaObj
      }
      this.$emit('childMessage', obj);
    },
  },
  created() {
  },
  async mounted() {
    const {data} = await getNowTime()
    this.serverTime = (data.data * 1000)
    console.log(this.proWhite, 'proWhiteproWhiteproWhite')
    console.log(this.proType, 'proTypeproTypeproType')
    console.log(this.proColor, 'proColorproColorproColor')
    this.dateList.forEach((item, index) => {
      item.time = this.GetDateStr(index, 1)
    })

    if (new Date(this.serverTime).getMinutes >= 45) {
      this.nowHour = new Date(this.serverTime).getHours() + (this.dataIndex * 24) + 1
    } else {
      this.nowHour = new Date(this.serverTime).getHours() + (this.dataIndex * 24)
    }
    this.nowSendFun1()
    if (this.type == 'xiu') {
      // authority_list(this.$store.state.token).then(res => {
      //   if (res.data.status == true) {
      //     this.kanchayuanList = res.data.data
      //   } else {
      //     Toast(res.data.msg)
      //   }
      // })
    }

  }
};
</script>
<style lang="scss" scoped>
.timePop {
  padding: 10px 0 10px 0;
  text-align: left;
  height: 450px;
  background: #fff;
}

.xiuPop {
  height: 520px;
}

.title {
  margin-top: 6px;
  font-size: 18px;
  color: #1a1a1a;
  padding-bottom: 16px;
  padding-left: 16px;
  border-bottom: 1px solid #F4F4F4;

}

.time_body {
  display: flex;
  //   height: 790px;
  margin-bottom: 20px;

  .pop_head {
    width: 25%;
    overflow: scroll;

    .date_box {
      display: flex;
      color: white;
      width: 100%;
      margin: 0 auto;
      flex-direction: column;

      .date_every {
        width: 100%;
        border-bottom: none;
        text-align: center;
        padding: 15px 0;
        position: relative;

        .date_top {
          color: #1a1a1a;
          text-align: center;

        }
      }
    }
  }
}
.bottomBtn{
  margin: 0 16px;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  padding: 13px;
  text-align: center;
}

.timeInBox1 {
  width: 75%;
  background-color: #FFFFFF;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow-y: scroll;
  /* flex-direction: column; */
  white-space: nowrap;
  color: #1a1a1a;
  height: 48vh;

  .select {
    text-align: left;
    width: 100%;
    /* border: 1px solid #DADADA; */
    border-bottom: 1px solid #F4F4F4;
    color: white;
    padding: 15px 16px 15px 0;
    position: relative;
    color: #1a1a1a;
  }
}

.noSelect1 .timeButtonUp,
.noSelect1 .timeButtonUp {
  color: #DADADA !important;
}

.select1 .timeButtonUp,
.select1 .timeButtonTop {
  font-size: 32px;
  color: #666666;
}

.timeButtonTop {
  font-size: 14px;
  position: relative;
  color: #666666;
}

.noSelect {
  .timeButtonUp {
    color: #DADADA !important;
  }
}

.selectLine {
  position: absolute;
  width: 4px;
  height: 16px;
  border-radius: 10px;
  transform: translate(0, -50%);
  top: 50%;
}


.scroll {
  white-space: nowrap;
  overflow: scroll;
  margin-bottom: 10px;
  margin-left: 16px;
  margin-top: 5px;

  .item {
    display: inline-block;
    font-weight: 400;
    font-size: 13px;
    color: #666666;
    line-height: 24px;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    padding: 10px;
    margin-right: 10px;
  }

  .active {
    color: #FC223B;
    background: #FFF6F2;
    border: 1px solid #FC223B;
  }
}

.scroll::-webkit-scrollbar {
  display: none
}
</style>