<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import { kefu_list} from "@/utils/api";
import {Toast} from "vant";
import {getjob, createCollection, up_job_status, del_collection} from "@/api/recruitment"

export default {
  name: "invite_details",
  components: {navigationBar},
  data(){
    return{
      collect:false,
      obj:{},
    }
  },
  mounted() {
    if(this.$route.query.item){
      this.getDetails(JSON.parse(this.$route.query.item));
    }
  },
  methods:{
    getDetails(item){
      getjob(item.id).then(res => {
        if(res.data.status == 200){
          let obj = res.data.data
          obj.benefitsLi = obj.benefits.split(',')
          if(obj.Collection){
            this.collect = true
          }else{
            this.collect = false
          }
          this.obj = obj
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    concat(){
      console.log(this.obj)
      kefu_list(this.$store.state.token,this.obj.u_id,4).then(res=>{
        if(res.data.status == true){
          let obj = res.data.data
          let list = obj.filter(item=>{return item.type == 4})
          let messageObj = {}
          if(list.find(item=>{return item.anotherid == this.obj.u_id&&item.userid == this.$store.state.user.id})){
            messageObj = list.find(item=>{return item.anotherid == this.obj.u_id&&item.userid == this.$store.state.user.id})
          }else{
            if(list.find(item=>{return item.anotherid == this.$store.state.user.id &&item.userid == this.obj.u_id})){
              messageObj = list.find(item=>{return item.anotherid == this.$store.state.user.id &&item.userid == this.obj.u_id})
              messageObj.anotherid =  this.obj.u_id
              messageObj.userid = this.$store.state.user.id
            }
          }
          console.log(messageObj,res.data.data)

          // return
          // this.$store.commit('updataChatDetail',messageObj)
          // this.$router.push({
          //   name: 'chat_details',
          // })

        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },

    add_collection(){
      console.log(this.collect)
      if(this.collect){
        //取消
        console.log(this.obj)
        del_collection(this.obj.Collection.id).then(res=>{
          if(res.data.status == 200){
            Toast('已取消')
            this.collect = false
          }else{
            Toast(res.data.msg)
          }
        }).catch(err=>{
          console.log(err)
        })
      }else{
        //添加
        createCollection(this.obj.id,2).then(res=>{
          if(res.data.status == 200){
            Toast('收藏成功')
            if(!this.obj.Collection){
              let obj = {
                id:res.data.data
              }
              this.obj.Collection = obj
            }else{
              this.obj.Collection.id = res.data.data.id
            }
            this.collect = true
          }else{
            Toast(res.data.msg)
          }
        }).catch(err=>{
          console.log(err)
        })
      }
    },
    closeInvete(){
      up_job_status(this.obj.id).then(res=>{
        if(res.data.status == 200){
          Toast(res.data.msg)
          this.obj.status = 2
        }
      })
    },
    pubilishAgub(){
      up_job_status(this.obj.id).then(res=>{
        if(res.data.status == 200){
          Toast(res.data.msg)
          this.obj.status = 1
        }
      })
    },
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="''" class="navigationBar">
        <template #search>
          <div class="rightFun">
            <img @click="add_collection" :src="$store.state.imageHost+'/img/Collect11.png'" alt="" class="icon" v-if="!collect">
            <img @click="add_collection" :src="$store.state.imageHost+'/img/Collect22.png'" alt="" class="icon" v-if="collect">
<!--            <img :src="$store.state.imageHost+'/img/share_invite.png'" alt="" class="icon">-->
            <img :src="$store.state.imageHost+'/img/report_invite.png'" alt="" class="icon">
          </div>
        </template>
      </navigationBar>
      <div class="inner">
        <div class="position">{{obj.position}}</div>
        <div class="emolument">{{obj.salary_min}}k - {{obj.salary_max}}k</div>
        <div class="timeAddress">
          <div class="address" v-if="obj.city_name||obj.district_name">
            <span class="iconfont icon-dingwei"></span>
            <span class="address_text" >{{ obj.city_name }} {{ obj.district_name }}</span>
          </div>
          <div class="address">
            <span class="iconfont icon-xiangqing-gongling"></span>
            <span class="address_text" >{{ obj.experience_required }}</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="title">岗位描述</div>
        <div class="tagScroll">
          <div class="ul">
            <div class="li" v-for="(item2,index2) in obj.benefitsLi" :key="index2">
              {{ item2 }}
            </div>
          </div>
        </div>
        <div class="requirements">{{obj.job_requirements}}</div>
        <div class="line"></div>
        <div class="corporation">
          <div class="leftImg">
<!--            <img :src="obj.company.logo_image" alt="" class="logo" v-if="obj.company">-->
            <img src="https://picture.bjbjkj.top/tp2024082102211421361260099.png" alt="" class="logo">
          </div>
          <div class="right">
            <div class="in">
              <div class="top" v-if="obj.company">
                <div class="text">{{ obj.company.business_license_name }}</div>
                <span class="iconfont icon-Frame-4"></span>
              </div>
              <div class="numbers" v-if="obj.company">
                {{obj.company.company_nums}}人
                <div class="is_attestation" v-if="obj.company.id">已认证</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom" v-if="obj.company">
        <div class="btn" @click="concat" v-if="$store.state.nowCompany.id!=obj.company.id">
           <span class="iconfont icon-Frame5"></span>聊一聊
        </div>
        <div class="btn" @click="closeInvete" v-if="$store.state.nowCompany.id==obj.company.id&&(obj.status&&obj.status==1)">
          关闭岗位
        </div>
        <div class="btn" @click="pubilishAgub" v-if="$store.state.nowCompany.id==obj.company.id&&(obj.status&&obj.status==2)">
          重新发布
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .rightFun{
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .iconfont{
      font-size: 24px;
      color: #666666;
      margin-left: 20px;
    }
    .icon{
      width: 24px;
      height: 24px;
      margin-left: 20px;
      object-fit: cover;
    }
  }
  .inner{
    text-align: left;
    margin: 10px 16px;
    padding-bottom: 100px;
    .position{
      text-align: justify;
      color: #1b1b1b;
      font-weight: 500;
      font-size: 22px;
      line-height: 31px;
    }
    .emolument{
      margin-top: 4px;
      color: #fc223b;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
    }
    .timeAddress{
      display: flex;
      color: #666666;
      margin-top: 10px;
      .address{
        margin-right: 20px;
        .iconfont{
          margin-right: 10px;
          font-size: 15px;
        }
        .address_text{
          color: #666666;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .line{
      width: 100%;
      height: 1px;
      background: #F6F6F6;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .title{
      color: #1b1b1b;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
    }
    .tagScroll{
      margin-top: 6px;
      .ul{
        text-align: left;
        white-space: nowrap;
        overflow-x: scroll;
        .li{
          width: fit-content;
          height: fit-content;
          display: inline-flex;
          place-content: center;
          place-items: center;
          gap: 10px;
          padding: 4px 12px;
          border-radius: 4px;
          background: #f6f6f6;
          color: #666666;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          margin-right: 6px;
        }
      }
      .ul::-webkit-scrollbar{
        display: none;
      }
    }
    .requirements{
      margin-top: 15px;
      color: #666666;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
    }
    .corporation{
      display: flex;
      .leftImg{
        margin-right: 10px;
        .logo{
          width: 52px;
          height: 52px;
        }
      }
      .right{
        flex:1;
        .in{
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .top{
            display: flex;
            justify-content: space-between;
            .text{
              width: 90%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #1b1b1b;
              font-weight: 400;
              font-size: 17px;
              line-height: 24px;
            }
            .iconfont{
              color: #666666;
              font-size: 24px;
            }
          }
          .numbers{
            vertical-align: middle;
          }
          .is_attestation{
            vertical-align: middle;
            margin-left: 10px;
            width: fit-content;
            height: 18px;
            background: #ffffff;
            font-weight: 500;
            border: 1px solid #0393f5;
            display: inline-flex;
            place-content: center;
            place-items: center;
            gap: 10px;
            flex-shrink: 0;
            padding: 2px 6px;
            border-radius: 5px;
            color: #0393f5;
            font-size: 10px;
            line-height: 14px;
          }

        }
      }
    }

  }
  .bottom{
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 0 20px 0;
    background: #fff;
    width: 100%;
    .btn{
      display: flex;
      place-content: center;
      place-items: center;
      gap: 10px;
      flex-shrink: 0;
      padding: 12px 13px;
      border-radius: 12px;
      background: #fc223b;
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      vertical-align: middle;
      margin-left: 16px;
      margin-right: 16px;
      .iconfont{
        margin-right: 0px;
        font-size: 24px;
        vertical-align: middle;
      }
    }

  }
</style>