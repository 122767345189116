<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {Dialog, Toast} from "vant";
import {activeGiftCard} from "@/api/gift_card";
import Clipboard from "clipboard";
import {HTTP_REQUEST_URL} from "@/config/app";

export default {
  name: "my_card_details",
  components: {navigationBar},
  data() {
    return {
      title: '',
      card: {},
      status: 1,

      number:'',
      password:'',
      activateShow:false,
      tipsShow:false,
      radio:1,
      blessList:[],

      shareList: [
        // { name: '微信', icon: 'wechat' },
        // { name: '微博', icon: 'weibo' },
        { name: '复制链接', icon: 'link',id:1 },
        // { name: '分享海报', icon: 'poster' },
        // { name: '二维码', icon: 'qrcode' },
      ],
      showShare: false
    }
  },
  methods: {
    onSelect(option){
      console.log(HTTP_REQUEST_URL)
      if(option.id == 1){
        let clipboard = new Clipboard("#copy", {
          text: function () {
            //返回字符串
            return '22';
          },
        });
        console.log(clipboard)
        clipboard.on("success", () => {
          //复制成功
          Toast('复制成功')
          clipboard.destroy();
        });
        clipboard.on("error", () => {
          //复制失败
          Toast('复制失败')
          clipboard.destroy();
        });
      }
    },
    openShare(){
      this.showShare = true
    },
    activeCard(){
      this.activateShow = true
    },
    chooseRadio(e){
      this.radio = e
    },
    confirm(){
      activeGiftCard(this.card.id,this.number,this.password,2).then(res=>{
        if(res.data.status != 200)Toast.clear()
        Dialog.alert({
          message: res.data.msg,
          confirmButtonText:"我知道了",
          confirmButtonColor:"#FC223B",
        }).then(() => {
          // on close
          this.$router.go(-1)
        });
      })
    },
  },
  mounted() {
    this.card = JSON.parse(this.$route.query.data)
    console.log(this.card)
    if (this.$route.query.type == 'activate') {
      this.title = '激活礼品卡'
      this.status = 1
      this.number = this.card.number
      this.password = this.card.card_pwd
      console.log(this.card.number)
    }
    if (this.$route.query.type == 'share') {
      this.title = '分享礼品卡'
      this.status = 2
      let list = this.card.giftcard.bless.split('\\r\\n')
      list = list.filter(item=>{return item})
      this.blessList = list
    }
  },

}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="title" class="navigation"></navigationBar>
      <div class="head" v-if="card.giftcard">
        <img v-if="card.giftcard" :src="card.giftcard.img" alt="" class="card-bg">
        <div class="title">{{ card.giftcard.title }}</div>
        <div class="price">
          <div class="unit">￥</div>
          <div class="yuan">{{ card.yuan }}</div>
          .
          <div class="mao">{{ card.mao }}</div>
        </div>
      </div>
      <div class="form" v-if="status == 1">
        <div class="line">
          <div class="label">卡号</div>
          <input type="text" class="input" v-model="number">
        </div>
        <div class="line">
          <div class="label">密码</div>
          <input disabled type="password" class="input"  v-model="password">
        </div>
      </div>
      <div class="button"  v-if="status == 1" @click="activeCard">
        确认激活
      </div>
      <van-dialog v-model="activateShow" @confirm="confirm"  show-cancel-button confirmButtonText="确认激活" confirmButtonColor="#FC223B" cancelButtonText="取消激活" cancelButtonColor="#C7C7C7">
        <van-divider
            :style="{ color: '#FD2A39', borderColor: '#FD2A39', padding: '0 50px',fontSize:'16px' }"
        >
          激活礼品卡
        </van-divider>

        <div class="form" v-if="status == 1">
          <div class="line">
            <div class="label">卡号</div>
            <input type="text" class="input" v-model="number">
          </div>
          <div class="line">
            <div class="label">密码</div>
            <input disabled type="password" class="input"  v-model="password">
          </div>
        </div>
        <div class="tips">
          激活成功→我的→我的余额→余额购物
        </div>
      </van-dialog>
      <div class="checkBox" v-if="status == 2">
        <div class="title">送上您的祝福语</div>
        <van-radio-group v-model="radio">
          <van-cell-group v-for="(item,index) in blessList" :key="index">
            <van-cell :title="item" clickable @click="chooseRadio(index)">
              <template #right-icon>
                <van-radio :name="index" />
              </template>
            </van-cell>
          </van-cell-group>
        <div>
          <van-cell-group class="Custom">
            <van-cell :title="'自定义祝福语'" clickable @click="chooseRadio(blessList.length)">
              <template #right-icon>
                <van-radio :name="blessList.length" />
              </template>
            </van-cell>
          </van-cell-group>
          <div class="textareaBox"  v-if="radio == blessList.length">
            <textarea name="" id="" cols="30" rows="10" class="customText" maxlength="50"></textarea>
          </div>
        </div>
        </van-radio-group>
      </div>
      <div class="bottomBtn" v-if="status == 2">
        <div class="btn button" @click="openShare">分享</div>
      </div>

      <van-share-sheet
          v-model="showShare"
          title="立即分享给好友"
          :options="shareList"
          @select="onSelect"
          id="copy"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.Custom .van-cell__title{
  color: rgb(252, 34, 59);
}
.textareaBox{
  height: 100px;
  border-radius: 4px;
  border: 1px solid rgba(199, 199, 199, 1);
  position: relative;

}
.customText{
  padding: 10px;
  height: 90px;
  border: none;
  width: 100%;
  color: rgba(27, 27, 27, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.container {
  background: linear-gradient(180deg, #ffffff 0%, #f4f6fa 100%);
}

.head {
  padding: 0px 0px;
  margin: 0 16px;
  border-radius: 10px;
  background-color: #fff;

  .title {
    padding-left: 16px;
    text-align: left;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    color: #1b1b1b;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  .price {
    padding-left: 16px;
    text-align: left;
    color: #FD2A39;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    align-items: baseline;
    margin-top: 5px;

    .yuan {
      font-size: 20px;
    }
  }

}

.card-bg {
  width: 100%;
  height: 214px;
  border-radius: 10px;
  object-fit: cover;
}

.form {
  margin: 10px 0;
  background-color: #fff;
  .line {
    display: flex;
    margin: 10px 16px;
    align-items: center;
    border-bottom: 1px solid #99999926;
    padding: 10px 0;

    .label {
      color: #666666;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      min-width: 40px;
      text-align: left;
    }

    .input {
      flex: 1;
      width: 0;
      color: #1b1b1b;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border: none;
    }
  }
  .line:nth-last-of-type(1) {
    border-bottom: none;
    padding-top: 0;
  }
}
.button{
  display: flex;
  place-content: center;
  place-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 32px;
  background: #fc223b;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding: 10px;
  margin: 40px 16px;
}
.tips{
  display: inline-flex;
  place-content: center;
  place-items: center;
  gap: 10px;
  flex-shrink: 0;
  padding: 6px 10px;
  border-radius: 5px;
  background: #ffe5e8;
  color: #fd2a39;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  width: 80%;
  margin-bottom: 30px;
}
.checkBox{
  margin: 10px 16px;
  background-color: #fff;
  text-align: left;
  padding: 10px 16px;
  border-radius: 10px;
  .title{
    color: #666666;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}
.bottomBtn{
  position: fixed;
  bottom: 0;
  width: 100%;
  .btn{
    margin: 10px 16px;
  }
}
</style>