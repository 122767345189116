<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";

export default {
  name: "card_list_record",
  components: {navigationBar},
  data() {
    return {
      type: 1,
    }
  },
  methods: {
    tab(type) {
      this.type = type
      // this.init()
      // this.getOrderList()
    },
  },

}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'赠送记录'" class="navigation"></navigationBar>
      <div class="tongji-index">
        <div class="tabbar acea-row">
          <div class="item" :class="{ active: type == 1 }">
            <div class="text-box"></div>
            <div class="item-box"></div>
            <div class="inner acea-row row-center row-middle" @click="tab('1')">待配送</div>
          </div>
          <div class="item" :class="{ active: type == 2 }">
            <div class="text-box"></div>
            <div class="item-box"></div>
            <div class="inner acea-row row-center row-middle" @click="tab('2')">已配送</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  height: 100vh;
  background: rgba(244, 246, 250, 1);
}

.tongji-index {
  padding: 0 10px;

  .tabbar {
    position: relative;
    z-index: 5;
    height: 48px;
    border-radius: 12px 12px 0 0;
    overflow: hidden;

    .item {
      position: relative;
      z-index: 5;
      flex: 1;
      font-size: 16px;
      color: #333333;

      &:last-child {
        .item-box {
          border-radius: 14px 0 0 0;
          transform: perspective(40px) scaleX(1.4) scaleY(1.5) rotateX(20deg) translate(10px, 0) !important;
        }

        .text-box {
          border-radius: 0 12px 0 0;
        }

      }

      &.active {
        z-index: 40;
        font-weight: 500;
        color: #2A7EFB;

        .item-box {
          display: block;
        }
      }
    }

    .text-box {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 39px;
      border-radius: 12px 0 0 0;
      background: rgb(248, 248, 248);
    }

    .item-box {
      display: none;
      position: absolute;
      bottom: 0;
      z-index: 2;
      width: 100%;
      height: 48px;
      border-radius: 0 8px 0 0;
      background: #FFFFFF;
      transform-origin: center bottom;
      transform: perspective(40px) scaleX(1.4) scaleY(1.5) rotateX(20deg) translate(-10px, 0);
    }

    .inner {
      position: absolute;
      bottom: 0;
      z-index: 3;
      width: 100%;
      height: 34px;
    }
  }

}
</style>