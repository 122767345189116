import {customerType, getFiveCus, kefu_list} from "@/utils/api";
import store from "@/store";
import {Toast} from "vant";

let sendFlag = true

// 防抖         等待时间内再次触发则重置时间
export function _debounce(func, wait) {
    let timer;
    return function () {
        let context = this; // 注意 this 指向
        let args = arguments; // arguments中存着e
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(context, args)
        }, wait)
    }
}

// 节流        固定等待时间触发一次接口
export function _throttle(fn, delay) {
    let valid = true
    return function () {
        let context = this; // 注意 this 指向
        let args = arguments; // arguments中存着e
        if (!valid) {
            //休息时间 暂不接客
            return false
        }
        // 工作时间，执行函数并且在间隔期内把状态位设为无效
        valid = false
        setTimeout(() => {
            valid = true;
            // fn(this, args)
            fn.apply(context, args)
        }, delay)
    }
}


export function _getChatRoom(type, innerType) {
    return new Promise((resolve, reject) => {
        // 1辅材 2水站 3超市 4快修 5房产
        getFiveCus(store.state.token, type).then(res => {
            if (res.data.status == true) {
                let list = res.data.data.cus_list
                if (list.length <= 0) {
                    Toast('客服忙线中')
                    reject('客服忙线中')
                    return
                }
                let cus_id = list[0].id
                console.log(cus_id);
                //创建聊天室kefu_list

                kefu_list(store.state.token, cus_id, innerType).then(res1 => {
                    if (res1.data.status == true) {
                        let list1 = res1.data.data
                        let message = list1.find(item => {
                            return item.anotherid == cus_id && store.state.user.id == item.userid
                        })
                        if (message) {
                            store.commit('updataChatDetail', message)
                        } else {
                            reject('找不到客服')
                            console.log('找不到客服')
                            // Toast('找不到客服')
                            return
                        }
                        let mesLi = list1.map(item => {
                            return item.message
                        }).flat()
                        if (mesLi.length > 0) {
                            mesLi.map(item => {
                                if (item.sendtime) {
                                    item.sendtimecc = item.sendtime.replace(/\-/g, '/')// eslint-disable-line
                                }
                                return item
                            })
                        }

                        let obj = {};
                        let peon = mesLi.sort(compareAlign('sendtimecc', 'positive')).reduce((cur, next) => {
                            obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
                            return cur;
                        }, []) //设置cur默认类型为数组，并且初始值为空的数组
                        message.message = peon

                        let name = ''
                        let avatar = store.state.config.find(item => {
                            return item.key == 'tbj_shop_logo'
                        }).value
                        if (type == 1) {
                            name = '辅材客服', message.avatar = avatar
                        }
                        if (type == 2) {
                            name = '水站客服', message.avatar = avatar
                        }
                        if (type == 3) {
                            name = '超市客服', message.avatar = avatar
                        }
                        if (type == 4) {
                            name = '快修客服', message.avatar = avatar
                        }
                        if (type == 5) {
                            name = '房产客服', message.avatar = avatar
                        }
                        message.nickname = name

                        store.commit('updataChatDetail', message)
                        resolve(message)
                    } else {
                        reject(res1.data.msg)
                        Toast(res1.data.msg)
                    }
                })


            } else {
                reject(res.data.msg)
                Toast(res.data.msg)
            }
        })
    })
}

export function compareAlign(prop, align) {
    return function (a, b) {
        var value1 = a[prop];
        var value2 = b[prop];
        if (align == "positive") {//正序
            return new Date(value1) - new Date(value2);
        } else if (align == "inverted") {//倒序
            return new Date(value2) - new Date(value1);
        }
    }
}


function rad(d) {
    return d * Math.PI / 180.0;
}
/**
 * 计算两点之间的距离
 *
 */
export function getDistances(lat1, lng1, lat2, lng2) {
    var radLat1 = rad(lat1);
    var radLat2 = rad(lat2);
    var a = radLat1 - radLat2;
    var b = rad(lng1) - rad(lng2);
    var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
        Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s = s * 6378.137; // EARTH_RADIUS;
    // 输出为公里
    s = Math.round(s * 10000) / 10000;
    var distance = s;
    var distance_str = "";
    if (parseInt(distance) >= 1) {
        // distance_str = distance.toFixed(1) + "km";
        distance_str = distance.toFixed(2) + "km";
    } else {
        // distance_str = distance * 1000 + "m";
        distance_str = (distance * 1000).toFixed(2) + "m";
    }
    //s=s.toFixed(4);
    // console.info('距离是', s);
    // console.info('距离是', distance_str);
    // return s;
    //小小修改，这里返回对象
    let objData = {
        distance: distance,
        distance_str: distance_str
    }
    return objData
}
/**
 * 是否可以JSON
 *
 */
//是否可以JSON
export function isValidJSON(text) {
    try {
        JSON.parse(text);
        return true;
    } catch (error) {
        return false;
    }
}

/**
 * 递归查询
 *
 */
//递归查询
export function getParentIdNew(list, key, iid, children) {
    for (let o of list || []) {
        if (o[key] == iid) return o
        const o_ = getParentIdNew(o[children], key, iid, children)
        if (o_) return o_
    }
}

export function sendApp() {
    // WebSettings webSettings=webView.getSettings();
    // //设置为可调用js方法
   // webSettings.setJavaScriptEnabled(true);
   // webView.setWebViewClient(new WebViewClient());
   // webView.setWebChromeClient(new WebChromeClient());
   // //js调用android
   // webView.addJavascriptInterface(new JsCommunication(), "handleMessage");
   // //你自己的h5地址
   // webView.loadUrl("http://192.168.23.6:8080/#/webview");
            console.log(sendFlag,'12312312312312312412513252362346')
            if(window.ReactNativeWebView){
                if(window.sendFlag){
                    if(sendFlag){
                        window.ReactNativeWebView.postMessage("8");
                        sendFlag = false
                    }
                }
                window.sendFlag = true
            }

}



//减法函数，用来得到精确的减法结果
//说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的减法结果。
//调用：$h.Sub(arg1,arg2)
//返回值：arg1减去arg2的精确结果
export function Sub(arg1, arg2) {
    arg1 = parseFloat(arg1);
    arg2 = parseFloat(arg2);
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = arg2.toString().split(".")[1].length
    } catch (e) {
        r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2));
    //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    return ((Mul(arg1, m) - Mul(arg2, m)) / m).toFixed(n);
}
//乘法函数，用来得到精确的乘法结果
//说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
//调用：$h.Mul(arg1,arg2)
//返回值：arg1乘以arg2的精确结果
export function Mul(arg1, arg2) {
    arg1 = parseFloat(arg1);
    arg2 = parseFloat(arg2);
    var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length
    } catch (e) {
        //
    }
    try {
        m += s2.split(".")[1].length
    } catch (e) {
        //
    }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}



/**
 * 处理客服不同的跳转；
 *
 */
export function getCustomer(userInfo, url, storeInfo, show) {
    let self = this;
    customerType().then(res => {
        let data = res.data.data;
        console.log(data.customer_type)
        if (data.customer_type == 1) {
            // uni.makePhoneCall({
            //     phoneNumber: data.customer_phone
            // });
        } else if (data.customer_type == 2) {
            let href = data.customer_url;
            let hrefO = href + '?uid=' + userInfo.uid + '&nickName=' + userInfo.nickname + '&phone=' +
                userInfo.phone + '&sex=' + userInfo.sex + '&avatar=' + userInfo.avatar +
                '&openid=' + userInfo.openid;
            let hrefT = href + '&uid=' + userInfo.uid + '&nickName=' + userInfo.nickname + '&phone=' +
                userInfo.phone + '&sex=' + userInfo.sex + '&avatar=' + userInfo.avatar +
                '&openid=' + userInfo.openid;
            let urls = encodeURIComponent(href.indexOf('?') === -1 ? hrefO : hrefT);
            console.log(data)
            if (data.customer_url.indexOf('work.weixin.qq.com') > 0) {
                console.log(url, storeInfo, show)
                // #ifdef MP
                // wx.openCustomerServiceChat({
                //     extInfo: {
                //         url: data.customer_url
                //     },
                //     corpId: data.wechat_work_corpid,
                //     showMessageCard: show ? true : false,
                //     sendMessageTitle: show ? storeInfo.store_name : '',
                //     sendMessagePath: show ? storeInfo.path : '',
                //     sendMessageImg: show ? storeInfo.image : '',
                //     success(res) {},
                //     fail(err) {
                //         self.Tips({
                //             title: err.errMsg
                //         });
                //     }
                // })
                // // #endif
                // // #ifdef H5
                // return window.location.href = data.customer_url
                // // #endif

            } else {
                console.log(urls)
                // uni.navigateTo({
                //     url: `/pages/annex/web_view/index?url=${urls}`
                // });
            }
        } else {
            window.router.push({
                path: url || '/chat'
            })
        }
    }).catch(err => {
        self.Tips({
            title: err
        });
    })
}




export function share(urls){
    // 生成当前页面链接并复制 需要后台配合  PC端使用
    //传入参数
    const handleGenerateLink = () => {
        //window.location对象包含当前URL的信息，例如协议（如“http”或“https”）、主机名、端口和
        //路径名。
        //因为使用hash模式 所以获取的是#号前URL
        // let { origin } = window.location
        // let { pathname } = window.location
        // console.log(HTTP_REQUEST_URL)
        // console.log(origin)
        // console.log(pathname)

        // console.log(val, '复制链接')
        // let url = window.location.href //当前或他人 页面链接地址  目前是当前
        // let url = `${origin + pathname}#/article_details/${articleID}`
        //已经获取完整路径
        //复制路径
        let inputNode = document.createElement('input')  // 创建input
        inputNode.value = urls // 赋值给 input 值
        document.body.appendChild(inputNode) // 插入进去
        inputNode.select() // 选择对象
        document.execCommand('Copy') // 原生调用执行浏览器复制命令
        inputNode.className = 'oInput'
        inputNode.style.display = 'none' // 隐藏
        alert('链接已复制')
    }
    return handleGenerateLink()
}