<script>
import {Toast} from "vant";
import {getJobCateList} from "@/api/recruitment";

export default {
  name: "mask_div",
  props:{
    maskType:[String,Number],
    chooseValue:[String,Number,Object],
    educationFromc:[String,Number,Object],
  },
  data(){
    return{
      value:'',
      textareaHeight:'',
      textAreaShowLi:[
          'preponderance',
          'work_experience',
          'project_experience',
          'corporation',
          'frequently',
          'Job_description',
      ],
      educationFrom:{
        school:'',//学校
        educationText:'',//学历
        profession:'',//专业
        time:'',//时间
      },
      educationObj:{
        full_time:'',
        background:'',
      },
      backgroundList: [
        // 第一列
        {
          values: ['初中以下', '初中', '高中', '大专', '本科', '硕士', '博士'],
          defaultIndex: 0,
        },
      ],
      timeList:[
        {values:[]},
        {values:[]},
      ],
      pop_show:false,
      pop_type:'',
    }
  },
  methods:{
    close(){
      this.$emit('closePop', false);
    },
    textareaInput(){
      this.textareaHeight = this.$refs.textareaInput.scrollHeight
    },
    submit(){
      if(this.maskType == 'education_experience'){
        this.$emit('submit', this.educationFrom);
      }else{
        var getFormatCode = function(strValue){
          return strValue.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;');
        }
        if (getFormatCode(this.value) ==="" || getFormatCode(this.value)===null){
          Toast('请输入')
          return
        }
        this.$emit('submit', getFormatCode(this.value));
      }
    },
    openPop(e){
      this.pop_type = e
      this.pop_show = true
    },
    closePop(){
      this.pop_show = false
    },
    save() {
      if(this.pop_type=='educationText'){
        console.log(this.$refs.backgroundList.getValues())
        this.educationFrom.educationText  = this.$refs.backgroundList.getValues()[0].job_name
        this.educationFrom.education_id  = this.$refs.backgroundList.getValues()[0].code
        this.backgroundList[0].defaultIndex = this.$refs.backgroundList.getIndexes()[0]
        // this.educationObj.full_time
      }
      if(this.pop_type=='time') {
        this.educationFrom.time  = this.$refs.timeList.getValues()[0]+'-'+this.$refs.timeList.getValues()[1]
        this.timeList[0].defaultIndex = this.$refs.timeList.getIndexes()[0]
        this.timeList[1].defaultIndex = this.$refs.timeList.getIndexes()[1]
      }
      this.pop_type = ''
      this.pop_show = false
    }
  },
  computed:{
    title(){
      let str = ''
      if (this.maskType == 'preponderance'){str = '个人优势'}
      if (this.maskType == 'work_experience'){str = '工作经历'}
      if (this.maskType == 'project_experience'){str = '项目经历'}
      if (this.maskType == 'corporation'){str = '公司介绍'}
      if (this.maskType == 'frequently'){str = '添加常用语'}
      if (this.maskType == 'Job_description'){str = '岗位描述'}
      return str
    },
    text(){
      let str = ''
      if (this.maskType == 'preponderance'){str = '展示您独特的个人优势，让老板对您的能力刮目相看。'}
      if (this.maskType == 'work_experience'){str = '描述您的工作经历，包括工作背景、所负责的工作内容、如何克服困难以及取得的工作成果。'}
      if (this.maskType == 'project_experience'){str = '描述您的项目经历，包括项目背景、所负责的项目内容、向老板展示您丰富的项目经验'}
      if (this.maskType == 'corporation'){str = '描述您公司的基本信息、企业文化等，帮助求职者了解贵公司'}
      if (this.maskType == 'frequently'){str = ''}
      if (this.maskType == 'Job_description'){str = ''}
      return str
    },
    placeholderStr(){
      let str = ''
      if (this.maskType == 'preponderance'){str = '请介绍个人的优势'}
      if (this.maskType == 'work_experience'){str = '请介绍个人的工作经历'}
      if (this.maskType == 'project_experience'){str = '请介绍个人的项目经历'}
      if (this.maskType == 'corporation'){str = '请介绍公司的整体情况'}
      if (this.maskType == 'frequently'){str = '输入您的常用语，避免填写隐私、广告等信息，否则系统将禁封您的帐号'}
      if (this.maskType == 'Job_description'){str = '1·工作内容\n' +'2·工作待遇\n' +'3·特别说明'}
      return str
    },
    maxlength(){
      let nums = 0
      if (this.maskType == 'preponderance'){nums = 300}
      if (this.maskType == 'work_experience'){nums = 300}
      if (this.maskType == 'project_experience'){nums = 300}
      if (this.maskType == 'corporation'){nums = 300}
      if (this.maskType == 'frequently'){nums = 200}
      if (this.maskType == 'Job_description'){nums = 200}
      return nums
    },
    type(){
      if(this.textAreaShowLi.some(item=>{
        return item == this.maskType
      })){
        return true
      }else{
        return false
      }
    },

    recentYears() {
      let years = [];
      for (let i = 0; i <= 25; i++) {
        years.push(new Date((new Date().getTime() - new Date(31536000000*i))).getFullYear());
      }
      years.reverse()
      return years;
    }
  },
  async mounted() {
    let datae = await getJobCateList()
    console.log(datae.data.data[2])
    this.backgroundList[0].values = datae.data.data[2]
    this.$nextTick(()=>{
      this.value = this.chooseValue.replaceAll('<br/>','\n').replaceAll('&nbsp;', ' ');
      // replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp; ');
    })
    if(this.type){
      this.$nextTick(()=>{
        this.$nextTick(()=>{
          this.textareaHeight = this.$refs.textareaInput.scrollHeight
        })
      })
    }else{
      this.educationFrom = this.educationFromc
    }
    this.timeList[0].values = this.recentYears
    this.timeList[1].values = this.recentYears
    // 31536000000

  }
}
</script>

<template>
<div class="container">
  <div class="area">
    <div class="top">
      <div class="backBtn" @click="close">
        <span class="iconfont icon-Frame-4"></span>
      </div>
      <div class="title">
        <div class="btn" @click="submit">确定</div>
      </div>
    </div>
    <div class="body" v-if="type">
      <div class="title">{{title}}</div>
      <div class="text">{{text}}</div>
      <textarea
          :style="'height:'+textareaHeight+'px;'"
          ref="textareaInput"
          id=""
          class="textarea"
          v-model="value"
          :placeholder="placeholderStr"
          @input="textareaInput"
          :maxlength="maxlength"
      ></textarea>
    </div>
    <div class="from" v-if="!type">
      <div class="fromBox">
        <div class="line">
          <div class="fromLineTitle">学校</div>
          <div class="fromLineValue">
            <input type="text" v-model="educationFrom.school" placeholder="请填写">
          </div>
        </div>

        <div class="line" @click="openPop('educationText')">
          <div class="fromLineTitle">学历</div>
          <div class="fromLineValue">
            <div class="left" :class="educationFrom.educationText?'yesValue':''">{{educationFrom.educationText?educationFrom.educationText:'请选择'}}</div>
            <div class="right"><span class="iconfont icon-Frame-4"></span></div>
          </div>
        </div>

        <div class="line">
          <div class="fromLineTitle">专业</div>
          <div class="fromLineValue">
            <input type="text" v-model="educationFrom.profession" placeholder="请填写">
          </div>
        </div>

        <div class="line" @click="openPop('time')">
          <div class="fromLineTitle">时间</div>
          <div class="fromLineValue">
            <div class="left" :class="educationFrom.time?'yesValue':''">{{educationFrom.time?educationFrom.time:'请选择'}}</div>
            <div class="right"><span class="iconfont icon-Frame-4"></span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer" v-if="type">
      {{value.length}}/{{maxlength}}
    </div>

    <van-popup v-model="pop_show" position="bottom">
      <div class="pop_inner">
        <van-picker
            v-if="pop_type == 'educationText'"
            ref="backgroundList"
            :columns="backgroundList"
            value-key="job_name"
        />
        <van-picker
            v-if="pop_type == 'time'"
            ref="timeList"
            :columns="timeList"
        />

      </div>
      <div class="pop_bottom">
        <div class="close btn" @click="closePop">取消</div>
        <div class="save btn" @click="save">确定</div>
      </div>
    </van-popup>
  </div>
</div>
</template>

<style scoped lang="scss">
.pop_bottom{
  background: #fff;
  padding: 30px;
  display: flex;
  justify-content: space-around;
  .btn{
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 40px;
    border-radius: 10px;
    background: #f0f0f0;
    color: #1b1b1b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .save{
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 40px;
    border-radius: 10px;
    background: #05c160;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

}

.area{
  height: 100vh;
  position: relative;
}
.top{
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 0;
  .backBtn{
    width: 10%;
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    .iconfont{
      font-size: 36px;
    }
  }
  .title{
    width: 85%;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: flex-end;
  }
}
.body{
  margin: 10px 16px;
  .title{
    color: #1b1b1b;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
  }
  .text{
    color: #666666;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
  }
  .textarea{
    width: 100%;
    resize: none;
    border: none;
    margin-top: 20px;
    height: auto;
  }

  .textarea::placeholder{
    color: #c7c7c7;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
}
.footer{
position: absolute;
  bottom: 10px;
  right: 16px;
  color: #c7c7c7;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
}
.fromBox{
  text-align: left;
  .line{
    margin: 0 16px;
    padding: 10px 0;
    border-bottom: 1px solid var(--房产描边F6F6F6, #f6f6f6);
    input{
      color: #1b1b1b;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      border: none;
      margin-top: 10px;
      width: 100%;
    }
    input::placeholder{
      color: #c7c7c7;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
    .fromLineValue{
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      .left{
        color: #c7c7c7;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
      .yesValue{
        color: #1b1b1b;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
      .right{
        .iconfont{
          font-size: 22px;
          color: #666666;
        }
      }
    }
  }
}
</style>