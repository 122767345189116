import request from "@/utils/request";
import store from "../store/index";
const newDevHost = store.state.newDevHost;
/*
* 礼品卡标签类型
* */
export function getCardCateTag(){
    return request.get(`${newDevHost}/api/getCardCateTag`)
}
/*
* 获取礼品卡列表
* */
export function getGiftCardList(page,limit,tag_id){
    return request.get(`${newDevHost}/api/getGiftCardList?page=${page}&limit=${limit}&tag_id=${tag_id}`)
}
/*
* 获取礼品卡列表
* */
export function getGiftCardDetail(id){
    return request.get(`${newDevHost}/api/getGiftCardDetail?id=${id}`)
}
/*
* 创建礼品卡订单
* */
export function createGiftOrder(gift_card_id,total_price,address_id,custom_price){
    let data = {
        gift_card_id,
        total_price,
        address_id,
    }
     if(custom_price){
        data.custom_price = custom_price
    }
    console.log(data)
    return request.post(`${newDevHost}/api/createGiftOrder`, data)
}
/*
* 我的礼品卡列表  type 2我收到的 3我送出的
* */
export function getGiftCardData(page,limit,type){
    return request.get(`${newDevHost}/api/getGiftCardData?page=${page}&limit=${limit}&type=${type}`)
}
/*
* 礼品卡激活 / 领取  rec_type 类型 1领取 2激活
* */
export function activeGiftCard(
    id,
    number,
    card_pwd,
    rec_type,
){
    let data = {
        id,
        number,
        card_pwd,
        rec_type,
    }
    return request.post(`${newDevHost}/api/activeGiftCard`,data)
}

