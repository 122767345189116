<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {defaultAddress} from "@/utils/api";
import {isValidJSON} from "@/utils/tool";
import {Toast} from "vant";
import {createGiftOrder} from "@/api/gift_card";

export default {
  name: "create_gift_card",
  components: {navigationBar},
  data() {
    return {
      details: {},
      checked: false,
      disabled: false,
      address: {},
    }
  },
  mounted() {
    this.details = JSON.parse(this.$route.query.data)
    this.disabled = Number(this.details.price) < Number(this.details.make_start_price)
    this.chooseAddress()
    console.log(this.details)
  },
  methods: {
    chooseAddress() {
      if (sessionStorage.getItem('now_choose_address')) {
        this.address = JSON.parse(sessionStorage.getItem('now_choose_address'))
      } else {
        defaultAddress(isValidJSON(this.$store.state.user) ? JSON.parse(this.$store.state.user).uid : this.$store.state.user.uid).then(res => {
          if (res.data.status == 200) {
            this.address = res.data.data
            sessionStorage.setItem('now_choose_address', JSON.stringify(res.data.data))
          } else {
            Toast(res.data.msg)
          }
        })
      }
    },
    goAddress() {
      this.$router.push({
        path: '/userAddress',
        query: {id: this.address.id, type: 'choose'},
      })
    },
    submit(){
      let data = {
        address_id:'',
        gift_card_id:this.details.id,
        total_price:this.details.price - this.details.subscript_price + (this.details.freight?this.details.freight:0.00),
      }
      console.log(this.disabled)
      if(!this.disabled){
        if(this.address.id){
          data.address_id = this.address.id
        }else{
          Toast('请选择地址')
        }
      }
      // ￥{{ details.type == 1 ? details.price : '自定义价格' }} 立即购买
      if(this.details.type !=1){
        data.custom_price = this.details.price - this.details.subscript_price + (this.details.freight?this.details.freight:0.00)
      }
      console.log(data)
      createGiftOrder(data.gift_card_id,data.total_price,data.address_id,data.custom_price).then(res=>{
        if(res.data.status == 200){
          console.log(res.data.data)
          this.details.result = res.data.data
          console.log(this.details)
          this.$router.push({
            path:"/pay",
            query:{card_data:JSON.stringify(this.details),backStep:3,type:""}
          })
        }else{
          Toast(res.data.msg)
        }
      })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != 'userAddress') {
      sessionStorage.removeItem('now_choose_address')
    }
    next();
  },
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'待付款'" class="navigation"></navigationBar>
      <img :src="require('/public/image/gift_create_back.png')" alt="" class="background">

      <div class="inner">
        <div class="card top_line">
          <div class="left">是否制作实体卡 <span class="text">（一旦售出，概不退换）</span></div>
          <van-checkbox v-model="checked" :disabled="disabled" checked-color="#FD2A39"></van-checkbox>
        </div>

        <div class="card address" @click="goAddress" v-if="checked">
          <div class="left">
            用户地址
          </div>
          <div class="rightBox">
            <div class="right" :class="address.id?'':'placeholder'">
              <span class="text" v-if="!address.id">请选择收货地址</span>
              <div class="addressIn" v-if="address.id">
                <div class="head_add">{{ address.province }}{{ address.city }}{{ address.district }} {{
                    address.street
                  }} {{ address.address_location_name }} {{ address.unit_name }} {{ address.layer_name }}
                </div>
                <div class="name">{{ address.real_name }}{{ address.phone }}</div>
              </div>
            </div>
            <span class="iconfont icon-Frame-4"></span>
          </div>
        </div>

        <div class="card goods_details">
          <div class="head">
            <div class="leftImg">
              <img :src="details.img" alt="" class="img">
            </div>
            <div class="rightInfo">
              <div class="title">{{details.title}}</div>

              <div class="text">规格：{{details.unit}}</div>
            </div>
          </div>
          <div class="price">
            <div class="unit">￥</div>
            <div class="yuan">{{details.yuan}}</div>.
            <div class="mao">{{details.mao}}</div>
          </div>
        </div>

        <div class="card priceDetails">
          <div class="line">
            <div class="label">商品总价</div>
            <div class="value">￥{{ details.price }}</div>
          </div>
          <div class="line">
            <div class="label">折扣</div>
            <div class="value">￥{{ details.subscript_price }}</div>
          </div>
          <div class="line">
            <div class="label">订单运费</div>
            <div class="value">￥{{ details.freight?details.freight:0.00 }}</div>
          </div>
          <div class="line">
            <div class="label">实付款</div>
            <div class="value red">￥{{ details.price - details.subscript_price + (details.freight?details.freight:0.00) }}</div>
          </div>
        </div>
        <div class="bottomBox">
          <div class="left">
            ￥{{details.price - details.subscript_price + (details.freight?details.freight:0.00)}}
          </div>
          <div class="right" @click="submit">去付款</div>
        </div>

      </div>


    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  position: relative;
  background: #f4f6fa;
}

.area {
  height: 100vh;
}

.navigation {
  position: absolute;
  background-color: transparent !important;
  top: 0;
}

.background {
  width: 100%;
}

.inner {
  padding: 0px 16px 10px 16px;
  margin-top: -40px;

  .card {
    border-radius: 10px;
    background: #ffffff;
    padding: 13px 10px;
    margin: 10px 0;
  }

  .top_line {
    display: flex;
    justify-content: space-between;

    .left {
      color: #1b1b1b;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;

      .text {
        color: #999999;
        font-size: 12px;
      }

    }
  }


  .address {
    display: flex;
    justify-content: space-between;

    .left {
      text-align: left;
      min-width: 80px;
      color: var(--666666, #666666);
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    .rightBox {
      display: flex;
      align-items: flex-start;
      text-align: left;

      .iconfont {
        font-size: 24px;
      }

      .placeholder {
        color: #c7c7c7;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }

      .iconfont {
        color: #787878;
      }

      .addressIn {
        color: #1b1b1b;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        .head_add {
        }

        .name {
          font-weight: 400;
          margin-top: 5px;
        }
      }
    }
  }
}
.goods_details{
  .head{
    display: flex;
    .leftImg{
      .img{
        width: 50px;
        height: 50px;
        border-radius: 6px;
      }
    }
    .rightInfo{
      padding-left: 10px;
      width: 0;
      flex: 1;
      text-align: left;
      .title{
        color: #1b1b1b;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        display: -webkit-box;
        /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
        -webkit-box-orient: vertical;
        /*要显示的行数*/
        -webkit-line-clamp: 2;
        /* 溢出部分隐藏 */
        overflow: hidden;
      }
      .text{
        color: #7f7f7f;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .price{
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    color: #fd2a39;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    .yuan{
      font-size: 20px;
    }
  }
}

.priceDetails{
  padding: 3px 10px !important;
  .line{
    display: flex;
    margin: 10px 0;
    .label{
      min-width: 80px;
      text-align: left;
      color: #1b1b1b;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
    .value{
      color: #1b1b1b;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
.red{
  color: #fd2a39!important;
}

.bottomBox{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px -0.5px 2px #99999926;
  width: 100%;
  left: 0;
  padding: 10px 0;
  .left{
    color: #fd2a39;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-left: 16px;
  }
  .right{
    border-radius: 36px;
    background: #fc223b;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 10px 31px;
    margin-right: 16px;
  }
}
</style>