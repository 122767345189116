<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import Bus from "@/utils/Bus";

export default {
  name: "chat_details",
  components: {NavigationBar},
  computed: {
    chatObj() {
      return this.$store.state.chatDetails
    },
    previewImg() {
      return []
    },
  },
  watch: {
    messageList: {
      handler(newVal) {
        console.log(newVal)
        this.$nextTick(() => {
          this.$refs.scrollView.scrollTop = this.$refs.scrollView.scrollHeight
        })
      },
      deep: true,
    },
    emojiFlag(n) {
      if(n==true){
        this.$refs.messageInput.$el.children[0].focus()
      }
      this.$nextTick(() => {
        this.$refs.scrollView.scrollTop = this.$refs.scrollView.scrollHeight
      })
    },
    moreFlag() {
      this.$nextTick(() => {
        this.$refs.scrollView.scrollTop = this.$refs.scrollView.scrollHeight
      })
    },
  },
  data() {
    return {
      title: '聊天室',
      message: '',
      messageList: [],
      blurIndex: null,
      cursorIndex: null,
      maxlength: 200,
      house_type: this.$store.state.chatDetails.type,
      emojiFlag: false,
      moreFlag: false,
      connectemoji: ['😊', '😅', '😲', '😭', '😂', '😄', '😩', '😞', '😵', '😒', '😍',
        '😤', '😜', '😝', '😋', '😘', '😚', '😷', '😳', '😃', '😆', '😁', '😢', '😨',
        '😠', '😣', '😌', '😖', '😔', '😰', '😱', '😪', '😏', '😓'
      ],
      moreList: [
        {icon: 'icon-xiangce', text: '相册'},
        {icon: 'icon-paishexuanzhong', text: '拍摄'},
        {icon: 'icon-weizhi2', text: '位置'},
      ],
    }
  },
  methods: {
    getNowTime() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      hour = hour < 10 ? "0" + hour : hour;
      minute = minute < 10 ? "0" + minute : minute;
      return year + "-" + month + "-" + day + " " + hour + ":" + minute;
    },
    focus(e) {
      this.blurIndex = this.$refs.messageInput.$el.children[0].selectionStart;
      console.log(e.target)
      e.target.setSelectionRange(this.cursorIndex, this.cursorIndex);
    },
    blur() {
      this.cursorIndex = this.$refs.messageInput.$el.children[0].selectionStart
    },
    // enter
    keyDown(e) {
      if (e.ctrlKey && e.keyCode == 13) {
        //用户点击了ctrl+enter触发
        if (this.message.length <= this.maxlength) {
          this.message += "\n";
        }
        this.$nextTick(() => {
          setTimeout(() => {
            const textarea = document.getElementById("kefu_message");
            textarea.scrollTop = textarea.scrollHeight;
          }, 13);
        });
      } else {
        if (!this.message.trim()) {
          this.message = "";
          // console.log(this.content)
        } else {
          // 发送消息
          let list_id = -1;
          if (this.messageList.length > 0) {
            list_id = this.messageList[this.messageList.length - 1].id;
          } else {
            list_id = -1
          }
          let msg = {
            type: "u_fasong",
            types: 1,
            message: this.message,
            user_id: this.$store.state.user.id, //我的id
            chat_id: this.chatObj.chatid,
            list_id: list_id,
            uid: this.chatObj.anotherid,
            house_type: this.house_type,
            user_type: 1,
            is_nill: 1,
            is_kf: this.chatObj.cus_type && this.chatObj.cus_type != 111 ? 1 : 2,   //1 对面是客服  2对面不是客服
            timestamp: Math.floor(new Date().getTime() / 1000),
          }
          console.log(msg,)
          this.messageList.push({
            chat_id: this.chatObj.chatid,
            list_id: list_id + 1,
            isdel: null,
            isdels: null,
            message: this.message,
            sendtime: this.getNowTime(),
            timestamp: Math.floor(new Date().getTime() / 1000),
            type: 1,
            userid: this.$store.state.user.id, //我的id
            house_type: 1,
            user_type: 1,
          })
          this.$store.state.websocket.send(JSON.stringify(msg));
          this.message = ''
        }
        if (e != undefined) {
          e.preventDefault(); // 阻止浏览器默认的敲击回车换行的方法
        }
      }
    },

    sendMessage() {
      if (!this.message.trim()) {
        this.message = "";
        // console.log(this.content)
      } else {
        // 发送消息
        let list_id = -1;
        if (this.messageList.length > 0) {
          list_id = this.messageList[this.messageList.length - 1].id;
        } else {
          list_id = -1
        }
        let msg = {
          type: "u_fasong",
          types: 1,
          message: this.message,
          user_id: this.$store.state.user.id, //我的id
          chat_id: this.chatObj.chatid,
          list_id: list_id,
          uid: this.chatObj.anotherid,
          house_type: this.house_type,
          user_type: 1,
          is_nill: 1,
          is_kf: this.chatObj.cus_type && this.chatObj.cus_type != 111 ? 1 : 2,   //1 对面是客服  2对面不是客服
          timestamp: Math.floor(new Date().getTime() / 1000),
        }
        console.log(msg,)
        this.messageList.push({
          chat_id: this.chatObj.chatid,
          list_id: list_id + 1,
          isdel: null,
          isdels: null,
          message: this.message,
          sendtime: this.getNowTime(),
          timestamp: Math.floor(new Date().getTime() / 1000),
          type: 1,
          userid: this.$store.state.user.id, //我的id
          house_type: 1,
          user_type: 1,
        })
        this.$store.state.websocket.send(JSON.stringify(msg));
        this.message = ''
      }
    },
    emojiBtn() {
      console.log('表情')
      this.emojiFlag = !this.emojiFlag
      this.moreFlag = false
    },
    moreBtn() {
      console.log('gd')
      this.moreFlag = !this.moreFlag
      this.emojiFlag = false
    },
    messageImageLoad() {
      this.$refs.scrollView.scrollTop = this.$refs.scrollView.scrollHeight
    },

    websocket() {
      console.log(this.$store.state.chatDetails)


      let msg={type:'u_yidu',
        user_id:this.$store.state.user.id,
        chat_id:this.chatObj.chatid}
      this.$store.state.websocket.send(JSON.stringify(msg));

      Bus.$on("websocket", (msg) => {
        // A发送来的消息
        let message = msg.content.data
        console.log(message)
        if (message) {
          if (message != 'pong' && message != 'Hello' && message != '200' && message.split(' ')[1] != '退出了') {
            // 接受消息
            if (JSON.parse(message).msg != '已读') {
              let obj = JSON.parse(message)
              if (JSON.parse(message).userid == this.$store.state.user.id && JSON.parse(message).user_type == 1) {
                // 自己发的消息
                if (obj.type == this.messageList[this.messageList.length - 1].type) {
                  if (obj.type < 5) {
                    // 文字 图片 视频 标签
                    this.messageList[this.messageList.length - 1] = obj
                    if (obj.type == 5) {
                      this.messageList[this.messageList.length - 1].message = JSON.parse(this.messageList[this.messageList.length - 1].message)
                    }
                  }
                }

              } else {
                // 其他人发来的消息
                if (obj.userid == this.chatObj.anotherid) {
                  if (!obj.sendtime) {
                    // 对面
                  } else {
                    this.messageList.push({
                      chatid: obj.chatid,
                      id: obj.id,
                      timestamp: obj.timestamp,
                      isdel: null,
                      isdels: null,
                      userid: obj.userid,
                      issendtime: obj.issendtime,
                      message: obj.message,
                      sendtime: obj.sendtime,
                      type: obj.type,
                      user_type: obj.user_type,
                    })

                    let msg = {
                      type: 'u_yidu',
                      user_id: this.$store.state.user.id,
                      chat_id: this.chatObj.chat_id,
                      is_kf: this.chatObj.cus_type && this.chatObj.cus_type != 111 ? 1 : 2,
                    }
                    if (this.chatObj.is_house) {
                      msg.is_house = 1
                    }
                    this.$store.state.websocket.send(JSON.stringify(msg));
                  }
                }


              }


            }


          }
        }
      });
    },
    emojiEvery(item) {
      // this.$refs.messageInput.$el.children[0].target.setSelectionRange(this.cursorIndex, this.cursorIndex);
      if (this.cursorIndex > 0) {
        let msag = this.message.slice(0, this.cursorIndex) + item + this.message.slice(this.cursorIndex)
        this.message = msag.toString()
      } else {
        this.message = this.message.slice(0, this.cursorIndex) + item + this.message.slice(this.cursorIndex)
      }
      this.$refs.messageInput.$el.children[0].focus()
    },
  },
  mounted() {
    console.log(this.chatObj,'chatDetails')
    console.log(this.$store.state)
    if (this.chatObj) {
      this.messageList = this.chatObj.message
    } else {
      // _getChatRoom()
    }


    // Bus.$on('')

    this.websocket()
  },
  beforeDestroy() {
    Bus.$off('websocket')
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigation-bar :title="chatObj.nickname"></navigation-bar>
      <div class="scrollView" ref="scrollView">
        <!--一条聊天记录-->
        <div class="chat-item" v-for="(item,index) in messageList" :key="index">
          <!-- 时间-->
          <div class="sendTime" v-if="item.issendtime == 1">
            {{ item.sendtime }}
          </div>
          <!--对方的消息-->
          <div class="content-wrapper-left"
               v-if="item.user_type==2||(item.user_type==1&&item.userid!=$store.state.user.id)">
            <!--            头像-->
            <div class="avatar avatar-left">
              <img :src="$store.state.imageHost+chatObj.avatar" alt="" class="avatarImg">
            </div>
            <!--            文字-->
            <div class="chat-content-left" v-if="item.type == 1">
              {{ item.message }}
            </div>
            <!--            图片-->
            <div class="chat-image-right" v-if="item.type==2">
              <el-image
                  v-if="$store.state.imageHost"
                  class='sendImg'
                  :src="$store.state.imageHost+item.message"
                  @load="messageImageLoad"
                  :preview-src-list="[previewImg]"
              >
              </el-image>
            </div>
            <!--            视频-->
            <div class="chat-video-right" v-if="item.type == 4">
              <video src="https://picture.bjbjkj.top/gqBmEr0LtlmQ85cb60a14f3e66ba8f4edef469b929a5.mp4"
                     :controls="false"></video>
            </div>
            <!--            位置-->
            <div class="chat-map-right" v-if="item.type == 5"></div>
          </div>
          <!--自己的消息-->
          <div class="content-wrapper-right" v-if="item.userid == $store.state.user.id&&item.user_type==1">
            <!--            文字-->
            <div class="chat-content-right" v-if="item.type == 1">{{ item.message }}</div>
            <!--            图片-->
            <div class="chat-image-right" v-if="item.type==2">
              <el-image
                  v-if="$store.state.imageHost"
                  class='sendImg'
                  :src="$store.state.imageHost+item.message"
                  :preview-src-list="[previewImg]"
                  @load="messageImageLoad"
              >
              </el-image>
            </div>
            <!--            视频-->
            <div class="chat-video-right" v-if="item.type == 4">
              <video src="https://picture.bjbjkj.top/gqBmEr0LtlmQ85cb60a14f3e66ba8f4edef469b929a5.mp4"
                     :controls="false"></video>
            </div>
            <!--            位置-->
            <div class="chat-map-right" v-if="item.type == 5"></div>
            <!--            头像-->
            <div class="avatar avatar-right">
              <img :src="$store.state.imageHost+$store.state.user.avatar" alt="" class="avatarImg">
            </div>
          </div>
        </div>
      </div>
      <div class="bottomSendBox">

        <div class="inSend">
          <div class="leftInput">
            <!--            <div class="textarea" contenteditable="true">{{message}}</div>-->
            <el-input
                ref="messageInput"
                type="textarea"
                autosize
                placeholder="请输入~"
                v-model="message"
                class="textarea"
                :maxlength="maxlength"
                :max="maxlength"
                @focus.stop.prevent="focus($event)"
                @blur.stop.prevent="blur($event)"
                id="kefu_message"
                @keydown.enter.native="keyDown"></el-input>
          </div>
          <div class="rightFun">
            <div class="emojiBox" @click="emojiBtn">
              <van-icon name="smile-o" class="emojiBtn" size='2em'
                        color="#F96422"/>
            </div>
            <div class="addBox">
              <van-icon name="add-o" v-if="message.length==0" size='2em'
                        data-str="more" color="#F96422" @click="moreBtn"/>
              <span class="iconfont icon-fasong" size='2em' v-else @click="sendMessage"></span>
            </div>
          </div>

        </div>

        <div class="emojiInner" v-if="emojiFlag">
          <div class="item" v-for="(item,index) in connectemoji" :key="index" @click="emojiEvery(item)">{{ item }}</div>
        </div>
        <div class="morteIner" v-if="moreFlag">
          <div class="moreBoxEvery" v-for="(item,index) in moreList" :key="index" @click="funBtn(index)">
            <div class="icon">
              <span class="iconfont" :class="item.icon"></span>
            </div>
            <span class="text">{{ item.text }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
.container {
  overflow: hidden;
  max-height: 100vh;

}

.area {
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.scrollView {
  background-color: #fafafa;
  overflow: scroll;
  flex: 1;

  .chat-item {
    padding: 10px;
    position: relative;
    min-height: 50px;

    .content-wrapper-left {
      float: left;
      justify-content: flex-start;
      display: flex;
      width: 100%;

      .chat-content-left {
        max-width: 245px;
        margin-right: 10px;
        background-color: #fff;
        padding: 9px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        word-break: break-all;
        position: relative;
        text-align: left;
      }
    }

    .content-wrapper-right {
      float: right;
      justify-content: flex-end;
      display: flex;
      width: 100%;

      .chat-content-right {
        max-width: 245px;
        margin-right: 10px;
        background-color: #fff;
        padding: 9px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        word-break: break-all;
        position: relative;
        text-align: left;
      }
    }

    .chat-image-right {
      max-width: 245px;
      margin-right: 10px;
      /* background-color: #ea8534; */
      padding: 0px;
      border-radius: 5px;
      //height: 240px;
      //width: 100%;
    }

    .avatar {
      width: 44px;
      height: 44px;
    }

    .avatarImg {
      width: 44px;
      height: 44px;
      border-radius: 5px;
      object-fit: cover;
    }
  }

  .chat-item:after {
    content: '';
    display: block;
    clear: both;
  }
}

.bottomSendBox {
  //position: fixed;
  //bottom: 0;
  //left: 0;
  width: 100%;
  padding-bottom: 22px;
  background-color: #fff;
  padding-top: 10px;

  .emojiInner {
    padding-top: 5px;
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 12.5%;
      font-size: 30px;
      text-align: center;
      padding: 6px 0;
    }
  }

  .morteIner {
    display: flex;
    padding-top: 5px;

    .moreBoxEvery {
      width: 25%;
      text-align: center;

      .icon {
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin: 5px auto;
        background-color: #fff;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center
      }
      .iconfont{
        font-size: 25px;
      }
    }
  }
}

.inSend {
  display: flex;
  align-items: center;
  margin: 0 16px;
  justify-content: space-between;

  .leftInput {
    flex: 1;
  }

  .textarea {
    background-color: #fafafa;
    //max-height: 150px;
    //position: relative;
    //padding: 10px;
    border: none;
    //border-radius: 10px;
    //min-height: 15px;
    //line-height: normal;
    //outline: 0;
    //border: 1px solid #ccc;
    //font-size: 15px;
    //word-wrap: break-word;
    //-webkit-user-modify: read-write-plaintext-only;
    text-align: left;
  }

  .rightFun {
    display: flex;
    align-items: center;

    .addBox {
      display: flex;
      align-items: center;
    }

    .iconfont {
      color: #F96422;
      font-size: 2em;
    }
  }

}

</style>
<style>
.el-textarea__inner {
  background: #FAFAFA !important;
  border: none;
  max-height: 150px;
  border: none !important;
}

.el-textarea__inner::-webkit-input-placeholder {
  //color: #FC223B;
}
</style>