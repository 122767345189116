<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {Toast} from "vant";
import {_throttle} from "@/utils/tool";
import {Collection_type2,del_Collection} from "@/api/recruitment"
export default {
  name: "invite_collect",
  components: {navigationBar},
  data(){
    return{
      manage:false,
      result:[],
      list:[
        // {
        //   name:'hahaha1',
        //   id:1,
        // },
        // {
        //   name:'hahaha2',
        //   id:2,
        // },
      ],
      checkedAll:false,

      hasMore: true,//是否可以继续滚动
      loadLock: true,//是否展示loding
      page:0,
      limit:10,
    }
  },
  methods:{
    checkboxClick(){
      if(!this.manage){
        this.$refs.checkboxGroup.toggleAll()
      }
    },
    manageFun(){
      this.manage = !this.manage
    },
    beforeclose(){

    },
    closeCollect(){
      if(this.result.length<=0){
        Toast('请选择')
        return
      }
      del_Collection(String(this.result),2).then(res=>{
        console.log(res.data.status)
        if(res.data.status == 200){
          this.manage = false
          this.list = []
          this.getList()
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    allclick(){
      console.log(this.checkedAll)
      if(this.checkedAll){
        this.result = this.list.map(item=>{return item.collection_id})
      }else{
        this.result = []
      }
    },
    godetails(item){
      if(!this.manage){
        let obj = JSON.parse(JSON.stringify(item))

        this.$router.push({
          path:'/invite_details',
          query:{item:JSON.stringify(obj)}
        })
      }else{
        //

      }

    },


    getList(){
      Collection_type2(this.page,this.limit).then(res=>{
        if(res.data.status == 200){
          let list = res.data.data.data
          this.list = this.list.concat(list)
          list.forEach(item=>{
            if(item.job){
              if(item.job.benefits){
                item.job.benefitsLi = item.job.benefits.split(',')
              }
            }
          })
          if (this.list.length >= res.data.data.count) {
            this.hasMore = false
          }
        }else{
          Toast(res.data.msg)
        }
        this.loadLock = false
      }).catch(err=>{
        console.log(err)
      })
    },
    containerScroll(event){
      // const dialog = event.target
      // if(dialog.scrollHeight - dialog.scrollTop === dialog.clientHeight){
      //     console.log('触底');
      // }
      //   console.log(event);
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      //   console.log(scrollStep, this.oldScrollTop);
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const { scrollHeight, clientHeight, scrollTop } = event.target;
        // console.log(scrollHeight - (scrollTop + clientHeight));
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          // console.log((scrollHeight - (scrollTop + clientHeight)) / clientHeight);
          if (this.hasMore) {
            this.loadLock = true
            this.qingqiu(this);
          }
        }
      }
    },

    qingqiu: _throttle(async function () {
      this.page+=1
      this.getList()
    },500),
  },
  watch:{
    manage(n){
      if(n){
        this.$refs.swipeCell.forEach((item)=>{
          item.open('left')
        })
      }else{
        this.$refs.swipeCell.forEach((item)=>{
          item.close('left')
        })
      }
    },
    result:{
      handler(n){
        if(n.length >= this.list.map(item=>{return item.id}).length){
          this.checkedAll = true
        }else{
          this.checkedAll = false
        }
      },
      deep:true
    }
  },
  mounted() {
    this.qingqiu()
  }
}
</script>

<template>
  <div class="container invite_collect" @scroll="containerScroll">
    <div class="area">
      <navigationBar :title="''" class="navigationBar">
        <template #search>
          <div class="cont">
            <div class="centerText">我的收藏</div>
            <div class="rightText" @click="manageFun">{{ manage?'完成':'管理' }}</div>
          </div>
        </template>
      </navigationBar>
      <div class="ul">
        <div class="li" v-for="(item,index) in list" :key="index">
          <van-checkbox-group v-model="result" ref="checkboxGroup"  v-if="item">
              <van-swipe-cell class="van-swipe-cell" ref="swipeCell"  :before-close="beforeclose" disabled  :stop-propagation="true">
                <template #left>
                  <van-checkbox :name="item.collection_id" @click="checkboxClick" checked-color="#FC223B">
                   </van-checkbox>
                </template>
                <div class="card" @click="godetails(item)">
                  <div class="title">
                    <div class="leftText">{{ item.position }}</div>
                    <div class="rightSalary">{{ item.salary_min }}K-{{ item.salary_max }}K</div>
                  </div>
                  <div class="name">
                    <div class="nameText">{{ item.company.name }}</div>
                    <div class="is_attestation">已认证</div>
                  </div>
                  <div class="address">
                    <span class="iconfont icon-dingwei"></span>
                    <span class="address_text">{{ item.province_name }} {{ item.district_name }}</span>
                  </div>
                  <div class="tagScroll">
                    <div class="ul">
                      <div class="li" v-for="(item2,index2) in item.benefitsLi" :key="index2">
                        {{item2}}
                      </div>
                    </div>
                  </div>
                </div>
              </van-swipe-cell>
          </van-checkbox-group>
        </div>
        <div class="loader-box">
          <div id="loader" v-if="loadLock"></div>
          <div id="nothing" v-if="!loadLock&&!hasMore">没有更多了~</div>
        </div>
      </div>
      <div class="bootomBox" v-if="manage">
        <div class="inne">
          <div class="left">
            <van-checkbox  v-model="checkedAll" checked-color="#ee0a24" @click="allclick">全选</van-checkbox>
          </div>
          <div class="rightBtn" @click="closeCollect">
            取消收藏
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
.navigationBar{
  background: #fff;
  .cont{
    flex: 1;
    padding-right: 39px;
    color: #1b1b1b;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    position: relative;
    .rightText{
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translate(-0%,-50%);
    }
  }
}
.container{
  background: #f4f6fa;
  height: 100vh;
  .area{
    height: 100vh;
  }
}
.ul{
  .li{
    //background: red;
    .card{
      border-radius: 10px;
      background: #ffffff;
      margin: 10px;
      padding: 10px;

      .title{
        display: flex;
        justify-content: space-between;
        .leftText{
          max-width: 60%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #1b1b1b;
        }
        .rightSalary{
          color: #fc223b;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          width: 40%;
          text-align: right;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .name{
        margin-top: 10px;
        display: flex;
        align-items: center;
          .nameText{
            color: #666666;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            margin-right: 10px;
            max-width: 80%;
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .is_attestation{
            width: fit-content;
            height: 18px;
            background: #ffffff;
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
            border: 1px solid #0393f5;
            display: inline-flex;
            place-content: center;
            place-items: center;
            gap: 10px;
            flex-shrink: 0;
            padding: 2px 6px;
            border-radius: 5px;
            color: #0393f5;
          }
      }
      .address{
        margin-top: 6px;
        text-align: left;
        .iconfont{
          font-size: 18px;
          color: #4891ee;
          vertical-align: middle;
          margin-right: 5px;
        }
        .address_text{
          color: #666666;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
        }
      }
      .tagScroll{
        margin-top: 6px;
        .ul{
          text-align: left;
          white-space: nowrap;
          overflow-x: scroll;
          .li{
            width: fit-content;
            height: fit-content;
            display: inline-flex;
            place-content: center;
            place-items: center;
            gap: 10px;
            padding: 4px 12px;
            border-radius: 4px;
            background: #f6f6f6;
            color: #666666;
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            margin-right: 6px;
          }
        }
        .ul::-webkit-scrollbar{
          display: none;
        }
      }
    }
  }
}

.van-cell{
  background: #f4f6fa;
}
.bootomBox{
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  .inne{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .rightBtn{
      width: fit-content;
      height: fit-content;
      display: inline-flex;
      place-content: center;
      place-items: center;
      gap: 10px;
      padding: 11px 20px;
      border-radius: 34px;
      background: #fc223b;
      color: #ffffff;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
</style>
<style>
.invite_collect .van-swipe-cell__left{
  display: flex;
  align-items: center;
  padding-left: 10px;
}
</style>