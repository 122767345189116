<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import Swiper from "swiper";
import {
  addCar, cart_add, computedOrder, createOrder, delCartList,
  get_attr,
  getProductDetails, orderConfirm,
  set_cart_num
} from "@/utils/api";
import {Dialog, Toast} from "vant";
import SukPop from "@/components/common/sukPop.vue";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import {_throttle, getParentIdNew} from "@/utils/tool";
import {seckill_detail} from "@/api/flash_sale";
export default {
  name: "flash_details",
  components: {SukPop, navigationBar, ElImageViewer},
  data() {
    return {
      swiper: {},
      swiperLi: [],
      obj: {
        storeInfo: {}
      },
      xizhi: [
        '划线价格：商品展示的划横线的价格是参考价格，并非原价，该价格可能是商品的专柜标价、商品吊牌价或由品牌供应商提供的正品零售价（如厂商指导价、建议零售价等）或该商品曾经在百街展示过的销售价格等，该价格仅供您参考。',
        '未划线价格：未划线的价格是商品在百街的实时销售标价，是您最终决定是否购买商品的依据。最终成交价格以订单结算页价格为准。',
        '到手价以实际的实时销售标价减去各类优惠，或使用积分享受抵现活动为准。',
        '每日的配送时间为08：00~20：00，夏冬时间略有差异，具体留意下单页提示',
        '商品详情图品仅供参考，具体以实物为准。',
      ],
      cartLi: [],
      carshow: false,
      allPirce: {
        yuan: 0,
        mao: 0,
      },
      addCarpop: false,
      num: 0,

      addCAR: 0,
      carNums: 0,

      ViewerShow: false,
      ViewList: [],
      detailsHtml: '',

      sku:{
        // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
        // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
        tree: [
          {
            k: "颜色",
            k_id: "1",
            v: [
              {
                id: "30349",
                name: "天蓝色",
                imgUrl:"https://img.yzcdn.cn/upload_files/2017/02/21/FjKTOxjVgnUuPmHJRdunvYky9OHP.jpg!100x100.jpg",
              },
              {
                id: "1215",
                name: "白色",
                imgUrl:"https://img.yzcdn.cn/upload_files/2017/02/21/FjKTOxjVgnUuPmHJRdunvYky9OHP.jpg!100x100.jpg",
              },
            ],
            k_s: "s1",
            count: 2,
          },
          {
            k: "尺寸",
            k_id: "2",
            v: [
              {
                id: "1193",
                name: "1",
              },
              {
                id: "1194",
                name: "2",
              },
            ],
            k_s: "s2",
            count: 2,
          },
        ],
        // 所有 sku 的组合列表，如下是：白色1、白色2、天蓝色1、天蓝色2
        list: [
          {
            id: 2259,
            price: 12000, //价格（单位分）
            s1: "1215",
            s2: "1193",
            stock_num: 20, //库存
            goods_id: 946755,
            a: 0,
          },
          {
            id: 2260,
            price: 11000,
            s1: "1215",
            s2: "1194",
            stock_num: 2, //库存
            goods_id: 946755,
          },
          {
            id: 2257,
            price: 13000,
            s1: "30349",
            s2: "1193",
            stock_num: 40, //库存
            goods_id: 946755,
          },
          {
            id: 2258,
            price: 10000,
            s1: "30349",
            s2: "1194",
            stock_num: 50, //库存
            goods_id: 946755,
          },
        ],
        price: "59.00-129.00", // 默认价格（单位元）
        stock_num: 999, // 商品总库存
        // a: 0,
        // collection_id: 999, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
        none_sku: false, // 是否无规格商品
        hide_stock: false, // 是否隐藏剩余库存
        quota:0,//限购数，0 表示不限购
      },
      initialSku: {
      },
      chaoshi:false,

      type: '', //活动类型   1秒杀活动  3拼团  4积分 6预售 7新人专享

    }
  },
  computed: {

  },
  methods: {
    initSwiper() {
      this.swiper = new Swiper(".swiper", {
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        loop: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
      });
    },
    getDetails(id) {
      let funApi = ''
      if(this.type == 1) {
        funApi = seckill_detail(id)
      }else{
        funApi = getProductDetails(id)
      }
      funApi.then(async res => {
        if (res.data.status == 200) {
          this.obj = res.data.data
          this.obj.storeInfo.picture = this.obj.storeInfo.image
          this.sku.price = this.obj.storeInfo.is_vip?(this.$store.state.is_vip.is_vip?this.obj.storeInfo.vip_price:this.obj.storeInfo.price):this.obj.storeInfo.price
          this.sku.stock_num = this.obj.storeInfo.stock
          this.sku.quota = this.obj.storeInfo.limit_num
          console.log(this.obj)
          const {data} = await get_attr(this.obj.storeInfo.product_id, 0)
          if (this.obj.productValue.length >= 1) {
            if (data.status == 200) {
              for (const res1Key in this.obj.productValue) {
                this.obj.storeInfo.specifications = this.obj.productValue[res1Key].suk
              }
            }
          } else {
            for (const res1Key in this.obj.productValue) {
              this.obj.storeInfo.specifications = this.obj.productValue[res1Key].suk
            }
          }
          this.getHtmlPic(this.obj.storeInfo.description)
          console.log(this.detailsHtml)
          this.swiperLi = res.data.data.storeInfo.slider_image||res.data.data.storeInfo.images
          if (!this.obj.storeInfo.yuan) {
              this.obj.storeInfo.yuan = this.obj.storeInfo.price.split('.')[0]
              this.obj.storeInfo.mao = this.obj.storeInfo.price.split('.')[1]
          }
          this.$nextTick(() => {
            this.initSwiper()
          })

            this.sku.collection_id = this.obj.storeInfo.product_id
          console.log(this.obj)
            let tree = JSON.parse(JSON.stringify(this.obj.productAttr))
            let list2 = Object.values(this.obj.productValue)
            let initialSku = {}
            tree.forEach((item) => {
              item.v=[];
              item.attr_value.forEach((item2,index2)=>{
                item.v.push({
                  id:  item.id+''+index2,
                  name:  item2.attr
                })
              })
              item.k = item.attr_name
              item.k_s = String(item.id)
              initialSku[item.k_s] = item.v[0].id
              item.imgUrl=''
              item.previewImgUrl=''
              // item.v.id = item.attr_value.id
              delete item.attr_value
              delete item.attr_values
              delete item.attr_name
              delete item.product_id
              delete item.type
            })
            list2.forEach(item=>{
              console.log(item)
              item.stock_num = item.stock
              item.goods_id = item.id
              item.price =  item.price*100
              tree.forEach(item2=>{
                if(!item[item2.k_s]){
                  item[item2.k_s]  = ''
                }
              })
              item.suk.split(',').forEach(item2=>{
                let id = getParentIdNew(tree,'name',item2,'v').id
                item[id.slice(0,id.length-1)]= id
              })

            })
            console.log(tree,'tree')
            console.log(list2,'list2')
            this.sku.tree = tree
            this.sku.list = list2
            this.initialSku = initialSku
            this.sku.collection_id = this.obj.storeInfo.product_id
          console.log(this.sku,'sku')

          if(this.type){
            this.chaoshi = true
          }else{
            if(this.obj.storeInfo.is_cat.is_shui == 1){
              this.chaoshi = false
            }else{
              this.chaoshi = true
            }
          }

        } else {
          Toast(res.data.msg)
        }
      })
    },
    getHtmlPic(val) {
      if (!val) return;
      const srcList = [];
      this.detailsHtml = val.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
        srcList.push(capture); // capture图片地址  match img标签整体
        return `<img src=${capture} class="content-img" style="width:100%;display:block">`; //给图片添加class方便后面取dom
      });
      this.$nextTick(() => {
        this.$nextTick(() => {
          const imgHtml = document.getElementsByClassName('content-img');
          for (let i = 0; i < imgHtml.length; i += 1) {
            imgHtml[i].onclick = () => {
              //解决点击预览时不是当前图片问题
              const tempImgList = [...srcList]; // 所有图片地址
              if (i === 0) {
                this.ViewList = tempImgList;
              } else {
                // 调整图片顺序，把当前图片放在第一位
                const start = tempImgList.splice(i);
                const remain = tempImgList.splice(0, i);
                this.ViewList = start.concat(remain);
              }
              this.ViewerShow = true
            };
          }
        });
      })
    },
    checkSwiper(item, index) {
      const image = new Image()
      let list = JSON.parse(JSON.stringify(this.swiperLi))
      const star = list.splice(index)
      const remain = list.splice(0, index);
      this.ViewList = star.concat(remain);
      this.ViewerShow = true


      image.src = (item)
      image.onerror = function () {
        console.log('图片加载失败')
      }
    },
    clearCar() {
      Dialog.confirm({
        title: '确认清空购物车？',
      })
          .then(() => {
            // on confirm
            if (!this.$store.state.token) return;
            let goods_id = []
            this.cartLi.forEach(item => {
              goods_id.push(item.id)
            })
            goods_id = String(goods_id)
            delCartList(goods_id).then(res => {
              if (res.data.status == 200) {
                Toast('清空成功')
                this.carshow = false
                this.cartLi = []
              } else {
                Toast(res.data.msg)
              }
            })
          })
          .catch(() => {
            // on cancel
          });
    },
    submit() {

      // this.$router.push({
      //   name: 'createOrderSecond',
      //   params: {list: this.carList, allPrice: this.allPirce,cat:2,},
      // })
    },
    openCar() {
      if (this.cartLi.length <= 0) return;
      this.carshow = true
    },
    openAddPop() {
      if (this.obj.storeInfo.product_id){
        this.$refs.suk.openPop()
      }
    },
    godetails(){
      this.$router.push({
        path: '/water_details',
        query: {goods: this.obj.storeInfo.product_id}
      })
    },
    addcar(e) {
      if (!this.$store.state.token) {
        Toast('请登录')
        return;
      }
      let nums = e.nums
      let goods_type_id = 1
      if (this.cartLi.find(item => {
        return item.products.id = e.id
      })) {
        nums += this.cartLi.find(item => {
          return item.products.id = e.id
        }).nums
      }
      if (e.goods_type_id == 6) {
        goods_type_id = 5
      }
      addCar(this.$store.state.token, e.id, nums, 2, goods_type_id).then(res => {
        // addCar(this.$store.state.token).then(res => {
        console.log(res)
        if (res.data.status == true) {
          Toast('加入成功')
          this.addCarpop = false
          this.addCAR = 1
        } else {
          Toast(res.data.msg)
        }
      })

    },
    scroll(e) {
      console.log()
      if (e.target.scrollTop < 375) {
        this.num = (e.target.scrollTop / 375).toFixed(1)
      } else {
        this.num = 1
      }
    },
    add(item) {
      if (!this.$store.state.token) {
        Toast('请登录')
        return;
      }
      let nums = 1
      set_cart_num(item.product_id, nums, item.product_attr_unique, 1,this.chaoshi?2:1).then(res => {
        if (res.data.status == 200) {
          item.cart_num += 1
          get_attr(item.id, 0)
          this.getCart()
        } else {
          Toast(res.data.msg)
        }
      })

    },
    buyClicked(e){
      console.log(e)
      console.log(this.obj)
      console.log()
      if(e.selectedSkuComb){
        if(e.selectedSkuComb.suk){
          //多规格
        }else{
          //单规格
          console.log()
        }
        // productId
        // cartNum
        // uniqueId
        // combinationId
        // secKillId
        // bargainId
        // type

        cart_add(e.goodsId,e.selectedNum,e.selectedSkuComb.unique,'',this.obj.storeInfo.id,'',1).then(res=>{
          if(res.data.status == 200){
            let obj = JSON.parse(JSON.stringify(this.obj))
            obj.cart_obj = res.data.data
            this.$router.push({
              path:'/createOrderSecond',
              query:{data:JSON.stringify(obj),type:1},
            })
          }else{
            Toast(res.data.msg)
          }
        })


      }else{
        console.log(e)
      }

    },
    submitTicket: _throttle(async function (e) {
      await set_cart_num(e.goodsId, e.selectedNum, '', -1, 1)
      let car_list = car_list.data.data.filter(item => {
        return item.product_id == e.goodsId
      })
      let confirmObj = {
        cartId: String(car_list.map(item => item.id))
      }
      let confirmPost = await orderConfirm(confirmObj)
      let data = {
        addressId: '',
        couponId: 0,
        payType: "weixin",
        useIntegral: 0,
      }
      let payobj = {
        addressId: '',
        couponId: 0,
        payType: this.payType,
        mark: '',
      }
      let result = await computedOrder(confirmPost.data.data.orderKey, data)
      createOrder(confirmPost.data.data.orderKey, payobj).then(res => {
        delete result.data.data.result.cartInfo
        result.data.data.result.result = res.data.data.result
        this.$router.push({
          path:"/pay",
          query:{data:JSON.stringify(result.data.data.result),backStep:1,type:""}
        })
      })
    }, 500),

    unadd(item) {
      if (!this.$store.state.token) {
        Toast('请登录')
        return;
      }
      let num = 1
      if ((item.cart_num <= item.sell_num) && item.sell_num < 1) {
        num = item.sell_num
      } else {
        num = 1
      }
      set_cart_num(item.product_id, num, item.product_attr_unique, 0,this.chaoshi?2:1).then(res => {
        if (res.data.status == 200) {
          item.cart_num -= 1
          get_attr(item.id, 0)
        } else {
          Toast(res.data.msg)
        }
      })
    },
    closeViewer() {
      this.ViewerShow = false
    },
  },
  mounted() {
    if(this.$route.query.type){
      this.type = this.$route.query.type
    }
    this.getDetails(this.$route.query.goods)

  },
}
</script>

<template>
  <div class="container goodsDetails" @scroll="scroll">
    <div class="area">
      <navigationBar :title="''" class="navigation"
                     :style="`background-color: rgba(255, 255, 255, ${num});`"></navigationBar>
      <div class="swiper">
        <div class="swiper-wrapper">
          <!-- <div class="swiper-slide"><img src="../../../../public/image/asdasd.png" alt="" class="img"></div> -->
          <div
              v-for="(item,index) in swiperLi" :key="index"
              class="swiper-slide"
              :style="`background-image:url(${item})`"
              @click="checkSwiper(item,index)"
          >
          </div>
          <!-- <div class="swiper-slide"><img src="../../../../public/image/asdasd1.png" alt="" class="img"></div> -->
        </div>
        <!-- 如果需要分页器 -->
        <div class="pagination">
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div class="center">
        <div class="price"  v-if="type==1">
          <div class="DailyPrice" >
            <span class="text">秒杀价</span> &nbsp;
            <div class="nowPirce"><span class="unit">￥</span><span class="yuan">{{ obj.storeInfo.yuan }}</span>.<span
                class="mao">{{ obj.storeInfo.mao }}</span></div>
            <div class="oldPrice">￥{{ obj.storeInfo.ot_price }}</div>
          </div>
        </div>
        <div class="name">{{ obj.storeInfo.title }} {{obj.storeInfo.product_type == 5?obj.storeInfo.id+'张':''}}</div>
        <div class="space">
          <div class="inner">
            <div class="item">
              <div class="brand" v-if="obj.storeInfo.brand_name">{{ obj.storeInfo.brand_name }}</div>
              <div class="text">品牌</div>
            </div>
            <div class="item">
              <div class="brand">{{ obj.storeInfo.unit_name }}</div>
              <div class="text">单位</div>
            </div>
            <div class="item">
              <div class="brand">{{ obj.storeInfo.specifications }}</div>
              <div class="text">规格</div>
            </div>
          </div>
        </div>
        <div class="introduce">
          <div class="item">
            <span class="iconfont icon-shijian1"></span>
            当日配送时间为08：00~20：00
          </div>
          <div class="item">
            <span class="iconfont icon-mian"></span>
            三里屯SOHO、通盈中心范围内免费配送
          </div>
          <div class="item">
            <span class="iconfont icon-jin"></span>
            超出三里屯SOHO、通盈中心范围内暂无法配送最快20分钟之内送达
          </div>
        </div>
      </div>
      <div class="details">
        <div class="topTitle">
          图文详情
        </div>
        <template v-if="ViewerShow">
          <ElImageViewer :on-close="closeViewer" :url-list="ViewList"></ElImageViewer>
        </template>
        <div class="inner" v-html="detailsHtml"></div>
        <div class="detailsImg"></div>
        <div class="topTitle">
          购买须知
        </div>
        <div class="xuzhi">
          <div class="item" v-for="(item,index) in xizhi" :key="index">
            <div class="unit">{{ index + 1 }}、</div>
            <div class="text">{{ item }}</div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="inner">
          <div class="left"></div>
          <div class="right" :class="chaoshi?'chaoshiBtn':''" v-if="!type">
            <div class="button" @click="openAddPop">{{obj.storeInfo.product_type == 5?'立即购买':'加入购物车'}}</div>
          </div>
          <div class="right">
            <div class="button" @click="godetails">单独购买</div>
          </div>
          <div class="right" v-if="type == 1">
            <div class="button" @click="openAddPop">立即购买</div>
          </div>
        </div>
      </div>
      <suk-pop v-if="sku.collection_id"  @buyClicked="buyClicked" :initial_sku="initialSku"  ref="suk" :skuParent="sku"  :goods="obj" @addCarc="addcar" :num="addCAR" :chaoshi="chaoshi" :parentsId="obj.storeInfo.product_id"></suk-pop>
    </div>
  </div>
</template>

<style scoped lang="scss">


.vipBox {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  color: #392723;
  line-height: 18px;
  align-items: center;
  .vipPrice {
    margin-right: 5px;
  }
}
.vip_price_tag{
  width: 34px;
  height: 14px;
  margin-left: 5px;
}
.bottom {
  box-shadow: 0px 0px 2px 0px rgba(153, 153, 153, 0.15);
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  .inner {
    padding: 12px 16px;
    display: flex;
    justify-content: flex-end;
  }

  .left {
    position: relative;

    .carImg {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }

    .nums {
      position: absolute;
      top: -20%;
      left: 80%;
      background-color: red;
      color: white;
      min-width: 14px;
      height: 14px;
      border-radius: 50px;
      text-align: center;
      line-height: 14px;
      padding: 2px;
      font-size: 12px;
    }

  }

  .right {
    margin-left: 15px;
    .button {
      background: #0085FF;
      border-radius: 32px 32px 32px 32px;
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 24px;
      text-align: left;
      padding: 10px 24px;
    }
  }

  .chaoshiBtn {
    .button {
      background: #FC223B;
    }
  }
}

.popup {
  position: relative;
  padding: 20px 16px 0 16px;
  background: #fff;

  .bottomBigbox {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .car {
        width: 72rpx;
        position: relative;
        display: flex;
        align-items: center;
      }

      .price {
        margin-left: 10rpx;
        font-size: 14px;
        color: #fd2a39;

      }

      .yuan {
        font-size: 24px;
      }

      .image {
        width: 36px;
        max-height: 36px;
      }

      .nums {
        position: absolute;
        top: 0;
        right: 0;
        background-color: red;
        color: white;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        text-align: center;
        line-height: 14px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .btnBox {
        padding: 10px 24px;
        border-radius: 32px;
        color: white;
        font-size: 14px;
        background: #0085FF;
      }
    }
  }

  .carBox {
    max-height: 50vh;
    overflow: scroll;
    margin-top: 10px;
    padding-bottom: 70px;

    .item {
      display: flex;
    }

    .leftImgBox {
      width: 75px;
      height: 75px;
      border-radius: 10px;
      overflow: hidden;

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .rightInfo {
      flex: 1;
      padding-left: 10px;
      padding-bottom: 10px;

      .brand {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        text-overflow: ellipsis;
        align-items: center;
        font-size: 13px;
        color: #1B1B1B;
        line-height: 18px;
      }

      .name {
        font-weight: 500;
        font-size: 14px;
        color: #1b1b1b;
        line-height: 24px;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
        text-align: left;
      }

      .detail {
        display: flex;
        font-weight: 400;
        font-size: 12px;
        color: #7f7f7f;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;

        .line {
          margin: 0 6px;
          width: 1px;
          height: 9px;
          background: #dddddd;
        }
      }

      .price_add {
        display: flex;
        justify-content: space-between;
        margin-right: 16px;
        align-items: flex-end;
        font-weight: 500;

        .leftPrice {
          display: flex;
          //align-items: baseline;
          align-items: flex-end;
          align-items: center;

          .sellPrice {
            display: flex;
            font-size: 14px;
            color: #fd2a39;
            line-height: 18px;
            align-items: baseline;
            .yuan {
              font-size: 20px !important;
            }
          }

          .lastPrice {
            text-decoration-line: line-through;
            line-height: 18px;
            color: #7f7f7f;
            font-size: 12px;
          }
        }

        .rightAdd {
          display: flex;

          .unadd,
          .add {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #398ae9;
            position: relative;
          }

          .add::after,
          .unadd:after {
            content: "";
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);
            width: 50%;
            height: 10%;
            background-color: white;
            top: 50%;
            left: 50%;
            border-radius: 100px;
          }

          .add::before {
            content: "";
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);
            width: 10%;
            height: 50%;
            background-color: white;
            top: 50%;
            left: 50%;
            border-radius: 100px;
          }

          .input {
            height: 22px;
            width: 22px;
            line-height: 22px;
            margin: 0 5px;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: #1b1b1b!important;
            white-space: nowrap;
            overflow: scroll;
          }

          .input::-webkit-scrollbar {
            display: none;
          }

          .addBox {
            background: #0085FF;
            border-radius: 5px 5px 5px 5px;
            font-weight: 500;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 24px;
            text-align: center;
            padding: 0 10px;
          }
        }
      }

      .vipBox {
        display: flex;
        font-weight: 600;
        font-size: 14px;
        color: #392723;
        line-height: 18px;

        .vipPrice {
          margin-right: 5px;
        }
      }
    }
  }

  .input {
    width: 24px;
    height: 24px;
    font-size: 18px;
    color: #1B1B1B;
    text-align: center;
    margin: 0 2px;
    font-weight: 500;
    white-space: nowrap;
    overflow: scroll;
  }

  .input::-webkit-scrollbar {
    display: none;
  }

  .carBox::-webkit-scrollbar {
    display: none;
  }

  .tioline {
    position: absolute;
    top: 10px;
    left: 50%;
    width: 10%;
    height: 5px;
    background: #0085ff;
    transform: translate(-50%, -0%);
    border-radius: 25px;
  }

  .title {
    display: flex;
    justify-content: space-between;
  }

  .input {
    color: red !important;
  }

}

.details {
  background-color: #fff;
  margin-top: 10px;

  .topTitle {
    font-weight: 500;
    font-size: 16px;
    color: #1B1B1B;
    line-height: 24px;
    text-align: left;
    padding: 10px 16px;

  }

  .inner {
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #F4F6FA;
    margin: 0 16px;
    text-align: left;


    .line {
      padding: 6px 0px;
      margin: 0 10px;
      display: flex;
      border-bottom: 1px solid #F4F6FA;

      .left {
        width: 20%;
        white-space: nowrap;
        margin-right: 10px;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 24px;
      }

      .right {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        color: #1B1B1B;
        line-height: 24px;
      }
    }
  }

  .xuzhi {

    .item {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      margin: 0 16px;
      font-size: 11px;
      color: #B0B0B0;
      line-height: 18px;
      text-align: left;
    }
  }

  padding-bottom: 100px;

}

.oldPrice {
  font-weight: 400;
  font-size: 12px;
  color: #7F7F7F;
  line-height: 18px;
  text-align: left;
  text-decoration-line: line-through;
}
.container {
  background-color: #F4F6FA;
  height: 100vh;
}

.center {
  margin-top: 10px;
  background-color: #fff;
  padding: 10px 0;

  .price {
    margin: 0 16px;

    .DailyPrice{
      display: flex;
      align-items: baseline;
      align-items: center;
      .nowPirce{
        margin-right: 5px;
      }
      .text{
        font-size: 16px;
      }
    }
    .vipBox{
      text-align: left;
    }

    .nowPirce {
      color: #FD2A39;

      .unit {
        font-size: 14px;
      }

      .yuan {
        font-size: 26px;
      }

      .mao {
        font-size: 20px;
      }
    }

    .oldPrice {
      font-weight: 400;
      font-size: 12px;
      color: #7F7F7F;
      line-height: 18px;
      text-align: left;
      text-decoration-line: line-through;
    }
  }

  .name {
    margin: 0 16px;
    text-align: left;
    font-size: 18px;
  }

  .space {
    margin: 0 16px;

    .inner {
      background-color: #F7F8FA;
      margin: 10px;
      border-radius: 6px;
      padding: 8px 0;
      display: flex;
      justify-content: space-around;

      .item {
        position: relative;
        width: 33%;
        padding-left: 12px;
        .brand {
          font-weight: 500;
          font-size: 14px;
          color: #131313;
          line-height: 19px;
        }

        .text {
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 18px;
          margin-top: 1px;
        }
      }

      .item:after {
        position: absolute;
        content: '';
        display: block;
        width: 1px;
        height: 18px;
        background-color: #D9D9D9;
        right: -10%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .item:nth-last-of-type(1):after {
        display: none;
      }
    }
  }

  .introduce {
    .item {
      border-top: 1px solid #F7F8FA;
      padding: 6px 16px;
      text-align: left;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 24px;

      .iconfont {
        color: #FD2A39;
        margin-right: 8px;
        font-size: 22px;
      }
    }
  }
}


.navigation {
  position: fixed;
  z-index: 10;
}

.swiper-slide {
  // background: red;
  height: 160px;
  background-size: cover;
  background-position: center center;
}

.swiper {
  overflow: hidden;
  position: relative;
  height: 375px;

  .swiper-slide {
    height: 375px;
  }

  .img {
    width: 100%;
    object-fit: cover;
  }

  // 分页器
  /* 两种都可以 */
  .swiper-pagination-total {
    font-size: 12px;
  }

  .swiper-pagination {
    background: rgba(51, 51, 51, 0.75);
    width: auto;
    // right: 10px;
    border-radius: 64px;
    padding: 0 5px;
    color: white;
    line-height: 24px;
    font-size: 13px;
  }

  // 分页器
  .pagination {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 10px;
    z-index: 3;
    margin: 0;
  }
}

.chaoshiPop{
  .tioline{
    background: #FC223B;
  }
  .bottomBigbox{
    .right{
      .btnBox{
        background: #FC223B;
      }
    }
  }
  .unadd,.add{
    background-color: #FC223B!important;
  }
}

</style>
<style>
.goodsDetails .van-notify--warning {
  background-color: #ff976a !important;
}

.goodsDetails .details img {
  width: 100%;
  display: block;
}
</style>