<template>
  <div class="app">
    <!-- <div class="works_box">
      <div class="kancha line">
        <div class="avatarBox">
          <img src="https://picture.bjbjkj.top/touxiang/7fc545aedbedcaf7f6ef03cea3ef206.png" class="avatar" alt="">
          <div class="span">勘察员</div>
        </div>
        <div class="rightInfo">
          <div class="title">工号：BJKX-001</div>
          <div class="info">
            <div class="everyImem">
              <div class="text">姓名</div>
              <div class="value">小徐</div>
            </div>
            <div class="everyImem">
              <div class="text">姓名</div>
              <div class="value">小徐</div>
            </div>
            <div class="everyImem">
              <div class="text">姓名</div>
              <div class="value">小徐</div>
            </div>
            <div class="everyImem">
              <div class="text">姓名</div>
              <div class="value">小徐</div>
            </div>
            <div class="everyImem mobile">
              <span class="iconfont icon-dianhua1"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="weixiu line">
        <div class="avatarBox">
          <img src="https://picture.bjbjkj.top/touxiang/7fc545aedbedcaf7f6ef03cea3ef206.png" class="avatar" alt="">
          <div class="span">维修工</div>
        </div>
        <div class="rightInfo">
          <div class="title">工号：BJKX-001</div>
          <div class="info">
            <div class="everyImem">
              <div class="text">姓名</div>
              <div class="value">小徐</div>
            </div>
            <div class="everyImem">
              <div class="text">姓名</div>
              <div class="value">小徐</div>
            </div>
            <div class="everyImem">
              <div class="text">姓名</div>
              <div class="value">小徐</div>
            </div>
            <div class="everyImem">
              <div class="text">姓名</div>
              <div class="value">小徐</div>
            </div>
            <div class="everyImem mobile">
              <span class="iconfont icon-dianhua1"></span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <works_weixiu></works_weixiu> -->
    <div class="fun">
      <div class="order_typeBox">
        <van-radio-group v-model="radio" class="radionGourp">
          <van-radio name="1" class="item" checked-color="#FC223B" icon-size='17' v-if="(radio==1&&identity==1)||identity!=1">
            <template #default>
              <span class="text">质保外</span>
            </template>
          </van-radio>
          <van-radio name="2"  class="item" checked-color="#FC223B" icon-size='17'  v-if="(radio==2&&identity==1)||identity!=1">
            <template #default>
              <span class="text">快修质保</span>
            </template>
          </van-radio>
          <van-radio name="3"  class="item" checked-color="#FC223B" icon-size='17'  v-if="(radio==3&&identity==1)||identity!=1">
            <template #default>
              <span class="text">装修质保</span>
            </template>
          </van-radio>
        </van-radio-group>
      </div>
      <div class="my_guarantee" @click="goAcceptance">
        <img src="../../../../public/image/shandian.png" alt="" class="shandian" @click="goAcceptance">
        {{(identity==1||!identity)?'我的质保':identity==2?'质保查询':'我的质保'}}
      </div>
    </div>
    <div class="innerarea">
      <div class="line border_bottom">
        <div class="lable">快修项目</div>
        <div class="right" style="color: #1b1b1b">
          <input type="text" v-model="userChooseWeixiu" style="border: none;text-align: right" :disabled="identity == 1">
        </div>
      </div>
      <div class="line">
        <div class="lable">勘查说明</div>
        <div class="right">
          <input
              v-model="maintenance_notes"
              type="text"
              class="input"
              placeholder="勘察员现场勘查备注说明"
              maxlength="50"
              :disabled="identity == 1"
          />
        </div>
      </div>
    </div>

    <div class="img_box">
      <div class="img_list">
        <div class="evert li" v-for="(item,index) in kanchaUpload" :key="index+index" >
          <el-image
              class="avatar"
              fit="cover"
              :src="$store.state.imageHost+item.tempFilePath"
              :preview-src-list="kanchaUploadsrcList">
          </el-image>
          <van-icon name="clear" class="icon" @click.stop="deleteImg(kanchaUpload,index,kanchafileList)" v-if="payText=='提交订单'"/>
        </div>
        <el-upload
            v-if="kanchaUpload.length<=4&&payText=='提交订单'"
            class="avatar-uploader"
            action
            :http-request="uploadFile"
            ref="upload"
            :limit="kanchaLimit"
            :on-remove="handleRemove"
            :file-list="kanchafileList"
            :on-exceed="handleExceed"
            :before-upload="beforeUpload"
            :show-file-list="false"
            :headers="headers"
            :on-change="fileChange1"
            multiple
            name="11"
        >


          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>

      </div>
    </div>

    <div class="innerarea">
      <div class="line border_bottom" @click="openTime">
        <div class="lable">上门时间</div>
        <div class="right" :class="yvyue_maintenance_time?'y':''">
          {{yvyue_maintenance_time?yvyue_maintenance_time:'请选择时间'}}
          <van-icon name="arrow" icon/>
        </div>
      </div>

      <div class="line">
        <div class="lable">包干工时</div>
        <div class="right">
          人工费用，变更另算
          <div class="input_tow_box">
            <input
                v-model="maintenance_price"
                type="number"
                class="input_tow"
                placeholder=""
                maxlength="10"
                oninput="if(value.length>11)value=value.slice(0,11)"
                :disabled="identity == 1"

            />元
          </div>
        </div>
      </div>
      <!--      <div class="line">-->
      <!--        <div class="lable">材料清单</div>-->
      <!--        <div class="right">-->
      <!--          <div class="checkGoods" @click="openCai()">查看材料清单</div>-->
      <!--          <div class="input_tow_box">-->
      <!--            <div class="input_tow"></div>-->
      <!--            元-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <van-popup-->
      <!--          get-container="app"-->
      <!--          v-model="cailiaoshow"-->
      <!--          round-->
      <!--          closeable-->
      <!--          position="bottom"-->
      <!--        >-->
      <!--          <div class="cailiaoBox">-->
      <!--            <div class="cailiaoTitle">材料清单</div>-->
      <!--            <div class="cailiaoBody">-->
      <!--              <div class="ul">-->
      <!--                <div class="li" v-for="(t, i) in 15" :key="i">-->
      <!--                  <img-->
      <!--                    class="leftImg"-->
      <!--                    src="https://img.zcool.cn/community/0121a55d903ad6a801211d53066683.jpg@1280w_1l_2o_100sh.jpg"-->
      <!--                    alt=""-->
      <!--                  />-->
      <!--                  <div class="infoBox">-->
      <!--                    <div class="brandBox">-->
      <!--                      <div class="bradn">品牌</div>-->
      <!--                      美耐特-->
      <!--                    </div>-->
      <!--                    <div class="name">改锥螺丝而分为氛围分为氛围</div>-->
      <!--                    <div class="size">尺寸：30*30*2.0mm*6mm</div>-->
      <!--                    <div class="weight">重量：0.35kg/个</div>-->
      <!--                    <div class="price">-->
      <!--                      <div class="leftPrice">-->
      <!--                        ￥<span class="yuan">8</span>.9-->
      <!--                      </div>-->
      <!--                      <div class="rightNums">x2</div>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="bottomBtn">-->
      <!--              <div class="left">-->
      <!--                <div class="top">-->
      <!--                  合计：<span class="price"-->
      <!--                    >￥<span class="yuan">19</span>.60</span-->
      <!--                  >-->
      <!--                </div>-->
      <!--                <div class="bot"></div>-->
      <!--              </div>-->
      <!--              <div class="right">-->
      <!--                <div class="btn">确认清单</div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </van-popup>-->
      <!--      </div>-->

      <!--      <div class="line">-->
      <!--        <div class="lable">材料配送费</div>-->
      <!--        <div class="right">-->
      <!--          <div class="input_tow_box">-->
      <!--            <input-->
      <!--              type="number"-->
      <!--              class="input_tow"-->
      <!--              placeholder=""-->
      <!--              maxlength="10"-->
      <!--            />元-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="line">
        <div class="lable">工具清单</div>
        <div class="right" :class="toolStr?'y':''" @click="toolsOpen">
          <span class="text">{{ toolStr }}</span>
          <van-icon name="arrow" icon/>
        </div>
      </div>
    </div>

    <div class="work_box">
      <div class="title">上门打卡</div>
      <div class="img_box">
        <div class="img_list">
          <div class="evert li" v-for="(item,index) in DoorUpload" :key="index+index" >
            <el-image
                class="avatar"
                fit="cover"
                :src="$store.state.imageHost+item.tempFilePath"
                :preview-src-list="DoorUploadsrcList">
            </el-image>
            <van-icon name="clear" class="icon" @click.stop="deleteImg(DoorUpload,index,DooorfileList)" v-if="obj.authority_user_status == 1"/>
          </div>
          <el-upload
              v-if="DoorUpload.length<=2&&obj.authority_user_status == 1"
              class="avatar-uploader"
              action
              :http-request="uploadFile"
              ref="upload"
              :limit="DoorLimit"
              :on-remove="handleRemove"
              :file-list="DooorfileList"
              :on-exceed="handleExceed"
              :before-upload="beforeUpload"
              :show-file-list="false"
              :headers="headers"
              :on-change="fileChange2"
              multiple
              name="22"
          >
            <i class="el-icon-plus avatar-uploader-icon" ></i>
          </el-upload>
        </div>
      </div>
    </div>


    <van-popup v-model="timeShow" round closeable position="bottom">
      <chooseTime :white="'white'" :type="'kancha'" :color="'#FC223B'" :allDays="true" @childMessage=childMessage></chooseTime>
    </van-popup>
    <van-popup v-model="workersShow" round closeable position="bottom" class="workPop">
      <div class="title">选择维修工</div>
      <div class="tabnav">
        <div class="item" v-for="(item,index) in weixiushifuTabbar" :key="index" :class="weixiushifuTabIndex==index?'chooseWeixiushifu':''" @click="chooseweixiuTbbar(index)">
          {{item.name}}
        </div>
      </div>
      <div class="weixiuBody">
        <div class="banOrYe">
          <div class=" banOrYeSon " :class="weixiushifuBaiOrYeIndex==1?'chooseBaiOrYe':''" @click="weixiushifuBai(1)">白班</div>
          <div class=" banOrYeSon " :class="weixiushifuBaiOrYeIndex==2?'chooseBaiOrYe':''" @click="weixiushifuBai(2)">夜班</div>
        </div>
        <div class="weixiushifuList" v-if="weixiushifuList[weixiushifuTabiD]">
          <div v-for="(item,index) in weixiushifuList[weixiushifuTabiD].data" :key="index">
            <div class="every"  v-if="item.is_baiben==weixiushifuBaiOrYeIndex" @click="radioChange2(item)">
              <div class="leftImg">
                <img :src="$store.state.imageHost+item.avatar" alt="" class="weixiuAvatar">
              </div>
              <div class="center">
                <div class="topId">
                  <div class="Num rightInfo">
                    <div class="title">工号：</div>
                    <div class="text">BJKX-{{item.id}}</div>
                  </div>
                </div>
                <div class="bodyInfo">
                  <div class="name rightInfo">
                    <div class="title">姓名</div>
                    <div class="text">{{item.name}}</div>
                  </div>
                  <div class="jiguan rightInfo">
                    <div class="title ">籍贯</div>
                    <div class="text">{{item.jiguan}}</div>
                  </div>
                  <div class="year rightInfo">
                    <div class="title ">工龄</div>
                    <div class="text">{{item.gonling}}</div>
                  </div>
                  <div class="pingfen rightInfo">
                    <div class="title ">评分</div>
                    <div class="text">5.0</div>
                  </div>
                </div>
              </div>
              <div class="rightcheckBox">
                <div class="checkBox">
                  <div class="choose" v-if="item.choose"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button" @click="saveWeixiu">确定</div>
    </van-popup>


    <van-popup v-model="toolsShow" round closeable position="bottom" class="toolsPop">
      <div class="title">选择工具</div>
      <div class="gongjuTab">
          <div class="item" v-for="(item,index) in toolTypes" :class="toolTypesIndex==item.id?'selectDate':''" :key="index" @click="toolTypesIndexFun(item)">
            {{item.name}}
          </div>
      </div>
      <div class="gongjuBody">
        <input type="text" maxlength="40" v-model="gongjuValue" class="gongjuInput" placeholder="手动填写工具" :disabled="identity==1">
        <div class="gongjuInne">
            <div class="every" v-for="(item,index) in gongjuList" :key="index" :class="item.types == toolTypesIndex?'have':''" @click="chooseToolItem(item)">
              <div class="inn" v-if="item.types == toolTypesIndex" :class="item.choose?'choose':''">
                {{item.name}}
              </div>
            </div>
        </div>

        <div class="yixuan">
          <span class="title">已选工具</span>
          <div class="yixianEeveryBox">{{chooseToolsStr}}{{gongjuValue}}</div>
        </div>
        <div class="botBtn" @click="saveTools">
          确认
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import works_weixiu from "./works_weixiu/works_weixiu.vue";
import Compressor from "compressorjs";
import {get_authority_list, tool, upload_file, upload_files, wokr_type_cat} from "@/utils/api";
import chooseTime from "@/components/common/choose_time/choose_time.vue";
import {Toast} from "vant";

export default {
  components: {
    chooseTime
    // works_weixiu,
  },
  props: {
    maintenance_type: {
      type: [String, Number],
    },
    obj: {
      type: [String, Number, Object],
    },
    obj1: {
      type: [String, Number, Object],
    },
    identity:{
      type: [String,Number],
    },
  },
  data() {
    return {
      radio: "1",//质保内外  装修质保
      cailiaoshow: false,
      otherIn: false,
      userChooseWeixiu: '',
      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["png", "jpg", "jpeg", 'mp4', 'mp3'],
      kanchafileList: [],//勘察上门照片files
      kanchaLimit: 5,//勘察上门照片最大数量
      kanchaUpload: [],//勘察上门照片

      DooorfileList: [],//上门照片 files
      DoorLimit: 3,//上门照片最大数量
      DoorUpload: [],//上门照片


      maintenance_price: 0,//包干公时
      toolStr:'',//工具str
      maintenance_notes:'',//勘察说明
      timeShow:false,//时间pop
      workersShow:false,//维修工pop
      yvyue_maintenance_time:'',//勘察员选择维修工上门时间

      weixiushifuTabbar:['水电','油','瓦','木','综合'],
      weixiushifuTabIndex:0,
      weixiushifuList:[],

      weixiushifuBaiOrYeIndex:1,//1 白班 2 夜班
      chooseWeixiushifu:{},//选择的维修工

      toolsShow:false,//gongju pOp
      toolTypes:[],//toolTab
      toolTypesIndex:0,//下标
      chooseGongjuList:[],//选择的工具
      gongjuValue:'',//手动输入的工具
      gongjuList:[],//所有工具
    };
  },
  watch: {
    maintenance_price(n){
      this.$emit('baogan', n);
    },
  },
  computed: {
    payText() {
      let str = ''
      if (this.obj.order_id) {
        if (this.identity == 1) {
          if (this.obj.pay_status == 1 || this.obj.pay_status == 6) {
            str = '订单支付'
          } else if (this.obj.pay_status == 2) {
            str = '支付成功'
          }
          if (this.obj.items.status == 2) {
            str = '已支付'
          }
          if (this.obj.items.status == 1) {
            str = '提交订单'
            if (this.obj.payment_time) {
              str = '已支付'
            }
          }
          if (this.obj.confirm == 2) {
            str = '已完成'
          }
        }
        if (this.identity == 2) {
          if (this.obj.items.status == 2) {
            str = '修改订单'
          }
          if (this.obj.items.status == 1) {
            str = '提交订单'
            if (this.obj.payment_time) {
              str = '已支付'
            }
          }
          if (this.obj.confirm == 2) {
            str = '已完成'
          }
        }

      } else {
        str = '上门打卡'
      }
      return str
    },
    chooseToolsStr(){
      let list = this.gongjuList.filter(item=>{return item.choose == true})
      let str = ''
      list.forEach(item=>{
        str+=item.name+','
      })
      return str
    },
    weixiushifuTabiD(){
      return this.weixiushifuTabbar[this.weixiushifuTabIndex].id
    },
    DoorUploadsrcList() {
      let list = []
      JSON.parse(JSON.stringify(this.DoorUpload)).forEach(item=>{
        list.push(this.$store.state.imageHost+item.tempFilePath)
      })
      return list
    },
    kanchaUploadsrcList() {
      let list = []
      JSON.parse(JSON.stringify(this.kanchaUpload)).forEach(item=>{
        list.push(this.$store.state.imageHost+item.tempFilePath)
      })
      return list
    },

  },
  methods: {
    goAcceptance() {
        this.$router.push({
          path:'/my_acceptance',
          query:(this.obj&&this.identity!=1)?this.obj:''
        })
    },
    chooseToolItem(item){
      if(this.identity == 1) return
      item.choose = !item.choose
    },
    saveTools(){
      // chooseToolsStr
      this.toolStr = this.chooseToolsStr+this.gongjuValue
      this.toolsShow = false
    },
    toolTypesIndexFun(index){
      if(this.toolTypesIndex == index.id)return
      this.toolTypesIndex = index.id
    },
    toolsOpen(){
      this.toolsShow = true
    },
    radioChange2(item){
      let index = this.weixiushifuList[this.weixiushifuTabiD].data.findIndex(e2=>{return e2.id == item.id})
      console.log(index);
      this.weixiushifuList[this.weixiushifuTabiD].data.forEach(item=>{
        item.choose = false
      })
      this.weixiushifuList[this.weixiushifuTabiD].data[index].choose = true
      this.chooseWeixiushifu = item
      console.log(this.weixiushifuList)
    },
    weixiushifuBai(e){
      if(this.weixiushifuBaiOrYeIndex == e)return;
      this.weixiushifuBaiOrYeIndex = e
    },
    saveWeixiu(){
      if(!this.chooseWeixiushifu){
          Toast('请选择维修师傅')
        return
      }
      let str = ''

      for (const key in this.weixiushifuList) {
        console.log(this.weixiushifuList[key].data);
        if(this.weixiushifuList[key].data){
          this.weixiushifuList[key].data.forEach(item=>{
            console.log(item.choose);
            if(item.choose){
              str+=item.name+','
            }
          })
        }
      }
      if(str==''){
        return
      }
    this.workersShow = false

    },
    chooseweixiuTbbar(e){
      if(e == this.weixiushifuTabIndex)return;
      this.weixiushifuTabIndex = e
    },
    deleteImg(list, index, fileList) {
      list.splice(index, 1)
      fileList.splice(index, 1)
    },


    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)

        }


      })
    },
    async beforeUpload(files) {
      let file = await this.yasuo(files)
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        if (files.type.startsWith('image/')) {   //图片
          //计算文件的大小
          const isLt5M = file.size / 1024 / 1024 < 1; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            return false;
          }
        } else if (files.type.startsWith('video/')) {//视频
          const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
    uploadFile(item) {
      // console.log(JSON.stringify(item))
      this.$message("文件上传中........");
      if (item.file.type.startsWith('image/')) {
        console.log('图片')
        console.log(item.file)
        upload_file(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 11) {
              this.kanchaUpload.push({tempFilePath: res.data.data.url, fileType: 'image'})
            }
            if (item.filename == 22) {
              this.DoorUpload.push({tempFilePath: res.data.data.url, fileType: 'image'})
            }
            console.log(res.data.data)
            // this.userUpload.push({tempFilePath:res.data.data.url,fileType:'image'})
          }
        })
      } else if (item.file.type.startsWith('video/')) {
        upload_files(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 11) {
              this.kanchaUpload.push({tempFilePath: res.data.data.url, fileType: 'video'})
            }
            if (item.filename == 22) {
              this.DoorUpload.push({tempFilePath: res.data.data.url, fileType: 'video'})
            }
            console.log(res.data.data)
            // this.userUpload
            // this.userUpload.push({tempFilePath:res.data.data.url,fileType:'video'})
          }
        })
      }
    },
    //上传成功后的回调
    handleSuccess() {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.handleClose();
    },
    fileChange1(file, kanchafileList) {
      this.kanchafileList = kanchafileList;
    },
    fileChange2(file, DooorfileList) {
      console.log(file)
      this.DooorfileList = DooorfileList;
    },
    async handleRemove() {
    },
    handleClose(i) {
      this.kanchafileList.splice(i, 1); //删除上传的文件
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },
    openTime() {
      if(this.identity == 1)return;
      this.timeShow = true
    },
    async childMessage(obj) {
      this.yvyue_maintenance_time = obj.item.time
      this.timeShow = false;
      this.workersShow = true
      get_authority_list(this.$store.state.token,obj.item.time).then(res=>{
        if(res.data.status == true){
          let list = res.data.data
          for (const key in list) {
            if(list[key].data){
              list[key].data.forEach(item=>{
                item.choose = false
              })
            }
          }
          this.weixiushifuList = list
          console.log(this.weixiushifuList)
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
      // this.$emit('yuangong', obj1);
    },
    openCai() {
      this.cailiaoshow = true;
    },

    async getTools(){
      let toolLi = await tool(this.$store.state.token,2);
      let workTab = await wokr_type_cat(this.$store.state.token,1)
      let toolTab  = await wokr_type_cat(this.$store.state.token,2)
      this.toolTypes = toolTab.data.data
      this.weixiushifuTabbar = workTab.data.data

      this.toolTypesIndex = toolTab.data.data[0].id
      console.log(toolLi.data.data,workTab.data.data)
      toolLi.data.data.forEach(item=>{
        item.choose = false

        if(this.obj.maintenance_user_id == null){
          if(item.moren ==1){
            // console.log(item)
            this.toolStr+=item.name+' '
            item.choose = true
          }
        }else{
          this.obj.items.tool.split(' ').forEach(item2=>{
            if(item.name == item2){
              item.choose = true
            }
          })
        }
      })
      this.gongjuList = toolLi.data.data
    },



    init(){
      console.log(this.obj)
      console.log(this.identity)

      console.log('============================')
      if(this.identity == 1){
        2
      }
      if(this.obj.authority_user_img){
          this.DoorUpload = JSON.parse(this.obj.authority_user_img)
      }
      if(this.obj.items.notes){
        this.maintenance_notes = this.obj.items.notes
      }
      if(this.obj.items.files_user_surveyor){
        this.kanchaUpload = JSON.parse(this.obj.items.files_user_surveyor)
      }
      this.yvyue_maintenance_time = this.obj.is_up_dizhi_status == 1?this.obj.service_time:this.obj.user_weixin_order_times
      if(this.obj.maintenance_user_id){
        this.chooseWeixiushifu.id = this.obj.maintenance_user_id
        this.chooseWeixiushifu.name = this.obj.name
      }
      if(this.obj.items.maintenance_price){
        this.maintenance_price = this.obj.items.maintenance_price
      }
      if(this.obj.items.tool!=null){
        this.toolStr = this.obj.items.tool
      }
    },

  },
  created() {
  },
  mounted() {
    console.log(this.obj.order_id,'order_id')
    if (this.obj.order_id) {
      // this.otherIn = true
      this.userChooseWeixiu = this.obj.items.title
    }

    this.getTools()

    if(this.obj.order_id){
      this.init()
    }

  },
};
</script>
<style lang="scss" scoped>
.work_box {
  margin-top: 20px;

  .title {
    font-size: 14px;
    color: #1b1b1b;
    position: relative;
    display: inline-block;
  }

  .title::after,
  .title::before {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    background-color: #666666;
    width: 95px;
    height: 1px;
  }

  .title::after {
    transform: translate(-100%, -50%);
    left: -50%;
  }

  .title::before {
    transform: translate(100%, -50%);
    right: -50%;
  }
}
.fun{
  text-align: right;
}
.works_box {
  text-align: left;

  .line {
    margin: 10px 0;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    display: flex;

    .avatarBox {
      position: relative;
      display: flex;
      overflow: hidden;
      border-radius: 10px;

      .avatar {
        width: 58px;
        height: 58px;
        background-color: #666666;
      }

      .span {
        background: rgba(0, 0, 0, 0.65);
        position: absolute;
        bottom: 0;
        font-size: 12px;
        width: 100%;
        text-align: center;
        line-height: 25px;
        color: #fff;
      }
    }

    .rightInfo {
      width: 80%;
      margin-left: 10px;

      .title {
        color: #1b1b1b;
        font-size: 14px;
      }

      .info {
        display: flex;
        align-items: center;

        .everyImem {
          width: 20%;
          align-items: center;
          text-align: center;

          .text {
            font-size: 12px;
            line-height: 18px;
            color: #666666;
          }

          .value {
            font-size: 14px;
            line-height: 18px;
            color: #1b1b1b;
          }
        }

        .mobile {
          .iconfont {
            background-color: #f5f5f5;
            padding: 6px;
            border-radius: 8px;
            color: #FC223B;
            font-size: 24px;
          }
        }
      }
    }
  }
}
.my_guarantee {
  background-color: #FC223B;
  display: inline-block;
  color: #fff;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0 50px 50px 0px;
  font-size: 14px;
  line-height: 24px;

  .shandian {
    width: 29px;
    position: absolute;
    left: 0;
    top: -5px;
    transform: translate(-80%, 0);
  }
}

.innerarea {
  margin-top: 10px;

  .line {
    display: flex;
    padding-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    margin-left: 8px;
    margin-right: 8px;
    padding: 10px 0;

    .checkGoods {
      background: #FC223B;
      color: #fff;
      font-size: 14px;
      padding: 4px 12px;
      border-radius: 8px;
    }

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #c7c7c7;
      font-size: 14px;
      width: 75%;
      text-align: right;
    }
    .y {
      color: #1b1b1b;
    }

    .lable {
      color: #666666;
      font-size: 14px;
      line-height: 24px;
    }

    .input {
      border: none;
      background: transparent;
      text-align: right;
      color: #1b1b1b;
      width: 100%;
    }

    .input_tow_box {
      border: 1px solid #FC223B;
      border-radius: 8px 8px 8px 8px;
      color: #FC223B;
      font-size: 14px;
      width: 72px;
      display: flex;
      padding: 4px 6px;
      margin-left: 10px;

      .input_tow {
        border: none;
        background: transparent;
        text-align: right;
        max-width: 80%;
        text-align: center;
        min-width: 80%;
      }
    }

    .input::placeholder {
      color: #c7c7c7;
    }
  }

  .border_bottom {
    border-bottom: 1px solid rgba(153, 153, 153, 0.15);
  }
}

.app {
  // background-color: red;
  padding: 0 16px;
}

.img_box {
  margin-top: 10px;

  .title {
    text-align: left;
    font-size: 12px;
    color: #999999;
  }

  .img_list {
    margin-top: 4px;
    display: flex;

    .li {
      width: 65px;
      height: 65px;
      margin-right: 5px;
      position: relative;
      border-radius: 10px;

      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      .icon {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(20%, -50%);
        font-size: 20px;
      }
    }

    .addImg {
      border: 1px dashed #999999;
      border-radius: 10px;
      position: relative;

    }

    .addImg:after,
    .addImg:before {
      position: absolute;
      content: '';
      display: block;
      width: 30%;
      height: 2px;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }

    .addImg:before {
      width: 2px;
      height: 30%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }
  }

}

.order_typeBox {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.cailiaoBox {
  padding: 10px 16px;
  text-align: left;
  max-height: 650px;

  .cailiaoBody {
    max-height: 500px;
    overflow: scroll;
    padding-bottom: 20px;
  }

  .cailiaoTitle {
    margin-top: 6px;
    font-size: 18px;
    color: #1a1a1a;
    margin-bottom: 16px;
    text-align: left;
  }

  .bottomBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      .top {
        display: flex;
        align-items: center;

        .price {
          color: #fd2a39;
          font-weight: 500;
          font-size: 14px;

          .yuan {
            font-size: 24px;
          }
        }
      }
    }

    .right {
      width: 60%;

      .btn {
        background: #FC223B;
        border-radius: 32px 32px 32px 32px;
        color: #fff;
        font-size: 14px;
        padding: 10px 24px;
      }
    }
  }

  .ul {
    .li {
      display: flex;

      // align-items: center;
      .leftImg {
        width: 75px;
        height: 75px;
        margin-right: 10px;
        border-radius: 10px 10px 10px 10px;
      }

      .infoBox {
        width: 77%;
      }

      .brandBox {
        color: #1b1b1b;
        line-height: 18px;
        font-weight: 500;
        font-size: 13px;
        display: flex;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .bradn {
          color: #1b1b1b;
          font-size: 12px;
          line-height: 18px;
          padding: 0 4px;
          background: #e8e8e8;
          border-radius: 4px 4px 4px 4px;
          margin-right: 5px;
        }
      }

      .name,
      .size,
      .weight {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .name {
        font-weight: 500;
        font-size: 14px;
        color: #1b1b1b;
        line-height: 24px;
      }

      .size,
      .weight {
        font-weight: 400;
        font-size: 13px;
        color: #7f7f7f;
        line-height: 18px;
      }

      .price {
        display: flex;
        justify-content: space-between;

        .leftPrice {
          color: #fd2a39;
          font-size: 14px;

          .yuan {
            font-size: 18px;
          }
        }

        .rightNums {
          font-weight: 400;
          font-size: 14px;
          color: #1b1b1b;
          line-height: 24px;
        }
      }
    }
  }
}

.radionGourp {
  display: flex;
  .item{
    margin-left: 10px;
  }
  .text {
    font-weight: 400;
    font-size: 12px;
    color: #666666;
    line-height: 24px;
  }
}


.toolsPop{
  .title{
    font-size: 16px;
    text-align: center;
    color: #1b1b1b;
    margin: 20px 0 20px 0;
  }
  .gongjuTab{
    display: flex;
    justify-content: space-around;
    color: #666666;
    .item{
      width: 18%;
      border: 1px solid white;
      border-bottom: none;
      text-align: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: #f9f9f7;
      font-size: 14px;
      padding: 10px 0;
    }
    .selectDate {
      background-color: #4e76fa;
      border: 1px solid #4e76fa;
      color: #ffffff;
    }
  }
  .gongjuBody{
    padding-top: 10px;
    .gongjuInne{
      height: 300px;
      overflow-y: scroll;
      //display: flex;
      //flex-wrap: wrap;
      text-align: left;
      .every{
        display: inline-block;
        //display: flex;
        .inn{
          //width: 30%;
          border: 1px solid #DADADA;
          margin: 5px;
          text-align: center;
          border-radius: 5px;
          color: #666666;
          font-size: 14px;
          padding: 10px 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          position: relative;
        }
        .choose{
          border: 1px solid #4e76fa;
          color: #4e76fa;
        }
      }
      .have{
        width: 30%;
      }
    }
    .yixuan {
      width: 95%;
      margin: 5px auto;
      border: 1px solid rgb(170, 170, 170);
      padding: 5px;
      height: 70px;
      overflow: auto;
      border-radius: 10px;
      text-align: left;
      .title{
        color: #666666;
        font-size: 12px;
        text-align: left;
      }
      .yixianEeveryBox{
        color: #666666;
        font-size: 12px;
      }

    }
    .gongjuInput{
      width: 90%;
      margin: 0 16px 20rpx 16px;
      padding: 5px;
      background-color: #e4e4e4;
      font-size: 12px;
      border-radius: 10px;
      border: 1px solid white;
      color: #666666;
    }
    .botBtn{
      color: white;
      border: 1px solid white;
      background: #4e76fa;
      font-size: 17px;
      padding: 13px 0px;
      border-radius: 8px;
      text-align: center;
      margin: 0 16px;
    }
  }
}
.workPop{
  .title{
    font-size: 16px;
    text-align: center;
    color: #1b1b1b;
    margin: 20px 0 20px 0;
  }
  .tabnav{
    display: flex;
    justify-content: space-around;
    color: #666666;

    .item{
      width: 19%;
      border: 1px solid white;
      border-bottom: none;
      text-align: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: #f9f9f7;
      font-size: 16px;
      padding: 12px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .chooseWeixiushifu{
      background-color: #4e76fa!important;
      border: transparent!important;
      color: white;
    }
  }

  .weixiuBody{
    height: 500px;
    .banOrYe{
      margin: 10px 16px;
      display: flex;
      justify-content: space-around;
    }
    .banOrYeSon{
      background-color: white;
      color: #666666;
      width: 40%;
      text-align: center;
      font-size: 14px;
      padding: 10px;
      border-radius: 10px;
      border: 1px solid #DADADA;
    }
    .chooseBaiOrYe{
      background: #f1f6fd!important;
      color: #4e76fa;
      border: 1px solid #4e76fa;
      font-weight: 600;
    }

    .weixiushifuList{
      margin: 0px 16px;
      position: relative;
      .every{
        width: 100%;
        height: auto;
        padding: 10px 0;
        text-align: center;
        margin: 0 0 0;
        position: relative;
        color: white;
        display: flex;
        .leftImg{
          .weixiuAvatar{
            width: 65px;
            height: 65px;
            border-radius: 10px;
          }
        }
        .center{
          display: flex;
          flex-direction: column;
          width: 80%;
          .topId {
            color: #4e76fa;
            text-indent: 1em;
            font-size: 14px;
            .rightInfo {
              display: flex;
              flex-direction: row;
              .title{
                margin: 0;
              }
            }
          }
          .bodyInfo {
            display: flex;

            .rightInfo {
              display: flex;
              flex-direction: column;
              width: 25%;
              .title {
                font-size: 12px;
                color: #666666;
                margin: 0;
              }
              .text {
                font-size: 14px;
                color: #1b1b1b;
                white-space: nowrap;
                overflow: scroll;
              }
            }
          }
        }
        .rightcheckBox{
          width: 20%;
          display: flex;
          align-items: center;
          justify-content: center;
          .checkBox{
            width: 18px;
            height: 18px;
            border: 1px solid #4e76fa;
            border-radius: 50%;
            position: relative;
            .choose{
              width: 70%;
              height: 70%;
              background-color: #4e76fa;
              border-radius: 50%;
              position: absolute;
              transform: translate(-50%,-50%);
              left: 50%;
              top: 50%;
            }
          }
        }
      }
    }
  }
  .button{
    background-color: #4e76fa;
    bottom: 0;
    left: 50%;
    color: white;
    font-size:16px;
    text-align: center;
    border-radius: 10px;
    padding: 13px;
    margin: 10px 16px;
  }
}

</style>