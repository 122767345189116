<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {defaultAddress, get_two_cat, repair_create, upload_file, upload_files} from "@/utils/api";
import {isValidJSON} from "@/utils/tool";
import chooseTime from "@/components/common/choose_time/choose_time.vue";
import {Dialog, Toast} from "vant";
import Compressor from "compressorjs";

export default {
  name: "new_home",
  components: {chooseTime, navigationBar},
  data() {
    return {
      userAddress: {},
      kanchaTime: '',
      kanchaTimeShow: '',
      userChooseWeixiu: '',
      description: '',
      weixiuContent: '',
      weixiutabbar: [],

      timeShow: false,
      itemShow: false,

      haveOrder: false,
      zhengzaiNum: 0,
      zhibaoNums: 0,
      userUpload: [],//
      srcList: [],
      otherIn: false,
      fileLimit: 4,
      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["png", "jpg", "jpeg", 'mp4', 'mp3'],
      fileList: [],
      maxImg: 4,

    }
  },
  methods: {
    checkImg() {

    },
    deleteImg(list, index) {
      list.splice(index, 1)
      this.handleClose()
    },
    order(e) {
      let obj = {
        title: "",
        type: e,
      };
      if (e == 3) {
        console.log(e)
      } else {
        if (e == 1) {
          obj.title = "正在快修";
        }
        if (e == 2) {
          obj.title = "快修记录";
        }
        this.$router.push({
          path: "/service_order",
          query: {title: obj.title, type: e},
        });
      }
    },
    async initialize() {
      if (sessionStorage.getItem('now_choose_address')) {
        let add = JSON.parse(sessionStorage.getItem('now_choose_address'))
        this.userAddress = add
      } else {
        await defaultAddress(isValidJSON(this.$store.state.user) ? JSON.parse(this.$store.state.user).uid : this.$store.state.user.uid).then(res => {
          if (res.data.status == 200) {
            this.userAddress = res.data.data
            sessionStorage.setItem('now_choose_address', JSON.stringify(res.data.data))
          }
        })
      }
    },
    goAddress() {
      if (this.haveOrder) return;
      this.$router.push({
        path: '/userAddress',
        query: {id: this.userAddress.id},
      })
    },
    openTime() {
      if (this.haveOrder) return;
      this.timeShow = true
    },
    openService() {
      if (this.haveOrder) return;
      this.itemShow = true
    },
    chooseItem(ide) {
      console.log(this.weixiutabbar[ide]);
      this.weixiutabbar[ide].choose = !this.weixiutabbar[ide].choose
    },
    saveItem() {
      let str = ''
      this.weixiutabbar.forEach(item => {
        if (item.choose) {
          str += (item.name + '、')
        }
      })
      str += this.weixiuContent
      if (str.endsWith('、')) {
        str = str.substr(0, str.length - 1)
      }
      this.userChooseWeixiu = str
      this.itemShow = false
    },
    childMessage(obj) {
      console.log(obj)
      console.log(obj.chooseKanchaObj)
      this.kanchaTime = obj.item.time
      this.kanchaTimeShow = obj.item.time
      this.chooseKanchaObj = obj.chooseKanchaObj
      this.timeShow = false;

    },
    submit() {
      if (this.haveOrder) return;
      if (!this.userChooseWeixiu) {
        Toast('请选择维修项目')
        return
      }
      if (!this.userAddress.id) {
        Toast('请选择维修地址')
        return
      }
      if (this.userUpload.length <= 0) {
        Toast('请上传图片')
        return;
      }

      repair_create(this.userAddress.id, this.userChooseWeixiu, JSON.stringify(this.userUpload), '', this.description).then(res => {
        if (res.data.status == 200) {
          Dialog.alert({
            message: '信息已提交，请耐心等待报价结果',
            confirmButtonText: '我知道了',
            confirmButtonColor: '#FC223B',
          }).then(() => {
            // on close
            this.$router.go(-1);
          });
        }
      })


    },


    uploadFile(item) {
      // console.log(JSON.stringify(item))
      this.$message("文件上传中........");
      if (item.file.type.startsWith('image/')) {
        console.log('图片')
        console.log(item.file)
        upload_file(item.file).then(res => {
          if (res.data.status == 200) {
            this.$message.close()
            this.$message.success("上传成功");
            this.userUpload.push({tempFilePath: res.data.data.url, fileType: 'image'})
            // this.userUpload
          }
        })
      } else if (item.file.type.startsWith('video/')) {
        upload_files(item.file).then(res => {
          if (res.data.status == 200) {
            this.$message.close()
            this.$message.success("上传成功");
            console.log(res.data.data)
            // this.userUpload
            this.userUpload.push({tempFilePath: res.data.data.url, fileType: 'video'})
          }
        })
      }
    },
    //上传成功后的回调
    handleSuccess() {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.handleClose();
    },
    fileChange(file, fileList) {
      console.log(fileList)
      let list = []
      fileList.forEach(item => {
        const FileExt = item.name.replace(/.+\./, "").toLowerCase();
        if (this.fileType.includes(FileExt)) {
          list.push(item)
          return true;
        } else {
          return false;
        }
      })
      this.fileList = list;
    },
    async handleRemove() {
    },

    handleClose(i) {
      this.fileList.splice(i, 1); //删除上传的文件
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },
    uploadImg() {
      console.log('上传图片')
    },
    async beforeUpload(files) {
      let file = await this.yasuo(files)
      console.log(file)

      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        if (files.type.startsWith('image/')) {   //图片
          //计算文件的大小
          const isLt5M = file.size / 1024 / 1024 < 1; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            return false;
          }
        } else if (files.type.startsWith('video/')) {//视频
          const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)

        }


      })
    },

    getContent() {
      get_two_cat().then(res => {
        if (res.data.status == 200) {
          let list = res.data.data
          list.forEach(item => {
            item.choose = false
          })
          this.weixiutabbar = list
        }
      })
    },
    otherInFun() {
      console.log(JSON.parse(this.$route.query.item));
      this.userUpload = JSON.parse(JSON.parse(this.$route.query.item).files)
      this.fileList = JSON.parse(JSON.parse(this.$route.query.item).files)
      this.fileList.forEach(item => {
        item.name = item.tempFilePath
      })
      console.log()
    },
  },
  watch: {
    userUpload: {
      handler(newVal) {
        let list = []
        newVal.forEach(item => {
          list.push(item.tempFilePath)
        })
        this.srcList = list
      },
      deep: true
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != 'userAddress') {
      sessionStorage.removeItem('now_choose_address')
    }
    next();
  },
  mounted() {
    this.initialize()
    this.getContent()
    if (this.$route.query.item) {
      this.otherInFun()
    }


  },
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'室内快修'" class="navigation"></navigationBar>
      <img :src="$store.state.imageHost+'/img/Group_4149%402x.png'" alt="" class="topimg">
      <div class="centerBody">
        <div style="padding: 10px 16px">
          <img :src="$store.state.imageHost+'/img/service_top_header.png'" alt="" class="service_top_header">
        </div>
        <div class="bodyinner">
          <div class="title">为你预约勘察</div>
          <div class="text">填写信息 › 免费预约 › 上门勘察</div>
          <div class="form">
            <!--            <div class="one_line " @click="goAddress">-->
            <!--              <div class="line">-->
            <!--                <div class="label">快修地址<span>*</span></div>-->
            <!--                <div class="right">-->
            <!--                  <div class="value" :class="userAddress.id?'':'placeholder'">{{userAddress.id?userAddress.real_name+' '+userAddress.phone:'选择维修地址'}}</div>-->
            <!--                  <span class="iconfont icon-Frame-4" v-if="!haveOrder"></span>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="address" v-if="userAddress.id">-->
            <!--                {{ userAddress.province }} {{ userAddress.city }} {{ userAddress.district }} {{ userAddress.street }} {{userAddress.address_location_name}} {{userAddress.unit_name}} {{userAddress.layer_name}}{{userAddress.detail}}-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="double_line">-->
            <!--              <div class="line" @click="openTime">-->
            <!--                <div class="label">预约时间<span>*</span></div>-->
            <!--                <div class="right">-->
            <!--                  <div class="value" :class="kanchaTimeShow?'':'placeholder'">{{kanchaTimeShow?kanchaTimeShow:'选择勘察时间'}}</div>-->
            <!--                  <span class="iconfont icon-Frame-4" v-if="!haveOrder"></span>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="line" @click="openService">-->
            <!--                <div class="label">快修项目<span>*</span></div>-->
            <!--                <div class="right">-->
            <!--                  <div class="value" :class="userChooseWeixiu?'':'placeholder'">{{userChooseWeixiu?'':'选择快修项目'}}</div>-->
            <!--                  <span class="iconfont icon-Frame-4" v-if="!haveOrder"></span>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->


            <div class="double_line">
              <!--              <div class="line" @click="openTime">-->
              <!--                <div class="label">预约时间<span>*</span></div>-->
              <!--                <div class="right">-->
              <!--                  <div class="value" :class="kanchaTimeShow?'':'placeholder'">{{kanchaTimeShow?kanchaTimeShow:'选择勘察时间'}}</div>-->
              <!--                  <span class="iconfont icon-Frame-4" v-if="!haveOrder"></span>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="one_line" @click="goAddress">
                <div class="line">
                  <div class="label">快修地址<span>*</span></div>
                  <div class="right">
                    <div class="value" :class="userAddress.id?'':'placeholder'">
                      {{ userAddress.id ? userAddress.real_name + ' ' + userAddress.phone : '选择维修地址' }}
                    </div>
                    <span class="iconfont icon-Frame-4" v-if="!haveOrder"></span>
                  </div>
                </div>
                <div class="address" v-if="userAddress.id">
                  {{ userAddress.province }} {{ userAddress.city }} {{ userAddress.district }} {{ userAddress.street }}
                  {{ userAddress.address_location_name }} {{ userAddress.unit_name }}
                  {{ userAddress.layer_name }}{{ userAddress.detail }}
                </div>
              </div>

              <div class="line" @click="openService">
                <div class="label">快修项目<span>*</span></div>
                <div class="right">
                  <div class="value" :class="userChooseWeixiu?'':'placeholder'">
                    {{ userChooseWeixiu ? userChooseWeixiu : '选择快修项目' }}
                  </div>
                  <span class="iconfont icon-Frame-4" v-if="!haveOrder"></span>
                </div>
              </div>
            </div>

            <div class="double_line">
              <div class="line">
                <div class="label">维修描述<span></span></div>
                <div class="right">
                  <input class="input" type="text" v-model="description" placeholder="请描述维修区域 方便准确报价">
                  <span class="iconfont icon-Frame-4" v-if="!haveOrder"></span>
                </div>
              </div>
            </div>

            <div class="img_box">
              <div class="title">照片、视频{{ userUpload.length }}/{{ maxImg }}</div>
              <div class="img_list">

                <div class="evert li" v-for="(item,index) in userUpload" :key="index+index" @click.stop="checkImg()">
                  <el-image
                      class="avatar"
                      fit="cover"
                      :src="item.tempFilePath"
                      :preview-src-list="srcList">
                  </el-image>
                  <van-icon name="clear" class="icon" @click.stop="deleteImg(userUpload,index)" v-if="!otherIn"/>
                </div>
                <el-upload
                    v-if="userUpload.length<fileLimit&&!otherIn"
                    class="avatar-uploader"
                    action
                    :http-request="uploadFile"
                    ref="upload"
                    :limit="fileLimit"
                    :on-remove="handleRemove"
                    :file-list="fileList"
                    :on-exceed="handleExceed"
                    :before-upload="beforeUpload"
                    :show-file-list="false"
                    :headers="headers"
                    :on-change="fileChange"
                >
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>

              </div>
            </div>


          </div>
        </div>
      </div>
      <van-popup v-model="timeShow" round closeable position="bottom">
        <chooseTime :white="'white'" :type="'xiu'" :color="'#FC223B'" @childMessage=childMessage></chooseTime>
      </van-popup>
      <van-popup v-model="itemShow" round closeable position="bottom">
        <div class="itemBox">
          <div class="title">维修项目</div>
          <div class="input">

            <el-input type="textarea"
                      autosize
                      placeholder="项目可多选、若下方快修项目没有，可以手动输入~"
                      v-model="weixiuContent" class="textarea" maxlength="100"></el-input>
          </div>
          <div class="itemBody">
            <div v-for="(it,idx) in weixiutabbar" :key="idx" class="li" @click="chooseItem(idx)">
              <div class="itemBody_name">{{ it.name }}</div>
              <van-checkbox :value="it.choose" v-if="it.choose" checked-color="#FC223B"></van-checkbox>
            </div>
          </div>
          <div class="button" @click="saveItem">确定</div>
        </div>
      </van-popup>

      <img :src="$store.state.imageHost+'img/Frame_4265%402x.png'" alt="" class="bottomimg">
      <div class="bottomBox">
        <div class="left">
          <div class="fun" @click="order(3)">
            <span class="iconfont icon-sannianzhibao"></span>
            我的质保
            <van-icon class="icon" :badge="zhibaoNums" v-if="zhibaoNums>0"/>
          </div>
          <div class="fun" @click="order(1)">
            <span class="iconfont icon-gongju"></span>
            正在快修
            <van-icon class="icon" :badge="zhengzaiNum" v-if="zhengzaiNum>0"/>
          </div>
          <div class="fun" @click="order(2)">
            <span class="iconfont icon-Frame18"></span>
            快修记录
          </div>
        </div>
        <div class="rightBtn" :class="haveOrder?'disabled':''" @click="submit">
          {{ haveOrder ? '预约成功 待勘察' : '免费预约勘察' }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {

}

.area {
  padding-bottom: 100px;
}

.topimg {
  position: absolute;
  z-index: 1;
}

.service_top_header {
  width: 100%;
}

.topimg, .bottomimg {

  width: 100%;
  object-fit: cover;
  display: block;
}

.centerBody {
  margin-top: 173px;
  position: relative;
  z-index: 45;
  background: linear-gradient(180deg, #feac84 0%, #feac8480 15.22%, #ffffff00 100%);

  .bodyinner {
    border-radius: 10px;
    background: #ffffff;
    margin: 0px 16px;
    padding: 10px 14px;
    text-align: left;

    .title {
      color: #1b1b1b;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 2px 0;
    }

    .text {
      color: #999999;
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
    }

    .one_line {
      //margin: 10px 0;
      border-radius: 10px;
      background: #f9f9f9;
      //padding: 10px 13px;
      .address {
        margin-top: 4px;
        color: #1b1b1b;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }

    .line {
      display: flex;
      justify-content: space-between;

      .label {
        color: #1b1b1b;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        span {
          color: #fc223b;
        }
      }

      .right {
        width: 80%;
        display: flex;
        justify-content: space-between;

        .value {
          flex: 1;
          text-align: right;
          font-size: 14px;
        }

        .iconfont {
          font-size: 24px;
          color: #999999;
          display: inline-block;
        }

        .input {
          flex: 1;
          width: 0;
          font-size: 14px;
          line-height: 20px;
          text-align: right;
          border: none;
          background-color: transparent;
        }
      }

      .placeholder {
        color: #999999;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }

    .double_line {
      margin: 10px 0;
      border-radius: 10px;
      background: #f9f9f9;
      padding: 10px 13px;

      .line:nth-of-type(1) {
        //margin-bottom: 10px;
        //padding-bottom: 10px;
        //border-bottom: 1px solid #99999926;
      }

      .one_line {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #99999926;
      }
    }
  }
}

.bottomBox {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  padding: 8px 0;
  z-index: 20;
  text-align: left;
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;
    margin-left: 16px;

    .fun {
      margin: 0 15px 0 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #666666;

      .iconfont {
        font-size: 24px;
      }

    }
  }

  .rightBtn {
    margin-right: 16px;
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 24px;
    border-radius: 49px;
    background: #f96422;
    color: #fff;
  }
}

.itemBox {
  padding: 10px 0 10px 16px;
  text-align: left;
  height: 500px;
  text-align: left;

  .button {
    color: #fff;
    background-color: #FC223B;
    text-align: center;
    padding: 14px;
    border-radius: 10px 10px 10px 10px;
    margin-right: 16px;
  }

  .title {
    margin-top: 6px;
    font-size: 18px;
    color: #1a1a1a;
    margin-bottom: 16px;
  }


  .itemBody {
    // display: flex;
    height: 320px;
    overflow: scroll;
    margin-bottom: 20px;
    margin-top: 10px;
    border-top: 1px solid #F4F4F4;

    .li {
      display: flex;
      justify-content: space-between;
      padding: 15px 16px 15px 0;
      border-bottom: 1px solid rgba(153, 153, 153, 0.15);;

      .name {
        font-size: 16px;
        color: #1b1b1b !important;
      }
    }
  }
}

.disabled {
  background: #c7c7c7 !important;
}

.img_box {
  margin-top: 10px;

  .title {
    color: var(--999999, #999999) !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 24px !important;
  }

  .img_list {
    margin-top: 4px;
    display: flex;

    .li {
      width: 65px;
      height: 65px;
      margin-right: 5px;
      position: relative;
      border-radius: 10px;

      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      .icon {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(20%, -50%);
        font-size: 20px;
      }
    }

    .addImg {
      border: 1px dashed #999999;
      border-radius: 10px;
      position: relative;

    }

    .addImg:after,
    .addImg:before {
      position: absolute;
      content: '';
      display: block;
      width: 30%;
      height: 2px;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }

    .addImg:before {
      width: 2px;
      height: 30%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }
  }

}

</style>