<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import Loding from "@/components/common/loding/loding.vue";
import {Alipay_h5_pay, materialspay, orderPay, vip_pay} from "@/utils/api";
import {Toast} from "vant";
import Bus from "@/utils/Bus";

export default {
  name: "pay",
  components: {Loding, navigationBar},
  data() {
    return {
      obj: {},
      backStep: 1,
      price: 0,
      radio: 1,
      loding: false,
      btnFlaf: true,
      pageStatus: 1,
      completeOrder: {},
      nowTime: '',
      paytype:'weixin',/*余额支付yue;积分支付'integral';线下支付'offline';支付宝'alipay';现金支付'cash';*/
      submitType:1,//1 正常  2 购买vip

    }
  },
  mounted() {
    if (this.$route.params.data) {
      this.obj = this.$route.params.data
      this.backStep = this.$route.params.backStep
      this.type = this.$route.params.type
      let price = 0
      price = this.obj.total_price
      this.price = price
      this.obj.handTime = this.handleTime(this.obj.ctime)
    }
    if (this.$route.query.data) {
      this.obj = JSON.parse(this.$route.query.data)
      this.backStep = Number(this.$route.query.backStep)
      this.type = this.$route.query.type
      // this.price = typeof this.obj.goods_amount =='string'?(Number(this.obj.goods_amount)+Number(this.obj.sender_amount?this.obj.sender_amount:0)).toFixed(2):Number(this.obj.goods_amount.toFixed(2)+Number(this.obj.sender_amount?this.obj.sender_amount:0)).toFixed(2)
      let price = 0
      // if (this.obj.status_text) {
      //   price = typeof this.obj.order_amount == 'string' ? (Number(this.obj.order_amount) + Number(this.obj.sender_amount ? this.obj.sender_amount : 0)).toFixed(2) : Number(this.obj.order_amount.toFixed(2) + Number(this.obj.sender_amount ? this.obj.sender_amount : 0)).toFixed(2)
      // } else {
      //   price = typeof this.obj.order_amount == 'string' ? (Number(this.obj.order_amount)).toFixed(2) : Number(this.obj.order_amount.toFixed(2)).toFixed(2)
      // }
      price = this.obj.total_price || this.obj.pay_price
      this.price = price
      this.obj.handTime = this.handleTime(this.obj.ctime)
    }
    if(this.$route.query.retutn_data){
      let obj = JSON.parse(this.$route.query.retutn_data)
      console.log(this.$route.query)
      this.backStep = obj.backStep
      this.price = obj.money
      this.completeOrder.order_id = obj.order_id
      this.pageStatus = 2
      this.obj.handTime = this.handleTime(obj.ctime.ctime)
      this.nowTime = this.$route.query.timestamp
    }
    if(this.$route.query.card_data){
      let obj = JSON.parse(this.$route.query.card_data)
      this.price = obj.price - obj.subscript_price + (obj.freight?obj.freight:0.00)
      this.obj  = obj
    }
    if(this.$route.query.vip_data){
      let obj = JSON.parse(this.$route.query.vip_data)
      this.price = obj.pre_price
      this.obj = obj
      this.submitType = 2

    }
    console.log(this.obj)
    console.log(this.$store.state.client_type)
    this.backStep = Number(this.$route.query.backStep)
    // getNowTime().then(res => {
    //   this.servertime = res.data.data
    //   // this.nowTime = this.handleTime(this.servertime)
    // })
    // this.getOrderDetail()
  },
  methods: {
    payBtn() {
      /*
      * radio1 微信支付   当前没有微信支付 暂时不开放
      * */
      console.log(this.obj)
      if(this.radio == 1){
        Toast('请选择付款方式')
        return;
      }
      if(this.obj.result.order_id == 2){
        Toast('请选择要支付的订单')
        return;
      }
      if(this.paytype == 'yue'&&parseFloat(this.$store.state.user.now_money)<parseFloat(this.price)){
        Toast('余额不足')
        return;
      }


      this.loding = true
      if (!this.btnFlaf) return;
      // this.btnFlaf = false
      let obj = {
        from: "weixinh5",
        paytype: this.paytype,
        quitUrl: "2",
        uni: this.obj.result.order_id,
      }

      if(this.submitType == 1){
        orderPay(obj).then(async res=>{
          this.loding = false
          if(res.data.status == 200){
            // const div = document.createElement('div');
            // div.innerHTML = (res.data.data.data);  //res.data是返回的表单
            // document.body.appendChild(div);
            // document.forms.alipaysubmit.submit();
            this.pageStatus = 2
            Bus.$emit("updataUserInfo", '1');

            Toast(res.data.msg)
          }else{
            Toast.clear()
            Toast(res.data.msg)
          }
        })
      }
      if(this.submitType == 2){
        console.log(obj)
        vip_pay(obj.uni,obj.paytype,obj.from,obj.quitUrl).then(res=>{
          this.loding = false
          if(res.data.status == 200){
            // const div = document.createElement('div');
            // div.innerHTML = (res.data.data.data);  //res.data是返回的表单
            // document.body.appendChild(div);
            // document.forms.alipaysubmit.submit();
            this.pageStatus = 2
            Bus.$emit("updataUserInfo", '1');
            Toast(res.data.msg)
          }else{
            Toast.clear()
            Toast(res.data.msg)
          }
        })
      }


      console.log('支付')
      this.loding = false
      // if (this.radio == 2) {
      //   this.balancepay()
      // }
      // if (this.radio == 3) {
      //   this.Alipay()
      // }
    },
    balancepay() {
      let params = {
        trade_type: 'JSAPI'
      }
      materialspay(this.$store.state.token, this.obj.order_id, 1, 'balancepay', JSON.stringify(params)).then(async (res) => {
        this.loding = false
        if (res.data.status) {
          console.log(res.data.data)
          // const {data} = await getNowTime()
          this.servertime = ((new Date()).getTime())/1000
          this.completeOrder = res.data.data
          this.nowTime = this.handleTime(this.servertime)
          console.log(this.nowTime, this.servertime)
          this.pageStatus = 2
          Toast(res.data.msg)
        } else {
          Toast(res.data.msg)
        }
      })
    },
    Alipay(){
      let params = {
        "trade_type":"H5",
        "os":"android"
      }
      Alipay_h5_pay(JSON.stringify(params),this.$store.state.token,this.obj.order_id).then(res=>{
        this.loding = false
        if(res.data.status == true){
          const div = document.createElement('div');
          div.innerHTML = (res.data.data.data);  //res.data是返回的表单
          document.body.appendChild(div);
          document.forms.alipaysubmit.submit();
          // setTimeout(()=>{
          // },300)
          // this.getOrderDetail()
        }else{
          Toast.clear()
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    handleTime(e) {
      if (typeof e == 'number') {
        let data = new Date(e * 1000);
        let y = data.getFullYear()
        let m = data.getMonth() + 1
        let d = data.getDate()
        let h = data.getHours()
        let min = data.getMinutes()
        let s = data.getSeconds()
        m = m < 10 ? '0' + m : m
        d = d < 10 ? '0' + d : d
        h = h < 10 ? '0' + h : h
        min = min < 10 ? '0' + min : min
        s = s < 10 ? '0' + s : s
        return y + '-' + m + '-' + d + ' ' + h + ':' + min + ':' + s
      } else if (typeof e == 'string') {
        return e
      }
    },
    back() {
      console.log('返回',this.backStep)
      this.$router.go(-this.backStep)
    },


    //
    //
    //
    // // 轮询结果
    // getOrderDetail() {
    //   //轮询方法,因为支付是跳转到第三方支付宝,我们无法获知用户是否支付成功,或者用户支付成功后是否跳转回来。轮询方法,在一定时间内
    //   clearTimeout(this.timer);
    //   this.timer = setTimeout(() => {
    //     let initTime = +new Date();
    //     let loop = () => {
    //
    //       getOrderindex(this.$store.state.token,this.obj.order_id).then((res) => {
    //         console.log(res)
    //         if (res.code == 20000 && res.data && res.data.payStatus == 30) {
    //           //支付成功的相关操作
    //         } else {
    //           let now = +new Date();
    //           if (now - initTime < 45000) {
    //             loop();
    //           } else {
    //             // 超时按照失败处理
    //             //支付失败的结果
    //           }
    //         }
    //       });
    //
    //     };
    //     loop();
    //   }, 500);
    // },

  },
  watch:{
    radio(n){
      console.log(n)
      if(n == 2){
        this.paytype ='yue'
      }
      if(n == 3){
        this.paytype ='alipay'
      }
    },
  },
  computed:{

  },
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'支付'" class="nivaBar" :class="pageStatus==2?'okNav':''"
                     :backStep="backStep"></navigationBar>
      <div v-if="pageStatus==1">
        <div class="price">
          <span class="unit">￥</span>
          {{ price }}
        </div>
        <div class="card">
          <div class="title">
            支付方式
          </div>
          <div class="payFunctionInnerBox">
            <van-radio-group v-model="radio" class="groupRadio">
              <van-cell-group>
<!--                <van-cell title="单选框 1" clickable @click="radio = 1">-->
<!--                  <template #title>-->
<!--                    <img src="../../../../public/image/wechart.png" alt="" class="image">-->
<!--                    <span class="cell_text">微信支付</span>-->
<!--                  </template>-->
<!--                  <template #right-icon>-->
<!--                    <van-radio :name="1"/>-->
<!--                  </template>-->
<!--                </van-cell>-->
                <van-cell title="单选框 2" clickable @click="radio = 2">
                  <template #title>
                    <img src="../../../../public/image/transfer.png" alt="" class="image">
                    <span class="cell_text">余额支付</span>
                  </template>
                  <template #right-icon>
                    <van-radio :name="2"/>
                  </template>
                </van-cell>
                <van-cell title="单选框 3" clickable @click="radio = 3">
                  <template #title>
                    <img src="../../../../public/image/transfer.png" alt="" class="image">
                    <span class="cell_text">支付宝</span>
                  </template>
                  <template #right-icon>
                    <van-radio :name="3"/>
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
          </div>

        </div>
        <div class="tarendaifu card">
          <div class="left">
            找人代付
          </div>
          <div class="right"><span class="iconfont icon-fenxiang"></span></div>
        </div>
        <div class="button" @click="payBtn">确认交易</div>
      </div>
      <div class="" v-if="pageStatus==2">
        <div class="background"></div>
        <div class="inner">
          <div class="successCenter">
            <div class="left">
              <van-icon name="success" class="icon" color="#398ae9"/>
            </div>
            <div class="right">
              <div class="one">支付成功！</div>
              <div class="two">感谢您的支持</div>
            </div>
          </div>
        </div>
<!--        <div class="successBottom">-->
<!--          <div class="line">-->
<!--            <div class="card">-->
<!--              <div class="orderId linee">-->
<!--                <div class="left">订单编号：</div>-->
<!--                <div class="right">{{ completeOrder.order_id }}</div>-->
<!--              </div>-->
<!--              <div class="payType linee">-->
<!--                <div class="left">支付方式：</div>-->
<!--                <div class="right">-->
<!--                  <div class="payText">{{ radio == 1 ? '微信支付' : radio==2?'余额支付':radio==3?'支付宝支付':'' }}</div>-->
<!--                  <div class="payDetails">已从{{ radio == 1 ? '微信支付' : radio==2?'余额支付':radio==3?'支付宝支付':'' }}付款￥{{ price }}</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="payTime linee">-->
<!--                <div class="left">下单时间：</div>-->
<!--                <div class="right">{{ obj.handTime }}</div>-->
<!--              </div>-->
<!--              <div class="completeTime linee">-->
<!--                <div class="left">成交时间：</div>-->
<!--                <div class="right">{{ nowTime }}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="buttonBox">
          <div class="back" @click="back">返回</div>
        </div>
      </div>
    </div>
    <loding v-if="loding"></loding>
  </div>
</template>

<style scoped lang="scss">
.nivaBar {
  position: relative;
  z-index: 10;

}

.container {
  background-color: #F4F6FA;
  height: 100vh;
}

.okNav {
  color: #fff;
}

.price {
  line-height: 100px;
  font-size: 32px;

  .unit {
    font-size: 18px;
  }
}

.card {
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  margin: 0 16px;
  padding: 10px 10px 0 10px;

  .title {
    font-weight: 400;
    font-size: 14px;
    color: #1B1B1B;
    line-height: 24px;
    //border-bottom: 1px solid rgba(153,153,153,0.15);
    padding-bottom: 10px;
  }

  .payFunctionInnerBox {
    text-align: left;
  }
}

.groupRadio {

  .image {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .cell_text {
    vertical-align: middle;
    font-weight: 400;
    font-size: 14px;
    color: #1B1B1B;
    line-height: 24px;
  }
}

.tarendaifu {
  margin-top: 10px;
  display: flex;
  padding-bottom: 10px;
  justify-content: space-between;

  .left {
    font-weight: 400;
    font-size: 14px;
    color: #1B1B1B;
    line-height: 24px;
  }

  .iconfont {
    font-size: 20px;
    color: #666666;
  }
}

.button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  background-color: #FC223B;
  transform: translate(-50%, -50%);
  color: #fff;
  border-radius: 10px;
  padding: 13px;
  width: 80%;
}

.background {
  background: #0085FF;
  border-radius: 0px 0px 0px 0px;
  position: fixed;
  top: 0;
  left: 0;
  height: 217px;
  width: 100%;
  z-index: 1;
}

.inner {
  position: relative;
  z-index: 2;

}

.successCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 35px 0;

  .left {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background-color: #fff;
    margin-right: 10px;
    text-align: center;
    line-height: 54px;
    font-size: 35px;
  }

  .right {
    text-align: left;

    .one {
      font-weight: 500;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 24px;
      text-align: left;
    }

    .two {
      font-weight: 400;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 24px;
      letter-spacing: 1px;
      text-align: justified;
    }
  }
}

.successBottom {
  position: relative;
  z-index: 2;

  .line {
    background: #005CC7;
    border-radius: 10px 10px 10px 10px;
    width: 80%;
    height: 10px;
    //transform: translate(-50%,-50%);
    margin: 0 auto;
    position: relative;

    .card {
      background-color: #fff;
      width: 85%;
      //height: 100px;
      top: 40%;
      position: absolute;
      transform: translate(-50%, -0%);
      left: 50%;
      padding: 10px 15px;
      margin: 0;
    }

    .linee {
      display: flex;
      color: #666666;
      font-size: 14px;
      margin-top: 10px;

      .left {

      }

      .right {
        text-align: left;
      }
    }

    .linee:nth-of-type(1) {
      margin-top: 0px;
    }
  }
}

.buttonBox {
  margin-top: 200px;
  display: flex;
  justify-content: center;

  .back {
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #0085FF;
    width: 110px;
    font-weight: 400;
    font-size: 14px;
    color: #0085FF;
    line-height: 24px;
    padding: 10px 0;
  }
}
</style>
<style>
.van-radio__icon--checked {
  .van-icon {
    border-color: #FC223B !important;
    background-color: #FC223B !important;
  }
}
</style>
