<script>
import {getSeekerDetail} from "@/api/recruitment";

export default {
  name: "idle_tabbar",
  components: {},
  props: ["active"],

  data() {
    return {
      tabbarList: [
        {
          icon: "icon-Frame-11",
          text: "企业招聘",
          chooseIcon: "icon-Frame1",
          src: "/invite_home",
          badge: 0,
        },
        {
          icon: "icon-Frame-19",
          text: "店铺招聘",
          chooseIcon: "icon-Frame28",
          src: "/store_recruitment",
          badge: 0,
        },
        {
          icon: "icon-Frame5",
          text: "发布",
          chooseIcon: "icon-Frame4",
          src: "",
          badge: 0,
        },
        {
          icon: "icon-Frame5",
          text: "消息",
          chooseIcon: "icon-Frame4",
          src: "/invite_message",
          badge: 0,
        },
        {
          icon: "icon-Frame7",
          text: "我的",
          chooseIcon: "icon-Frame6",
          src: "/invite_mine",
          badge: 0,
        },

      ],
      tabbarList2:[
        {
          icon: "icon-Frame-11",
          text: "人才",
          chooseIcon: "icon-Frame1",
          src: "/invite_talents",
          badge: 0,
        },
        {
          icon: "icon-Frame-11",
          text: "发布",
          chooseIcon: "icon-Frame1",
          src: "/invite_add_position",
          badge: 0,
        },
        {
          icon: "icon-Frame7",
          text: "我的",
          chooseIcon: "icon-Frame6",
          src: "/invite_mine",
          badge: 0,
        },
      ],
      chooseTab: this.active,
      job_hunter:false,
      recruiter:false,

      popupshow:false,
    };
  },
  methods: {
    switchTab(index) {
      if (this.chooseTab == index) return;
      if (this.tabbarList[index].src) {
        this.chooseTab = index;
      }
    },
    goupdata() {
      console.log("下载");
    },
    beforeChange(index){
      if(this.$store.state.user.job_standing==1){
        if (index === 2) {
          if(!this.job_hunter&&!this.recruiter){
            this.popupshow = true
          }else{
            if(this.job_hunter){
              this.$router.push('/online_resume');
            }
            if(this.recruiter){
              // this.$router.push('/online_resume');
            }
          }
          return false;
        }else{
          return true
        }
      }else{
        if (index === 1){
          // return false
          if(this.$store.state.nowCompany.id){
            return true
          }else{
            this.popupshow = true
            return false
          }

        }else{
          return true
        }
      }

    },
  },
  created() {},
  async mounted() {
    //job_standing  1牛人 （求职者）   2企业 （招聘者）
    if(this.$store.state.user.job_standing!=2){
      // 1牛人 （求职者）
      let first = await getSeekerDetail(this.$store.state.token,this.$store.state.user.id)
      console.log(first)
      if(first.data.data.id&&!this.job_hunter){
        this.job_hunter = true
      }
      this.recruiter = false
    }else{
      // 2企业 （招聘者）
      this.job_hunter = false
      this.recruiter = true
    }

  },
}
</script>

!<template>
  <div>
    <van-popup v-model="popupshow" round position="bottom">
      <div class="popup">
        <div class="cardLi first">
          <div class="left">
            <div class="title">我要求职 <span class="iconfont icon-Frame-4"></span></div>
            <div class="text">前往填写简历</div>
          </div>
          <img :src="$store.state.imageHost+'/img/qiuzhi.png'" alt="" class="img">
        </div>
        <div class="cardLi second">
          <div class="left">
            <div class="title">我要招聘 <span class="iconfont icon-Frame-4"></span></div>
            <div class="text">前往发布岗位</div>
          </div>
          <img :src="$store.state.imageHost+'/img/zhaopin.png'" alt="" class="img">
        </div>
        <div class="close" @click="popupshow=false">
          <div class="innn">
            <span class="iconfont icon-Frame-5"></span>
          </div>
        </div>
      </div>
    </van-popup>

<!--    this.$store.state.user.job_standing!=2?tabbarList:tabbarList2-->
    <router-view />
    <div class="outBox"  ref="homeTabbar" >
      <van-tabbar replace  v-model="chooseTab" active-color="#FC223B" inactive-color="#1B1B1B" class="tabbar" router :before-change="beforeChange" v-if="$store.state.user.job_standing!=2">
        <van-tabbar-item v-for="(item,index) in tabbarList" :key="index" :badge="item.badge>0?item.badge:''" :to="item.src">
          <span class="text" v-if="index!=2">{{ item.text }}</span>
          <div  v-else class="centerOut">
            <div class="center">
              <div class="centeriNN" >
                <!--              <img src="https://picture.bjbjkj.top/image/fabuText.png" alt="" class="center_img">-->
              </div>
            </div>
              <span class="text">{{ item.text }}{{ job_hunter?'简历':'' }}{{recruiter?'岗位':''}}</span>
          </div>
          <template #icon="props">
            <svg class="icon" aria-hidden="true" v-if="index!=2&&(props.active&&index==1)">
              <use :xlink:href="props.active?'#'+item.chooseIcon:'#'+item.icon" class="iconfont"></use>
            </svg>
            <span class="iconfont" :class="props.active?item.chooseIcon:item.icon"  v-if="index!=2&&(props.active&&index!=1)||(!props.active&&index!=2)"></span>
          </template>
        </van-tabbar-item>
      </van-tabbar>
      <van-tabbar replace  v-model="chooseTab" active-color="#FC223B" inactive-color="#1B1B1B" class="tabbar" router :before-change="beforeChange" v-else>
        <van-tabbar-item v-for="(item,index) in tabbarList2" :key="index" :badge="item.badge>0?item.badge:''" :to="item.src">
          <span class="text" v-if="index!=1">{{ item.text }}</span>
          <div class="centerOut" v-else>
            <div class="center" >
              <div class="centeriNN" >
                <!--              <img src="https://picture.bjbjkj.top/image/fabuText.png" alt="" class="center_img">-->
              </div>
            </div>
            <span class="text">{{ item.text }}{{ job_hunter?'简历':'' }}{{recruiter?'岗位':''}}</span>
          </div>

          <template #icon="props">
            <span class="iconfont" :class="props.active?item.chooseIcon:item.icon"  v-if="index!=1&&(props.active&&index!=1)||(!props.active&&index!=1)"></span>
          </template>
        </van-tabbar-item>
      </van-tabbar>

    </div>
    <div class=""></div>

    <!-- <div class="tabbar">
      <div class="innerBox">
        <div class="home" v-for="(item, index) in tabbarList" :key="index" :class="index == chooseTab ? 'chooseTab' : ''"
          @click="switchTab(index)">
          <span class="iconfont" :class="item.icon"></span>
          <span class="text">{{ item.text }}</span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<style scoped lang="scss">
.van-tabbar{
  height: 62px;
}
.popup{
  padding: 17px 16px;
  .cardLi{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    text-align: left;
    border-radius: 16px;
    .img{
      width: 56px;
      height: 56px;
    }
    .left{
      .title{
        color: #1b1b1b;
        font-weight: 500;
        font-size: 24px;
        line-height: 34px;
        vertical-align: middle;
        .iconfont{
          font-size: 32px;
          vertical-align: middle;
        }
      }
      .text{
        color: #1b1b1b;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

  }
  .first{
    background: #fee610;
  }
  .second{
    background: #0393f5;
    .title,.text{
      color: #fff!important;
    }
  }

  .close{
    margin: 20px auto;
    width: 52px;
    height: 52px;
    border-radius: 41px;
    background: #ffffff;
    box-shadow: 0px 0px 9.4px #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: center;
    .iconfont{
      font-size: 32px;
      color:#1B1B1B;
    }
    .innn{
      width: 80%;
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f7f7f7;
      border-radius: 41px;
    }
  }
}
.icon{
  width: .8em;
  height: .8em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.centerOut{
  margin-bottom: 38px;
}
.center{
  padding: 10px;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  background: #FFFFFF;
  box-shadow: 0px -1px 2px 0px rgba(255,127,142,0.1);
  .centeriNN{
    border-radius: 50%;
    width: 38px;
    height: 38px;
    background-color: #FC223B;
    line-height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .center_img{
      width: 36px;
      object-fit: cover;
    }
  }
  .centeriNN::after,.centeriNN::before{
    display: block;
    content: '';
    position: absolute;
    background-color: #fff;
    border-radius: 5px;
  }
  .centeriNN::after{
    width: 3.2%;
    height: 50%;
  }
  .centeriNN::before{
    width: 50%;
    height: 3.2%;
  }

}
.text{
  font-size: 10px;
}
.iconfont{
  font-size: 1.2rem;
}
</style>