<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {integral_list, list_top} from "@/api/point";
import {Toast} from "vant";

export default {
  name: "particulars",
  components: {navigationBar},
  data(){
    return{
      headLi:[
        {name:'分享所得',nums:0,},
        {name:'消费所得',nums:0,},
        {name:'收入',nums:0,},
        {name:'支出',nums:0,},
        {name:'我的积分',nums:0,},
      ],
      tabs:[
        {
          name: '全部',
          value: 0,
          page: 1,
          limit: 10,
          list2: [],
          showIndex: 0,
          list: [],
          maxlength: 1000,
          loading: false,
          noMore: false,
        },
        {
          name: '收入',
          value: 1,
          page: 1,
          limit: 10,
          list2: [],
          showIndex: 0,
          list: [],
          maxlength: 1000,
          loading: false,
          noMore: false,
        },
        {
          name: '支付',
          value: 2,
          page: 1,
          limit: 10,
          list2: [],
          showIndex: 0,
          list: [],
          maxlength: 1000,
          loading: false,
          noMore: false,
        },
      ],
      tabActive:0,
    }
  },
  mounted() {
    this.getTopLi()
  },
  computed: {
    disabled() {
      return (this.tabActive != -1 ? this.tabs[this.tabActive].loading : true) || (this.tabActive != -1 ? this.tabs[this.tabActive].noMore : false)
    },
    noMore() {
      return this.tabs[this.tabActive].list.length >= this.tabs[this.tabActive >= 0 ? this.tabActive : 0].maxlength
    },

  },
  methods:{
    getTopLi(){
      list_top().then(res=>{
        if(res.data.status == 200){
          this.headLi[4].nums = res.data.data.sum
          this.headLi[2].nums = res.data.data.gain_sum
          this.headLi[3].nums = res.data.data.expend_sum
          this.headLi[1].nums = res.data.data.sale_sum
          this.headLi[0].nums = res.data.data.share_sum
        }
      })
    },
    getBodyLi(){
      integral_list()
    },
    tabsChange(){

    },
    tabsClick(name) {
      if (this.tabActive == name) return;
    },
    load() {
      this.tabs[this.tabActive].loading = true
      if (this.tabs[this.tabActive].noMore) {
        return
      }
      let page = this.tabs[this.tabActive].page
      let limit = this.tabs[this.tabActive].limit += 10
      integral_list(page,limit,this.tabs[this.tabActive].value).then(res=>{
        if(res.data.status == 200){
          console.log(res.data.data)
          let maxlength = limit
          let list = res.data.data.list
          this.tabs[this.tabActive].list = list
          if (maxlength >= res.data.data.count) {
            this.tabs[this.tabActive].noMore = true
            this.tabs[this.tabActive].loading = false
          }
          this.tabs[this.tabActive].loading = false
          this.tabs[this.tabActive].maxlength = maxlength
        }else{
          Toast(res.data.msg)
        }
      })
    },
  },
}
</script>

<template>
<div class="container" id="particulars">
  <div class="area">
    <navigationBar :title="'积分明细'" class="navigation"></navigationBar>
    <img :src="$store.state.imageHost+'/img/Group_4151@2x.png'" alt="" class="topImg">
    <div class="head">
      <div class="every" v-for="(item,index) in headLi" :key="index">
        <div class="name">{{item.name}}</div>
        <div class="nums" :class="index == headLi.length-1?'red':''">{{item.nums}}</div>
      </div>
    </div>

    <div class="body">
      <van-tabs v-model="tabActive" animated @change="tabsChange" @click="tabsClick" class="tabs">
        <van-tab
            v-for="(item, index) in tabs"
            :title="item.name"
            :key="index"
        >
          <template #title>{{ item.name }}</template>
                  <div class="ul" v-infinite-scroll="load"
                       :infinite-scroll-disabled="disabled" :infinite-scroll-immediate="false">
                    <div v-for="(itemc,indexcc) in tabs[index].list" :key="indexcc+'itemc'" class="li">
                      <div class="order-body"  v-if="itemc.number>0">
                        <div class="line">
                          <div class="line_head">
                            <div class="left">
                              {{itemc.title}}
                            </div>
                            <div class="right">
                              {{itemc.pm==0?'-':itemc.pm==1?'+':''}} {{itemc.number}}
                            </div>
                          </div>
                          <div class="line_time">
                              {{itemc.add_time}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <p v-if="tabs[index].loading">加载中...</p>
                    <p v-if="tabs[index].noMore">没有更多了</p>
                  </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.container{
  position: relative;
  height: 100vh;
  .navigation{
    background-color: transparent!important;
    position: relative;
    z-index: 2;
  }
  background: #f7f7f7;
}
.topImg{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.head{
  display: flex;
  border-radius: 10px;
  background: #ffffff;
  position: relative;
  z-index: 2;
  margin: 0 16px;
  padding:15px 16px;
  .every{
    flex: 1;
    max-width: 20%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .name{
      color: #999999;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    }
    .nums{
      width: 90%;
      color: #000000;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      margin-top: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .red{
      color: #fd2a39;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
  }
}
.body{
  background: #ffffff;
  margin: 10px 16px;
  border-radius: 10px;
  .tabs{
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 10px;
  }
}

.order-body{
  margin: 10px;
  border-bottom: 1px solid #f6f6f6;
  .line{
    padding-bottom: 10px;
    text-align: left;
    .line_head{
      display: flex;
      justify-content: space-between;
      color: #1b1b1b;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      .left{
        width: 70%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .right{
        width: 30%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: right;
        text-align: right;
      }
    }
    .line_time{
      color: #acacac;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-top: 5px;
    }

  }

}

</style>
<style>
#particulars .van-tabs__wrap{
  border-radius: 10px;
}

</style>