<script>
// import {getNowTime} from "@/utils/api";

import {cancel, cancelOrder} from "@/utils/api";
// import { cancelOrder} from "@/utils/api";
import {Dialog} from "vant";

export default {
  name: "order_li",
  props: {
    item: {
      type: [Object, Number],
    },
    tabActive: {
      type: [Number, String]
    },
    severTime: {
      type: [Number],
    }
  },
  watch: {
    // item(newVal){
    //   console.log(newVal)
    // },
    choosePoPo(n){
      console.log(n)
    },
  },
  data() {
    return {
      time: 30 * 60 * 60 * 1000,
      actions: [{text: '选项一'}, {text: '选项二'}, {text: '选项三'}],
      showPopover: false,
      choosePoPo: {},
    }
  },
  methods: {
    finish(e) {
      console.log(e.order_id,e)
      cancelOrder({id:e.order_id}).then(res => {
              console.log(res)
      })
      // cancel(e.order_id, this.$store.state.token).then(res => {
      //   console.log(res)
      // })
    },
    popoverselect(action, index) {
      console.log(action, index)
    },
    popoveropen(item) {
      this.choosePoPo = item
    },
    popoverclose() {
      this.choosePoPo = {}
    },
    buttonFun() {
      console.log('按钮')
    },
    checkOrderDetails(item) {
      console.log('查看详情')
      this.$router.push({
        path:'/order_details',
        query:{order_id:item.order_id},
      })
    },
    quxiaodingdan(item){
      Dialog.confirm({
        title: '温馨提示',
        message: '确认取消该订单',
      })
          .then(() => {
            cancel(item.order_id).then(() => {
              this.$emit('cancelOr',item)//触发transfer方法，this.user 为向父组件传递的数据
            })
          })
          .catch(() => {
            // on cancel
          });
    },
    shenqing(){},
    setOrder(){},
    gopay(item){
      if(item.ctime){
        if(item.ctime.indexOf('-') > 0){
          item.ctime = item.ctime.replace(/-/g, '/')
        }
        item.ctime = new Date(item.ctime).getTime()/1000
      }else{
        item.ctime = item.add_time
      }
      this.$router.push({
        path:"/pay",
        query:{data:JSON.stringify(item),backStep:1,type:"u_order_songsui"}
      })

    },
    again(){},
    evaluate(){},
  },
  mounted() {

  }
}
</script>

<template>
  <div class="item">
    <div class="top" @click.stop="checkOrderDetails(item)">
      <div class="leftTime">
        {{ item._add_time }}
      </div>
      <div class="rightStatus">
        <div class="remaining" v-if="item.paid==100">
<!--          <div class="left">{{ item._status._msg }}</div>-->
<!--          <div class="timeNums">-->
<!--            <van-count-down :time="item.atime" @finish="finish(item)">-->
<!--              <template #default="timeData">-->
<!--                <span class="block">{{ timeData.hours }}</span>-->
<!--                <span class="colon">:</span>-->
<!--                <span class="block">{{ timeData.minutes }}</span>-->
<!--                <span class="colon">:</span>-->
<!--                <span class="block">{{ timeData.seconds }}</span>-->
<!--              </template>-->
<!--            </van-count-down>-->
<!--          </div>-->
        </div>
        <div class="status" v-else>
          {{item._status._title}}
          <span v-if="item.refund.length">{{item.is_all_refund?'，退款中':'，部分退款中'}}</span>
          <!--          <div class="" v-if="tabActive==0">-->
<!--          <div v-if="item.status==1&&tabActive!=5">-->
<!--            <span class="text" v-if="item.status==1&&item.order_type!=6">-->
<!--              {{-->
<!--                item.ship_status == 0 ? '备货中...' :-->
<!--                    item.ship_status == 1 && item.order_type == 11 ? '待回收' :-->
<!--                        item.ship_status == 1 && item.order_type != 11 ? '商家发货中' :-->
<!--                            item.ship_status == 2 && item.order_type == 11 ? '取货中' :-->
<!--                                item.ship_status == 2 && item.order_type != 11 ? '商家已发货' :-->
<!--                                    item.confirm == 1 ? '已送达' :-->
<!--                                        item.is_comment == 1 ? '待评价' : '交易完成'-->
<!--              }}-->
<!--            </span>-->

<!--          </div>-->
<!--          <div v-if="item.status==2&&tabActive!=5">-->
<!--              <span class="text" v-if="item.pay_status==2">-->
<!--                {{ item.is_comment == 2 ? '已评价' : '已完成' }}-->
<!--              </span>-->
<!--            <span class="text" v-if="item.pay_status>=3">已完成</span>-->
<!--          </div>-->
<!--          <div v-if="item.status==3&&tabActive!=5">-->
<!--            已取消-->
<!--          </div>-->
<!--          <div v-if="(item.status==3||item.status==2||item.status==1)&&tabActive==5">-->
<!--            <span v-if="item.status==1">售后中</span>-->
<!--            <span v-if="item.status==2&&item.bill_refund.status==1">售后中</span>-->
<!--            <span v-if="item.status==2&&item.bill_refund.status==2">已退款</span>-->
<!--            <span v-if="item.status==2&&item.bill_refund.status==3">退款中</span>-->
<!--            <span v-if="item.status==2&&item.bill_refund.status==4">已拒绝</span>-->
<!--            <span v-if="item.status==2&&item.bill_reship.status<=2&&!item.bill_change&&!item.bill_refund">售后中</span>-->
<!--            <span v-if="item.status==2&&item.type==3&&item.bill_change.status<=2">售后中</span>-->
<!--            <span v-if="item.status==2&&item.type==3&&item.bill_change.status==3">已完成</span>-->
<!--            <span v-if="item.status==2&&item.bill_reship.status==3&&!item.bill_change&&!item.bill_refund">已完成</span>-->
<!--            <span v-if="item.status==3">已拒绝</span>-->
<!--          </div>-->
<!--          <span class="text" v-if="item.status==2"></span>-->
          <!--          </div>-->
          <!--          <div class="" v-if="tabActive==1">11</div>-->
          <!--          <div class="" v-if="tabActive==2">112</div>-->
          <!--          <div class="" v-if="tabActive==3">113</div>-->
          <!--          <div class="" v-if="tabActive==5">113</div>-->
        </div>
      </div>
    </div>
    <div class="centerBody" @click="checkOrderDetails(item)">
      <div class="scroll">
        <div class="more-goods" v-if="item.cartInfo.length>=2">
          <div class="li" v-for="(itemac,index) in item.cartInfo" :key="'item'+index">
            <div class="goods">
              <div class="imgBox">
                <img class="img" :src="itemac.productInfo.image" alt="">
              </div>
            </div>
          </div>

        </div>
        <div class="oneGoods" v-if="item.cartInfo.length==1">
          <div class="imgBox">
            <img class="img" :src="item.cartInfo[0].productInfo.image" alt="">
          </div>
          <div class="goodsInfo">
            <div class="leftGoods">
              <div class="name">{{ item.cartInfo[0].productInfo.store_name }}</div>
              <div class="num-price">
                <div class="sku">{{item.cartInfo[0].productInfo.attrInfo.suk}}</div>
                <!--              <div class="nums">x{{ item.cartInfo[0].cart_num }}</div>-->
                <!--              <div class="price">￥{{ item.cartInfo[0].price }}</div>-->
              </div>
            </div>
            <div class="right">
              <div class="base-price">￥{{item.pay_price}}</div>
              <div class="all-nums">共{{item.total_num}}件</div>
            </div>

          </div>
        </div>
        <div class="oneGoods" v-if="item.water_ticket&&item.type == 11">
          <div class="imgBox">
            <img class="img" :src="item.water_ticket.image" alt="">
          </div>
          <div class="goodsInfo">
            <div class="leftGoods">
              <div class="name">{{ item.water_ticket.goods_name }}</div>
              <div class="num-price">
                <div class="sku">{{item.water_ticket.remark}}</div>
                <!--              <div class="nums">x{{ item.cartInfo[0].cart_num }}</div>-->
                <!--              <div class="price">￥{{ item.cartInfo[0].price }}</div>-->
              </div>
            </div>
            <div class="right">
              <div class="all-nums">共{{item.total_num}}件</div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="orderTag flex">
      <div class="">
        <span class="tt">{{item._status._title}}</span>
        <span class="mm">{{item._status._msg}}</span>
      </div>
      <span class="iconfont icon-Frame-4"></span>
    </div>
    <div class="footer">
      <div class="price">
        实付款<span class="num">￥{{ item.pay_price }}</span>
      </div>
      <div class="btnBox" v-if="tabActive<5">
        <div class="left">
<!--          <van-popover-->
<!--              v-model="showPopover"-->
<!--              trigger="click"-->
<!--              :actions="actions"-->
<!--              placement="bottom-start"-->
<!--              @select="popoverselect"-->
<!--              @open="popoveropen(item)"-->
<!--              @close="popoverclose(item)"-->
<!--          >-->
<!--            <template #reference>-->
<!--              <span class="iconfont icon-Frame20"></span>-->
<!--            </template>-->
<!--          </van-popover>-->
        </div>
        <div class="right">
<!--          <div class="btn" @click.stop="buttonFun" v-if="item.pay_status==1&&item.status==1">申请退款</div>-->
          <div class="btn" v-if="item._status._type == 0 || item._status._type == 9" @click="quxiaodingdan(item)"
                  >取消订单
          </div>
<!--          <div class="btn"-->
<!--                  v-if="(item.pay_status==2||item.pay_status==4)&&item.status==1&&(item.order_type==1||item.order_type==5||item.order_type==12)&&tabActive!=5&&item.shenqingFlag"-->
<!--                  @click="shenqing" >申请退款-->
<!--          </div>-->
<!--          <div class="btn"-->
<!--                  v-if="(item.pay_status==2||item.pay_status==4)&&(item.status==2&&item.confirm==2)&&(item.order_type==1||item.order_type==5||item.order_type==12)&&tabActive!=5"-->
<!--                  @click="shenqing" >申请售后-->
<!--          </div>-->
<!--          <div class="btn" v-if="item.status==1&&item.ship_status<=2&&tabActive!=5" @click="setOrder"-->
<!--                  >修改订单-->
<!--          </div>-->
          <div class="btn last" v-if="item._status._type == 0" @click="gopay(item)" >
            去付款
          </div>
<!--          <div class="btn " v-if="(item.status!=1||item.confirm==2)&&item.order_type!=11&&item.order_type!=3"-->
<!--                  @click="again" >再来一单-->
<!--          </div>-->
          <div class="btn last" v-if="item._status._type == 3"
                  @click="evaluate" >评价
          </div>
<!--          <div class="btn last"-->
<!--                  v-if="item.status==2&&(tabActive==5||tabActive==0)&&item.ship_status==3&&item.is_comment==2"-->
<!--                  @click="evaluate" >已评价-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.flex{
  display: flex;
}
.more-goods {
  text-align: left;
}
.orderTag{
  background-color: rgba(245,245,245);
  padding: 10px 11px;
  margin-top: 14px;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  justify-content: space-between;
  .tt{
    color: rgba(51,51,51);
    font-size: 14px;
  }
  .mm{
    padding-left: 11px;
    font-size: 14px;
    color: rgba(153,153,153);
  }
}

.item {
  margin: 20px 0;
  background: #FFFFFF;
  box-shadow: 0 0 2px 0 rgba(102, 102, 102, 0.25);
  border-radius: 10px;
  padding: 10px;

  .top {
    display: flex;
    justify-content: space-between;

    .leftTime {
      font-weight: 400;
      font-size: 14px;
      color: #1B1B1B;
      line-height: 24px;
      text-align: left;
      max-width: 65%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .rightStatus {
      .status {
        font-weight: 400;
        font-size: 14px;
        color: #1B1B1B;
        line-height: 24px;
        text-align: left;
      }

      .remaining {
        display: flex;
        align-items: center;
        background: rgba(249, 100, 34, 0.15);
        border-radius: 8px 8px 8px 8px;
        padding-right: 10px;

        .left {
          background: #FC223B;
          border-radius: 8px 8px 8px 8px;
          font-weight: 500;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 24px;
          text-align: center;
          padding: 0 5px;
          margin-right: 10px;

          .a {

          }
        }

        .timeNums {
          .block, .colon {
            font-weight: 400;
            font-size: 14px;
            color: #FC223B;
            line-height: 24px;
          }
        }
      }
    }
  }

  .centerBody {
    margin-top: 10px;
    //background: red;
    width: 100%;
    overflow: scroll;
    overflow-x: scroll;

    .scroll {
      white-space: nowrap;

      .li {
        display: inline-block;
        margin-right: 15px;
      }

      .li:nth-last-of-type(1) {
        margin-right: 0;
      }

      .goods {
        .imgBox {
          width: 82px;
          height: 82px;
          border-radius: 10px;
          background: #F9FCFF;
          overflow: hidden;

          .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            //width: 10px;
          }
        }

      }

      .oneGoods {
        display: flex;
        //justify-content: space-between;
        .imgBox {
          width: 82px;
          height: 82px;
          min-width: 82px;
          min-height: 82px;
          overflow: hidden;
          background: #F9FCFF;
          border-radius: 10px;

          .img {
            width: 100%;
            height: 100%;

            object-fit: cover;
          }
        }

        .goodsInfo {
          flex: 1;
          text-align: left;
          padding-left: 10px;
          display: flex;
          //flex-direction: column;
          justify-content: space-between;
          .leftGoods{
            //width: 70%;
            white-space: wrap;
            .name{
              padding-right: 30px;
              display: -webkit-box;
              /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
              -webkit-box-orient:vertical;
              /*要显示的行数*/
              -webkit-line-clamp:2;
              /* 溢出部分隐藏 */
              overflow:hidden;
            }
          }
          .right{
            text-align: right;
            .base-price{

            }
            .all-nums{
              color: rgba(153,153,153);
              font-size: 13px;

            }
          }

          .num-price {
            .sku{
              font-size: 13px;
              line-height: 19px;
              color: rgba(153,153,153);
            }
            display: flex;
            justify-content: space-between;

            .nums {
              font-weight: 400;
              font-size: 14px;
              color: #666666;
              line-height: 24px;
            }

            .price {
              font-weight: 500;
              font-size: 16px;
              color: #1B1B1B;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  .centerBody::-webkit-scrollbar {
    display: none;
  }

  .footer {
    border-top: 1px solid rgba(153, 153, 153, 0.15);;
    margin-top: 10px;
    padding-top: 10px;
    text-align: right;

    .price {
      display: inline-block;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 24px;

      .num {
        font-weight: 500;
        font-size: 14px;
        color: #1B1B1B;
        line-height: 24px;
      }
    }

    .btnBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      cursor: pointer;

      .left {
        width: 12%;
        text-align: left;

        .iconfont {
          color: #999999;
        }
      }

      .right {
        display: flex;
        .btn {
          background: #FC223B;
          border-radius: 36px;
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 24px;
          text-align: center;
          padding: 5px 17px;
          margin-left: 10px;
          max-width: 100px;
        }
      }
    }
  }
}
</style>