<template>
  <div class="bodybasd">
    <div class="imgBox">
      <img
        :style="'width:'+width+'px;height:'+width+'px;object-fit:cover;border-radius:5px;'"
        :src="item2.productInfo.image"
        alt=""
      />
    </div>
    <div class="goodsInfo">
<!--      <div class="brand">-->
<!--        <div class="brandBox">品牌</div>-->
<!--        {{brand.name}}-->
<!--      </div>-->
      <div class="name">{{name}}</div>
      <div class="size">规格：{{size}}</div>
<!--      <div class="weight">重量：{{weight}}/kg</div>-->
      <div class="priceAndAdd">
        <div>
          <div class="prcie">
            <div class="unit">￥</div>
            <div class="yuan">{{item2.productInfo.yuan}}.</div>{{item2.productInfo.mao}}
          </div>
        </div>
        <div class="rightAdd">
          <div
            class="unadd"
            v-if="item2.cart_num"
            @click="unAddCar(item2)"
          ></div>
          <div class="input" v-if="item2.cart_num">
            {{ item2.cart_num }}
          </div>
          <div class="add" @click="addCar(item2)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {_throttle} from "@/utils/tool";
import { set_cart_num} from "@/utils/api";
import {Toast} from "vant";

export default {
  components: {},
  props: {
    brand:[String,Object],
    name:[String],
    size:[String],
    weight:[String],
    width:[Number],
    item2:[Object],
  },
  data() {
    return {
      
    };
  },
  watch: {

  },
  computed: {},
  methods: {
    addCar(item2) {
      this.jiekou(item2,1)
    },
    unAddCar(item2) {
      this.jiekou(item2,0)
    },
    jiekou:_throttle(function (item2,type){
      console.log(type)
      set_cart_num(item2.product_id, 1, item2.product_attr_unique, type, item2.cat).then(res => {
        if(res.data.status == 200) {
          // item2.cart_num = nums
          if(type == 0){
            item2.cart_num-=1
          }
          if(type == 1){
            item2.cart_num+=1
          }
        }else{
          Toast(res.data.msg)
        }
      })
      // addCar(this.$store.state.token,item2.products.id,nums,2,item2.type).then(res=>{
      //   if(res.data.status==true){
      //     console.log(res)
      //     item2.nums = nums
      //   }else{
      //     Toast(res.data.msg)
      //   }
      // }).catch(err=>{
      //   console.log(err)
      // })
    },300)
  },
  created() {},
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.bodybasd {
    display: flex;

}
// .img {
//     width: 75px;
//     height: 75px;
//     border-radius: 10px;
//   }
//   .listImg{

//   }
  .goodsInfo {
    flex: 1;
    padding-left: 10px;
    .brand {
      display: flex;
      font-size: 13px;
      color: #1b1b1b;
      line-height: 18px;
      align-items: center;
      font-weight: 500;
      .brandBox {
        font-size: 12px;
        background: #E8E8E8;
        color: #1B1B1B;
        padding: 0 4px;
        border-radius: 4px;
        margin-right: 5px;
        font-weight: 500;
      }
    }
    .name {
      display: -webkit-box;
      /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
      -webkit-box-orient:vertical;
      /*要显示的行数*/
      -webkit-line-clamp:2;
      /* 溢出部分隐藏 */
      overflow:hidden;
      font-weight: 500;
      font-size: 14px;
      color: #1b1b1b;
      line-height: 24px;
      text-align: left;
    }
    .size,
    .weight {
      font-weight: 400;
      font-size: 13px;
      color: #7f7f7f;
      line-height: 18px;
      text-align: left;
    }
    .priceAndAdd {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      align-items: center;
      .prcie {
        display: flex;
        color: #fd2a39;
        font-size: 12px;
        align-items: baseline;
        // max-width: 65%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .unit {
          font-size: 14px;
        }
        .yuan {
          font-size: 18px;
        }
      }
      .rightAdd {
        display: flex;
        align-items: center;
        .unadd,
        .add {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: #FC223B;
          position: relative;
        }
        .add::after,
        .unadd:after {
          content: "";
          display: block;
          position: absolute;
          transform: translate(-50%, -50%);
          width: 50%;
          height: 10%;
          background-color: white;
          top: 50%;
          left: 50%;
          border-radius: 100px;
        }
        .add::before {
          content: "";
          display: block;
          position: absolute;
          transform: translate(-50%, -50%);
          width: 10%;
          height: 50%;
          background-color: white;
          top: 50%;
          left: 50%;
          border-radius: 100px;
        }
        .input {
          height: 22px;
          width: 22px;
          line-height: 22px;
          margin: 0 5px;
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          color: #1b1b1b;
        }
      }
    }
  }
</style>