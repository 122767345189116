<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import Compressor from "compressorjs";
import {upload_file, upload_files} from "@/utils/api";
import {Toast} from "vant";
import {getQuestionCate,createQuestion} from "@/api/idle"
export default {
  name: "problem_feedback",
  components: {navigationBar},
  data(){
    return{
      problemLi:[
        {text:'功能异常'},
        {text:'体验问题'},
        {text:'功能建议'},
        {text:'其他问题'},
      ],
      chooseProblemIndex:0,
      chooseProblemId:0,
      problemLoad:[],
      problemFiles:[],
      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["png", "jpg", "jpeg",],
      problemLimit:8,
      details:'',//输入框
    }
  },
  methods:{
    chooseProble(index,item){
      if(this.chooseProblemIndex==index)return;
      if(this.chooseProblemId==item.id)return;
      this.chooseProblemIndex = index;
      this.chooseProblemId = item.id;
    },
    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)

        }


      })
    },
    async beforeUpload(files) {
      console.log(files)
      let file = await this.yasuo(files)
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        if (files.type.startsWith('image/')) {   //图片
          //计算文件的大小
          const isLt5M = file.size / 1024 / 1024 < 1; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            return false;
          }
        } else if (files.type.startsWith('video/')) {//视频
          const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
    uploadFile(item) {
      // console.log(JSON.stringify(item))
      this.$message("文件上传中........");
      if (item.file.type.startsWith('image/')) {
        upload_file(item.file).then(res => {
          if (res.data.status == 200) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 22) {
              this.problemLoad.push(res.data.data.url)
            }
            // this.userUpload.push({tempFilePath:res.data.data.saveurl,fileType:'image'})
          }
        })
      } else if (item.file.type.startsWith('video/')) {
        upload_files(item.file).then(res => {
          if (res.data.status == 200) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 22) {
              this.problemLoad.push(res.data.data.url)
            }
          }
        })
      }
    },
    //上传成功后的回调
    handleSuccess() {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.handleClose();
    },
    fileChange2(file, problemFiles) {
      let list = []
      problemFiles.forEach(item => {
        const FileExt = item.name.replace(/.+\./, "").toLowerCase();
        if (this.fileType.includes(FileExt)) {
          list.push(item)
          return true;
        } else {
          return false;
        }
      })
      this.problemFiles = list;
    },
    async handleRemove() {
    },
    handleClose(i) {
      this.yanshoufileList.splice(i, 1); //删除上传的文件
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },
    deleteImg(list, index, fileList) {
      list.splice(index, 1)
      fileList.splice(index, 1)
    },

    submitDeedBack(){
      console.log('提交反馈')
      createQuestion(this.chooseProblemId,this.details,this.problemLoad).then(res=>{
        if(res.data.status == 200){
          Toast('提交成功')
          this.$router.go(-1)
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },


    getTypes(){
      getQuestionCate(1).then(res => {
        if(res.data.status == 200){
          this.problemLi = res.data.data
          this.chooseProblemId = this.problemLi[0].id
        }else {
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },


  },
  computed:{
    infomationsrcList(){
      let list = []
      JSON.parse(JSON.stringify(this.problemLoad)).forEach(item => {
        list.push(item)
      })
      return list
    }
  },
  mounted() {
    this.getTypes()
  }
}
</script>

<template>
<div class="container">
  <div class="area">
    <navigationBar :title="'问题反馈'"></navigationBar>
    <div class="title">
      问题类型
    </div>
    <div class="probloemLi">
      <div class="ul">
        <div class="li" :class="[chooseProblemIndex==index?'chooseProblem':'',chooseProblemId==item.id?'chooseProblem':'']" v-for="(item,index) in problemLi" :key="index" @click="chooseProble(index,item)">
          {{item.cate_name}}
        </div>
      </div>
    </div>
    <div class="title">
      问题类型
    </div>
    <div class="textareaBox">
      <textarea name="" id="" cols="30" rows="10" v-model="details" class="textarea" maxlength="200" placeholder="请尽量详细地描述所遇到的问题或建议，我们会快马加鞭地尽快处理（最多200字)"></textarea>
    </div>
    <div class="littleTitle">
      最多选择8张图片
    </div>

    <div class="img_box">
      <div class="img_list">
        <div class="evert li" v-for="(item,index) in problemLoad" :key="index">
          <el-image
              class="avatar"
              fit="cover"
              :src="item"
              :preview-src-list="infomationsrcList">
          </el-image>
          <van-icon name="clear" class="icon" @click.stop="deleteImg(problemLoad,index,problemFiles)"/>
        </div>
        <el-upload
            v-if="problemLoad.length<=7"
            class="avatar-uploader"
            action
            :http-request="uploadFile"
            ref="upload"
            :limit="problemLimit"
            :on-remove="handleRemove"
            :file-list="problemFiles"
            :on-exceed="handleExceed"
            :before-upload="beforeUpload"
            :show-file-list="false"
            :headers="headers"
            :on-change="fileChange2"
            multiple
            name="22"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
    </div>

    <div class="footer">
      <div>感谢我们的成长之路由您陪伴</div>
      <div>
        我们会积极听取您的意见建议，努力更好的为您服务。
      </div>
    </div>
    <div class="bottomBtn" @click="submitDeedBack">
      提交反馈
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.title{
  color: #1b1b1b;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  padding-left: 16px;
}
.probloemLi{
  .ul{
    display: flex;
    margin: 10px 16px;
    .li{
      width: fit-content;
      height: fit-content;
      display: inline-flex;
      place-content: center;
      place-items: center;
      gap: 10px;
      padding: 6px 12px;
      border-radius: 25px;
      background: #f5f5f5;
      text-align: center;
      color: #1b1b1b;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      margin-right: 12px;
    }
    .chooseProblem{
      background: #fc223b;
      color: #ffffff;
    }
  }
}
.textareaBox{
  border-radius: 10px;
  background: #f5f5f5;
  max-height: 120px;
  margin: 10px 16px;
  padding: 10px;
  .textarea{
    width: 100%;
    height: 100%;
    border: none;
    max-height: 120px;
    background: transparent;
    align-items: center;
    text-align: justify;
    color: #1b1b1b;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }
  .textarea::placeholder{
    display: flex;
    align-items: center;
    text-align: justify;
    color: #999999;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }
}
.littleTitle{
  padding-left: 16px;
  align-items: center;
  text-align: justify;
  color: #999999;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}
.img_box {
  margin: 0px 16px 10px 16px;
  .title {
    text-align: left;
    font-size: 12px;
    color: #999999;
  }

  .img_list {
    margin-top: 4px;
    display: flex;
    flex-wrap: wrap;

    .li {
      width: 76px;
      height: 76px;
      margin-right: 10spx;
      position: relative;
      border-radius: 10px;
      margin-top: 10px;

      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      .icon {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(20%, -50%);
        font-size: 20px;
      }
    }

    .addImg {
      border: 1px dashed #999999;
      border-radius: 10px;
      position: relative;

    }

    .addImg:after,
    .addImg:before {
      position: absolute;
      content: '';
      display: block;
      width: 30%;
      height: 2px;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }

    .addImg:before {
      width: 2px;
      height: 30%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }
  }

}

.avatar-uploader {
  width: 76px;
  height: 76px;
  line-height: 76px;
  margin-top: 10px;
}
.footer{
  align-items: center;
  text-align: center;
  color: #999999;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  padding-top: 150px;
}
.bottomBtn{
  text-align: center;
  color: #ffffff;
  font-family: "PingFang SC";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-radius: 10px;
  background: #fc223b;
  position: fixed;
  bottom: 10px;
  left: 50%;
  width: 90%;
  transform: translate(-50%,0);
  padding: 13px 0;
}
</style>