<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {category, getIntegralCount, store_integral_index, store_integral_list} from "@/api/point";
import {Dialog, Toast} from "vant";
import {_throttle} from "@/utils/tool";

export default {
  name: "point_home",
  components: {navigationBar},
  data() {
    return {
      tabs: [
        {text: '全部'},
        {text: '0~1000'},
        {text: '1000~5000'},
        {text: '5000~10000'},
      ],
      chooseTabsIndex: 0,
      ulWidth: 0,

      mainMenuList: [],
      leftList: [],
      rightList: [],
      leftHeight: 0,
      rightHeight: 0, //分别定义左右两边的高度

      page: 1,
      limit: 10,
      hasMore: true,//是否可以继续滚动
      loadLock: true,//是否展示loding

      ruleShow:false,

      ruleList:[
          '1、只有从未在本平台注册的新用户视为被邀请，且必须通过邀请人的邀请链接注册完成认证。',
          '2、被邀请人必须成功注册，否则邀请人将无法获得积分奖励。',
          '3、每位邀请人在活动期间内最多可以获得36次积分奖励，但是可以无限邀请新用户。',
          '4、邀请人获得的积分奖励有效期为一年，过期后将自动失效。请在有效期内使用积分，以免造成不必要的损失。',
          '5、具体活动以平台公布的内容为准。在参与邀请新人得积分活动前，请务必仔细阅读并理解平台的相关规则。',
      ],

      my_integral:0,
    }
  },
  mounted() {
    this.integralCategory()
    store_integral_index()
    getIntegralCount().then(res=>{
      if(res.data.status == 200) {
        console.log(res.data.data)
        this.my_integral = res.data.data.sum
        // sum	int	总积分
        // gain_sum	int	消费得积分
        // share_sum	string	分享的积分
      }

    })
  },
  methods: {
    changeTabs(e) {
      if (e == this.chooseTabsIndex) return;
      this.chooseTabsIndex = e;
      this.leftList = []
      this.rightList = []
      this.mainMenuList = []
      this.hasMore = true
      this.loadLock = true
      this.updateGoods()
    },
    goDeatils(e) {
      if(this.$store.state.user&&this.$store.state.token){
        this.$router.push({
          path:'/point_goods_details',
          query:{goods:JSON.stringify(e)},
        })
      }else{
        // Toast('')
        Dialog.confirm({
          title: '您还未登录，请先登录',
          confirmButtonText:'去登录'
        })
            .then(() => {
              // on confirm
              this.$router.push('/login')
            })
            .catch(() => {
              // on cancel
            });
      }
    },
    integralCategory() {
      category().then(res => {
        let data = res.data.data;
        data.unshift({
          value: '全部'
        })
        this.tabs = data;
        this.$nextTick(() => {
          this.ulWidth = this.$refs.ul.offsetWidth;
          this.qingqiu(this)
        })
      }).catch(err => {
        Toast(err.data.msg)
      })
    },
    getBoxHeight(leftList, rightList, _this) {
      //获取左右两边高度
      return new Promise((resolve) => {
        _this.$nextTick(() => {
          _this.leftList = leftList;
          _this.rightList = rightList;
          _this.leftHeight = _this.$refs.left.offsetHeight;
          _this.rightHeight = _this.$refs.right.offsetHeight;
          // console.log("左边", _this.leftHeight, "右边", _this.rightHeight);
        });
        resolve();
      });
    },
    qingqiu: _throttle(async function (_that) {
      this.limit += 10
      store_integral_list(this.tabs[this.chooseTabsIndex].value, this.page, this.limit).then(async res => {
        if (res.data.status == 200) {
          let list = res.data.data
          if (list.length < this.limit) {
            this.hasMore = false
          }
          this.mainMenuList = list
          for (const item of _that.mainMenuList) {
            // console.log("左边", _that.leftHeight, "右边", _that.rightHeight);
            if (_that.leftHeight <= _that.rightHeight) {
              _that.leftList.push(item);
            } else {
              _that.rightList.push(item);
            }
            await _that.getBoxHeight(_that.leftList, _that.rightList, _that);
          }
          this.loadLock = false
        } else {
          Toast(res.data.msg)
        }
      })
    },200),
    updateGoods(){
      let _that = this
      store_integral_list(this.tabs[this.chooseTabsIndex].value, this.page, this.limit).then(async res => {
        if (res.data.status == 200) {
          let list = res.data.data
          if (list.length < this.limit) {
            this.hasMore = false
          }
          this.mainMenuList = list
          for (const item of _that.mainMenuList) {
            // console.log("左边", _that.leftHeight, "右边", _that.rightHeight);
            if (_that.leftHeight <= _that.rightHeight) {
              _that.leftList.push(item);
            } else {
              _that.rightList.push(item);
            }
            await _that.getBoxHeight(_that.leftList, _that.rightList, _that);
          }
          this.loadLock = false
        } else {
          Toast(res.data.msg)
        }
      })
    },

    containerScroll(event){
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const {scrollHeight, clientHeight, scrollTop} = event.target;
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <= 0.05
        ) {
          if (this.hasMore) {
            this.loadLock = true
            this.qingqiu(this);
          }
        }
      }
    },
    particulars(){
      this.$router.push({
        path:'/particulars'
      })
    },
    rules(){
      this.ruleShow = true
    },

  },

}
</script>

<template>
  <div class="container" @scroll="containerScroll">
    <div class="area">
      <navigationBar :title="'积分商城'" class="navigation"></navigationBar>
      <div class="head">
        <div class="noLogin" v-if="!$store.state.user&&!$store.state.token">
          <div class="inn">
            <div class="left">登录后可查看账户明细</div>
            <div class="loginBtn">登录</div>
          </div>
        </div>
        <div class="pointBox">
          <div class="left">
            <div class="top_text">我的积分</div>
            <div class="nums">{{ $store.state.user ? my_integral : 0 }}</div>
          </div>
          <div class="right">
            <div class="fun" @click="particulars"><span class="iconfont icon-jifen1"></span>积分明细</div>
            <div class="fun" @click="rules"><span class="iconfont icon-guize"></span>规则</div>
          </div>
        </div>
      </div>
      <div class="lineE"></div>
      <div class="tabs" ref="ul">
        <div class="tabs_every" :class="chooseTabsIndex==index?'choose_tabs':''" v-for="(item,index) in tabs"
             :key="index" @click="changeTabs(index)">
          {{ item.value }}
        </div>
        <div class="line"
             :style="'transform: translateX('+(((((ulWidth/4)/2).toFixed(0)*(chooseTabsIndex+1))+(((ulWidth/4)/2).toFixed(0)*chooseTabsIndex))-10)+'px) ;transition-duration: 0.3s;'"></div>
      </div>
      <div class="body">
        <div id="content">
          <div class="left" ref="left">
            <div class="item" v-for="(item, index) in leftList" :key="index" @click.stop="goDeatils(item)">
              <img :src="item.image" class="img" alt=""/>
              <div class="bottom">
                <div class="name">{{ item.title }}</div>
                <div class="priceLine">
                  <div class="lef">
                    <div class="nowPrice">
                      <div class="leftUnit"></div>
                      <div class="rightPrice">{{ item.integral }}</div>
                    </div>
                    <div class="lastPrice">
                      积分
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="right" ref="right">
            <div class="item" v-for="(item, index) in rightList" :key="index" @click="goDeatils(item)">
              <img :src="item.image" class="img" alt=""/>
              <div class="bottom">
                <div class="name">{{ item.title }}</div>
                <div class="priceLine">
                  <div class="lef">
                    <div class="nowPrice">
                      <div class="leftUnit"></div>
                      <div class="rightPrice">{{ item.integral }}</div>
                    </div>
                    <div class="lastPrice">
                      积分
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="loader-box">
          <div id="loader" v-if="loadLock"></div>
          <div id="nothing" v-if="!loadLock&&!hasMore">没有更多了~</div>
        </div>
      </div>
      <div style="width: 100%; height: 100px"></div>
      <van-popup v-model="ruleShow" round  position="bottom" class="pop">
        <div class="popIn">
          <div class="pop_title">
            <span class="text">*</span>积分商城规则
          </div>
          <div class="pop_body">
            <div class="every" v-for="(item,index) in ruleList" :key="index">
              {{item}}
            </div>
          </div>
          <div class="pop_btn" @click="ruleShow = false">我知道了</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<style scoped lang="scss">
.pop{
  .popIn{
    margin: 16px;
  }
  .pop_title{
    color: #1b1b1b;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    .text{
      color: #ff0000;
    }
  }
  .pop_body{
    text-align: left;
    color: #1b1b1b;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    .every{
      margin: 10px 0;
    }
  }
  .pop_btn{
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 24px;
    border-radius: 32px;
    background: #fc223b;
    margin-top: 10px;
    color: #fff;
  }
}

.container {
  background: #f6f6f6;
  height: 100vh;
}

.area {
}

.head {
  padding: 10px 16px;
  margin-top: 10px;
  background-color: #fff;

  .noLogin {
    border-radius: 10px;
    background: #f8f8f8;

    .inn {
      display: flex;
      justify-content: space-between;
      padding: 10px;

      .left {
        color: #1b1b1b;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
      }

      .loginBtn {
        color: #eb554f;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }

  .pointBox {
    display: flex;
    align-items: flex-end;
    margin-top: 15px;

    .left {
      width: 65%;
      text-align: left;

      .top_text {
        color: #1b1b1b;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }

      .nums {
        margin-top: 5px;
        display: flex;
        align-items: center;
        color: #fd2a39;
        font-weight: 600;
        font-size: 32px;
        line-height: 45px;
      }
    }

    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      color: #1b1b1b;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;

      .fun:nth-last-of-type(1) {
        margin-left: 15px;
      }
      .fun{
        .iconfont{
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }

  }
}

.lineE {
  height: 1px;
  transform: rotate(0.000005deg);
  border: 1px solid #e0e0e0;
}

.tabs {
  position: relative;
  display: flex;
  background-color: #fff;
  padding: 20px 0;

  .tabs_every {
    width: 25%;
    color: #999999;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }

  .choose_tabs {
    color: #1b1b1b;
    font-weight: 500;
  }

  .line {
    position: absolute;
    height: 2px;
    width: 15%;
    border-radius: 26px;
    background: #fd2a39;
    bottom: 20%;
    left: -5%;
  }
}

.body {
  position: relative;
  width: 100%;
  // height: 100vh;
  background: #f6f6f6;

  #content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 10px;
    background: #F5F5F5;
    padding-top: 10px;
  }

  .left,
  .right {
    width: 48.5%;
    margin: 0%;

    .img {
      width: 100%;
      //background-color: #fff;
      min-height: 100px;
    }
  }

  .item {
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    /* box-shadow: 0px 0px 2px 0px rgba(102,102,102,0.25); */
    display: flex;
    flex-direction: column;
    text-align: left;

    .bottom {
      padding: 6px 10px;

      .unit {
        font-size: 10px;
      }

      .price {
        color: #FC223B;
        font-size: 18px;
      }

      .priceLine {
        display: flex;
        color: #FC223B;
        align-items: center;
        width: 100%;
        margin-top: 6px;

        .lef {
          display: flex;
          align-items: baseline;
          width: 100%;
        }

        .nowPrice,
        .lastPrice {
          display: flex;
          align-items: baseline;

          .leftUnit,
          .rightPrice {
            font-size: 12px;
            color: #7f7f7f;
          }
        }

        .leftUnit {
          font-size: 10px;
          line-height: 10px;
        }

        .rightPrice {
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .nowPrice {
          .rightPrice {
            font-size: 18px;
          }

          .leftUnit,
          .rightPrice {
            color: #FC223B !important;
          }
        }
      }

      .nowPrice {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .lastPrice {
        color: #7f7f7f;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        margin-left: 5px;
      }
    }

    .name {
      display: -webkit-box;
      /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
      -webkit-box-orient: vertical;
      /*要显示的行数*/
      -webkit-line-clamp: 2;
      /* 溢出部分隐藏 */
      overflow: hidden;
      font-size: 14px;
      color: #1b1b1b;
    }

    .sellNums {
      line-height: 18px;
      font-size: 12px;
      color: #7f7f7f;
    }
  }
}

</style>