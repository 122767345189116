<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {addressEdit, addressList, deleteAddress} from "@/utils/api";
import {Dialog, Toast} from "vant";
import Bus from "@/utils/Bus";
import {_throttle} from "@/utils/tool";

export default {
  name: "userAddress",
  components: {
    navigationBar
  },
  data() {
    return {
      userAddress: [],
      list: [],
      type: 1,//1列表  2选择
      moren: '',
      page: 1, limit: 10,
      hasMore: true,//是否可以继续滚动
      loadLock: true,//是否展示loding
      oldScrollTop: 0,
    }
  },
  methods: {
    containerScroll(event) {
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const {scrollHeight, clientHeight, scrollTop} = event.target;
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          if (this.hasMore) {
            this.loadLock = true
            this.qingqiu(this);
          }
        }
      }
    },
    getList() {
      addressList(this.page, this.limit).then(res => {
        if (res.data.status == 200) {
          let list = res.data.data
          if(this.type == 2){
            list.forEach(item => {
              if (item.id == JSON.parse(sessionStorage.getItem('now_choose_address')).id) {
                this.moren = item.id
              }
            })
          }else{
            list.forEach(item => {
              if (item.is_default == 1) {
                this.moren = item.id
              }
            })
          }
          if ((this.list.length == list.length) || list.length == 0) {
            this.hasMore = false
          }
          this.list = list
          this.loadLock = false
        }
      })
      // getUserShip(this.$store.state.token).then((res)=>{
      //   if(res.data.status==true){
      //     let list = res.data.data
      //     list.forEach(item=>{
      //       if(item.is_def == 1){
      //         this.moren = item.id
      //       }
      //     })
      //     if(this.$route.query.id){
      //       this.type = 2
      //       this.moren = list.find(item=>{return item.id == this.$route.query.id}).id
      //     }
      //     console.log(list,this.moren)
      //     this.list = list
      //   }
      // })
    },
    qingqiu: _throttle(async function () {
      this.limit += 10
      this.getList()
    },200),
    addAddress() {
      this.$router.push({
        path: '/addAddress',
        query: {},
      })
    },
    edit(e) {
      this.$router.push({
        path: '/addAddress',
        query: {item: JSON.stringify(e)},
      })
    },
    deleteFun(e) {
      Dialog.confirm({
        title: '确定删除地址吗？',
      }).then(() => {
        let data = {
          id:e.id
        }
        deleteAddress(data).then(res => {
          if (res.data.status == 200) {
            this.qingqiu()
          } else {
            Toast(res.data.msg)
          }
        })

      }).catch(() => {
        //
      })
    },

    radioChange(item) {
      let v_1_1_0AddressPost = {
        province: item.province,//省
        city: item.city,//市
        district: item.district,//区
        city_id: item.city_id,//区id
        street: item.street,//街道
      }
      let obj = {
        address: v_1_1_0AddressPost,
        detail: item.detail,
        is_default: 1,
        real_name: item.real_name,
        phone: item.phone,
        latitude: '',
        longitude: '',
        id: item.id ? item.id : 0,
        address_location:item.address_location,
        unit_id:item.unit_id,
        layer_id:item.layer_id,
        address_location_name:item.address_location_name,
        unit_name:item.unit_name,
        layer_name:item.layer_name,
      }
      addressEdit(obj).then(res => {
        if (res.data.status == 200) {
          console.log(res.data)
          Toast('设置默认成功')
        } else {
          Toast(res.data.msg)
        }
      })
      // editShip(
      //     this.$store.state.token,
      //     item.name,
      //     item.address,
      //     item.mobile,
      //     1,
      //     item.province_name,
      //     item.city_name,
      //     item.district_name,
      //     item.province_id,
      //     item.city_id,
      //     item.district_id,
      //     item.details_name?item.details_name:'',
      //     item.id ? item.id : '',
      // ).then(res=>{
      //   if(res.data.status == true){
      //     this.getList()
      //   }
      // })
    },


    radioChangeChoose(item) {
      console.log(this.type)
      let v_1_1_0AddressPost = {
        province: item.province,//省
        city: item.city,//市
        district: item.district,//区
        city_id: item.city_id,//区id
        street: item.street,//街道
      }
      let obj = {
        address: v_1_1_0AddressPost,
        detail: item.detail,
        is_default: 1,
        real_name: item.real_name,
        phone: item.phone,
        latitude: '',
        longitude: '',
        id: item.id ? item.id : 0,
      }
      if(this.type == 2){
        console.log('选择')
        console.log(item)
        sessionStorage.setItem('now_choose_address',JSON.stringify(item))
        this.$router.go(-1)
      }else{
        addressEdit(obj).then(res => {
          console.log(res)
          if (res.data.status == 200) {
            console.log(res.data)
          } else {
            Toast(res.data.msg)
          }
        })
      }
      // getUserShip_ster(this.$store.state.token,item.id).then((res)=>{
      //   if(res.data.status==true){
      //     Bus.$emit("updataUserInfo", '1');
      //     Bus.$on('sendUserInfo',(msg)=>{
      //       console.log(msg,'msg')
      //       this.$router.go(-1)
      //     })
      //   }else{
      //     Toast(res.data.msg)
      //   }
      // })
    },
  },
  async mounted() {
    this.qingqiu()
    if (this.$route.query.id || this.$route.query.type == 'choose') {
      this.type = 2
    }
  },
  beforeDestroy() {
    Bus.$off("sendUserInfo");
  }
}
</script>

<template>
  <div class="container" @scroll="containerScroll">
    <div class="area">
      <navigationBar :title="'用户地址'"></navigationBar>
      <div class="ul" v-if="list.length>0">
        <div class="li" v-for="(item,index) in list" :key="index">
          <div class="iteme">
            <van-radio-group v-model="moren">
              <div class="item">
                <div class="head" v-if="type==1">
                  <div class="head_top">
                    <span class="name">{{ item.real_name }}</span> &nbsp;
                    <span class="mobile">{{ item.phone }}</span>
                    <van-tag color="#FFF3ED" text-color="#F96422" style="margin-left: 0;" v-if="item.is_def === 1">
                      默认
                    </van-tag>
                  </div>
                  <div class="addressDetails">{{ item.province + item.city + item.district + item.street + item.address_location_name + item.unit_name + item.layer_name + item.detail }}</div>
                </div>
                <div class="foot" v-if="type==1">
                  <div class="left">
                    <van-radio @click="radioChange(item)" :name="item.id" checked-color="#ee0a24">默认收货地址
                    </van-radio>
                    <!--                  <van-radio v-model="item.moren" class="radio" icon-size="18px">默认收货地址</van-radio>-->
                  </div>
                  <div class="right">
                    <div class="righttext" @click="edit(item)">
                      <span class="iconfont icon-bianji1 icon"></span> 编辑
                    </div>
                    <div class="righttext deleteBtn" @click="deleteFun(item)">
                      <span class="iconfont icon-shanchu1 icon"></span>删除
                    </div>
                  </div>
                </div>
                <div class="outItem" v-if="type == 2">
                  <van-radio class="radioFun" @click="radioChangeChoose(item)" :name="item.id" checked-color="#ee0a24">
                    <div class="head" v-if="type == 2">
                      <div class="head_top">
                        <span class="name">{{ item.real_name }}</span>
                        <span class="mobile">{{ item.phone }}</span>
                        <van-tag color="#FFF3ED" text-color="#F96422" style="margin-left: 0;" v-if="item.is_def === 1">
                          默认
                        </van-tag>
                      </div>
                      <div class="addressDetails">{{ item.province + item.city + item.district + item.street + item.address_location_name + item.layer_name + item.detail }}</div>
                    </div>
                  </van-radio>
                </div>
              </div>
            </van-radio-group>
            <div class="editRight" v-if="type === 2">
              <div class="righttext" @click="edit(item)">
                <span class="iconfont icon-bianji1 icon"></span> 编辑
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="list.length<=0">
        暂无收货地址
      </div>
      <div class="bottomBox">
        <button @click="addAddress" class="sendBtn">添加地址</button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  height: 100vh;
}

.bottomBox {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 20px 0;
  width: 100%;

  .sendBtn {
    border-radius: 10px;
    background: #fc223b;
    border: none;
    color: #fff;
    padding: 14px;
    width: 85%;
  }
}

.ul {
  padding-bottom: 100px;

  .li {
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 2px #66666640;
    margin: 10px 16px;
    padding: 10px;

    .item {
      .head {
        text-align: left;

        .head_top {
          padding-bottom: 4px;
        }

        .addressDetails {
          padding-bottom: 10px;
        }
      }

      .outItem {
        display: flex;
        align-items: center;

        .radioFun {
          margin-right: 15px;
        }

        .head {
          flex: 1;
        }
      }

      .foot {
        border-top: 1px solid #eeeeee;
        margin: 0px 10px;
        padding: 10px 0 0 0;
        display: flex;
        justify-content: space-between;

        .left {
          color: #999999;
          font-size: 14px;
        }

        .right {
          display: flex;
          align-items: center;

          .righttext {
            text-align: center;
            font-size: 13px;
            color: #999999;
          }

          .deleteBtn {
            margin-left: 15px;
          }
        }
      }

    }

    .iteme {
      display: flex;
      align-items: center;

      .van-radio-group {
        flex: 1;
      }
    }

    .editRight {

      .righttext {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        color: #666666;
      }

    }

  }
}
</style>