<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {getGiftCardData} from "@/api/gift_card";
import {_throttle} from "@/utils/tool";

export default {
  name: "my_card_list",
  components: {navigationBar},
  data(){
    return{
      page:1,
      limit:10,
      hasMore: true,//是否可以继续滚动
      loadLock: true,//是否展示loding
      list:[],
    }
  },
  methods:{
    goRecord(){
      this.$router.push({path:'/card_list_record'})
    },
    getList(){
      getGiftCardData(this.page,this.limit,'').then(res=>{
        if(res.data.status == 200){
          let list = res.data.data.data
          list.forEach(item=>{
            // status 1激活 2挂失 3补卡
            if(item.custom_price){
              item.yuan = item.custom_price.split('.')[0]
              item.mao = item.custom_price.split('.')[1]
            }else{
              item.yuan = item.giftcard.price.split('.')[0]
              item.mao = item.giftcard.price.split('.')[1]
            }
          })
          console.log(list)
          this.list = list
          if(list.length == 0 || list.length>=res.data.data.count){
            this.hasMore = false
          }
          this.loadLock = false
        }
      })
    },
    scrollContainer(event){
      console.log(123)
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const {scrollHeight, clientHeight, scrollTop} = event.target;
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          if (this.hasMore) {
            this.loadLock = true
            this.qingqiu(this);
          }
        }
      }
    },
    qingqiu:_throttle(function (){
      this.limit+=10
      this.getList()
    },200),
    godetaisl(item,e){
      this.$router.push({
        path:'/my_card_details',
        query:{data:JSON.stringify(item),type:e}
      })
    },


  },
  mounted() {
    this.getList()
  },
}
</script>

<template>
<div class="container" @scroll="scrollContainer">
  <div class="area">
    <navigationBar :title="'我的礼品卡'" class="navigation"></navigationBar>
    <div class="rightTitle">
      <span @click="goRecord"><span class="iconfont icon-Frame37"></span>
      赠送记录</span>
    </div>
    <div class="ul" v-if="list.length > 0">
      <div class="li" v-for="(item,index) in list" :key="index">
          <div class="card">
            <div class="head">
              <img :src="item.giftcard.img" alt="" class="leftImg">
              <div class="right">
                <div class="title">{{item.giftcard.title}}</div>
                <div class="price">
                  <div class="unit">￥</div>
                  <div class="yuan">{{item.yuan}}</div>.
                  <div class="mao">{{item.mao}}</div>
                </div>
              </div>
            </div>
            <div class="time">
              购买时间：{{item.storeorder.pay_time}}
            </div>
            <div class="bottomBtn">
<!--              status 1激活 2挂失 3补卡-->
              <div class="btn" v-if="item.status == 0" @click="godetaisl(item,'share')">分享</div>
              <div class="btn" v-if="item.status == 0"  @click="godetaisl(item,'activate')">激活</div>
              <div class="no btn" v-if="item.status == 1">已激活</div>
            </div>
          </div>
      </div>
      <div class="loader-box">
        <div id="loader" v-if="loadLock"></div>
        <div id="nothing" v-if="!loadLock&&!hasMore">没有更多了~</div>
      </div>
    </div>
    <div class="none" v-else>
      暂无礼品卡
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.container{
  height: 100vh;
  min-height: 100vh;
  background: #f4f6fa;
}
.area{
}
.rightTitle{
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  padding-right: 16px;
  .iconfont{
    vertical-align: middle;
  }
}
.card{
  border-radius: 10px;
  background: #ffffff;
  margin: 10px 16px;
  padding: 10px;
  .head{
    display: flex;
    .leftImg{
      width: 86px;
      height: 54px;
      object-fit: cover;
      border-radius: 7px;
    }
    .right{
      padding-left: 10px;
      width: 0;
      flex: 1;
      .title{
        text-align: left;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .price{
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        color: #fd2a39;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        .yuan{
          font-size: 20px;
        }
      }
    }
  }
  .time{
    text-align: left;
    margin-top: 10px;
    color: #9e9fa6;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
  }
  .bottomBtn{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .btn{
      border-radius: 8px;
      color: #ffffff;
      font-weight: 500;
      font-size: 13px;
      background: #f03525;
      width: 68px;
      height: 34px;
      line-height: 34px;
      margin-left: 10px;
    }
    .no{
      background: #eaeae8;
      color: #96959c;
      font-weight: 400;
      font-size: 13px;
      line-height: 34px;
    }
  }

}
</style>