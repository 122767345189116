<script>
import invite_tabbar from "@/components/common/tabbar/invite_tabbar.vue";
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import Swiper from "swiper";
import {_throttle} from "@/utils/tool";
import {Toast} from "vant";
import FiltrateCorporation from "@/components/common/invite_job/expect_job/filtrate_corporation.vue";
import {getJobCateList,getCompanyList,jobseeker_list,job_Company_list} from "@/api/recruitment"


export default {
  name: "talents",
  components: {
    NavigationBar,
    invite_tabbar,
    FiltrateCorporation,
  },
  data() {
    return {
      location: {
        address: '北京',
      },
      address:{},

      swiper: {},

      navList:[
        // {text:'最新发布',},
        // {text:'热招岗位',},
        // {text:'急招岗位',},
        // {text:'兼职专区',},
        // {text:'岗位筛选',},
      ],
      chooseNav:0,
      ulWidth:0,

      hasMore: true,//是否可以继续滚动
      loadLock: true,//是否展示loding
      page:0,
      limit:10,
      industry_id:'',
      industry_type_id:1,
      choosePositionObj: {},
      expect_job_show:false,

      list:[],
      filterList:[],
      PositionList:[],
    }
  },
  computed:{
    job_type(){
      let str = 1
      //工作类型 1或者 2 1=全职 2=兼职
      if(this.chooseNav == 3){
        str = 2
      }
      return str
    }
  },
  methods:{
    getList(){
      getJobCateList().then(res=>{
        console.log(res.data)
        if(res.data.status ==200){
          let obj = res.data.data
          let obj1 = []
          // /1工作经验 /2学历/ 3求职状态/4薪资
          for (const objKey in obj) {
            let obj2 = {}
            if(objKey == 1){
              obj2.type = '工作经验'
              obj2.list = obj[objKey]
            }
            if(objKey == 2){
              obj2.type = '学历'
              obj2.list = obj[objKey]
            }
            if(objKey == 3){
              obj2.type = '求职状态'
              obj2.list = obj[objKey]
            }
            if(objKey == 4){
              obj2.type = '薪资'
              obj2.list = obj[objKey]
            }
            if(obj2.type){
              obj1.push(obj2)
            }
          }
          obj1.forEach(item=>{
            item.list.forEach(item2=>{
              item2.choose = false
            })
            item.list.unshift({job_name:'全部',id:'',code:'',choose:true,})
          })
          this.filterList = obj1
          console.log(this.filterList)
        }
      })
    },
    chooseNavFun(index){

      if(index == this.navList.length){
        this.expect_job_show = true
        return;
      }
      if(this.chooseNav == index)return;
      this.chooseNav=index;
      this.list = []
      this.page = 0
      this.$nextTick(()=>{
        this.qingqiu()
      })
    },
    containerScroll(event){
      // const dialog = event.target
      // if(dialog.scrollHeight - dialog.scrollTop === dialog.clientHeight){
      //     console.log('触底');
      // }
      //   console.log(event);
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      //   console.log(scrollStep, this.oldScrollTop);
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const { scrollHeight, clientHeight, scrollTop } = event.target;
        // console.log(scrollHeight - (scrollTop + clientHeight));
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          // console.log((scrollHeight - (scrollTop + clientHeight)) / clientHeight);
          if (this.hasMore) {
            this.loadLock = true
            this.qingqiu(this);
          }
        }
      }
    },
    qingqiu: _throttle(async function () {
      console.log(this.navList[this.chooseNav])
      this.page+=1
      console.log(this.filterList.find(item=>{return item.type == "学历"}).list.find(item=>{return item.choose}))
      console.log(this.filterList.find(item=>{return item.type == "学历"}).list.find(item=>{return item.choose}).code)
      console.log(this.address)
      jobseeker_list(
          this.page,
          this.limit,
          this.address[0]?this.address[0].id?this.address[0].id:0:0,
          this.address[1]?this.address[1].id?this.address[1].id:0:0,
          this.address[2]?this.address[2].id?this.address[2].id:0:0,
          this.filterList.find(item=>{return item.type == "学历"}).list.find(item=>{return item.choose}).code,
          '',
          this.navList.length>0?this.navList[this.chooseNav].industry_id:'',
          this.filterList.find(item=>{return item.type == "工作经验"}).list.find(item=>{return item.choose}).code,
          this.filterList.find(item=>{return item.type == "求职状态"}).list.find(item=>{return item.choose}).code,
          this.filterList.find(item=>{return item.type == "薪资"}).list.find(item=>{return item.choose}).code,
      ).then(res=>{
        if(res.data.status == 200){
          let degreeLi = this.filterList.find(item=>{return item.type == '学历'}).list
          let list = res.data.data.data
          console.log(res.data.data.data,degreeLi)
          list.forEach(item=>{
            item.degree_text = degreeLi.find(item2=>{return item2.code == item.degree})?degreeLi.find(item2=>{return item2.code == item.degree}).job_name:''
            // item.benefitsLi = item.benefits.split(',')
          })
          this.list = this.list.concat(list)
          if (this.list.length >= res.data.data.count) {
            this.hasMore = false
          }
          this.loadLock = false
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },500),
    godetails(item){
      console.log(item)
      this.$router.push({
        // path:'/invite_details',
        path:'/biographical_notes',

        query:{item:JSON.stringify(item)}
      })
    },
    closeExpoect(e) {
      this.expect_job_show = e
    },
    choosePosition(e) {
      if(this.disable == true)return
      this.choosePositionObj = e
      this.navList[this.navList.length-1].text = e.industry_name
      this.expect_job_show = false
      this.chooseNav = this.navList.length-1
      this.industry_id = e.id
    },
    chooseIndustry_type_id(e){
      this.list = []
      this.page = 0
      this.industry_type_id = e
      this.qingqiu()
    },

    getTopNav(){
      job_Company_list(this.$store.state.nowCompany.id,this.page,this.limit).then(res=>{
        console.log(res)
        if(res.data.status == 200){
          let list = res.data.data.data
          this.PositionList = list
          // this.navList = [...new Set(list.map(item=>{return item.industry}).map(JSON.stringify))].map(JSON.parse)
          this.navList = list
          // {text:'岗位筛选',},
        }else {
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },

    getCompany(){
      getCompanyList().then(res=>{
        if(res.data.status == 200){
          if(res.data.data.count == 0){
            //没有公司
              // this
          }else{
            let list = res.data.data.data
            if(list.find(item=>{return item.acquiesce == 1})){
              this.$store.commit('updatenowCompany',list.find(item=>{return item.acquiesce == 1}))
              let address= [
                {name:this.$store.state.nowCompany.province_name,id:this.$store.state.nowCompany.province_id},
                {name:this.$store.state.nowCompany.city_name,id:this.$store.state.nowCompany.city_id},
                {name:this.$store.state.nowCompany.district_name,id:this.$store.state.nowCompany.district_id},
              ]
              this.address = address
              this.getTopNav()
              this.$nextTick(()=>{
                this.qingqiu()
              })
            }
          }

        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    addPostion(){
      console.log('添加岗位')
      this.$router.push('/invite_add_position')
    },
    chooseFilter(e){
      this.filterList = e
      this.expect_job_show = false
      this.list = []
      this.page = 0
      this.$nextTick(()=>{
        this.qingqiu()
      })
    },
    saveAddress(e){
      this.address = e
    },
  },
  mounted() {
    this.swiper = new Swiper('.swiper', {
      pagination: {
        el: ".swiper-pagination",
      },
      loop: false,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
    });
    this.ulWidth = this.$refs.ul.offsetWidth;
    this.getCompany()
    this.getList()
    //
  },
  created() {

  },
}
</script>

<template>
  <div class="container" @scroll="containerScroll">
    <div class="area">
      <navigationBar :title="''" class="navigationBar" :particular="'idle'">
        <template #search>
          <div class="searchBox">
            <div class="text">人才</div>
            <div class="iconBox">

            </div>
          </div>
        </template>
      </navigationBar>

      <filtrateCorporation
          class="expect_job"
          @closePop="closeExpoect"
          @choosePosition="chooseFilter"
          @saveAddress="saveAddress"
          :invalidObj="address"
          :liste="filterList"
          v-if="expect_job_show"
          :home="'home'"
      ></filtrateCorporation>

      <div class="swiper publicitySwiper" v-if="$store.state.nowCompany.id">
        <div class="swiper-wrapper">
          <div v-for="(item,index) in 3" :key="index"
               class="swiper-slide"
               :style="`background-image:url(${$store.state.imageHost}/tp2024071803001500257731617.png)`"
          ></div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination">
        </div>
      </div>
      <div class="nav">
        <div class="ul" ref="ul">
          <div class="line" v-if="$store.state.nowCompany.id && PositionList.length>0" :style="'transform: translateX('+(((((ulWidth/(navList.length+1))/2).toFixed(0)*(chooseNav+1))+(((ulWidth/(navList.length+1))/2).toFixed(0)*chooseNav))-10)+'px) translateX(-50%);transition-duration: 0.3s;'"></div>
          <div class="li" v-for="(item,index) in navList" :key="index" :class="chooseNav==index?'chooseNav':''" @click="chooseNavFun(index)">
            <span class="text">{{item.position}}</span>
          </div>
          <div class="li" @click="chooseNavFun(navList.length)" :class="chooseNav==navList.length?'chooseNav':''">
            筛选
            <span class="iconfont icon-a-Frame3274"></span>
          </div>

        </div>
      </div>

      <div class="list">
        <div class="ul">
          <div class="li">
            <div class="cardItem" v-for="(item,index) in list" :key="index"  @click="godetails(item)">
              <div class="info">
                <div class="leftImg">
                  <img  :src="item.user_avatar?$store.state.imageHost+item.user_avatar:$store.state.imageHost+$store.state.config.find(e=>{return e.key=='tbj_shop_logo'}).values" alt="" class="avatar">
                </div>
                <div class="rightInfo">
                  <div class="lineT">
                    <div class="name">{{item.user_name}}</div>
                    <div class="address" v-if="item.city_name||item.district_name">
                      <span class="iconfont icon-dingwei"></span>
                      <span class="address_text" >{{ item.city_name }} {{ item.district_name }}</span>
                    </div>
                  </div>
                  <div class="lineT">
                    <div class="left1">
                      <span class="text education">{{item.degree_text}}</span>
                      <span class="text worker">{{item.working_years}}年</span>
                      <span class="text education">{{item.user_job_status}}</span>
                    </div>
                    <div class="rightpay">
                      {{String(item.salary_min)}}k-{{String(item.salary_max)}}k
                    </div>
                  </div>
                </div>
              </div>
              <div class="details" v-html="item.advantage">
              </div>
            </div>
          </div>
          <div class="loader-box" v-if="list.length>0">
            <div id="loader" v-if="loadLock"></div>
            <div id="nothing" v-if="!loadLock&&!hasMore">没有更多了~</div>
          </div>
          <div  v-if="list.length<=0" class="no_post">
            <img :src="$store.state.imageHost+'/img/no_post.png'" alt="" class="no_post_img">
            <div class="no_post_text">发布职位后 推荐给你优秀牛人</div>
            <div class="publish_post_btn" @click="addPostion">发布职位</div>
          </div>
        </div>
      </div>
      <invite_tabbar :active="0"></invite_tabbar>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cardItem{
  margin: 10px 16px;
  border-radius: 10px;
  background: #ffffff;
  padding: 10px;
  .details{
    margin-top: 10px;
    text-align: left;
    display: -webkit-box;
    /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
    -webkit-box-orient:vertical;
    /*要显示的行数*/
    -webkit-line-clamp:3;
    /* 溢出部分隐藏 */
    overflow:hidden;
    color: #666666;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .info{
    display: flex;
    .leftImg{
      .avatar{
        width: 42px;
        height: 42px;
        border-radius: 50%;
      }
    }
    .rightInfo{
      text-align: left;
      flex: 1;
      padding-left: 10px;
      .lineT{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .name{
          color: #1b1b1b;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          width: 60%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .address{
          text-align: left;
          .iconfont{
            font-size: 18px;
            color: #4891ee;
            vertical-align: middle;
            margin-right: 5px;
          }
          .address_text{
            color: #666666;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
          }
        }
        .left1{
          color: #666666;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          width: 64%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          .text{
            margin-right: 4px;
          }
        }
        .rightpay{
          margin-top: 4px;
          color: #0393f5;
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
        }

      }
    }

  }
}
.no_post{
  padding-top: 100px;
  .no_post_img{
    width: 168px;
  }
  .no_post_text{
    color: #999999;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }
  .publish_post_btn{
    margin-top: 20px;
    display: inline-block;
    place-content: center;
    place-items: center;
    gap: 7.9px;
    flex-shrink: 0;
    padding: 6.3px 19px;
    border-radius: 29.2px;
    background: #c7c7c7;
    color: #ffffff;
    font-weight: 500;
    font-size: 12.6px;
    line-height: 19px;
  }
}
.expect_job {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: #fff!important;
  left: 0;
  top: 0;
}
.swiper-slide {
  // background: red;
  height: 160px;
  background-size: cover;
  background-position: center center;
}

.swiper {
  margin: 10px 16px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;

  .swiper-slide {
    max-height: 180px;
  }


  .img {
    width: 100%;
  }

  // 分页器
  /* 两种都可以 */
  .swiper-pagination-total {
    font-size: 12px;
  }

  .swiper-pagination {
    //background: rgba(51, 51, 51, 0.75);
    width: 100%;
    // right: 10px;
    border-radius: 64px;
    color: white;
    line-height: 24px;
    font-size: 13px;
  }

  //.text{
  //  color: #333333;
  //  font-weight: 400;
  //  font-size: 12px;
  //  line-height: 17px;
  //}

}

.swiper-wrapper {

  .page {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .every {
      width: 20%;
    }
  }
}

.publicity .swiper-slide {
  max-height: 88px;
}

.container {
  background: #f4f6fa;
  height: 100vh;
}

.navigationBar {
  background-image: url("https://picture.bjbjkj.top/img/inviteMask.png");
  background-repeat: no-repeat;
  background-size: 100%;

  .searchBox {
    display: flex;
    width: 80%;
    text-align: center;
    justify-content: center;
    position: relative;
  }
}


.nav{

  .ul{
    text-align: left;
    margin: 10px 16px;
    display: flex;
    position: relative;
    justify-content: space-between;
    .li{
      display: inline-block;
      text-align: center;
      color: #55565a;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: -webkit-flex;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      box-sizing: border-box;
      cursor: pointer;
      display: inline-block;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      position: relative;

      .text{
        display: inline-block;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .iconfont{
        vertical-align: middle;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(28%,-50%);
        font-size: 22px;
        margin-left: -5px;
      }
    }
    .li:nth-last-of-type(1){
      padding-right: 10px;
    }
    .chooseNav{
      text-align: center;
      color: #0393f5;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    .line{
      position: absolute;
      width: 24px;
      height: 2px;
      border-radius: 17px;
      background: #0393f5;
      bottom: -4px;
      left: 0;
    }

  }
  .vantabs{
    margin: 10px 16px;

  }
  //.van-tabs__nav{
  //  background-color: red;
  //}
}
.list{
  padding-top: 1px;
  padding-bottom: 100px;
}
</style>