<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import order_li from "@/components/common/home_tabbar_page/order/order_li.vue";
import { aftersalesList, order_list} from "@/utils/api";
import {Toast} from "vant";
// import loding from "@/components/common/loding/loding.vue";

export default {
  name: "order_list",
  components: {
    navigationBar,
    // eslint-disable-next-line vue/no-unused-components
    order_li
  },

  data() {
    return {
      tabActive: -1,
      servertime: 0,
      shareTabList: [
        {
          name: '全部',
          value: 0,
          page: 1,
          limit: 10,
          list2: [],
          showIndex: 0,
          list: [],
          maxlength: 1000,
          loading: false,
          noMore: false,
        },
        {
          name: '待付款',
          value: 1,
          page: 1,
          limit: 10,
          list2: [],
          showIndex: 0,
          list: [],
          maxlength: 1000,
          loading: false,
          noMore: false,
        },
        {
          name: '待发货',
          value: 2,
          page: 1,
          limit: 10,
          list2: [],
          showIndex: 0,
          list: [],
          maxlength: 1000,
          loading: false,
          noMore: false,
        },
        {
          name: '待收货',
          value: 3,
          page: 1,
          limit: 10,
          list2: [],
          showIndex: 0,
          list: [],
          maxlength: 1000,
          loading: false,
          noMore: false,
        },
        {
          name: '待评价',
          value: 4,
          page: 1,
          limit: 10,
          list2: [],
          showIndex: 0,
          list: [],
          maxlength: 1000,
          loading: false,
          noMore: false,
        },
        {
          name: '退款/售后',
          value: 5,
          page: 1,
          limit: 10,
          list2: [],
          showIndex: 0,
          list: [],
          maxlength: 1000,
          loading: false,
          noMore: false,
        },
      ],

      list: [],
      maxlength: 100,
      page: 1,
      limit: 10,
      status: 0,
      order_type: 0,

      count: 0,
      loadingMore: true
    }
  },
  watch: {
    tabActive(n) {
      this.status = n
      if (n == 4) {
        this.status = 6
      }
      if (n == 5) {
        this.status = 7
      }
      // 0=待支付,1=待发货,2=待收货,3=待评价,4=已完成,-1=退款中,-2=已退款,-3=退款
      if (n == 0) {
        this.status = ''
      }
      if (n == 1) {
        this.status = 0
      }
      if (n == 2) {
        this.status = 1
      }
      if (n == 3) {
        this.status = 2
      }
      if (n == 4) {
        this.status = 3
      }
      if (n == 5) {
        this.status = ''
      }
      this.getList()
    },
  },
  computed: {
    disabled() {
      return (this.tabActive != -1 ? this.shareTabList[this.tabActive].loading : true) || (this.tabActive != -1 ? this.shareTabList[this.tabActive].noMore : false)
    },
    noMore() {
      return this.shareTabList[this.tabActive].list.length >= this.shareTabList[this.tabActive >= 0 ? this.tabActive : 0].maxlength
    },
  },
  methods: {
    load() {
      console.log(123,'加载',this.tabActive)
      this.shareTabList[this.tabActive].loading = true
      if (this.shareTabList[this.tabActive].noMore) {
        return
      }
      let page = this.shareTabList[this.tabActive].page
      let limit = this.shareTabList[this.tabActive].limit += 10
      if (this.tabActive != 5) {
        order_list(this.status, page, limit, '').then(res => {
          if(res.data.status == 200){
            let maxlength = limit
            let list = res.data.data
            list.forEach(item=>{
              if(item.type == 31){
                item.pay_price = item.total_price
              }
            })
            console.log(maxlength,list.length)
            this.shareTabList[this.tabActive].list = list
            // if (list.length >= maxlength) {
            if (maxlength > list.length) {
              this.shareTabList[this.tabActive].noMore = true
              this.shareTabList[this.tabActive].loading = false
            }
            this.shareTabList[this.tabActive].loading = false
            this.shareTabList[this.tabActive].maxlength = maxlength
          }else{
            Toast(res.data.msg)
          }
        })
      }
      if (this.tabActive == 5) {
        console.log(12345)
        aftersalesList(this.$store.state.token, page, limit).then(res => {
              console.log(res)
              if (res.data.status) {
                let maxlength = res.data.data.total_page
                let list = res.data.data.list
                this.shareTabList[this.tabActive].list = list
                if (list.length == 0 || this.shareTabList[this.tabActive].list.length >= maxlength) {
                  this.shareTabList[this.tabActive].maxlength = 0
                  this.shareTabList[this.tabActive].loading = false
                  this.shareTabList[this.tabActive].noMore = true
                }
              } else {
                Toast(res.data.msg)
              }
            }
        ).catch(err => {
          console.log(err)
        })
      }

    },
    tabsChange() {
      // this.list = []
    },
    tabsClick(name) {
      if (this.tabActive == name) return;
    },
    onClickDisabled() {
      console.log('评价')
    },
    // checkOrderDetails(){
    //   console.log('查看详情')
    // },
    getList() {
      // this.loadingMore = true
      console.log(this.shareTabList)
      console.log(this.tabActive)
      let page = this.shareTabList[this.tabActive].page
      let limit = this.shareTabList[this.tabActive].limit
      // if(this.tabActive !=5){
      //   getOrderList(this.$store.state.token, page, limit, this.status).then(res => {
      //     if (res.data.status == true) {
      //       let maxlength = res.data.data.count
      //       let list = res.data.data.list
      //
      //       list.forEach(item => {
      //         // console.log(item.ctime)
      //         if(item.confirm_time){
      //           if((new Date(this.servertime*1000).getTime() - new Date(item.confirm_time.replace(' ','T')).getTime())>(60*60*24*15*1000)){
      //             item.shenqingFlag = false
      //           }else{
      //             item.shenqingFlag = true
      //           }
      //         }else{
      //           item.shenqingFlag = true
      //         }
      //         this.getRemainingTime(item)
      //         console.log(item)
      //       })
      //       this.shareTabList[this.tabActive].loading = false
      //       this.shareTabList[this.tabActive].list = list
      //
      //       this.shareTabList[this.tabActive].list2 = list
      //       this.shareTabList[this.tabActive].maxlength = maxlength
      //     } else {
      //       Toast(res.data.msg)
      //     }
      //   })
      // }
      // if(this.tabActive ==5){
      //   console.log(12345)
      //   aftersalesList(this.$store.state.token, page, limit).then(res=>{
      //     console.log(res)
      //       if(res.data.status){
      //         let maxlength = res.data.data.total_page
      //         let list = res.data.data.list
      //         console.log(list.length)
      //         if(list.length == 0){
      //           this.shareTabList[this.tabActive].maxlength = 0
      //           this.shareTabList[this.tabActive].loading = false
      //           this.shareTabList[this.tabActive].noMore = false
      //         }
      //         console.log(maxlength, this.shareTabList[this.tabActive])
      //
      //       }else{
      //         Toast(res.data.msg)
      //       }
      //   }
      //   ).catch(err=>{
      //     console.log(err)
      //   })
      // }
      order_list(this.status, page, limit, '').then(res => {
        if (res.data.status == 200) {
          let maxlength = limit
          let list = res.data.data
          list.forEach(item => {
            if(item.paid!=1){
              // this.getRemainingTime(item)
            }
            list.forEach(item=>{
              if(item.type == 31){
                item.pay_price = item.total_price
              }
            })
          })
          this.shareTabList[this.tabActive].loading = false
          this.shareTabList[this.tabActive].list = list
          this.shareTabList[this.tabActive].list2 = list
          this.shareTabList[this.tabActive].maxlength = maxlength
        }
      })
    },
    getRemainingTime(item) {
      let day = new Date(this.servertime * 1000);
      let time = day.getTime()
      item.atime = ((item.add_time*1000) + 900000) - time
      // item.atime = new Date(item.ctime.split(' ')[0] + 'T' + item.ctime.split(' ')[1]).getTime() + 900000 - time
      // return 2
    },
    cancelOr(e){
      this.shareTabList[this.tabActive].list.filter(item=>{return item.order_id != e.order_id})
      location.reload();
    },
  },
  mounted() {
    if(this.$route.params.id){
      this.tabActive = Number(this.$route.params.id)
    }
    // this.getList()
    // getNowTime().then(res => {
    //   if (res.data.status == true) {
    //     this.servertime = res.data.data
    //   }
    // })
  },
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'订单'" class="navigation"></navigationBar>
      <div class="top-background-img"></div>
      <div class="innerContent">
        <div class="topSearch">
          <span class="iconfont icon-grommet-icons_form-search-1"></span>
          <div class="line"></div>
          <div class="placeHolder">搜索你想要的订单...</div>
        </div>
        <div class="navTag">
          <van-tabs v-model="tabActive" animated @change="tabsChange" @click="tabsClick" @disabled="onClickDisabled">
            <van-tab
                v-for="(item, index) in shareTabList"
                :title="item.name"
                :key="index"
                :disabled="index==5"
            >
              <template #title>{{ item.name }}</template>
              <div class="ul" v-infinite-scroll="load"
                   :infinite-scroll-disabled="disabled" :infinite-scroll-immediate="false">
                <div v-for="(itemc,indexcc) in shareTabList[index].list" :key="indexcc+'itemc'" class="li">
                  <div class="order-body">
                    <order_li @cancelOr="cancelOr" :item="itemc" :tabActive="tabActive" :severTime="servertime"></order_li>
                  </div>
                </div>
                <p v-if="shareTabList[index].loading">加载中...</p>
                <p v-if="shareTabList[index].noMore">没有更多了</p>
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.ul {
  height: 100vh;
  overflow: auto;
}

.ul::-webkit-scrollbar {
  display: none;
}

.top-background-img {
  background: linear-gradient(0deg, rgba(248, 251, 255, 0), #FFF9F1);
  width: 100%;
  height: 280px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.navigation {
  position: relative;
  z-index: 2;
}

.innerContent {
  position: relative;
  z-index: 2;

  .topSearch {
    background: #FFFFFF;
    border-radius: 40px 40px 40px 40px;
    border: 1px solid #FC223B;
    margin: 0 16px;
    display: flex;
    align-items: center;
    padding: 6px 0;
    cursor: pointer;

    .iconfont {
      color: #FC223B;
      font-size: 24px;
      margin-left: 15px;
    }

    .line {
      height: 12px;
      width: 2px;
      background: #FC223B;
      margin: 0 10px;
    }

    .placeHolder {
      color: #666666;
      font-size: 12px;
    }
  }

  .navTag {
    margin: 0 16px;

    .van-tab--disabled {

    }

    .van-tab__text {
      font-weight: 400;
      font-size: 16px;
      color: #1B1B1B;
      //color: red;
      line-height: 24px;
      text-align: left;
    }

    .van-tab--active {
      .van-tab__text {
        font-weight: 500;
        font-size: 16px;
        color: #1B1B1B;
        line-height: 24px;
      }
    }


  }

}

//.van-tabs__nav .van-tab{
//  color: #1B1B1B;
//}
</style>

<style>
.van-tabs__nav--line .van-tabs__line {
  background: #FC223B;
  width: 15px;
  height: 5px;
}

.van-tabs__track .van-tab__pane {
  margin-top: 4px;
}

.van-tabs__track .van-tab__pane::-webkit-scrollbar {
  //display: none;
}

.innerContent .navTag .van-tabs__nav .van-tab {
  color: #1B1B1B;
}

.innerContent .navTag .van-tabs__nav .van-tab--disabled {
  cursor: pointer;
}
</style>