<script>
// import {getrecruitfirmtype} from "@/utils/api";
import {getRecruitFirmType} from "@/api/recruitment";
import {Toast} from "vant";

export default {
  name: "enterprise_types",
  data(){
    return{
      list:[],
      navIndex:0,
      liHieght:0,
      ulHieght:0,
    }
  },


  async mounted() {
    const {data} = await getRecruitFirmType()
    let list = data.data
    console.log(list)
    if(list.length == 0){
      Toast('暂无数据')
      return
    }
    console.log(list.map(item=>{return {name:item.name,children:item.data?item.data:[]}}))
    this.list = list

    this.$nextTick(()=>{
      this.liHieght = this.$refs.li[0].scrollHeight
      this.ulHieght = this.liHieght*this.$refs.li.length
    })
  },
  methods:{
    close(){
      this.$emit('closePop', false);
    },
    chooseNav(i){
      if(this.navIndex == i)return;
      this.navIndex = i;
    },
    chooseItem(item){
      this.$emit('submit', item);
    },
  },

}
</script>

<template>
<div class="container">
  <div class="top">
    <div class="backBtn" @click="close">
      <span class="iconfont icon-Frame-4"></span>
    </div>
  </div>
  <div class="body" v-if="list.length>0">
    <div class="left">
        <div class="ul" ref="ul">
          <div class="li" ref="li" v-for="(item,index) in list" :key="index" :class="index == navIndex?'chooseLi':''" @click="chooseNav(index)">
            <div class="item" >
              {{item.name}}
            </div>
          </div>
          <div class="line" :style="'height:'+liHieght+'px;top:'+((ulHieght/list.length)*navIndex)+'px;transition-duration: 0.3s;'"></div>
        </div>
    </div>
    <div class="right">
      <div class="item" v-for="(item,index) in list[navIndex].data?list[navIndex].data:[]" :key="index" @click="chooseItem(item)">
        {{item.name}}
      </div>
    </div>
  </div>

</div>
</template>

<style scoped lang="scss">
.top{
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 0;
  .backBtn{
    width: 10%;
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    .iconfont{
      font-size: 36px;
    }
  }
  .title{
    width: 85%;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: flex-end;
  }
}
.body{
  display: flex;
  text-align: left;
  margin: 10px 16px 0 0;
  .left{
    width: 40%;
    .ul{
      position: relative;
      .line{
        width: 4px;
        background: #fc223b;
        position: absolute;
      }
    }
    .li{
      text-align: center;
      place-content: center;
      place-items: center;
      gap: 10px;
      flex-shrink: 0;
      padding: 14px 0 14px 16px ;
      background: #fafafa;


      .item{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        color: #666666;
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
      }
    }
    .chooseLi{
      background: #ffffff;
    }
  }
  .right{
    width: 60%;
    .item{
      padding: 14px 0px 14px 10px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #99999915;
      color: #333333;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
</style>