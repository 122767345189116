<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {getFeedBackData} from "@/api/idle";
import {Toast} from "vant";

export default {
  name: "FAQ",
  components: {NavigationBar},
  data(){
    return{
      topSwiper:[],
      topFunList:[],
      accordionLi:[],
      activeNames:[],
    }
  },
  methods:{
    getProblem(){
      getFeedBackData().then(res=>{
        if(res.data.status==200){
          this.topSwiper = res.data.data.top_img
          this.topFunList = res.data.data.list
          this.accordionLi = res.data.data.data
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    godetails(item){
      if(item.id!=4){
        this.$router.push({
          path:"/FAQdetails",
          query:item
        })
      }else{
        this.$router.push({path: '/problem_feedback'})
      }

    },
  },
  mounted() {
    this.getProblem()
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'常见问题'" class="navigationBar"></navigationBar>
      <div class="topSwiper">
        <div class="inne">
          <img :src="`${$store.state.imageHost}${topSwiper.value}`" alt="" class="topImg">
        </div>
      </div>
      <div class="funLi">
        <div class="ul">
          <div class="li" v-for="(item,index) in topFunList" :key="index" @click="godetails(item)">
            <img :src="$store.state.imageHost+item.title_icon" class="topIcon" alt="">
            <div class="text">{{item.list_item}}</div>
          </div>
        </div>
      </div>
      <div class="accordion">
        <div class="ul">
          <div class="li" v-for="(item,index) in accordionLi" :key="index">
            <div class="title">{{item.list_item}}</div>
            <div class="accordionList">
              <van-collapse v-model="activeNames">
                <van-collapse-item v-for="(item2,index2) in item.item" :key="index2" :title="item2.content" :name="item2.id">
                  {{item2.list_item}}
                </van-collapse-item>
              </van-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.topSwiper{
  width: 100%;
  .inne{
    margin: 10px 16px;
    max-height: 100px;
    border-radius: 10px;
    overflow: hidden;
  }
  .topImg{
    //margin: 10px 16px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 100px;
    border-radius: 10px;

  }
}
.funLi{
  width: 100%;
  .ul{
    margin: 10px 16px;
    display: flex;
    .li{
      width: 25%;
      .topIcon{
        width: 36px;
        height: 36px;
        object-fit: cover;
      }
      .text{
        color: #333333;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
      }
    }
  }
}
.accordion{
  width: 100%;
  padding-top: 1px;
  .ul{
    margin: 10px 16px;
    text-align: left;
    .title{
      color: #1b1b1b;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
  }
}
</style>