import request from "@/utils/request";
import store from "../store/index";
const newDevHost = store.state.newDevHost;
/*
* 积分商城首页数据
* */
export function store_integral_index(){
    return request.get(`${newDevHost}/api/store_integral/index`)
}
/*
* 积分商城积分范围导航
* */
export function category(){
    return request.get(`${newDevHost}/api/store_integral/category`)
}
/*
* 积分商品列表
* */
export function store_integral_list(range,page,limit){
    return request.get(`${newDevHost}/api/store_integral/list?range=${range}&page=${page}&limit=${limit}`)
}
/*
* 积分商品详情
* */
export function store_integral_detail(id){
    return request.get(`${newDevHost}/api/store_integral/detail/${id}`)
}
/*
* 订单确认
* */
export function confirm_point(unique,num){
    let data = {
        unique,
        num
    }
    return request.post(`${newDevHost}/api/store_integral/order/confirm`,data)
}
/*
* 订单创建
* */
export function point_create(
    addressId,mark,payType,from,quitUrl,unique,num,custom_form
){
    let data = {
        addressId,
        mark,
        payType,
        from,
        quitUrl,
        unique,
        num,
        custom_form,
    }
    return request.post(`${newDevHost}/api/store_integral/order/create`,data)
}
/*
* 账单类 - 积分记录   底部明细
* */
export function integral_list(page,limit,top_str){
    return request.get(`${newDevHost}/api/integral/list?page=${page}&limit=${limit}&top_str=${top_str}`)
}
/*
* 积分记录 - 顶部积分明细
* */
export function list_top(){
    return request.get(`${newDevHost}/api/integral/list_top`)
}
/*
* 我的积分
* */
export function getIntegralCount(){
    return request.get(`${newDevHost}/api/store_integral/getIntegralCount`)
}
