<script>
import {getNowTime, orderList_jilu} from "@/utils/api";
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
// import {Toast} from "vant";

export default {
  name: "workRecords",
  components: {NavigationBar},
  data() {
    return {
      serverTime: '',
      nowMonth: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      lastDay: -1,
      list: [],
      xiuxiDay: 0,
      nowDayN: new Date().getDate(),
      topBoxIndex: 0,
      nowDay: (new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1) + '.' + new Date().getDate() + '',
      week: '',
      weekDay: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
    }
  },
  async mounted() {
    const {data} = await getNowTime()
    console.log(data.data)
    this.serverTime = data.data
    this.lastDay = this.getLastDay(this.year, new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getMonth() + 1)
    this.nowMonth = new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getMonth() + 1;
    this.year = new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getFullYear();
    this.nowDayN = new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getDate();
    this.nowDay = (new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getMonth() + 1 < 10 ? '0' + (new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getMonth() + 1) : new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getMonth() + 1) + '.' + new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getDate() + ''
    this.week = new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getDay()
    this.getList(this.year + '-' + this.nowMonth + '-' + 1, this.year + '-' + this.nowMonth + '-' + this.lastDay)
  },
  methods: {
    getLastDay(year, month) {
      // console.log(year,month);
      const date1 = new Date(year, month, 0)
      return date1.getDate()
    },
    getNowTime(e) {
      let date = new Date(e);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      hour = hour < 10 ? "0" + hour : hour;
      minute = minute < 10 ? "0" + minute : minute;
      return year + "-" + month + "-" + day + " " + hour + ":" + minute;
    },
    getList() {
      orderList_jilu().then(res=>{
        if(res.data.status == 200){
          console.log(res.data.data)
          let list = res.data.data.list
          list.forEach(item=>{
            item.confirm_time_text = this.getNowTime(item.confirm_time*1000)
          })
          this.list = list
        }
      })
      // get_work_list(this.$store.state.token, star, end).then(res => {
      //   if (res.data.status == true) {
      //     let list = res.data.data
      //     list.forEach(item => {
      //       if (item.come_work_time) {
      //         item.come_work_ime1 = item.come_work_time.split(' ')[0] + ' ' + item.come_work_time.split(' ')[1].split(':')[0] + ':' + item.come_work_time.split(' ')[1].split(':')[1]
      //         item.month = item.come_work_time.split(' ')[0].split('-')[1] + '/' + item.come_work_time.split(' ')[0].split('-')[2]
      //       } else {
      //         item.come_work_time1 = '未打卡'
      //       }
      //       if (item.go_work_time) {
      //         item.go_work_time1 = item.go_work_time.split(' ')[0] + ' ' + item.go_work_time.split(' ')[1].split(':')[0] + ':' + item.go_work_time.split(' ')[1].split(':')[1]
      //         item.month = item.go_work_time.split(' ')[0].split('-')[1] + '/' + item.go_work_time.split(' ')[0].split('-')[2]
      //       } else {
      //         item.go_work_time1 = '未打卡'
      //       }
      //     })
      //     this.list = list
      //     if (this.year == new Date(this.serverTime * 1000).getFullYear() && this.nowMonth == new Date(this.serverTime * 1000).getMonth() + 1) {
      //       this.xiuxiDay = this.getLastDay(new Date(this.serverTime * 1000).getFullYear(), new Date(this.serverTime * 1000).getMonth() + 1) - this.list.length
      //     } else {
      //       this.xiuxiDay = this.lastDay - this.list.length
      //     }
      //   } else {
      //     Toast(res.data.msg)
      //   }
      // }).catch(err => {
      //   console.log(err)
      // })
    },
    lastMonth() {
      this.nowMonth -= 1
      if (this.nowMonth <= 0) {
        this.nowMonth = 12
        this.year -= 1
      }

      this.lastDay = this.getLastDay(this.year, this.nowMonth)
      let star = this.year + '-' + this.nowMonth + '-' + 1
      let end = this.year + '-' + this.nowMonth + '-' + this.lastDay
      this.getList(star, end)
    },
    nextMonth() {
      if (this.year <= new Date(this.serverTime * 1000).getFullYear()) {
        if (this.year == new Date(this.serverTime * 1000).getFullYear() && this.nowMonth >= (new Date(this.serverTime * 1000).getMonth() + 1)) {
          return
        }
        this.nowMonth += 1
        if (this.nowMonth >= 13) {
          this.nowMonth = 1
          this.year += 1

        }
        this.lastDay = this.getLastDay(this.year, this.nowMonth)
        let star = this.year + '-' + this.nowMonth + '-' + 1
        let end = this.year + '-' + this.nowMonth + '-' + this.lastDay
        this.getList(star, end)
      }

    },
    mobile(){},
    godetails(item){
      this.$router.push({
        path: 'employee_order_details',
        query: {action: 2, order_type: 0, order_id: item.order_id,}
      })
    },
  },
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'接单记录'"></navigationBar>
      <div class="listBox">
        <div class="listEvery" v-for="(item,index) in list" :key="index" @click.stop.prevent="godetails(item)">
          <div class="head">
            <div class="left">{{item.user_location}}</div>
            <div class="right">{{item.order_id}}</div>
          </div>
          <div class="body">
            <div class="line">
              <div class="label">客户姓名：</div>
              <div class="value">{{item.real_name}}</div>
            </div>
            <div class="line">
              <div class="label">送达时间：</div>
              <div class="value">{{item.confirm_time_text}}</div>
            </div>
            <div class="line">
              <div class="label">用户备注：</div>
              <div class="value">{{item.remark}}</div>
            </div>
          </div>
          <div class="footer">
            <div class="btn" @click.stop="mobile"><a :href="`tel:${item.user_phone}`"><span class="iconfont icon-dianhua-F"></span></a></div>
          </div>
        </div>
        <div class="none" v-if="list.length<=0">
          暂无数据
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
.listBox {
  width: 100%;
  margin: 0 auto;
  background-color: white;

  .listEvery {
    //width: 90%;
    margin: 10px 16px;
    box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
    border-radius: 10px;
    //display: flex;
    align-items: center;
    padding: 10px;
    //justify-content: space-between;
    position: relative;
    .head{
      display: flex;
      justify-content: space-between;
      .left{
        color: #ff0000;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
      .right{
        color: #1b1b1b;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
    .body{
      margin-top: 8px;
      .line{
        margin: 2px 0;
        display: flex;
        .label{}
        .value{}
      }
    }
    .footer{
      text-align: right;
      .btn{
        display: inline-block;
        margin-right: 10px;
        border-radius: 8px;
        background: #0085ff;
        padding: 8px 26px;
        color: #fff;
        .iconfont{
          color: #fff;
        }
      }
    }

  }
}
</style>