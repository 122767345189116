import request from "@/utils/request";
import store from "../store/index";
const newDevHost = store.state.newDevHost;


/**
 * 秒杀产品时间区间
 *
 */
export function seckill_index(){
    return request.get(`${newDevHost}/api/seckill/index`);
}
/**
 * 秒杀产品列表
 *
 */

export function sckill_list(time,page,limit){
    return request.get(`${newDevHost}/api/seckill/list/${time}?page=${page}&limit=${limit}`);
}
/**
 * 秒杀产品详情
 *
 */
export function seckill_detail(id){
    return request.get(`${newDevHost}/api/seckill/detail/${id}`)
}