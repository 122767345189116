<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {getGiftCardDetail} from "@/api/gift_card";
import {Toast} from "vant";

export default {
  name: "gift_card_details",
  computed: {

  },
  components: {navigationBar},
  data() {
    return {
      img: '',
      details: {},
      show:false,
      customValue:'',
      bottomCardLi: [
        {title: '适用范围&场景', list: ['适用平台：尚百街', '适用商品或服务类别：服装、食品', '适用场景：生日送礼、节日祝福、商务馈赠']},
        {
          title: '使用说明&场景',
          list: ['使用方法：简单介绍如何在相应的商家或平台上使用', '礼品卡，包括可能的激活步骤、兑换方式等', '有效期：购买后一个月']
        },
        {
          title: '购买须知&场景',
          list: ['尊敬的平台用户，请您购买付费阅读服务前务必谨慎阅读以下条款：', '1.【温馨提示】 此卡一旦售出，概不退换。', '2.【付费阅读服务不适用于七天无理由退货】请您知悉，平台上提供的付费阅读为在线阅读商品，根据《消费者权益保护法》等规定，不适用七天无理由退货规定。']
        },
      ],
      detailsHtml:'',
    }
  },
  mounted() {
    this.getDetails(this.$route.query.id)
  },
  methods: {
    getDetails(id) {
      getGiftCardDetail(id).then(res => {
        if (res.data.status == 200) {
          const {img} = res.data.data
          this.img = img
          this.details = res.data.data
          if (this.details.type == 1) {
            this.details.yuan = this.details.price.split('.')[0]
            this.details.mao = this.details.price.split('.')[1]
          }
          this.getHtmlPic(this.details.content)
        }
      })
    },
    getHtmlPic(val) {
      if (!val) return;
      const srcList = [];
      this.detailsHtml = val.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
        srcList.push(capture); // capture图片地址  match img标签整体
        return `<img src=${capture} class="content-img" style="width:100%;display:block">`; //给图片添加class方便后面取dom
      });
      this.$nextTick(() => {
        this.$nextTick(() => {
          const imgHtml = document.getElementsByClassName('content-img');
          for (let i = 0; i < imgHtml.length; i += 1) {
            imgHtml[i].onclick = () => {
              //解决点击预览时不是当前图片问题
              const tempImgList = [...srcList]; // 所有图片地址
              if (i === 0) {
                this.ViewList = tempImgList;
              } else {
                // 调整图片顺序，把当前图片放在第一位
                const start = tempImgList.splice(i);
                const remain = tempImgList.splice(0, i);
                this.ViewList = start.concat(remain);
              }
              this.ViewerShow = true
            };
          }
        });
      })
    },
    submit() {
      if(this.details.type == 1){
        //z直接下单
        this.$router.push({
          path:'/create_gift_card',
          query:{data:JSON.stringify(this.details)}
        })
      }else if(this.details.type == 2){
        //自定义价格
        this.show = true
      }
    },
    confirm(){
      if(Number(this.customValue)){
        if(Number(this.customValue)>=1){
          this.details.price = Number(this.customValue);
          this.details.yuan = Number(this.customValue).toFixed(2).split('.')[0];
          this.details.mao = Number(this.customValue).toFixed(2).split('.')[1];
          this.$router.push({
            path:'/create_gift_card',
            query:{data:JSON.stringify(this.details)}
          })
        }
        //
      }else{
        Toast('请输入数字')
      }
    },
  },

}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'礼品卡详情'" class="navigation"></navigationBar>
      <div class="topImgBox">
        <img :src="img" class="topImg"/>
      </div>
      <div class="head">
        <div class="priceBox" :style="`background-image: url('${$store.state.imageHost}/img/image@2x.png')`">
          <div class="priceIn" v-if="details.type == 1">
            <div class="unit">￥</div>
            <div class="price">{{ details.yuan }}</div>
            .
            <div class="mao">{{ details.mao }}</div>
            <div class="ot_prcie">￥{{ details.original_price }}</div>
          </div>
          <div class="custom" v-if="details.type == 2">
            ￥自定义价格
          </div>
        </div>
        <div class="detail_info">
          <div class="title">{{ details.title }}</div>
          <div class="line">
            <div class="btn">
              <div class="top">{{ details.type == 1 ? details.price : '自定义价格' }}</div>
              <div class="foot">面额</div>
            </div>
            <div class="btn centerBtn">
              <div class="top">{{ details.unit }}</div>
              <div class="foot">单位</div>
            </div>
            <div class="btn">
              <div class="top" v-if="details.catetag">{{ details.catetag.cate_tag_name }}</div>
              <div class="foot">主题</div>
            </div>
          </div>
        </div>
      </div>
      <div class="details">
        <div class="title">图文详情</div>
        <div class="inner" v-html="details.content"></div>
      </div>
      <div class="bottomBox">
        <div class="btn" @click="submit">
          ￥{{ details.type == 1 ? details.price : '自定义价格' }} 立即购买
        </div>
      </div>
      <div class="card" v-for="(item,index) in bottomCardLi" :key="index">
        <div class="title">{{item.title}}</div>
        <div class="text" v-for="(item2,index2) in item.list" :key="index2">
          {{item2}}
        </div>
      </div>


      <van-dialog v-model="show" title="自定义价格" class="pop" @confirm="confirm" round-button>
        <div class="title">输入自定义价格后，该礼品卡的面值余额为您所支付的价格</div>
        <div class="inputBox">
          <div class="left">面值价格</div>
          <input type="number" v-model="customValue" class="input">
          <div class="placeholder">（元）</div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<style scoped lang="scss">
.area {
  min-height: 100vh;
  padding-bottom: 100px;
}

.topImgBox {
  margin: 20px 16px;

  .topImg {
    width: 100%;
    height: 214px;
    border-radius: 10px;
    object-fit: cover;
  }
}

.head {

  .priceBox {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 16px 16px 60px;

    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    .priceIn {
      display: flex;
      align-items: baseline;
    }

    .custom {
      color: #ffffff;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-align: left;
    }

    .price {
      font-size: 24px;
    }

    .ot_prcie {
      color: #fee1df;
      font-family: "PingFang SC";
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      text-decoration: line-through;
      margin-left: 10px;
    }

  }

  .detail_info {
    margin-top: -50px;
    background: #fff;
    padding: 12px 16px;
    border-radius: 20px 20px 0 0;
    text-align: left;

    .title {
      display: -webkit-box;
      /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
      -webkit-box-orient: vertical;
      /*要显示的行数*/
      -webkit-line-clamp: 3;
      /* 溢出部分隐藏 */
      overflow: hidden;

      color: #1b1b1b;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }

    .line {
      display: flex;
      margin: 15px 0;
      border-radius: 6px;
      background: #f7f8fa;
      padding: 10px 0;

      .btn {
        flex: 1;
        text-align: center;
        width: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .top {
          color: #131313;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
        }

        .foot {
          color: #999999;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          margin-top: 5px;
        }
      }

      .centerBtn {
        position: relative;
      }

      .centerBtn::after, .centerBtn::before {
        display: block;
        content: '';
        position: absolute;
        background: #d9d9d9;;
        width: 1.1px;
        height: 18px;
        transform: translate(-50%, -50%);
        top: 50%;
      }

      .centerBtn::after {
        left: 0;
      }

      .centerBtn::before {
        right: 0;
      }

    }
  }
}

.details {
  border-top: 5px solid #f7f8fa;

  .title {
    padding: 10px;
    color: #1b1b1b;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
}

.bottomBox {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -0.5px 2px #99999926;
  padding: 10px 0;

  .btn {
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 10px 24px;
    border-radius: 32px;
    background: #fc223b;
    margin: 0 16px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}
.card{
  text-align: left;
  border-radius: 10px;
  background: #f7f8fa;
  margin: 10px 16px;
  padding: 10px;
  .title{
    color: #1b1b1b;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .text{
    color: #5e6064;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }
}
.pop{

  .title{
    color: #999999;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    margin-top: 10px;
  }
  .inputBox{
    display: flex;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 2px #66666640;
    margin: 10px 16px;
    padding: 10px;
    align-items: center;
    .left{
      color: var(--666666, #666666);
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
    .placeholder{
      color: #c7c7c7;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
    .input{
      width: 55%;
      border: none;
      padding-left: 10px;
    }
  }
}
.van-dialog{
  width: 80%;
}
</style>