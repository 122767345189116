<script>
import idle_tabbar from "@/components/common/tabbar/idle_tabbar.vue";
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {_throttle, isValidJSON} from "@/utils/tool";
import {getFreeMarkList} from "@/api/idle";

export default {
  name: "liberty",
  components: {navigationBar, idle_tabbar},
  data() {
    return {
      hasMore: true,//是否可以继续滚动
      loadLock: true,//是否展示loding

      mainMenuList: [],
      leftList: [],
      rightList: [],
      leftHeight: 0,
      rightHeight: 0, //分别定义左右两边的高度
      oldScrollTop: 0,
    }
  },
  methods:{
    getBoxHeight(leftList, rightList, _this) {
      //获取左右两边高度
      return new Promise((resolve) => {
        _this.$nextTick(() => {
          _this.leftList = leftList;
          _this.rightList = rightList;
          _this.leftHeight = _this.$refs.left.offsetHeight;
          _this.rightHeight = _this.$refs.right.offsetHeight;
          // console.log("左边", _this.leftHeight, "右边", _this.rightHeight);
        });
        resolve();
      });
    },
    containerScroll(event) {
      console.log(event)
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const {scrollHeight, clientHeight, scrollTop} = event.target;
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          if (this.hasMore) {
            this.loadLock = true
            this.qingqiu(this);
          }
        }
      }
    },

    qingqiu: _throttle(async function (_that) {
      // const { leftList, rightList, mainMenuList, leftHeight, rightHeight } =
      getFreeMarkList(this.$store.state.token,).then(async res => {
        let list = res.data.data.data
        list.forEach(item => {
          // console.log(item.distance,item.dx.split(',')[0],item.dx.split(',')[1],getDistances(this.$store.state.nowPosition.location.lat,this.$store.state.nowPosition.location.lng,item.dx.split(',')[1],item.dx.split(',')[0]))
          // item.distanceObj = getDistances(this.$store.state.nowPosition.location.lat,this.$store.state.nowPosition.location.lng,item.dx.split(',')[1],item.dx.split(',')[0])
          if (item.images) {
            if (isValidJSON(item.images)) {
              item.showImg = JSON.parse(item.images)[0]
            } else {
              item.showImg = item.images.split(',')[0]
            }
            if (typeof item.showImg == 'string') {
              if (!item.showImg.startsWith('https:')) {
                item.showImg = this.$store.state.imageHost + item.showImg
              } else {
                item.showImg = item.showImg
              }
            } else {
              item.showImg = this.$store.state.imageHost + item.showImg.tempFilePath
            }
          }
        })
        this.mainMenuList = list
        if (this.mainMenuList.length >= list.length) {
          this.hasMore = false
        }
        for (const item of _that.mainMenuList) {
          console.log("左边", _that.leftHeight, "右边", _that.rightHeight);
          if (_that.leftHeight <= _that.rightHeight) {
            _that.leftList.push(item);
          } else {
            _that.rightList.push(item);
          }
          await _that.getBoxHeight(_that.leftList, _that.rightList, _that);
        }
        this.loadLock = false
      }).catch(err => {
        console.log(err)
      })

      // _that.leftList = leftList;
      // _that.rightList = rightList;
      // _that.leftHeight = _that.$refs.left.offsetHeight;
      // _that.rightHeight = _that.$refs.right.offsetHeight;
      // console.log("左边", leftHeight, "右边", rightHeight);
      // console.log(_that);
    }, 500),


    godetails(item){
      this.$router.push({
        path:'/idleDetails',
        query:{id:item.id}
      })
    },
  },
  mounted() {
    this.qingqiu(this)
  }
}
</script>

<template>
  <div class="container" ref="container" @scroll="containerScroll">
    <div class="area">
      <navigationBar
          :title="'自由市场'"
          :backBtn="false"
          class="navigation"
      ></navigationBar>

      <div class="pubu">
        <div id="box">
          <div id="left-box" ref="left">
            <div class="item" v-for="(item,index) in leftList" :key="index" @click="godetails(item)">
              <div class="imageBox">
                <img :src="item.showImg" alt="" class="img">
              </div>
              <div class="bottom">
                <div class="name">{{ item.product_name }}</div>
                <div class="sellNums">{{ item.sell_count }}</div>
                <div class="priceLine">
                  <div class="nowPrice">
                    <div class="leftUnit">￥</div>
                    <div class="rightPrice">{{ item.price }}</div>
                  </div>
                  <div class="lastPrice">
                    预览{{ item.visit_count }}
                  </div>
                </div>
                <div class="userInfo">
                  <div class="left">
                    <img class="avatar"
                         :src="$store.state.imageHost+item.avatar"
                         alt="">
                    <span class="nickname">{{ item.nickname }}</span>
                  </div>
                  <div class="rightAdd">
<!--                    <span class="iconfont icon-Frame11"></span>-->
<!--                    {{ item.distanceObj.distance_str }}-->
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div id="right-box" ref="right">
            <div class="item" v-for="(item,index) in rightList" :key="index" @click="godetails(item)">
              <div class="imageBox">
                <img :src="item.showImg" alt="" class="img">
              </div>
              <div class="bottom">
                <div class="name">{{ item.product_name }}</div>
                <div class="sellNums">{{ item.sell_count }}</div>
                <div class="priceLine">
                  <div class="nowPrice">
                    <div class="leftUnit">￥</div>
                    <div class="rightPrice">{{ item.price }}</div>
                  </div>
                  <div class="lastPrice">
                    预览{{ item.visit_count }}
                  </div>
                </div>
                <div class="userInfo">
                  <div class="left">
                    <img class="avatar"
                         :src="$store.state.imageHost+item.avatar"
                         alt="">
                    <span class="nickname">{{ item.nickname }}</span>
                  </div>
                  <div class="rightAdd">
<!--                    <span class="iconfont icon-Frame11"></span>-->
<!--                    {{ item.distanceObj.distance_str }}-->
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="loader-box">
          <div id="loader" v-if="loadLock"></div>
          <div id="nothing" v-if="!loadLock&&!hasMore">没有更多了~</div>
        </div>
      </div>
      <div style="width: 100%;height: 100px;"></div>

      <idle_tabbar :active="1" ref="tabbar"></idle_tabbar>
    </div>
  </div>
</template>

<style scoped lang="scss">
.pubu{
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.container {
  height: 100vh;
  background-color: #F9F9F9;
}

.navigation {
  background-color: #fff;
}
#box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

#box img {
  object-fit: cover;
  background-color: #eee;
  margin-bottom: 10px;
}

#left-box,
#right-box {
  width: 180px;


  .item {
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    /* box-shadow: 0rpx 0rpx 4rpx 0rpx rgba(102,102,102,0.25); */
    display: flex;
    flex-direction: column;
    text-align: left;

    .img {
      width: 100%;
      background-color: #fff;
    }

    .imageBox {
      background-color: #fff;
    }

    .bottom {
      padding: 6px 10px;

      .unit {
        font-size: 10px;
      }

      .price {
        color: #FC223B;
        font-size: 18px;
      }

      .priceLine {
        display: flex;
        color: #FC223B;
        align-items: baseline;
        width: 100%;
        margin-top: 6px;
        justify-content: space-between;

        .nowPrice, .lastPrice {
          display: flex;
          align-items: baseline;

          .leftUnit,
          .rightPrice {
            font-size: 12px;
            color: #7f7f7f;
          }
        }

        .leftUnit {
          font-size: 10px;
          line-height: 10px;
        }

        .rightPrice {
          font-weight: 400;
          width: 95%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .nowPrice {
          .rightPrice {
            font-size: 18px;
          }

          .leftUnit,
          .rightPrice {
            color: #FC223B !important;
          }
        }

        .lastPrice {
          text-align: right;
          color: #7f7f7f;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        }
      }

      .nowPrice {
        max-width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }


      .userInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;

        .left {
          width: 50%;

          .avatar {
            width: 18px;
            height: 18px;
            object-fit: cover;
            border-radius: 50%;
            margin-bottom: 0;
            vertical-align: middle;
            margin-right: 4px;
          }

          .nickname {
            display: inline-block;
            color: #999999;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;

          }

        }

        .rightAdd {
          text-align: right;
          color: #999999;
          font-weight: 400;
          font-size: 11px;
          line-height: 18px;
        }
      }

    }

    .name {
      display: -webkit-box;
      /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
      -webkit-box-orient: vertical;
      /*要显示的行数*/
      -webkit-line-clamp: 2;
      /* 溢出部分隐藏 */
      overflow: hidden;
      font-size: 14px;
      color: #1b1b1b;
    }

    .sellNums {
      line-height: 18px;
      font-size: 12px;
      color: #7f7f7f;
    }
  }
}

.loader-box {
  width: 375px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loader {
  width: 25px;
  height: 25px;
  border: 3px solid #ddd;
  border-radius: 50%;
  border-bottom: 3px solid #717171;
  transform: rotate(0deg);
  animation: loading 1s linear infinite;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

</style>