<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {Toast} from "vant";
import MaskDiv from "@/components/common/invite_job/online/mask/mask.vue";
import {getRecruitLanguageList,createLanguage,updateLanguage} from "@/api/recruitment"
export default {
  name: "frequently",
  components: {MaskDiv, navigationBar},
  data(){
    return{
      list:[],
      maskType:'',
      chooseValue:'',
      chooseObj:{},
      set:false,
    }
  },
  methods:{
    getList(){
      getRecruitLanguageList().then((res)=>{
        if(res.data.status == 200){
          let list = res.data.data
          this.list = list
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    openMask(e) {
      this.maskType = e
    },
    openMask22(e,item){
      if(this.set){
        this.maskType = e
        this.chooseValue = item.content
        this.chooseObj = item
      }
    },
    closeMask(){
      this.maskType = ''
      this.chooseValue = ''
      this.chooseObj = {}
    },
    submitMask(e){
      if(this.set){
        updateLanguage(this.chooseObj.id,e).then((res)=>{
          if(res.data.status == 200){
            this.maskType=''
            this.getList()
            this.set = false
            Toast({
              message: '修改成功',
              duration:1000,
              onClose:()=>{
              }
            });
          }else{
            Toast(res.data.msg)
          }
        }).catch(err=>{
          console.log(err)
        })
      }else{
        createLanguage(e).then((res)=>{
          if(res.data.status == 200){
            this.maskType=''
            Toast({
              message: '添加成功',
              duration:1000,
              onClose:()=>{
                this.getList()
              }
            });
          }else{
            Toast(res.data.msg)
          }
        }).catch(err=>{
          console.log(err)
        })
      }

    },

    setEdti(){
      this.set = !this.set
    },
  },
  mounted() {
    this.getList()
  }
}
</script>

<template>
<div class="container">
  <div class="area">
    <navigationBar :title="'常用语'" class="navigationBar">
    </navigationBar>

    <MaskDiv @closePop="closeMask"
             @submit="submitMask"
             class="popmask"
             v-if="maskType"
             :maskType="maskType"
             :chooseValue="chooseValue"
    >
    </MaskDiv>
    <div class="ul">
      <div class="li" v-for="(item,index) in list" :key="index" @click="openMask22('frequently',item)">
        <div class="in">
          <span class="text">{{ item.content }}</span>
          <span class="iconfont icon-changyongyu-xiugai" v-if="set"></span>
        </div>
      </div>

<!--      <div class="tag">/</div>-->
    </div>
    <div class="bot">
      <div class="left eve" @click="openMask('frequently')" v-if="!set">
       <span class="iconfont icon-changyongyu-tianjia"></span> 添加
      </div>
      <div class="right eve" @click="setEdti">
        <span class="iconfont icon-changyongyu-xiugai"></span> {{set?'取消':'修改'}}
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.container{
  .area{
    height: 100vh;
  }
}
.ul{
  .li{
    text-align: left;
    margin: 0px 16px;
    padding: 20px 0;
    border-bottom: 1px solid #F6F6F6;
    .in{
      display: flex;
      align-items: center;
      .text{
        display: inline-block;
        width: 95%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #1b1b1b;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      .iconfont{
        font-size: 24px;
        color: #666666;
      }
    }
  }
  .tag{
    margin-top: 10px;
  }
}
.popmask {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: #fff;
  left: 0;
  top: 0;
}
.bot{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  gap: 69px;
  flex-shrink: 0;
  padding: 12px 0px;
  background: #ffffff;
  box-shadow: 0px -0.5px 2px #99999926;
  display: flex;
  justify-content: space-between;
  .eve{
    width: 45%;
    vertical-align: middle;
    text-align: center;
    color: #1b1b1b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    .iconfont{
      font-size: 24px;
      vertical-align: middle;
      color: #FC223B;
    }
  }
}
</style>