<script>
import idle_tabbar from "@/components/common/tabbar/idle_tabbar.vue";
import {Dialog, Toast} from "vant";
import Bus from "@/utils/Bus";
import {getListCount,getRegAuthentDetail} from "@/api/idle";

export default {
  name: "mine",
  components: {idle_tabbar},
  data(){
    return{
      StatisticsLi:[
        {text:'我发布的',nums:1,icon:'',id:1},
        {text:'我卖出的',nums:0,icon:'',id:2},
        {text:'我买到的',nums:0,icon:'',id:3},
        {text:'收藏宝贝',nums:0,icon:'',id:4},
      ],
    }
  },
  methods:{
    gologin() {
      this.$router.push({name: '登录'})
    },
    authentication(){
      this.$router.push({path: '/idle_certification',query:{show:true}})
    },
    FAQ(){this.$router.push({path: '/FAQ'})},
    feedback(){
      this.$router.push({path: '/problem_feedback'})
    },
    async recycleIn(){
      console.log(await this.getIndividual())
      if(await this.getIndividual()){
        if((await this.getIndividual()).data){
          this.$router.push('recycleCheckIn')
        }else{
          Dialog.alert({
            message: '您还未进行身份认证，请前往认证',
            showCancelButton:true,
            confirmButtonText:'去认证',
          }).then(() => {
            // on close
            this.$router.push({path: '/idle_certification',query:{type:'personage'}})
          }).catch(()=>{
            // on catch
          });
        }
      }else{
        Toast('错误，请重试')
      }
    },
    async removeIn(){
      if(await this.getIndividual()){
        if((await this.getIndividual()).data){
          this.$router.push('MoveCheckIn')
        }else{
          Dialog.alert({
            message: '您还未进行身份认证，请前往认证',
            showCancelButton:true,
            confirmButtonText:'去认证',
          }).then(() => {
            // on close
            this.$router.push({path: '/idle_certification',query:{type:'personage'}})
          }).catch(()=>{
            // on catch
          });
        }
      }else{
        Toast('错误，请重试')
      }
    },
    statisticsFun(item){
      if(item.id == 1){
        this.$router.push('/myRelease')
      }
      if(item.id == 2){
        this.$router.push('/mySell')
      }
      if(item.id == 3){
        this.$router.push('/myBought')
      }
      if(item.id == 4){
        this.$router.push('/myCollect')
      }
    },
    topFunOne(){console.log('发布店铺转让')},
    topFunTwo(){console.log('发布办公转租')},


    getNums(){
      getListCount(this.$store.state.token).then(res=>{
          if(res.data.status == 200){
            let obj  = res.data.data
            this.StatisticsLi[0].nums = obj.publish_count
            this.StatisticsLi[1].nums = obj.sale_count
            this.StatisticsLi[2].nums = obj.buy_count
            this.StatisticsLi[3].nums = obj.collect_count
          }else{
            Toast(res.data.msg)
          }
      }).catch(err=>{
        console.log(err)
      })
    },

    async getIndividual(){
      if((await getRegAuthentDetail(this.$store.state.token)).data.status == 200){
        return (await getRegAuthentDetail(this.$store.state.token)).data
      }else{
        return false
      }
    },
  },
  mounted() {
    Bus.$emit("updataUserInfo", '1');
    this.getNums()

  },
  beforeDestroy() {
    Bus.$off("sendUserInfo");
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <div class="topNav">
        <div class="topInfo" :class="!$store.state.token?'nologinInfo':''">
          <div class="userInfo" >
            <div class="leftImg" v-if="$store.state.token">
              <!-- <img class="avatar" src="https://picture.bjbjkj.top/tp2024062803111511572202305.png" alt="" /> -->
              <el-image
                  v-if="$store.state.user.avatar"
                  class='avatar'
                  :src="$store.state.user.avatar"
                  :preview-src-list="[$store.state.user.avatar]">
              </el-image>
            </div>
            <div class="rightInfo" v-if="$store.state.token">
              <div class="username">{{ $store.state.user.nickname }}</div>
              <div class="mobile">{{ $store.state.user.mobile }}</div>
            </div>
            <div class="noLogin" v-if="!$store.state.token" @click="gologin">
              <img class="avatar" :src="$store.state.imageHost+'/touxiang/7fc545aedbedcaf7f6ef03cea3ef206.png'" alt="" />
              请登录~
            </div>
          </div>
          <div v-if="$store.state.token"
               style="text-align: right; width: 10%; min-width: 30px;display:flex;align-items: center;"
              >
          </div>
        </div>
      </div>
<!--      <div class="card topFun">-->
<!--        <div class="left every" @click="topFunOne">-->
<!--          <div class="title">发布店铺转让</div>-->
<!--          <div class="text">"旺铺转让，商机无限"</div>-->
<!--          <img :src="$store.state.imageHost+'/img/idleRFun.png'" alt="" class="backg">-->
<!--        </div>-->
<!--        <div class="right every" @click="topFunTwo">-->
<!--          <div class="title">发布办公转租</div>-->
<!--          <div class="text">"焕发生机，期待未来"</div>-->
<!--          <img :src="$store.state.imageHost+'/img/idleLFun.png'" alt="" class="backg">-->
<!--        </div>-->
<!--      </div>-->

      <div class="card statistics">
        <div class="every" v-for="(item,index) in StatisticsLi" :key="index" @click="statisticsFun(item)">
          <img src="https://m.360buyimg.com/babel/jfs/t1/189590/5/43143/7643/660157ebF638a43d9/b2843ee17c84c1b4.png" alt="" class="iconImg">
<!--          <span class="iconfont icon-pengyouquan"></span>-->
          <div class="text">
            {{item.text}}
          </div>
          <div class="nums" v-if="item.nums>0">
            {{item.nums}}
          </div>
        </div>
      </div>

      <div class="card body">
        <van-cell-group>
          <van-cell @click="authentication">
            <template #title>
              <span class="cell_text">实名认证</span>
            </template>
            <template #right-icon>
<!--              <div class="status">审核中</div>-->
            </template>
          </van-cell>
          <van-cell @click="FAQ">
            <template #title>
              <span class="cell_text">常见问题</span>
            </template>
            <template #right-icon>
              <span class="iconfont icon-Frame-4"></span>
            </template>
          </van-cell>
          <van-cell @click="feedback">
            <template #title >
              <span class="cell_text">问题反馈</span>
            </template>
            <template #right-icon>
              <span class="iconfont icon-Frame-4"></span>
            </template>
          </van-cell>
          <van-cell @click="recycleIn">
            <template #title>
              <span class="cell_text">回收入驻</span>
            </template>
            <template #right-icon>
              <span class="iconfont icon-Frame-4"></span>
            </template>
          </van-cell>
          <van-cell @click="removeIn">
            <template #title >
              <span class="cell_text">搬家入驻</span>
            </template>
            <template #right-icon>
              <span class="iconfont icon-Frame-4"></span>
            </template>
          </van-cell>
        </van-cell-group>
      </div>

      <idle_tabbar :active="4" ref="tabbar"></idle_tabbar>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container{
  background-color: #F4F6FA;
}
.topNav {
  top: 0;
  left: 0;
  width: 100%;
  background: #fff5e9;
  z-index: 2;
  padding-top: 20px;
  position: relative;
}
.topInfo {
  margin: 0 16px;
  display: flex;
  align-items: center;
  padding-bottom: 42px;

  .userInfo {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    .rightInfo {
      width: 75%;
      text-align: left;
      padding-left: 10px;

      .username {
        font-weight: 500;
        font-size: 18px;
        color: #1b1b1b;
        line-height: 24px;
      }

      .mobile {
        font-weight: 400;
        font-size: 14px;
        color: #1b1b1b;
        line-height: 18px;
        margin-top: 6px
      }
    }

    .noLogin {
      .avatar{
        border-radius: 50%;
        width: 54px;
        height: 54px;
        object-fit: cover;
      }
      display: flex;
      align-items: center;
      text-indent: 1em;
      line-height: 40px;
    }

    .leftImg {
      display: flex;
    }
  }

  .avatar {
    width: 54px;
    height: 54px;
    border-radius: 10px;
    object-fit: cover;
    // object-fit: fill; // 填充，撑满
    // object-fit: contain; // 保持原比例缩小
    // object-fit: cover;
    // object-fit: none;
    // object-fit: scale-down;
  }
}
.card{
  background-color: #fff;

}
.topFun{
  position: relative;
  z-index: 2;
  margin: -20px 16px 0 16px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  .every{
    width: 42%;
    text-align: left;
    padding: 10px;
    border-radius: 10px;
    padding-bottom: 40px;
    position: relative;
    overflow: hidden;
    .title{
      color: #8a52c6;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    .text{
      color: #97a3dc;
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
    }
    .backg{
      width: 55px;
      height: 55px;
      object-fit: cover;
      position: absolute;
      right: 0;
      bottom: -10px;
    }
  }
  .left{
    background: linear-gradient(101.67deg, #f4f2ff 0.02%, #f4f5ff 119.7%);
    .title{
      color: #8a52c6;
    }
    .text{
      color: #BFA4E3;
    }
  }
  .right{
    background: linear-gradient(101.67deg, #e3f0fe 0.02%, #f8fcff 119.7%);
    .title{
      color: #4250BA;
    }
    .text{
      color: #97A3DC;
    }
  }

}

.statistics{
  margin: 10px 16px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  .every{
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .nums{
      background-color: #FD2A39;
      border-radius: 50px;
      color: #c7c7c7;
      font-weight: 500;
      font-size: 10.5px;
      line-height: 14px;
      min-width: 14px;
      position: absolute;
      right: 20px;
      top: 0;
    }
    .iconImg{
      width: 40%;
      margin-bottom: 4.5px;
    }
    .iconfont{
      font-size: 32px;
      margin-bottom: 4.5px;
      line-height: 32px;
    }
    .text{
      color: #1b1b1b;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
    }
  }
}
.body{
  margin: 10px 16px;
  border-radius: 10px;
  padding: 0 10px;

  text-align: left;
  .van-cell{
   padding:  15px 0;
    .cell_text{
      color: #333333;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
    .iconfont{
      font-size: 30px;
      width: 20px;
      color: #999999;
    }
    .status{
      color: #999999;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
</style>