import axios from 'axios'
// import { Loading } from 'element-ui';
import { Toast } from 'vant';

import router from '../router/'
import store from "@/store";
// import {sendApp} from "@/utils/tool";

// import {
//     Message
// } from 'element-ui'
const request = axios.create({
    baseURL: "https:/dev.taobaijie.com/",//开发域名
    timeout: 20000,
});
// service.defaults.headers.post['Content-Type'] = 'content-type:application/json'; // 配置请求头
// service.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;application/x-www-form-urlencoded' // 配置请求头

//添加请求拦截器
request.interceptors.request.use(

    config => {
        // Loading.service({
        //     lock: true,
        //     text: '加载中.....',
        //     spinner: 'el-icon-loading',
        //     background: 'rgba(0, 0, 0, 0.7)'
        // });
        Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: '加载中...',
        });
        config.headers['Content-Type'] = 'application/json'
        // config.headers['Content-Type'] = 'application/json;application/x-www-form-urlencoded;charset=UTF-8;application/x-www-form-urlencoded;'
        if (store.state.token) config.headers['Authori-zation'] = 'Bearer '+store.state.token;
        if (config.data == undefined) {
            config.data = {}
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    });
//添加响应拦截器
request.interceptors.response.use(
    response => {
        if (response.data.status != 200) {
            if ([410000, 410001, 410002].indexOf(response.data.status) !== -1) {
                Toast('请重新登录')
                setTimeout(() => { // 返回请先登录的状态码 回跳登录
                    sessionStorage.removeItem('token')
                    sessionStorage.removeItem('user_id')
                    sessionStorage.removeItem('timeStamp')
                    router.options.isAddDynamicMenuRoutes = false
                    if(window.location.hash!='#/Login'){
                        if (window.ReactNativeWebView) {
                            // window.ReactNativeWebView.postMessage("8");
                            // sendApp()
                            window.sendFun()

                        }else{
                            router.push({
                                name: '登录'
                            })
                        }
                    }
                }, 600)
            }  else if (response.data.status == 410010){
                Toast(response.data.msg)
            }else if(response.data.status == 410020){
                Toast(response.data.msg)
            } else if(response.data.status == 403){
                return response
            }else{
                return (response || '系统错误');
            }
        }
        Toast.clear()
        return response;
    },
    error => {
        if (!error.response) {
            // Loading.service().close();
            Toast.clear()
            Toast('请求超时')
            // Message.warning('请求超时')
            return
        }
        console.log(error,'error')
        // Loading.service().close();
        Toast.clear()
        if (error.response.status) {
            switch (error.response.status) {
                case 404:
                    Toast('服务器被外星人抓走了~')
                    // Message.warning('服务器被外星人抓走了~')
                    break
                // 其他错误，直接抛出错误提示
                default:
                    Toast('服务器发生意外错误，错误code：\' + error.response.status')
                // Message.warning('服务器发生意外错误，错误code：' + error.response.status)
            }
        }
        return Promise.reject(error)
    });
export default request;
