!
<template>
  <div class="container messageListPage">
    <div class="area">
      <div class="navigation">
        <div class="title">消息</div>
        <div class="clear">
          <van-icon name="filter-o"/>
          清除未读
        </div>
      </div>
      <div class="list">
        <div class="" v-for="(item,index) in messageList" :key="index">
          <van-swipe-cell>
            <div class="li" @click.stop="godetailsFun(item)">
              <!--          {{$store.state.imageHost}}{{item.anotherinfo.avatar?item.anotherinfo.avatar:item.anotherinfo.logo}}-->
              <div class="leftImg">
                <img class="avatar" :src="item.avatar" alt="">
              </div>
              <div class="rightBig">
                <div class="Rittop">
                  <div class="rttopTitle" v-if="!item.cus_type">{{ item.nickname }}</div>
                  <div class="rttopTime">{{ item._update_time }}</div>
                </div>
                <div class="Rittop">
                  <div class="rttopTitle message">
                    <span v-if="[1,2].includes(item.message_type)" v-html="item.message"></span>
                    <div v-else>{{ messageType(item.message_type)}}</div>
                  </div>
                  <div class="rttopTime unread" v-if="item.mssage_num>0">
                    {{ item.mssage_num > 99 ? '99+' : item.mssage_num}}
                  </div>
                </div>
              </div>
            </div>
            <template #right>
              <van-button square type="danger" text="删除" @click="deleteMessage(item)"/>
            </template>
          </van-swipe-cell>


        </div>

      </div>
      <tabbar :active="3"></tabbar>
    </div>


  </div>
</template>

<script>
import tabbar from '@/components/common/tabbar/home_tabbar.vue'
import navigationBar from '../../navigation-bar/navigation-bar.vue';
import {messageList, upUnread} from "@/utils/api";
import {Toast} from "vant";


export default {
  components: {
    tabbar,
    // eslint-disable-next-line vue/no-unused-components
    navigationBar
  },
  props: {},
  data() {
    return {
      messageList: [],
      serverTime: (new Date().getTime()) / 1000,
      allmessNum: 0,
      page: 1,
      limit: 10,
    };
  },
  watch: {},
  computed: {},
  methods: {
    messageType(val) {
      let obj = {
        3: '[图片]',
        4: '[语音]',
        5: '[商品]',
        6: '[订单]',
      };
      return obj[val]
    },
    deleteMessage(item) {
      console.log(item)
    },
    formatTime2(date) {
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      var hour = date.getHours()
      var minute = date.getMinutes()
      var second = date.getSeconds()
      console.log([year, month, day].map(this.formatNumber2).join('/'))
      return [year, month, day].map(this.formatNumber2).join('/') + ' ' + [hour, minute, second].map(this.formatNumber2).join(
          ':')
    },
    formatNumber2(n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    },
    dataChange(data) {
      // 时间处理 主要根据当前时间的凌晨和当前时间周一的凌晨 做判断依据 （时间都转化成时间戳好计算，自认为）
      var time = this.formatTime2(new Date(this.serverTime * 1000));
      // let date = time.replace(/\//g, "-")
      let date = time
      var date1 = new Date(date)//eslint-disable-line
      var lc = new Date(this.serverTime * 1000).setHours(0, 0, 0, 0) // 当前时间的凌晨的时间戳
      // console.log("当前时间的凌晨的时间戳 =>", lc)
      var nowlc = new Date(lc);
      var nowTime = nowlc.getTime();
      var day = nowlc.getDay();
      var oneDay = 24 * 60 * 60 * 1000;
      var Monday = nowTime - (day - 1) * oneDay;
      var monday = new Date(Monday);
      var mondaytamp = Number(monday); //当前时间周一凌晨的时间戳
      // console.log("当前时间周一凌晨的时间戳 =>", mondaytamp) //
      var date2 = new Date(data)
      var timetamp = Number(new Date(data)); // 转化从时间戳
      // console.log("后台获取的要处理的时间的时间戳 =>", timetamp)
      let d = ((lc - date2) / 1000) // 两个时间相差的秒数
      if (d > 0) {
        var min = (d / 60).toFixed(0)
        var h = (min / 60).toFixed(0)
        if (h <= 24) {
          data = "昨天"
        } else if (h <= 48) {
          data = "前天"
        } else if (h > 48) {
          let d = timetamp - mondaytamp
          if (d >= 0) {
            var l = ["日", "一", "二", "三", "四", "五", "六"];
            let z = date2.getDay()
            data = "周" + l[z]
            // if (z == 1) {
            //   data = "周一"
            // } else if (z == 2) {
            //   data = "周二"
            // } else if (z == 3) {
            //   data = "周三"
            // } else if (z == 4) {
            //   data = "周四"
            // } else if (z == 5) {
            //   data = "周五"
            // } else if (z == 6) {
            //   data = "周六"
            // } else if (z == 0) {
            //   data = "周日"
            // }
          } else if (d < 0) {
            let y = date2.getFullYear()
            let m = date2.getMonth()
            let d = date2.getDate()
            data = y + "年" + (m + 1) + "月" + d + "日"
          }
        }
      } else {
        // 当时时间段  时分
        let h = date2.getHours()
        let m = date2.getMinutes()
        h = h < 10 ? '0' + h : h
        m = m < 10 ? '0' + m : m
        data = h + ":" + m
      }
      return data
    },
    compareAlign(prop, align) {
      return function (a, b) {
        var value1 = a[prop];
        var value2 = b[prop];
        if (align == "positive") {//正序
          return new Date(value1) - new Date(value2);
        } else if (align == "inverted") {//倒序
          return new Date(value2) - new Date(value1);
        }
      }
    },
    getShopMessageList() {
      messageList(this.page, this.limit).then((res) => {
        if (res.data.status == 200) {
          console.log(res.data.data)
          let data = res.data.data.service;
          this.system = res.data.data.system;
          this.loading = false;
          data.forEach(item => {
            if ([1, 2].includes(item.message_type)) {
              item.message = this.replace_em(item.message);
            }
          });
          this.messageList = this.messageList.concat(data);
          this.finished = data.length < this.limit;
          this.page += 1;


          // this.messageList = list
          // this.allmessNum = allmessNum

          console.log(this.messageList)

        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    replace_em(str) {
      str = str.replace(/\[em-([a-z_]*)\]/g, "<span class='em em-$1'/></span>");
      return str;
    },
    godetailsFun(itemc) {
      console.log(itemc.to_uid)
      this.$router.push({
        path:`/chat?to_uid=${itemc.to_uid}&type=1`
      })
      // path: 

      // uni.navigateTo({
      //   url: '/pages/extension/customer_list/chat?to_uid=' + id + '&type=1'
      // })
    },


    clearUnready() {
      upUnread(this.$store.state.token).then(res => {
        console.log(res)
        this.allmessNum = 0
        this.messageList.forEach(item => {
          item.unread = 0
        })
      }).catch(err => {
        console.log(err)
      })
    },
  },
  created() {
  },
  async mounted() {
    // this.serverTime = await getNowTime()
    this.getShopMessageList()
  }
};
</script>
<style lang="scss" scoped>
.navigation {
  display: flex;
  padding: 20px 0 20px 16px;
  align-items: baseline;

  .title {
    line-height: 16px;
    font-size: 20px;

  }

  .van-icon {
    font-size: 12px;
  }

  .clear {
    font-size: 14px;
    margin-left: 5px;
  }

}

.list {
  height: 85vh;
  overflow-y: scroll;
  padding: 0 0 0 16px;

  .li {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    overflow: hidden;

    .leftImg {
      //display: flex;

      .avatar {
        width: 54px;
        height: 54px;
        border-radius: 10px;
      }
    }

    .rightBig {
      //flex: 1;
      margin-left: 10px;
      //padding-right: 10px;
      border-bottom: 1px solid #99999926;
      //border-bottom: 1px solid red;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //padding-bottom: 5px;
      width: 80%;

      .Rittop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .rttopTitle {
          max-width: 55%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          color: #1b1b1b;
        }

        .rttopTime {
          max-width: 40%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          color: #999999;
        }

        .message {
          font-size: 14px;
          color: #999999;
          max-width: 80%;
          min-height: 20px;
        }

        .unread {
          color: white;
          background-color: #F74440;
          line-height: 14px;
          min-height: 14px;
          min-width: 18px;
          text-align: center;
          border-radius: 50px;
          padding: 3px;
        }
      }

      .Rittop:nth-last-of-type(1) {
        margin-top: 2px;
      }

      padding-bottom: 12px;
    }
  }
}

.list::-webkit-scrollbar {
  display: none;
}
</style>
<style>
.messageListPage .van-button {
  height: 100%;
}
</style>