import request from "@/utils/request";
import store from "../store/index";
import service from "@/utils/axios";
const newDevHost = store.state.newDevHost;
/*
* 获取招聘列表
* */
export function job_list(page,limit,page_view,ctime_status,position,job_type,industry_id,industry_type_id){
    return request.get(`${newDevHost}/api/job_list?page=${page}&limit=${limit}&page_view=${page_view}&ctime_status=${ctime_status}&position=${position}&&job_type=${job_type}&&industry_id=${industry_id}&&industry_type_id=${industry_type_id}`,)
}
/*
* 求职招聘 - 获取行业数据列表
* */
export function getIndustryList() {
    return request.get(`${newDevHost}/api/getIndustryList`)
}
/*
* 获取个人认证详情
* */
export function authentication_detail(id){
    return request.get(`${newDevHost}/api/authentication_detail/${id}`)
}
/*
* 个人认证创建
* */
export function authentication_create(user_name,Identity_id,identity_positive,identity_reverse) {
    let data = {user_name,Identity_id,identity_positive,identity_reverse}
    return service.post(`${newDevHost}/api/authentication_create`,data)
}
/*
* 切换身份
* */
export function update_acquiesce(job_standing){
    let data = {
        job_standing
    }
    return request.post(`${newDevHost}/api/update_acquiesce`,data)
}
/*
* 查询/1工作经验 /2学历/ 3求职状态/4薪资
* */
export function getJobCateList(){
    return request.get(`${newDevHost}/api/getJobCateList`)
}
/*
* 求职招聘 - 我的 - 获取个人简历
* */
export function getSeekerDetail(id){
    return request.get(`${newDevHost}/api/getSeekerDetail?id=${id}`)
}
/*
* 求职招聘 - 修改个人简历
* */
export function updateSeeker(
                             user_name,
                             user_age,
                             job_type,
                             industry_id,
                             salary_min,
                             salary_max,
                             province_id,
                             city_id,
                             district_id,
                             province_name,
                             city_name,
                             district_name,
                             school,
                             degree,
                             profession,
                             graduation_time,
                             user_job_status,
                             advantage,
                             project_experience,
                             work_experience,
                             working_years,
                             user_avatar,
                             id){
    let data = {
        user_name,
        user_age,
        job_type,
        industry_id,
        salary_min,
        salary_max,
        province_id,
        city_id,
        district_id,
        province_name,
        city_name,
        district_name,
        school,
        degree,
        profession,
        graduation_time,
        user_job_status,
        advantage,
        project_experience,
        work_experience,
        working_years,
        user_avatar,
        id
    }
    return request.post(`${newDevHost}/api/updateSeeker/${id}`,data)
}
/*
* 求职招聘 - 创建求职 - 个人简历
* */
export function createSeeker(user_name,user_age,job_type,industry_id,salary_min,salary_max,
                             province_id,city_id,district_id,province_name,city_name,district_name,
                             school,degree,profession,graduation_time,user_job_status,advantage,
                             project_experience,work_experience,working_years,user_avatar){
    let data = {user_name,user_age,job_type,industry_id,salary_min,salary_max,
        province_id,city_id,district_id,province_name,city_name,district_name,
        school,degree,profession,graduation_time,user_job_status,advantage,
        project_experience,work_experience,working_years,user_avatar}
    return request.post(`${newDevHost}/api/createSeeker`,data)
}
/*
* 个人简历上下架
* */
export function up_seeker_status(id){
    let data = {
        id
    }
    return request.post(`${newDevHost}/api/up_seeker_status`,data)
}
/*
* 获取详情
* */
export function getjob(id){
    return request.get(`${newDevHost}/api/getjob?id=${id}`)
}
/*
* 收藏求职者添加
* */
export function createCollection(job_id,type){
    let data = {
        job_id,
        type,
    }
    return request.post(`${newDevHost}/api/createCollection`,data)
}
/*
* 在线简历上下架
* */
export function up_job_status(id){
    let data = {id}
    return request.post(`${newDevHost}/api/up_job_status`,data)
}
/*
* 取消收藏
* */
export function del_collection(id){
    let data = {
        id
    }
    return request.post(`${newDevHost}/api/del_collection`,data)
}
/*
*  求职招聘  取消收藏  全部
* */
export function del_Collection(id,type){
    let data = {
        id,type
    }
    return request.post(`${newDevHost}/api/del_Collection`,data)
}
/*
* 我收藏的 - 求职者列表
* */
export function getCollectionList(page,limit){
    return request.get(`${newDevHost}/api/getCollectionList?page=${page}&limit=${limit}`)
}
/*
*  收藏列表岗位
* */

export function Collection_type2(page,limit){
    let data = {
        page,
        limit
    }
    return request.post(`${newDevHost}/api/Collection_type2`,data)
}
/*
*  求职招聘  附件简历列表
* */
export function resumelist(){
    return request.get(`${newDevHost}/api/resumelist`)
}
/*
*  获取企业认证列表
* */
export function getCompanyList() {
    return request.get(`${newDevHost}/api/getCompanyList`)
}
/*
*  上传附件简历
* */
export function createResume(file_url,file_name) {
    let data = {
        file_url,
        file_name
    }
    return request.post(`${newDevHost}/api/createResume`,data)
}
/*
*  删除附件简历
* */

export function deleteResume(id) {
    let data = {
        id
    }
    return request.post(`${newDevHost}/api/deleteResume`,data)
}
/*
*  获取求职列表
* */
export function jobseeker_list(page,limit,province_id,city_id,district_id,degree,job_type,industry_id,working_years,user_job_status,salary) {
    return request.get(`${newDevHost}/api/jobseeker_list?page=${page}&limit=${limit}&province_id=${province_id}&city_id=${city_id}&district_id=${district_id}&degree=${degree}&job_type=${job_type}&industry_id=${industry_id}&working_years=${working_years}&user_job_status=${user_job_status}&salary=${salary}`)
}
/*
*  查询企业下的职位
* */
export function job_Company_list(f_id,page,limit,status='') {
    return request.get(`${newDevHost}/api/job_Company_list?f_id=${f_id}&page=${page}&limit=${limit}&status=${status}&status_type=${status}&f_id=${f_id}`)
}
/*
*  新建岗位
* */
export function createJob(
    position,
    job_type,
    industry_id,
    // province_id,
    // city_id,
    // district_id,
    // province_name,
    // city_name,
    // district_name,
    // address,
    experience_required,
    salary_min,
    salary_max,
    job_requirements,
    is_phone,
    f_id,
    phone_count,
    chat_count,
    Integral_usage,
    benefits,
    u_phone,
    u_name,
    industry_type_id,
){
    let data = {
        position,
        job_type,
        industry_id,
        // province_id,
        // city_id,
        // district_id,
        // province_name,
        // city_name,
        // district_name,
        // address,
        experience_required,
        salary_min,
        salary_max,
        job_requirements,
        is_phone,
        f_id,
        phone_count,
        chat_count,
        Integral_usage,
        benefits,
        industry_type_id,
    }
    if(u_phone){
        data.u_phone = u_phone
    }
    if(u_name){
        data.u_phone = u_name
    }
    return request.post(`${newDevHost}/api/createJob`,data)
}
/*
*  求职使用积分配置
* */
export function jobpoints_list(){
    return request.get(`${newDevHost}/api/jobpoints_list`)
}
/*
*  获取企业认证详情
* */
export function getCompany(id){
    return request.get(`${newDevHost}/api/getCompany?id=${id}`)
}
/*
*  编辑企业认证
* */
export function create_company(
    business_license_name,
    company_type,
    business_license_images,
    corporate_capacity_images,
    name,
    province_id,
    city_id,
    district_id,
    province_name,
    city_name,
    district_name,
    address,
    company_nums,
    logo_image,
){
    let data = {district_name,city_id,business_license_name, company_type, business_license_images, corporate_capacity_images, name, province_id, district_id, province_name, city_name, address, company_nums, logo_image,}
    return request.post(`${newDevHost}/api/create_company`,data)
}
/*
*  编辑企业认证
* */
export function update_company(
    id,
    business_license_name,
    company_type,
    business_license_images,
    corporate_capacity_images,
    name,
    province_id,
    city_id,
    district_id,
    province_name,
    city_name,
    district_name,
    address,
    company_nums,
    logo_image,
){
    let data = {id,district_name,city_id, business_license_name, company_type, business_license_images, corporate_capacity_images, name, province_id, district_id, province_name, city_name, address, company_nums, logo_image,}
    return request.post(`${newDevHost}/api/update_company`,data)
}
/*
*  xiugai 修改 企业介绍
* */
export function update_company_introduction(id,introduction,logo_image) {
    let data = {
        id,
        introduction,
        logo_image
    }
    return request.post(`${newDevHost}/api/update_company_introduction`,data)
}
/*
*  切换企业
* */
export function update_company_acquiesce(id) {
    let data = {id}
    return request.post(`${newDevHost}/api/update_company_acquiesce`,data)
}
/*
*  企业类型列表
* */
export function getRecruitFirmType(){
    return request.get(`${newDevHost}/api/getRecruitFirmType`)
}
/*
*  人才管理
* */
export function Collection_type1(page,limit){
    return request.get(`${newDevHost}/api/Collection_type1?page=${page}&limit=${limit}`)
}
/*
*  个人中心 - 获取常用语列表
* */
export function getRecruitLanguageList(){
    return request.get(`${newDevHost}/api/getRecruitLanguageList`)
}
/*
*  个人中心 - 添加常用语
* */
export function createLanguage(content) {
    let data = {
        content
    }
    return request.post(`${newDevHost}/api/createLanguage`,data)
}
/*
*  个人中心 - 修改常用语句
* */
export function updateLanguage(id,content) {
    let data = {
        id,
        content
    }
    return request.post(`${newDevHost}/api/updateLanguage`,data)
}






