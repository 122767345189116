import Vue from 'vue'
import App from './App.vue'
// import socket from '/lib/chat'
import Cache from './utils/cache'
// Vue.prototype.$socket = new socket();
Vue.prototype.$Cache = Cache;

//路由
import router from "./router";
window.router = router;
Vue.config.productionTip = false
// vant 引用
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

// swiper 引用
import "swiper/swiper.min.css"
import "/public/tuoluojiang.css"
import '/static/css/base.css';


/** iconfont
 * Symbol 引用
 */
import '@/assets/icon/iconfont.css'


import "@/assets/icon/iconfont.js"



import store from './store'

//引用axios、
import axios from "./utils/axios.js"
Vue.prototype.$axios = axios;

//ElementUI  引用
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);



import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)


import md5 from "js-md5"
Vue.prototype.$md5 = md5



new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
