<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {Dialog, Toast} from "vant";
import {isValidJSON} from "@/utils/tool";
import {getProductDetail,checkIsQuote,recovercollect,quote} from "@/api/idle"
export default {
  name: "idleDetails",
  components: {navigationBar},
  data(){
    return{
      popshow:false,
      NoticeShow:false,
      Expected:0,
      specification:[
        // {title:'品牌',text:'Apple/苹果'},
        // {title:'型号',text:'Apple 15 Pro Max'},
        // {title:'存储容量',text:'256GB'},
        // {title:'版本',text:'大陆国行'},
        // {title:'品牌',text:'Apple/苹果'},
        // {title:'型号',text:'Apple 15 Pro Max'},
        // {title:'存储容量',text:'256GB'},
        // {title:'版本',text:'大陆国行'},
      ],
      delatils:[
          'https://picture.bjbjkj.top/tp2024071803001500257731617.png',
          'https://picture.bjbjkj.top/tp2024071803001500257731617.png',
          'https://picture.bjbjkj.top/tp2024071803001500257731617.png',
          'https://picture.bjbjkj.top/tp2024071803001500257731617.png',
          'https://picture.bjbjkj.top/tp2024071803001500257731617.png',
          'https://picture.bjbjkj.top/tp2024071803001500257731617.png',
      ],
      good:{},
      is_offer:false,
    }
  },
  methods:{
    share(){
      console.log('分享')
      if(!this.$store.state.token){
        Dialog.confirm({
          title: '您还未登录，请先登录',
          confirmButtonText:'去登录'
        })
            .then(() => {
              // on confirm
              this.$router.push('/login')
            })
            .catch(() => {
              // on cancel
            });
        return
      }
    },
    collect(e){
      if(!this.$store.state.token){
        Dialog.confirm({
          title: '您还未登录，请先登录',
          confirmButtonText:'去登录'
        })
            .then(() => {
              // on confirm
              this.$router.push('/login')
            })
            .catch(() => {
              // on cancel
            });
        return
      }


      recovercollect(this.good.id,e).then(res=>{
        console.log(res)
        if(res.data.status){
          if(e==1){
            this.good.is_collect = 1
            Toast('收藏成功')
          }else{
            this.good.is_collect = 0
            Toast('取消收藏')
          }
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })



    },
    report(){
      console.log('举报')
      if(!this.$store.state.token){
        Dialog.confirm({
          title: '您还未登录，请先登录',
          confirmButtonText:'去登录'
        })
            .then(() => {
              // on confirm
              this.$router.push('/login')
            })
            .catch(() => {
              // on cancel
            });
        return
      }
    },
    opensubmit(){
      console.log('出价')
      if(!this.$store.state.token){
        Dialog.confirm({
          title: '您还未登录，请先登录',
          confirmButtonText:'去登录'
        })
            .then(() => {
              // on confirm
              this.$router.push('/login')
            })
            .catch(() => {
              // on cancel
            });
        return
      }
      this.popshow = true
    },
    submit(){
      quote(this.good.id,this.Expected,this.good.user.id).then(res=>{
        if(res.data.status == true){
          Toast(res.data.msg)
          this.popshow = false
          this.is_offer = true
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    getDetails() {
      getProductDetail(this.$route.query.id).then(res=>{
        if(res.data.status == 200){
          this.good = res.data.data
          // this.good.distanceObj = getDistances(this.$store.state.nowPosition.location.lat,this.$store.state.nowPosition.location.lng,this.good.dx.split(',')[1],this.good.dx.split(',')[0])
          // if(parseInt(this.good.distance)>=1000){
          //   this.good.distanceObj.distance_str = (this.good.distance/1000).toFixed(2)+ "km"
          // }
          if(this.good.images){
            if(isValidJSON(this.good.images)){
              this.good.showImg = JSON.parse(this.good.images)[0]
              // if(JSON.parse(this.good.images).every(item=>{return typeof item == 'string'})){
              //   this.delatils = JSON.parse(this.good.images).map(item=> {return this.$store.state.imageHost+item})
              // }else{
              //     this.delatils = JSON.parse(this.good.images).map(item=> {return this.$store.state.imageHost+item.tempFilePath})
              // }
            }else{
              this.good.showImg = this.good.images.split(',')[0]
            }
            if(typeof this.good.showImg == 'string'){
              if(!this.good.showImg.startsWith('https:')){
                this.good.showImg = this.$store.state.imageHost+this.good.showImg
              }
            }else{
              this.good.showImg = this.good.showImg.tempFilePath
            }

          }

          if(this.good.showImg.split(',').every(item=>{return typeof item == 'string'})){
            // console.log(!this.good.showImg.startsWith('https'))
            console.log(this.good.showImg.split(','))
              this.delatils = this.good.images.split(',').map(item=> {return this.$store.state.imageHost+item})
          }else{
            this.delatils = JSON.parse(this.good.images).map(item=> {return this.$store.state.imageHost+item.tempFilePath})
          }
          if(this.good.images[0] == '['){
            this.delatils =  JSON.parse(this.good.images).map(item=> {return this.$store.state.imageHost+item})
          }
          // this.delatils = this.good.images
          console.log(this.delatils)
          this.Expected = this.good.price
          this.specification = [
            {title:'品牌',text:this.good.brand},
            {title:'型号',text:this.good.product_mode},
            // {title:'存储容量',text:'256GB'},
            // {title:'版本',text:'大陆国行'},
            // {title:'品牌',text:'Apple/苹果'},
            // {title:'型号',text:'Apple 15 Pro Max'},
            // {title:'存储容量',text:'256GB'},
            // {title:'版本',text:'大陆国行'},
          ],
          console.log(this.good)
          this.good.IPadd = this.good.address.split(',')[0]
          checkIsQuote(this.good.id).then(res=>{
            if(res.data.status == 200){
              this.is_offer = true
            }else if(res.data.status == 400){
              Toast.clear()
              this.is_offer = false
            }
          }).catch(err=>{
            console.log(err)
            this.is_offer = false
          })
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    NoticePop(){
      this.NoticeShow = true
    },

    goShop(){
      this.$router.push({
        path:'/idle_shop_details',
        query:{item:this.good.uid,type:'person',category_id:this.good.category_id},
      })
    }
  },
   mounted() {
     this.getDetails()
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'宝贝详情'"></navigationBar>
      <div class="topUser" v-if="$store.state.imageHost&&good.user">
        <div class="left" @click='goShop'>
          <img class="avatar" :src="$store.state.imageHost+good.user.avatar" alt="" v-if="$store.state.imageHost&&good.user.avatar">
          <div class="rightInfo">
            <div class="topinfo">
              <div class="nickname">{{ good.user.nickname }}</div>
<!--              <div class="authentication">-->
<!--                已认证-->
<!--              </div>-->
            </div>
            <div class="bottomTag">
              IP{{ good.IPadd }}
            </div>
          </div>
        </div>
        <div class="right">
<!--          <span class="iconfont icon-Frame11"></span>-->
<!--          {{ good.distanceObj.distance_str }}-->
        </div>
      </div>
      <div class="babyprice">
        <div class="leftPrice">
          <span class="unit">￥</span>{{ good.price }}
        </div>
        <div class="Preview">
          预览{{good.visit_count}}
        </div>
      </div>
      <div class="babytitle">
        <span class="text">{{ good.product_name }}</span><span class="tag" :class="good.sort_tag==2?'Advance':''">{{ good.sort_tag==1?'在售':'预售' }}</span>
      </div>
      <div class="Trading_instructions" @click="NoticePop">
        <div class="left">交易须知</div>
        <div class="right">出价之后，该订单将锁定不再展示且卖家具体信息将仅对您公开。若未成功自提或</div>
        <span class="iconfont icon-Frame-4"></span>
      </div>
      <div class="Seller_information" v-if="!is_offer">
        <div class="titleBox">
          <div class="title">卖家信息</div>
          <div class="text">（出价之后，可查看卖家完整信息）</div>
        </div>
        <div class="name_mobile">
          <div class="leftNmae">张先生</div>
          <div class="rightMobile">155****5555</div>
        </div>
        <div class="address">
          <div class="title">取件地址：</div>
          <div class="infomation">北京市朝阳区关东店北街与北京市朝阳区关东店北街与北京市朝阳区关东店北街与北京市朝阳区关东店北街与*********</div>
        </div>
      </div>
      <div class="Seller_information" v-if="is_offer">
        <div class="titleBox">
          <div class="title">卖家信息</div>
          <div class="text">（出价之后，可查看卖家完整信息）</div>
        </div>
        <div class="name_mobile">
          <div class="leftNmae">{{ good.user.nickname }}</div>
          <div class="rightMobile">{{ good.user.mobile }}</div>
        </div>
        <div class="address">
          <div class="title">取件地址：</div>
          <div class="infomation">{{ good.address+good.detail_address }}</div>
        </div>
      </div>
      <div class="introduce">{{ good.product_desc }}</div>
      <div class="outTab">
        <div class="table">
          <div class="every" v-for="(item,index) in specification" :key="index">
            <div class="title">{{ item.title }}</div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>
      <div class="details">
        <img   class="detailsImg" alt="">
        <el-image
            v-for="(item,index) in delatils"
            :key="index"
            :src="item"
            :preview-src-list="delatils"
          class="detailsImg"
        >
        </el-image>
      </div>

      <div style="width: 100%;height: 100px;"></div>





      <div class="bottomLine">
        <div class="left">
<!--          <div class="share every" @click="share">-->
<!--              <span class="iconfont icon-fenxiang1"></span>-->
<!--            <span class="text">分享</span>-->
<!--          </div>-->
          <div class="Collect every" @click="collect(1)" v-if="good.is_collect==0">
            <span class="iconfont icon-weishoucang"></span>
            <span class="text">收藏</span>
          </div>
          <div class="Collect every" :class="good.is_collect==1?'CollectY':''" @click="collect(2)" v-if="good.is_collect==1">
            <span class="iconfont icon-yishoucang"></span>
            <span class="text">已收藏</span>
          </div>
          <div class="Collect every" @click="report">
            <span class="iconfont icon-Frame30"></span>
            <span class="text">举报</span>
          </div>
        </div>
        <div class="rightBtn" @click="opensubmit">
          <span class="iconfont icon-Frame26"></span>我要出价
        </div>
      </div>

      <van-popup v-model="popshow" closeable class="popBox">
        <div class="title">我要出价</div>
        <div class="info">填写该信息若出价被采纳，您的联系信息将单独展示给卖家，方便印双方联系。</div>
        <div class="inputBox">
          <div class="top">
            <div class="text">期望价格</div>
            <input type="number" class="input" v-model="Expected" >
          </div>
          <div class="last">(元)</div>
        </div>
        <div class="btn" @click="submit">
          确定
        </div>
      </van-popup>

      <van-popup v-model="NoticeShow" closeable position="bottom" class="Notice">
        <div class="title">交易须知</div>
        <div class="body">
          <div>支付订金：</div>
          <div>支付订金后，该宝贝将锁定不再展示；且卖家具体信息将仅对您公开。若未成功自提或出现交易失败，服务定金随时可退。</div>
          <div></div>
          <div>交易流程：</div>
          <div>支付订金后，可按卖家信息及时与卖家进行联系，确认订单信息与取货时间：若未成功自提或出现交易失败，服务订金随时可退，成功提货后，要将剩余货款当面与卖家结清。</div>
          <div></div>
          <div>退还订金：</div>
          <div>1、若买家不想交易，可取消您本次的订单，订金将原路退回。</div>
          <div>2、若卖家不想交易，可取消您本次的订单，买家订金将原路退回。</div>
          <div>3、如买家48小时内未完成交易，平台默认为交易失败，订金原路退回给买家，该宝贝将重新上架。</div>
        </div>
        <div class="btn" @click="NoticeShow=false">我知道了</div>
      </van-popup>

    </div>
  </div>
</template>

<style scoped lang="scss">
.popBox{
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 10px;
  padding-right: 10px;
  width: 75%;
  background: #fff!important;
  background-color: #fff!important;
  border-radius: 10px;
  .title{
    color: #1b1b1b;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .info{
    margin-top: 10px;
    color: #999999;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: left;
  }
  .inputBox{
    margin-top: 10px;
    display: flex;

    .top{
      display: flex;

      .text{
        color: var(--666666, #666666);
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
      .input{
        border: none;
        padding-left: 20px;
        width: 65%;
        height: 24px;
        color: #1b1b1b;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
    .last{
      color: #c7c7c7;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .btn{
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 24px;
    border-radius: 32px;
    background: #fc223b;
    margin-top: 10px;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

}
.Notice{
  padding-top: 16px;
  .title{
    color: #1b1b1b;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .body{
    text-align: left;
    padding: 10px 16px;
    color: #1a1a1a;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    div{
      min-height: 20px;
    }
  }
  .btn{
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 24px;
    border-radius: 32px;
    background: #fc223b;
    margin-top: 10px;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 16px;
  }
}
.topUser{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 10px 16px;
  .left{
    width: 75%;
    display: flex;
    align-items: center;
    .avatar{
      width: 42px;
      height: 42px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }
    .rightInfo{
      text-align: left;
      max-width: 80%;
      .topinfo{
        display: flex;
        align-items: flex-end;
        .nickname{
          color: #1b1b1b;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .authentication{
          margin-left: 12px;
          width: fit-content;
          height: fit-content;
          display: inline-flex;
          place-content: center;
          place-items: center;
          gap: 10px;
          padding: 2px 6px;
          border-radius: 5px;
          background: #fff4e9;
          color: #ff9f4d;
          font-weight: 500;
          font-size: 10px;
          line-height: 14px;
        }
      }
      .bottomTag{
        width: fit-content;
        height: fit-content;
        display: inline-flex;
        place-content: center;
        place-items: center;
        background-color: #F7F7F7;
        gap: 10px;
        padding: 1px 2px;
        border-radius: 2px;
        color: #999999;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
      }
    }


  }
  .right{
    text-align: right;
    color: #999999;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
  }
}
.babyprice{
  display: flex;
  justify-content: space-between;
  margin: 0 16px;
  align-items: center;
  .leftPrice{
    color: #fd2a39;
    font-weight: 500;
    line-height: 34px;
    font-size: 24px;
    .unit{
      font-size: 16px;
    }
  }
  .Preview{
    align-items: center;
    color: #7f7f7f;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}
.babytitle{
  text-align: left;
  margin: 0 16px;
  color: #1b1b1b;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  .text{

  }
  .tag{
    border-radius: 5px;
    background: #fd2a39;
    align-items: center;
    color: #ffffff;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    padding: 1px 4px;
    margin-left: 14px;
  }
  .Advance{
    background: #24B512;
  }
}
.Trading_instructions{
  border-radius: 10px;
  background: #f5f5f5;
  padding: 6px 9px;
  margin: 10px 16px;
  display: flex;
  align-items: center;
  .left{
    color: #1b1b1b;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
  }
  .right{
    white-space: nowrap;
    width: 80%;
    overflow: hidden;
    padding-left: 10px;
    text-overflow: ellipsis;
    color: #999999;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
  }
  .iconfont{
    color: #999999;
  }
}
.Seller_information{
  border-radius: 10px;
  border: 1px solid #ffe7d2;
  background: #fff4e9;
  margin: 10px 16px;
  padding: 10px;
  text-align: left;
  .titleBox{
    display: flex;
    align-items: flex-end;
    .title{
      color: #1b1b1b;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
    .text{
      color: #1b1b1b;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
    }
  }

  .name_mobile{
    display: flex;
    margin: 6px 0 4px 0;
    .leftNmae{
      color: #1b1b1b;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    }
    .rightMobile{
      color: #1b1b1b;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      margin-left: 10px;
    }
  }

  .address{
    display: flex;
    color: #1b1b1b;
    font-weight: 400;
    font-size: 13px;
    flex-wrap: wrap;
    .title{
      min-width: 70px;
      text-align: left;
      line-height: 18px;

    }
    .infomation{
      display: -webkit-box;
      /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
      -webkit-box-orient:vertical;
      /*要显示的行数*/
      -webkit-line-clamp:2;
      /* 溢出部分隐藏 */
      overflow:hidden;
      width: 75%;
      line-height: 18px;

    }
  }
}

.introduce{
  text-align: left;
  margin: 10px 16px;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}

.bottomLine{
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  left: 0;
  bottom: 0;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 0px -0.5px 2px #99999926;
  .left{
    width: 50%;
    display: flex;
    margin-left: 16px;
    .every{
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      .iconfont{
        font-size: 24px;
      }
      .text{
        text-align: center;
        color: #666666;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        margin-top: 2px;
      }

    }
    .CollectY{
      color: red;
    }
  }
  .rightBtn{
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 12px 20px;
    border-radius: 34px;
    background: #fc223b;
    margin-right: 16px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    .iconfont{
      font-size: 24px;
    }

  }
}

.outTab{
  margin: 0 0 0 16px;
  overflow-y: scroll;
  .table{
    display: flex;
    flex-wrap: nowrap;
    overflow-y: scroll;
    padding-right: 16px;
    .every{
      white-space: nowrap;
      text-align: left;
      margin-right: 30px;
      position: relative;
      .title{
        color: #999999;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
      }
      .text{
        color: #1b1b1b;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
      }
    }
    .every:after{
      position: absolute;
      display: block;
      content: '';
      width: 1px;
      height: 100%;
      background-color: #EDEDED;
      right: -14px;
      top: 0;
    }
    .every:nth-last-of-type(1){
      margin-right: 0px;
    }
    .every:nth-last-of-type(1):after{
      display: none;
    }
  }
}
.outTab::-webkit-scrollbar,.table::-webkit-scrollbar{
  display: none;
}

.details{
  margin: 10px 16px;
  border-radius: 10px;
  //border-radius: 10px 10px 0 0 ;
  font-size: 0;
  .detailsImg{
    width: 100%;
    display: block;
  }
}
</style>