<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {Toast} from "vant";
import ExpectJob from "@/components/common/invite_job/expect_job/expect_job.vue";
import MaskDiv from "@/components/common/invite_job/online/mask/mask.vue";
import AddContact from "@/components/common/invite_job/Corporation/addContact.vue";
import {getJobCateList,getCompanyList,createJob,jobpoints_list} from "@/api/recruitment"

export default {
  name: "add_position",
  components: {AddContact, MaskDiv, ExpectJob, navigationBar},
  data() {
    return {
      is_call_mobile: false,
      PointsList: [],
      choosePointsIndex: 0,

      // 职位类型（如1全职、2兼职等）
      Job_hunting_typeList: [
        {text: '全职',type:1},
        {text: '兼职',type:2},
      ],
      expect_job_show: false,
      addContactMask: false,
      pop_show: false,
      disable: false,
      pop_type: '',
      maskType: '',
      chooseValue: '',
      Working_yearsList: [],
      Job_tag_list: [],
      Contact_person: [],//联系人
      moneyList: [
        {values: []},
        {values: []},
      ],
      chooseFirmObj: {},//企业
      name: '',//岗位名称
      type: '',//岗位类型
      profession: {},//行业
      Working_years: '',//工作经验
      Working_years_id: '',//工作经验id
      Expected_salary: '',//薪资范围
      job_description: '',


      deficiencyFlag:false,
    }
  },
  methods: {
    getPoints() {
      jobpoints_list().then(res => {
        if (res.data.status == 200) {
          this.PointsList = res.data.data
          console.log(this.PointsList)
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getFirmList() {
      getCompanyList().then(res => {
        if (res.data.status == 200) {
          let list = res.data.data.data
          if (list.find(item => {
            return item.acquiesce == 1
          })) {
            this.chooseFirmObj = list.find(item => {
              return item.acquiesce == 1
            })
          } else {
            if (list.length > 0) {
              this.chooseFirmObj = list[0]
            }
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    goMakePoints() {
      console.log('去赚积分')
    },
    choosePointsFun(e) {
      if (this.choosePointsIndex == e) return
      this.choosePointsIndex = e
    },


    chooseFirm() {
      this.$router.push({
        path: '/invite_corporation_manage',
        query: {type: 'choose', item: JSON.stringify(this.chooseFirmObj)},
      })
    },

    // chooseFirm(){
    //   console.log('选择企业')
    // },
    openPop(e) {
      this.pop_type = e
      this.pop_show = true
    },
    closePop() {
      this.pop_type = ''
      this.pop_show = false
    },
    save() {
      if (this.pop_type == 'Job_hunting_type') {
        this.type = this.$refs.Job_hunting_type.getValues()[0].text
        this.type_id = this.$refs.Job_hunting_type.getValues()[0].type
      }
      if (this.pop_type == 'Working_years') {
        this.Working_years = this.$refs.Working_years.getValues()[0].text
        this.Working_years_id = this.$refs.Working_years.getValues()[0].id
      }
      if (this.pop_type == 'Expected_salary') {
        this.Expected_salary = this.$refs.Expected_salary.getValues()[0] + '-' + this.$refs.Expected_salary.getValues()[1]
      }
      this.closePop()
    },


    closeExpoect(e) {
      this.expect_job_show = e
    },
    choosePosition(e) {
      this.profession = e
      this.expect_job_show = false

    },
    moneyChange(picker) {
      picker.setColumnValues(1, this.recentMoney.slice((this.$refs.Expected_salary.getIndexes()[0]) + 1, -1));
    },
    closeMask() {
      this.maskType = ''
      this.addContactMask = false
    },
    submitMask(e) {
      if (this.maskType == 'Job_description') {
        this.job_description = e
      }
      if(this.addContactMask){
        e.choose = false
        let obj = {
          choose:false,
          name:e.name,
          mobile:e.mobile
        }
        this.Contact_person.push(obj)
        if(!this.Contact_person.find(item=>{return item.choose})){
          this.Contact_person[0].choose = true
        }
      }
      this.closeMask()
    },
    openMask(e) {
      if (this.disable == true) return
      this.maskType = e
      this.chooseValue = ''
      this.chooseValue = this.job_description
    },
    chooseTagItme(item){
      item.choose = !item.choose
    },
    addContactFun(){
      console.log('添加联系人')
      this.addContactMask = true
    },
    closeConcat(){
      this.addContactMask = false
    },
    chooseContact(item){

      if(item.choose)return;
      this.Contact_person.forEach(item=>{
        item.choose = false
      })
      item.choose = true

    },

    submitForm(){
      // chooseFirmObj: {},//企业
      // name: '',//岗位名称
      //     type: '',//岗位类型
      //     profession: {},//行业
      // Working_years: '',//工作经验
      //     Working_years_id: '',//工作经验id
      //     Expected_salary: '',//薪资范围
      //     job_description: '',//岗位描述
      if(!this.chooseFirmObj.id){Toast('请选择要发布的企业');return}
      if (!this.name){Toast('请输入企业名称');return;}
      if (!this.type){Toast('请选择岗位类型');return;}
      if (!this.profession.id){Toast('请选择所属行业');return;}
      if (!this.Working_years_id){Toast('请选择工作经验');return;}
      if (!this.Expected_salary){Toast('请选择薪资范围');return;}
      if (!this.job_description){Toast('请填写岗位描述');return;}
      if (!String(this.Job_tag_list.map(item=>{return item.choose?item.id:''}).filter(item=>{return item}))){Toast('请选择标签');return;}
      createJob(
          this.name,
          this.type_id,
          this.profession.id,
//dzdzdz地址信息
          this.Working_years_id,
          (this.Expected_salary.split('-')[0]).replace('k', ''),
          (this.Expected_salary.split('-')[1]).replace('k', ''),
          this.job_description,
          this.is_call_mobile?1:2,
          this.chooseFirmObj.id,
          this.PointsList[this.choosePointsIndex].phone_count,
          this.PointsList[this.choosePointsIndex].chat_count,
          this.PointsList[this.choosePointsIndex].points?this.PointsList[this.choosePointsIndex].points:0,
          String(this.Job_tag_list.map(item=>{return item.choose?item.id:''}).filter(item=>{return item})),
          this.Contact_person.length>0?this.Contact_person.find(item=>{return item.choose}).mobile:'',
          this.Contact_person.length>0?this.Contact_person.find(item=>{return item.choose}).name:'',
          this.profession.chooseNav,
      ).then(res=>{
        console.log(res)
        if(res.data.status == 400){
          Toast(res.data.msg)
          return;
        }
        if(!res.data.status){
          if(res.data.msg == "积分不足"){
            this.deficiencyFlag = true
            return;
          }
        }
        if(res.data.status == 200){
          Toast(res.data.msg)
          this.$router.go(-1)
        }
      })
    },

    earnBtn(){
      console.log('赚积分')
      this.deficiencyFlag = false
    },
  },
  computed: {
    recentMoney() {
      let money = [];
      for (let i = 0; i <= 25; i++) {
        money.push((i + 1) + 'k');
      }
      return money;
    }
  },
  async mounted() {
    this.moneyList[0].values = this.recentMoney.slice(0, -2)
    this.moneyList[1].values = this.recentMoney.slice(1)
    this.getPoints()
    this.getFirmList()
    let datae = await getJobCateList()
    let Working_yearsList = datae.data.data[1]
    Working_yearsList.forEach(item => {
      item.text = item.job_name
    })
    this.Working_yearsList = Working_yearsList
    let Job_tag_list = datae.data.data[5]
    this.Working_yearsList = Working_yearsList
    Job_tag_list.forEach(item=>{
      item.choose = false
    })
    this.Job_tag_list = Job_tag_list
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="''" class="navigationBar">
      </navigationBar>
      <MaskDiv @closePop="closeMask"
               @submit="submitMask"
               class="popmask"
               v-if="maskType"
               :maskType="maskType"
               :chooseValue="chooseValue"
      >
      </MaskDiv>

      <ExpectJob
          class="expect_job"
          @closePop="closeExpoect"
          @choosePosition="choosePosition"
          :invalidObj="profession"
          v-if="expect_job_show"
      ></ExpectJob>

      <addContact
          @closePop="closeConcat"
          class="addContactMask"
          @submit="submitMask"
          v-if="addContactMask"
      ></addContact>

      <van-popup v-model="deficiencyFlag" class="popsss">
        <div style="position: relative">
          <img :src="$store.state.imageHost+'/img/deficiencyFlag.png'" alt="" class="deficiencyImg">
          <div class="textBox">
            <div class="title">积分不足</div>
            <div class="text">前往积分商城去赚积分吧！</div>
            <div class="btn" @click="earnBtn">前往积分商城</div>
          </div>
        </div>
      </van-popup>

      



      <div class="body">
        <div class="topTitle">发布岗位</div>
        <div class="line" @click="chooseFirm">
          <div class="title">选择企业</div>
          <div class="select bottom">{{ chooseFirmObj.id ? chooseFirmObj.name : '' }}</div>
        </div>
        <div class="line">
          <div class="title">岗位名称</div>
          <input class="bottom input" type="text" v-model="name" placeholder="请填写岗位名称，例“销售专员”（限制22字内）"
                 maxlength="22">
        </div>
        <div class="line" @click="openPop('Job_hunting_type')">
          <div class="title">岗位类型</div>
          <div class="select bottom">
            <div :class="type?'':'placeholder'">{{ type ? type : '请选择类型' }}</div>
            <span class="iconfont icon-Frame-4"></span>
          </div>

        </div>
        <div class="line" @click="()=>{expect_job_show = true}">
          <div class="title">所属行业</div>
          <div class="select bottom">
            <div :class="profession.id?'':'placeholder'">
              {{ profession.id ? profession.industry_name : '请选择所属行业' }}
            </div>
            <span class="iconfont icon-Frame-4"></span>
          </div>

        </div>
        <div class="line" @click="openPop('Working_years')">
          <div class="title">工作经验</div>
          <div class="select bottom">
            <div :class="Working_years_id?'':'placeholder'">{{ Working_years_id ? Working_years : '请选择经验要求' }}
            </div>
            <span class="iconfont icon-Frame-4"></span>
          </div>

        </div>
        <div class="line" @click="openPop('Expected_salary')">
          <div class="title">薪资范围</div>
          <div class="select bottom">
            <div :class="Expected_salary?'':'placeholder'">
              {{ Expected_salary ? Expected_salary : '请选择合理的薪资范围' }}
            </div>
            <span class="iconfont icon-Frame-4"></span>
          </div>
        </div>
        <div class="line" @click="openMask('Job_description')">
          <div class="title">岗位描述</div>
          <div class="select bottom">
            <div :class="job_description?'':'placeholder'" v-html="job_description ? job_description : '介绍工作内容、岗位要求'">
            </div>
            <span class="iconfont icon-Frame-4"></span>
          </div>
        </div>
        <div class="line chooseSwitchBox">
          <div class="head">
            <div class="">是否可以让求职者拨打电话咨询岗位</div>
            <van-switch v-model="is_call_mobile" size="16px" active-color="#fc223b" inactive-color="#dcdee0"/>
          </div>
          <div class="foot" v-if="is_call_mobile">
            <div class="ul">
              <div class="li" v-for="(item,index) in Contact_person" :key="index" @click="chooseContact(item)">
                <div class="left">{{item.name}} {{item.mobile}}</div>
                <div class="radioBox" :class="item.choose?'radio1':'radio2'">
                  <span class="iconfont icon-duigou" v-if="item.choose"></span>
                </div>
              </div>
            </div>
            <div class="addContact" @click="addContactFun">点击添加姓名电话</div>
          </div>
        </div>
        <div class="line">
          <div class="title">岗位标签</div>
          <div class="tagUl">
            <div class="li" :class="item.choose?'chooseTag':''" v-for="(item,index) in Job_tag_list" :key="index" @click="chooseTagItme(item)"  >
              {{ item.job_name }}
            </div>
          </div>

        </div>

        <div class="pointsUl">
          <div class="ul">
            <div class="pointsLi" v-for="(item,index) in PointsList" @click="choosePointsFun(index)" :key="index"
                 :class="choosePointsIndex==index?'choosePointsLi':''">
              <div class="item">
                <div class="top">
                  <div class="pointNum">{{ item.points ? item.points : 0}}积分</div>
                  <div class="text">发布一个岗位</div>
                </div>
                <div class="foot">
                  <div class="text" v-if="item.phone_count>=1">{{ item.phone_count }}次主动电话机会</div>
                  <div class="text" v-if="item.chat_count>=1">{{ item.chat_count }}次主动开聊机会</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="choosePoints" v-if="PointsList.length>0">
          <div class="tit">
            <div class="left">消耗积分:{{ PointsList[choosePointsIndex].points?PointsList[choosePointsIndex].points:0 }}</div>
            <div class="right" @click="goMakePoints">去赚积分 <span class="iconfont icon-quzhuanjifenjiantou"></span>
            </div>
          </div>
          <div class="tit">
            <div class="left">我的积分:{{this.$store.state.user.integral}}</div>
            <div class="right">剩余积分:{{this.$store.state.user.integral - PointsList[choosePointsIndex].points}}</div>
          </div>
        </div>


        <div class="bottomBox">
          <div class="btn" @click="submitForm">发布岗位</div>
        </div>
      </div>
      <van-popup v-model="pop_show" position="bottom">
        <van-picker
            v-if="pop_type == 'Job_hunting_type'"
            ref="Job_hunting_type"
            :columns="Job_hunting_typeList"
        />
        <van-picker
            v-if="pop_type == 'Working_years'"
            ref="Working_years"
            :columns="Working_yearsList"
        />
        <van-picker
            v-if="pop_type == 'Expected_salary'"
            ref="Expected_salary"
            :columns="moneyList"
            @change="moneyChange"
        />
        <div class="pop_bottom">
          <div class="close btn" @click="closePop">取消</div>
          <div class="save btn" @click="save">确定</div>
        </div>
      </van-popup>

    </div>
  </div>
</template>

<style scoped lang="scss">
.popsss{
  background-color: transparent!important;
  .textBox{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,-30%);
    width: 100%;
    .title{
      text-align: center;
      color: #333333;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 10px;
    }
    .text{
      text-align: center;
      color: #666666;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 15px;
    }
    .btn{
      border-radius: 28px;
      background: #ffffff;
      display: inline-block;
      box-shadow: 0px 2px 4px #daafb066;
      color: #ff7275;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding: 12px 26px;
    }
  }
}
.deficiencyImg{
  width: 273px;
  height: 273px;
}
.bottomBox {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 14px 0;
  background: #ffffff;
  box-shadow: 0px -0.5px 2px #99999926;

  .btn {
    background-color: #fc223b;
    text-align: center;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 12px;
    padding: 12px 13px;
    width: 80%;
    margin: 0 auto;
  }
}

.body {
  padding: 10px 16px 100px;

  .line {
    text-align: left;
    border-bottom: 1px solid #99999926;
    margin-top: 15px;

    .tagUl {
      margin-top: 10px;
      display: flex;
      align-items: center;
      overflow: scroll;
      white-space: nowrap;
      padding-bottom: 15px;

      .li {
        width: fit-content;
        height: fit-content;
        display: inline-flex;
        place-content: center;
        place-items: center;
        gap: 10px;
        padding: 8px 12px;
        border-radius: 10px;
        background: #f6f6f6;
        text-align: center;
        color: #1b1b1b;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-right: 10px;

      }
      .chooseTag{
        background: #fc223b;
        color: #ffffff;
      }
    }
    .tagUl::-webkit-scrollbar{
      display: none;
    }

    .title {
      color: #1b1b1b;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }

    .select {
      color: #1b1b1b;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      justify-content: space-between;
    }

    .bottom {
      margin-top: 8px;
      margin-bottom: 15px;
    }

    .input {
      border: none;
      width: 100%;
    }
  }

  .chooseSwitchBox {
    .head{
      display: flex;
      padding-bottom: 8px;
      justify-content: space-between;
      align-items: center;
    }
    .foot{
      .addContact{
        color: #ff0000;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-decoration-line: underline;
        padding-bottom: 15px;
      }
      .radioBox{
        width: 16px;
        height: 16px;
        border: 1px solid #666666;
        box-sizing: border-box;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconfont{
          font-size: 11px!important;
          color: #fff !important;
        }
      }
      .radio1{
        background: #FC223B;
        border: 1px solid #FC223B;
        color: #fff;
      }
      .ul{

        .li{
          display: flex;
          justify-content: space-between;
          border-radius: 10px;
          border: 1px solid #f6f6f6;
          box-sizing: border-box;
          padding: 10px;
          margin: 10px 0;

        }
      }
    }

  }

  .pointsUl {
    margin-top: 15px;

    .ul {
      overflow: scroll;
      width: 100%;
      white-space: nowrap;

      .pointsLi {
        display: inline-block;
        border-radius: 16px;
        border: 2px solid #eeeeee;
        background: #ffffff;
        width: 138px;
        margin-right: 8px;

        .top {
          padding: 10px;
          text-align: center;
          color: #1b1b1b;
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;

          .text {
            color: #303030;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-top: 4px;
          }
        }

        .foot {
          border-radius: 0px 0px 15px 15px;
          background: #f7f7f7;
          width: 100%;
          padding: 10px 0;
          color: #666666;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;

          .text {
            margin-top: 5px;
          }
        }
      }

    }

    .ul::-webkit-scrollbar {
      display: none;
    }
  }

  .pointsUl::-webkit-scrollbar {
    display: none;
  }

  .choosePointsLi {
    border: 2px solid #ffc4ae !important;
    background: #ffeeec !important;

    .top {
      .text {
        color: #714c47;
      }

      .pointNum {
        color: #d13938;
      }
    }

    .foot {
      background: #ffd1cc !important;
      color: #ac2a1b !important;
    }
  }

  .choosePoints {
    border-radius: 16px;
    border: 2px solid #ffc4ae;
    background: #ffeeec;
    margin-top: 10px;
    color: #d13938;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 10px;

    .tit {
      display: flex;
      justify-content: space-between;
      margin-top: 4px;

      .iconfont {
        margin-left: -5px;
      }

    }

    .tit:nth-of-type(1) {
      margin-top: 0px;
    }
  }
}

.topTitle {
  color: #1b1b1b;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  margin-bottom: 20px;
}

.pop_bottom {
  background: #fff;
  padding: 30px;
  display: flex;
  justify-content: space-around;

  .btn {
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 40px;
    border-radius: 10px;
    background: #f0f0f0;
    color: #1b1b1b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .save {
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 40px;
    border-radius: 10px;
    background: #05c160;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

}

.placeholder {
  color: #c7c7c7 !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.popmask, .expect_job ,.addContactMask{
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: #fff;
  left: 0;
  top: 0;
}
</style>