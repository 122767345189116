<script>
import {store_integral_detail} from "@/api/point";
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import Swiper from "swiper";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import SukPop from "@/components/common/sukPop.vue";
import {getParentIdNew} from "@/utils/tool";

export default {
  name: "point_goods_details",
  components: {SukPop, navigationBar,ElImageViewer},
  data(){
    return{
      goods:{},
      swiperLi:[],
      type: '', //活动类型
      ViewerShow: false,
      ViewList: [],
      detailsHtml: '',
      chaoshi:false,
      xizhi: [
        '划线价格：商品展示的划横线的价格是参考价格，并非原价，该价格可能是商品的专柜标价、商品吊牌价或由品牌供应商提供的正品零售价（如厂商指导价、建议零售价等）或该商品曾经在百街展示过的销售价格等，该价格仅供您参考。',
        '未划线价格：未划线的价格是商品在百街的实时销售标价，是您最终决定是否购买商品的依据。最终成交价格以订单结算页价格为准。',
        '到手价以实际的实时销售标价减去各类优惠，或使用积分享受抵现活动为准。',
        '每日的配送时间为08：00~20：00，夏冬时间略有差异，具体留意下单页提示',
        '商品详情图品仅供参考，具体以实物为准。',
      ],
      sku:{
        tree: [
          {
            k: "颜色",
            k_id: "1",
            v: [
              {
                id: "30349",
                name: "天蓝色",
                imgUrl:"https://img.yzcdn.cn/upload_files/2017/02/21/FjKTOxjVgnUuPmHJRdunvYky9OHP.jpg!100x100.jpg",
              },
              {
                id: "1215",
                name: "白色",
                imgUrl:"https://img.yzcdn.cn/upload_files/2017/02/21/FjKTOxjVgnUuPmHJRdunvYky9OHP.jpg!100x100.jpg",
              },
            ],
            k_s: "s1",
            count: 2,
          },
          {
            k: "尺寸",
            k_id: "2",
            v: [
              {
                id: "1193",
                name: "1",
              },
              {
                id: "1194",
                name: "2",
              },
            ],
            k_s: "s2",
            count: 2,
          },
        ],
        list: [
          {
            id: 2259,
            price: 12000, //价格（单位分）
            s1: "1215",
            s2: "1193",
            stock_num: 20, //库存
            goods_id: 946755,
            a: 0,
          },
          {
            id: 2260,
            price: 11000,
            s1: "1215",
            s2: "1194",
            stock_num: 2, //库存
            goods_id: 946755,
          },
          {
            id: 2257,
            price: 13000,
            s1: "30349",
            s2: "1193",
            stock_num: 40, //库存
            goods_id: 946755,
          },
          {
            id: 2258,
            price: 10000,
            s1: "30349",
            s2: "1194",
            stock_num: 50, //库存
            goods_id: 946755,
          },
        ],
        price: "59.00-129.00", // 默认价格（单位元）
        stock_num: 999, // 商品总库存
        // a: 0,
        collection_id: 999, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
        none_sku: false, // 是否无规格商品
        hide_stock: false, // 是否隐藏剩余库存
        quota:0,//限购数，0 表示不限购
      },
      initialSku: {
      },
      addCAR: 0,

    }
  },
  methods:{
    initSwiper() {
      this.swiper = new Swiper(".swiper", {
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        loop: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
      });
    },
    closeViewer() {
      this.ViewerShow = false
    },
    checkSwiper(item, index) {
      const image = new Image()
      let list = JSON.parse(JSON.stringify(this.swiperLi))
      const star = list.splice(index)
      const remain = list.splice(0, index);
      this.ViewList = star.concat(remain);
      this.ViewerShow = true


      image.src = (item)
      image.onerror = function () {
        console.log('图片加载失败')
      }
    },
    getDetails(item){
      store_integral_detail(item.id).then(async res=>{
        if(res.data.status == 200){
          this.goods = res.data.data
          this.swiperLi = this.goods.storeInfo.slider_image||this.goods.storeInfo.images
          console.log(this.swiperLi)
          this.getHtmlPic(this.goods.storeInfo.description)
          this.$nextTick(()=>{
            this.initSwiper()
          })
          let id = this.goods.storeInfo.id
          this.goods.storeInfo.id = this.goods.storeInfo.product_id
          this.goods.storeInfo.product_id = id
          this.goods.storeInfo.sell_num =  this.goods.storeInfo.sales



            let tree = JSON.parse(JSON.stringify(this.goods.productAttr))
            let list2 = Object.values(this.goods.productValue)
            let initialSku = {}
            tree.forEach((item) => {
              item.v=[];
              item.attr_value.forEach((item2,index2)=>{
                item.v.push({
                  id:  item.id+''+index2,
                  name:  item2.attr
                })
              })
              item.k = item.attr_name
              item.k_s = String(item.id)
              initialSku[item.k_s] = item.v[0].id
              item.imgUrl=''
              item.previewImgUrl=''
              // item.v.id = item.attr_value.id
              delete item.attr_value
              delete item.attr_values
              delete item.attr_name
              delete item.product_id
              delete item.type
            })
            list2.forEach(item=>{
              item.stock_num = item.stock
              item.goods_id = item.id
              item.price =  item.price*100
              tree.forEach(item2=>{
                if(!item[item2.k_s]){
                  item[item2.k_s]  = ''
                }
              })
              item.suk.split(',').forEach(item2=>{
                let id = getParentIdNew(tree,'name',item2,'v').id
                item[id.slice(0,id.length-1)]= id
              })

            })
            this.sku.tree = tree
            this.sku.list = list2
            this.initialSku = initialSku
        }
      })
    },
    getHtmlPic(val) {
      if (!val) return;
      const srcList = [];
      this.detailsHtml = val.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
        srcList.push(capture); // capture图片地址  match img标签整体
        return `<img src=${capture} class="content-img" style="width:100%;display:block">`; //给图片添加class方便后面取dom
      });
      this.$nextTick(() => {
        this.$nextTick(() => {
          const imgHtml = document.getElementsByClassName('content-img');
          for (let i = 0; i < imgHtml.length; i += 1) {
            imgHtml[i].onclick = () => {
              //解决点击预览时不是当前图片问题
              const tempImgList = [...srcList]; // 所有图片地址
              if (i === 0) {
                this.ViewList = tempImgList;
              } else {
                // 调整图片顺序，把当前图片放在第一位
                const start = tempImgList.splice(i);
                const remain = tempImgList.splice(0, i);
                this.ViewList = start.concat(remain);
              }
              this.ViewerShow = true
            };
          }
        });
      })
    },
    submit(){
      if (this.goods.storeInfo.id){
        this.$refs.suk.openPop()
      }
    },

    buyClicked(e){
      if(e.selectedSkuComb){
        let obj = e
        obj.storeInfo = this.goods.storeInfo
        sessionStorage.setItem('create_point',JSON.stringify(obj))
        this.$router.push('/createOrderSecond')
      }else{
        console.log(e)
      }
    },
    addcar(){},

  },
  mounted() {
    this.getDetails(JSON.parse(this.$route.query.goods))
  },
}
</script>

<template>
<div class="container">
  <div class="area">
    <navigationBar :title="''" class="navigation"></navigationBar>
      <div class="swiper">
        <div class="swiper-wrapper">
          <div
              v-for="(item,index) in swiperLi" :key="index"
              class="swiper-slide"
              :style="`background-image:url(${item})`"
              @click="checkSwiper(item,index)"
          >
          </div>
        </div>
        <div class="pagination">
          <div class="swiper-pagination"></div>
        </div>
      </div>
    <div class="goodInfo">
      <div class="integral" v-if="goods.storeInfo">{{goods.storeInfo.integral}} <span class="unit">积分</span></div>
      <div class="title" v-if="goods.storeInfo">{{goods.storeInfo.title || goods.storeInfo.store_name}}</div>
    </div>
    <div class="details">
      <template v-if="ViewerShow">
        <ElImageViewer :on-close="closeViewer" :url-list="ViewList"></ElImageViewer>
      </template>
      <div class="content" v-html="detailsHtml"></div>
    </div>
    <div class="notice">
      <div class="title">兑换须知</div>
      <div class="xuzhi">
        <div class="item" v-for="(item,index) in xizhi" :key="index">
          <div class="unit">{{ index + 1 }}、</div>
          <div class="text">{{ item }}</div>
        </div>
      </div>
    </div>
    <div class="dian" style="width: 100%;height: 100px"></div>
    <div class="bottomBox">
      <div class="btn" @click="submit">
        立即兑换
      </div>
    </div>
    <suk-pop :point="true" v-if="goods.storeInfo" @buyClicked="buyClicked" :initial_sku="initialSku"  ref="suk" :skuParent="sku"  :goods="goods" @addCarc="addcar" :num="addCAR" :chaoshi="chaoshi"></suk-pop>
  </div>
</div>
</template>

<style scoped lang="scss">
.container{
  background: #f4f6fa;
  position: relative;
  .navigation{
    position: absolute;
    background-color: transparent!important;
    z-index: 99;
  }
}
.swiper {
  overflow: hidden;
  position: relative;
  height: 375px;
  background: #fff;
  .swiper-slide {
    height: 375px;
  }

  .img {
    width: 100%;
    object-fit: cover;
  }

  // 分页器
  /* 两种都可以 */
  .swiper-pagination-total {
    font-size: 12px;
  }

  .swiper-pagination {
    background: rgba(51, 51, 51, 0.75);
    width: auto;
    // right: 10px;
    border-radius: 64px;
    padding: 0 5px;
    color: white;
    line-height: 24px;
    font-size: 13px;
  }

  // 分页器
  .pagination {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 10px;
    z-index: 3;
    margin: 0;
  }
}
.swiper-slide{
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.goodInfo{
  background: #fff;
  text-align: left;
  padding: 10px 16px;
  margin: 10px 0 0 0;
  .integral{
    color: #fd2a39;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    .unit{
      color: #7f7f7f;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
    }
  }
  .title{
    padding-top: 10px;
    color: #1b1b1b;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: -webkit-box;
    /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
    -webkit-box-orient: vertical;
    /*要显示的行数*/
    -webkit-line-clamp: 2;
    /* 溢出部分隐藏 */
    overflow: hidden;
  }
}
.details{
  text-align: left;
  background: #fff;
}
.notice{
  text-align: left;
  padding: 16px 10px;
  .title{
    color: #1b1b1b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .xuzhi {
    .item {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      font-size: 11px;
      color: #B0B0B0;
      line-height: 18px;
      text-align: left;
    }
  }

}
.bottomBox{
  position: fixed;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px -0.5px 2px #99999926;
  padding: 10px 0;
  width: 100%;
  .btn{
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 24px;
    border-radius: 32px;
    background: #fc223b;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 0 16px;
  }
}
</style>