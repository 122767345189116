import request from "@/utils/request";
import store from "../store/index";
const newDevHost = store.state.newDevHost;
/*
* 维修创建  status
* 1//等待报价
* 2//已报价 未支付
* 3//已支付待接单
* 4//已支付 已接单
* 5//已支付已维修 待验收
* 6//已支付已维修 验收未通过有问题
* 7//已支付已维修 验收通过
*
* 1正在快修  2记录
* */
export function user_repair_list(
    type,
    status,
    page,
    limit
){

    let data = {
        type,
        status,
        page,
        limit
    }
    return request.post(`${newDevHost}/api/order/user_repair_list`,data)
}