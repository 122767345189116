<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import Compressor from "compressorjs";
import {upload_file} from "@/utils/api";
import enterprise_types from "@/components/common/invite_job/invite_certification/enterprise_types.vue";
import {Toast} from "vant";
import {getCompany, update_company,create_company} from "@/api/recruitment"

export default {
  name: "invite_Company_certification",
  components: {enterprise_types, navigationBar},
  data() {
    return {
      disable: false,
      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["png", "jpg", "jpeg"],
      Resume: {
        name: '',//企业证件照全程，
        type: '',//企业类型
        type_id: '',//企业类型
        license: '',//营业执照
        front: '',//正面
        contrary: '',//反面
        shop_name: '',//公司名称
        address: '',//公司地址
        address_province_id: '',//省
        address_province_name: '',//省
        address_city_id: '',//市
        address_city_name: '',//市
        address_district_id: '',//区
        address_district_name: '',//区
        address_details: '',//公司地址xx详细地址
        scale: '',//规模
        logo: '',//logo
      },

      rules: {
        name: [{required: true, message: '请输入企业证件照全称', trigger: 'blur'},],
        type: [{required: true, message: '请选择企业类型', trigger: 'blur'},],
        license: [{required: true, message: '请上传营业执照', trigger: 'blur'},],
        front: [{required: true, message: '请上传正面证件', trigger: 'blur'},],
        contrary: [{required: true, message: '请上传反面证件', trigger: 'blur'},],

        shop_name: [{required: true, message: '请输入公司名称', trigger: 'blur'},],
        address: [{required: true, message: '请选择公司地址', trigger: 'blur'},],
        address_details: [{required: true, message: '请选择详细地址', trigger: 'blur'},],
        scale: [{required: true, message: '请选择公司人员规模', trigger: 'blur'},],
        logo: [{required: true, message: '请上传公司logo', trigger: 'blur'},],
      },
      licenseFiles: [],
      frontFiles: [],
      contraryFiles: [],
      logoFiles: [],

      checked: false,
      pop_show: false,
      pop_type: '',
      maskType: '',

      address_li: this.$store.state.allAddress,
      scaleList: [
        '0-20人',
        '20-99人',
        '100-499人',
        '500-999人',
        '1000-9999人',
        '10000人以上',
      ],
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //
          if (this.checked === false) {
            Toast('请阅读并同意协议')
            return
          }
          // name: '',//企业证件照全程，
          //     type: '',//企业类型
          //     license: '',//营业执照
          //     front: '',//正面
          //     contrary: '',//反面
          //     shop_name:'',//公司名称
          //     address:'',//公司地址
          //     address_details:'',//公司地址xx详细地址
          //     scale:'',//规模
          //     logo:'',//logo
          let corporate_capacity_images = `${this.Resume.front},${this.Resume.contrary}`
          if(this.Resume.id){
            update_company(this.Resume.id, this.Resume.name, this.Resume.type_id, this.Resume.license, corporate_capacity_images, this.Resume.shop_name, this.Resume.address_province_id, this.Resume.address_city_id, this.Resume.address_district_id, this.Resume.address_province_name, this.Resume.address_city_name, this.Resume.address_district_name,this.Resume.address_details,this.Resume.scale,this.Resume.logo).then(res=>{
              if(res.data.status == 200){
                Toast('提交成功')
                this.$router.go(-1)
              }else{
                Toast(res.data.msg)
              }
            }).catch(err=>{
              console.log(err)
            })
          }else{
            create_company(this.Resume.name, this.Resume.type_id, this.Resume.license, corporate_capacity_images, this.Resume.shop_name, this.Resume.address_province_id, this.Resume.address_city_id, this.Resume.address_district_id, this.Resume.address_province_name, this.Resume.address_city_name, this.Resume.address_district_name,this.Resume.address_details,this.Resume.scale,this.Resume.logo).then(res=>{
              if(res.data.status == 200){
                Toast('提交成功')
                this.$router.go(-1)
              }else{
                Toast(res.data.msg)
              }
            }).catch(err=>{
              console.log(err)
            })
          }


        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },

    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)
        }


      })
    },
    async beforeUpload(files) {
      console.log(files)
      let file = await this.yasuo(files)
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        if (files.type.startsWith('image/')) {   //图片
          //计算文件的大小
          const isLt5M = file.size / 1024 / 1024 < 1; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            return false;
          }
        } else if (files.type.startsWith('video/')) {//视频
          const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
    uploadFile(item) {
      // console.log(JSON.stringify(item))
      this.$message("文件上传中........");
      if (item.file.type.startsWith('image/')) {
        upload_file(item.file).then(res => {
          if (res.data.status == 200) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 'license') {//营业执照
              this.Resume.license = res.data.data.url
              this.licenseFiles = []
              // this.kanchaUpload.push({tempFilePath: res.data.data.url, fileType: 'video'})
            }
            if (item.filename == 'front') {//正面
              this.Resume.front = res.data.data.url
              this.frontFiles = []
              // this.DoorUpload.push({tempFilePath: res.data.data.url, fileType: 'video'})
            }
            if (item.filename == 'contrary') {//反面
              this.Resume.contrary = res.data.data.url
              this.contraryFiles = []
              // this.DoorUpload.push({tempFilePath: res.data.data.url, fileType: 'video'})
            }
            if (item.filename == 'logo') {//反面
              this.Resume.logo = res.data.data.url
              this.logoFiles = []
              // this.DoorUpload.push({tempFilePath: res.data.data.url, fileType: 'video'})
            }
          }
        })
      }
    },
    handleSuccess() {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.handleClose();
    },
    fileChange(file, infomationFiles) {
      this.avatarFiles = infomationFiles;
    },
    async handleRemove() {
    },
    handleClose(i) {
      this.avatarFiles.splice(i, 1); //删除上传的文件
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },

    openMask() {
      this.maskType = true
    },
    closeMask() {
      this.maskType = false
    },
    openPop(e) {
      this.pop_show = true
      this.pop_type = e
    },
    popClosed() {
      this.pop_type = ''
    },
    closePop() {
      this.pop_show = false
    },
    save() {
      if (this.pop_type == 'scale') {
        this.Resume.scale = this.$refs.scale.getValues()[0]
      }
      if (this.pop_type == 'Working_city') {
        console.log(this.$refs.Working_city.getValues())

        this.Resume.address = String(this.$refs.Working_city.getValues().map(item => {
          return item.name
        })).replaceAll(',', '-')
        this.Resume.address_province_id = this.$refs.Working_city.getValues()[0].id
        this.Resume.address_province_name = this.$refs.Working_city.getValues()[0].name
        this.Resume.address_city_id = this.$refs.Working_city.getValues()[1].id
        this.Resume.address_city_name = this.$refs.Working_city.getValues()[1].name
        this.Resume.address_district_id = this.$refs.Working_city.getValues()[2].id
        this.Resume.address_district_name = this.$refs.Working_city.getValues()[2].name
      }
      this.closePop()
    },
    submitMask(e) {
      console.log(e)
      this.maskType = false
      this.Resume.type = e.name
      this.Resume.type_id = e.id
    },
  },
  mounted() {
    console.log(this.$store.state.allAddress)
    if(this.$route.query.item){
      getCompany(JSON.parse(this.$route.query.item).id).then(res=>{
        if(res.data.status==true){
          this.disable = true
          let Resume = res.data.data
          console.log(Resume)
          let address = [
            {name:Resume.province_name,id:Resume.province_id},
            {name:Resume.city_name,id:Resume.city_id},
            {name:Resume.district_name,id:Resume.district_id},
          ]
          this.Resume = {
            name: Resume.business_license_name,//企业证件照全程，
            type: Resume.companytype.name,//企业类型
            type_id: Resume.companytype.id,//企业类型
            license: Resume.business_license_images,//营业执照
            front: Resume.corporate_capacity_images.split(',')[0],//正面
            contrary: Resume.corporate_capacity_images.split(',')[1],//反面
            shop_name: Resume.name,//公司名称
            address: String(address.map(item=>{return item.name})).replaceAll(',','-'),//公司地址
            address_province_id: address[0].id,//省
            address_province_name: address[0].name,//省
            address_city_id: address[1].id,//市
            address_city_name: address[1].name,//市
            address_district_id: address[2].id,//区
            address_district_name: address[2].name,//区
            address_details: Resume.address,//公司地址xx详细地址
            scale: Resume.company_nums,//规模
            logo: Resume.logo_image,//logo
            id:Resume.id,
            introduce:Resume.company_nums,
            audit_state:Resume.audit_state,
          }
              // `audit_state` tinyint(1) NOT NULL DEFAULT '0' COMMENT '审核状态（0: 待审核，1: 审核通过，2: 审核拒绝）',
        }
      }).catch(err=>{
        console.log(err)
      })
    }
  }

}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'企业认证'" class="navigationBar"></navigationBar>
      <enterprise_types
          @closePop="closeMask"
          @submit="submitMask"
          class="popmask"
          v-if="maskType"
      ></enterprise_types>
      <el-form :model="Resume" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">

        <van-cell class="cell" size="large" label="">
          <template #title>
            <span class="custom-title">企业证照全称</span>
          </template>
          <template #label>
            <el-form-item label="" prop="name">
              <input class="input" type="text" placeholder="请输入（需与企业证照的名称一致）" v-model="Resume.name"
                     :disabled="disable">
            </el-form-item>
          </template>
        </van-cell>
        <van-cell class="cell selectCell" size="large" label="" @click="openMask">
          <template #title>
            <span class="custom-title">企业类型</span>
          </template>
          <template #label>
            <el-form-item label="" prop="type">
              <span class="text" :class="Resume.type ? '' : 'placeholder'">{{
                  Resume.type ? Resume.type : '请选择'
                }}</span>
            </el-form-item>
            <span class="iconfont icon-Frame-4"></span>
          </template>
        </van-cell>
        <van-cell class="cell selectCell" size="large" label="">
          <template #title>
            <span class="custom-title">请上传【营业执照】照片</span>
          </template>
          <template #label>
            <el-form-item label="" prop="license">
              <div class="">
                <el-upload
                    class="avatar-uploader"
                    action
                    :http-request="uploadFile"
                    ref="upload"
                    :limit="1"
                    :file-list="licenseFiles"
                    :on-remove="handleRemove"
                    :on-exceed="handleExceed"
                    :before-upload="beforeUpload"
                    :show-file-list="false"
                    :headers="headers"
                    :on-change="fileChange"
                    multiple
                    name="license"
                >
                  <div class="imageBox">
                    <div v-if="!Resume.license"
                         class="innerBox"
                         :style="`background-image: url('${$store.state.imageHost}/img/Frame@2x（1.png')`">
                      <img :src="$store.state.imageHost+'/img/Group_1538%402x.png'" alt="" class="image">
                    </div>
                    <img :src="Resume.license" alt="" class="showImg" v-if="Resume.license">
                    <div class="text">上传营业执照</div>
                  </div>
                </el-upload>
              </div>
            </el-form-item>
          </template>
        </van-cell>
        <van-cell class="cell selectCell" size="large" label="">
          <template #title>
            <span class="custom-title">上传法人身份证</span>
          </template>
          <template #label>
            <el-form-item label="" prop="front">
              <div class="">
                <el-upload
                    class="avatar-uploader"
                    action
                    :http-request="uploadFile"
                    ref="upload"
                    :limit="1"
                    :file-list="frontFiles"
                    :on-remove="handleRemove"
                    :on-exceed="handleExceed"
                    :before-upload="beforeUpload"
                    :show-file-list="false"
                    :headers="headers"
                    :on-change="fileChange"
                    multiple
                    name="front"
                >
                  <div class="imageBox">
                    <div
                        v-if="!Resume.front"
                        class="innerBox"
                        :style="`background-image: url('${$store.state.imageHost}/img/Rectangle@2x.png')`">
                      <img :src="$store.state.imageHost+'/img/Group_1538%402x.png'" alt="" class="image">
                    </div>
                    <img :src="Resume.front" alt="" class="showImg" v-if="Resume.front">
                    <div class="text">上传身份证正面</div>
                  </div>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item label="" prop="contrary">
              <div class="">
                <el-upload
                    class="avatar-uploader"
                    action
                    :http-request="uploadFile"
                    ref="upload"
                    :limit="1"
                    :file-list="contraryFiles"
                    :on-remove="handleRemove"
                    :on-exceed="handleExceed"
                    :before-upload="beforeUpload"
                    :show-file-list="false"
                    :headers="headers"
                    :on-change="fileChange"
                    multiple
                    name="contrary"
                >
                  <div class="imageBox">
                    <div class="innerBox"
                         v-if="!Resume.contrary"
                         :style="`background-image: url('${$store.state.imageHost}/img/Frame@2x（1.png')`">
                      <img :src="$store.state.imageHost+'/img/Group_1538%402x.png'" alt="" class="image">
                    </div>
                    <img :src="Resume.contrary" alt="" class="showImg" v-if="Resume.contrary">
                    <div class="text">上传身份证反面</div>
                  </div>
                </el-upload>
              </div>
            </el-form-item>
          </template>
        </van-cell>

        <van-cell class="cell" size="large" label="">
          <template #title>
            <span class="custom-title">公司名称</span>
          </template>
          <template #label>
            <el-form-item label="" prop="shop_name">
              <input class="input" type="text" placeholder="请输入" v-model="Resume.shop_name"
                     :disabled="disable">
            </el-form-item>
          </template>
        </van-cell>

        <van-cell class="cell selectCell" size="large" label="" @click="openPop('Working_city')">
          <template #title>
            <span class="custom-title">公司地址</span>
          </template>
          <template #label>
            <el-form-item label="" prop="address">
              <span class="text" :class="Resume.address ? '' : 'placeholder'">{{
                  Resume.address ? Resume.address : '请选择'
                }}</span>
            </el-form-item>
            <span class="iconfont icon-Frame-4"></span>
          </template>
        </van-cell>


        <van-cell class="cell" size="large" label="">
          <template #title>
            <span class="custom-title">详细地址</span>
          </template>
          <template #label>
            <el-form-item label="" prop="address_details">
              <input class="input" type="text" placeholder="请输入" v-model="Resume.address_details"
                     :disabled="disable">
            </el-form-item>
          </template>
        </van-cell>

        <van-cell class="cell selectCell" size="large" label="" @click="openPop('scale')">
          <template #title>
            <span class="custom-title">人员规模</span>
          </template>
          <template #label>
            <el-form-item label="" prop="scale">
              <span class="text" :class="Resume.scale ? '' : 'placeholder'">{{
                  Resume.scale ? Resume.scale : '请选择公司人员规模'
                }}</span>
            </el-form-item>
            <span class="iconfont icon-Frame-4"></span>
          </template>
        </van-cell>


        <van-cell class="cell selectCell" size="large" label="">
          <template #title>
            <span class="custom-title">公司logo</span>
          </template>
          <template #label>
            <el-form-item label="" prop="logo">
              <div class="">
                <el-upload
                    class="logo-uploader"
                    action
                    :http-request="uploadFile"
                    ref="upload"
                    :limit="1"
                    :file-list="logoFiles"
                    :on-remove="handleRemove"
                    :on-exceed="handleExceed"
                    :before-upload="beforeUpload"
                    :show-file-list="false"
                    :headers="headers"
                    :on-change="fileChange"
                    multiple
                    name="logo"
                >
                  <div class="logoInner" v-if="!Resume.logo">
                    <span class="iconfont icon-xiangji"></span>
                    <div>添加图片</div>
                  </div>
                  <img v-if="Resume.logo" :src="Resume.logo" alt="" class="image">
                </el-upload>
              </div>
            </el-form-item>
          </template>
        </van-cell>


        <div class="bottomBtn" v-if="!Resume.id">
          <div class="agreementBpox">
            <van-checkbox v-model="checked" checked-color="#fc223b">
              <template #default>
                <div class="checkboxText">阅读并同意<a href="">《用户协议》</a>及<a href="">《隐私政策》</a></div>
              </template>
            </van-checkbox>
          </div>
          <!--            <el-button @click="resetForm('ruleForm')">重置</el-button>-->
          <div @click="submitForm('ruleForm')" class="oneSubmit">提交</div>
        </div>

        <div class="bottomBtn" v-if="Resume.id">
          <div class="agreementBpox" v-if="!disable">
            <van-checkbox v-model="checked" checked-color="#fc223b">
              <template #default>
                <div class="checkboxText">阅读并同意<a href="">《用户协议》</a>及<a href="">《隐私政策》</a></div>
              </template>
            </van-checkbox>
          </div>
<!--          `audit_state` tinyint(1) NOT NULL DEFAULT '0' COMMENT '审核状态（0: 待审核，1: 审核通过，2: 审核拒绝）',-->
          <div @click="submitForm('ruleForm')" class="oneSubmit" v-if="!disable">提交</div>
          <div @click="disable = false" class="oneSubmit" v-if="disable&&Resume.audit_state == 1">修改</div>
          <div  class="oneSubmit oneSubmit2" v-if="disable&&Resume.audit_state == 0">审核中</div>
          <div  class="oneSubmit oneSubmit3" v-if="disable&&Resume.audit_state == 2">拒绝</div>
        </div>
      </el-form>

      <van-popup v-model="pop_show" position="bottom" @closed="popClosed">
        <div class="pop_inner">
          <van-picker
              v-if="pop_type == 'scale'"
              ref="scale"
              :columns="scaleList"
          />

          <van-picker
              v-if="pop_type == 'Working_city'"
              ref="Working_city"
              value-key="name"
              :columns="address_li"
          />

        </div>
        <div class="pop_bottom">
          <div class="close btn" @click="closePop">取消</div>
          <div class="save btn" @click="save">确定</div>
        </div>
      </van-popup>

    </div>
  </div>
</template>

<style scoped lang="scss">
.popmask {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: #fff;
  left: 0;
  top: 0;
}

.logoInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #c7c7c7;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 100%;

  span, div {
    height: 24px;
    font-size: 16px;

  }

  .iconfont {
    margin-bottom: 0px !important;
  }
}

.demo-ruleForm {
  padding-bottom: 100px;
}

.bottomBtn {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px 0;
  justify-content: center;
  box-shadow: 0px -0.5px 2px #99999926;

  .oneSubmit {
    border-radius: 10px;
    background-color: #fc223b;
    color: #fff;
    text-align: center;
    line-height: 45px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .oneSubmit2{
    background-color: #fec7ce;

  }
  .oneSubmit3{}

  .agreementBpox {
    margin: 10px 16px;

    .checkboxText {
      color: #666666;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;

      a {
        color: #F96422;
      }
    }
  }
}

a {
  color: #F96422;
}

input:disabled {
  background-color: #fff;
}

.navigationBar {
  position: relative;

  .edit {
    position: absolute;
    right: 20px;
  }
}

.pop_bottom {
  background: #fff;
  padding: 30px;
  display: flex;
  justify-content: space-around;

  .btn {
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 40px;
    border-radius: 10px;
    background: #f0f0f0;
    color: #1b1b1b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .save {
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 40px;
    border-radius: 10px;
    background: #05c160;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

}

.popmask, .expect_job {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: #fff;
  left: 0;
  top: 0;
}

.avatar-uploader {
  width: 162px;
  height: 127px;
  border-radius: 10px;
  background: #eeeeee;
  border: none;
  padding-bottom: 6px;

  .imageBox {
    display: flex;
    flex-direction: column;

    .text {
      text-align: center;
      color: #1b1b1b;
      font-weight: 400;
      font-size: 14px;
      line-height: 18.2px;
      margin: 6px;
    }
  }

  .innerBox {
    flex-direction: column;
    width: 146px;
    height: 90px;
    border-radius: 10px;
    margin: 6px 8px;
    min-height: 90px;
    background-size: 80%;
    background: #fff no-repeat center;
    display: flex;
    align-items: center;
    justify-content: center;

    .inn {
      width: 146px;
      height: 90px;
      border-radius: 10px;
      background: #ffffff;
    }

    .iconfont {
      font-size: 24px;
      margin-bottom: 0px;
    }

    .text {
      text-align: center;
      color: #1b1b1b;
      font-weight: 400;
      font-size: 14px;
      line-height: 18.2px;
    }

    .image {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }

  .showImg {
    width: 146px;
    height: 90px;
    object-fit: cover;
    margin: 6px 8px;

  }
}

.logo-uploader {
  width: 97px;
  height: 97px;
  border-radius: 10px;
  border: 1.3px dashed #c7c7c7;
  box-sizing: border-box;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.showImgBox {
  position: relative;

  .iconfont {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.imageShow {
  width: 76px;
  height: 76px;
  border-radius: 50px;
  object-fit: cover;
}

.container {
  .area {
    min-height: 100vh;
  }
}

.van-cell__title {
  text-align: left;
  width: 80%;
}

.van-cell__value {
  width: 10%;
  -webkit-box-flex: initial;
  -webkit-flex: initial;
  flex: initial;
}

.van-cell__label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: 10px;
  color: #1b1b1b;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 22px;
}

.van-cell__label {

  .text {
    display: inline-block;
    display: -webkit-box;
    /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
    -webkit-box-orient: vertical;
    /*要显示的行数*/
    -webkit-line-clamp: 2;
    /* 溢出部分隐藏 */
    overflow: hidden;
  }
}

.el-form-item__content {
  font-size: 16px;
  line-height: 22px;
}

.el-form-item {
  margin-bottom: 20px;
}

.cell {
  border-bottom: 1px solid var(--房产描边F6F6F6, #f6f6f6);
  padding: 20px 0 0 0;
  margin: 0 16px;
  width: initial;

  .custom-title {
    color: #1b1b1b;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    .essential {
      color: #fc223b;
    }
  }

  .placeholder {
    color: #c7c7c7;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .input {
    border: none;
    color: #1b1b1b;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    width: 100%;
  }

  .input::placeholder {
    color: #c7c7c7;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .iconfont {
    font-size: 24px;
    color: #666666;
    margin-bottom: 20px;
  }
}

.avatar {
  .van-cell__value {
    width: 40%
  }

  .van-cell__title {
    width: 50%;
  }
}

.selectCell {
  .van-cell__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    vertical-align: middle;
  }

  .text {
    vertical-align: middle;
    color: #1b1b1b;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}

.placeholder {
  color: #c7c7c7 !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.bottomBox {
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
  width: 100%;

  .btn {
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 12px 13px;
    border-radius: 12px;
    background: #0393f5;
    margin: 15px 16px;
    text-align: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>


<style>
.logo-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
</style>
