<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import idle_tabbar from "@/components/common/tabbar/idle_tabbar.vue";
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";
import { getLunboList} from "@/utils/api";
import {_throttle,isValidJSON} from "@/utils/tool";
//
import {Toast} from "vant";
import Bus from "@/utils/Bus";
import {getCategory, getConfigImage, getPeripProductList} from "@/api/idle";

export default {
  name: "home",
  components: {NavigationBar, idle_tabbar},
  data() {
    return {
      publicitySwiper: {},
      topSwiper: [],
      location: {
        address: '北京',
      },
      funLi: [
        {
          title: '货运搬家',
          text: "效率至上 用户首选 ",
          text2: '安全搬运 放心选择',
          icon: require('/public/image/freight.png'),
          type: 2,
        },
        {
          title: '废品回收',
          text: "附近师傅 上门回收 ",
          text2: '创新再生 环保共赢',
          icon: require('/public/image/recycle.png'),
          type: 1,
        },
      ],
      KongLi: [],
      pay_flow: [
        {text: '线上出价', img: '/image/idle_chujia.png'},
        {text: '卖家同意', img: '/image/idle_maijia.png'},
        {text: '线上沟通', img: '/image/idle_goutong.png'},
        {text: '现场交易', img: '/image/idle_jiaoyi.png'},
        {text: '当面结算', img: '/image/idle_jiesuan.png'},
      ],
      tabsActive: 0,

      hasMore: true,//是否可以继续滚动
      loadLock: true,//是否展示loding

      mainMenuList: [],
      leftList: [],
      rightList: [],
      leftHeight: 0,
      rightHeight: 0, //分别定义左右两边的高度
      oldScrollTop: 0,
      page: 0,
      limit: 10,
    }
  },
  async mounted() {
    if (this.$route.query.idle_token) {
      this.$store.commit('updataToken', this.$route.query.idle_token)
      sessionStorage.setItem('token', this.$route.query.idle_token);
      Bus.$emit("updataUserInfo", '1');
    }
    this.qingqiu(this)

    this.getCategory()

    // this.getSwiperList()
    console.log(await getConfigImage(6,this.$store.state.client_type),'6首页轮播图')

  },
  methods: {
    sendssssss(){
      window.sendFun()
    },

    godetails(e) {
      this.$router.push({
        path: '/idleDetails',
        query: {id: e.id}
      })
    },
    getSwiperList() {
      getLunboList(this.$store.state.token).then(res => {
        if (res.data.status == true) {
          this.topSwiper = res.data.data

          this.$nextTick(() => {
            this.publicitySwiper = new Swiper('.swiper', {
              pagination: {
                el: ".swiper-pagination",
                type: "fraction",
              },
              loop: false,
              autoplay: {
                delay: 3000,
                disableOnInteraction: false,
              },
            });
          })
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getSplitArray(list, splitCount = 10) {
      const result = [];
      for (let i = 0; i < list.length; i += splitCount) {
        result.push(list.slice(i, i + splitCount));
      }
      return result;
    },
    getCategory() {
      getCategory().then(res => {
        if (res.data.status == 200) {
          let list = res.data.data
          list = list.filter(item => {return item.cate_name !='自由市场'})
          list.forEach(item => {
            item.swiper = {}
            item.class = item.cate_name
          })
          this.KongLi = list
          this.$nextTick(() => {
            this.KongLi[this.tabsActive].swiper = new Swiper('.' + this.KongLi[this.tabsActive].class, {
              pagination: {
                el: ".swiper-pagination",
                // clickable设置为true时，点击分页器的指示点分页器会控制Swiper切换
                clickable: true,
                //修改分页器样式
                bulletClass: 'swiper-pagination-bullet',
                //激活样式
                bulletActiveClass: 'my-bullet-active',
              },
              loop: false,
              autoplay: false,
            });
          })


        }
      }).catch(err => {
        console.log(err)
      })
    },

    getBoxHeight(leftList, rightList, _this) {
      //获取左右两边高度
      return new Promise((resolve) => {
        _this.$nextTick(() => {
          _this.leftList = leftList;
          _this.rightList = rightList;
          _this.leftHeight = _this.$refs.left.offsetHeight;
          _this.rightHeight = _this.$refs.right.offsetHeight;
          // console.log("左边", _this.leftHeight, "右边", _this.rightHeight);
        });
        resolve();
      });
    },
    containerScroll(event) {
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const {scrollHeight, clientHeight, scrollTop} = event.target;
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          if (this.hasMore) {
            this.loadLock = true
            this.qingqiu(this);
          }
        }
      }
    },

    qingqiu: _throttle(async function (_that) {
      this.page += 1
      console.log(_that)
      // const { leftList, rightList, mainMenuList, leftHeight, rightHeight } =
      // getCateProductList(this.page, this.limit)
      // getPeripProductList(this.page, this.limit).then(async res => {
      //   if(res.data.status == 200){
      //     let list = res.data.data.data
      //     console.log(list)
      //   }
      // })
      getPeripProductList(this.page, this.limit).then(async res => {
        let list = res.data.data.data
        // list = list.sort(function (a, b) {
        //   return a.sort - b.sort
        // })
        list.forEach(item => {
          // console.log(item.distance,item.dx.split(',')[0],item.dx.split(',')[1],getDistances(this.$store.state.nowPosition.location.lat,this.$store.state.nowPosition.location.lng,item.dx.split(',')[1],item.dx.split(',')[0]))
          // item.distanceObj = getDistances(this.$store.state.nowPosition.location.lat, this.$store.state.nowPosition.location.lng, item.dx.split(',')[1], item.dx.split(',')[0])
          // if(parseInt(item.distance)>=1000){
          //   item.distanceObj.distance_str = (item.distance/1000).toFixed(2)+ "km"
          // }

          if (item.images) {
            if (isValidJSON(item.images)) {
              item.showImg = JSON.parse(item.images)[0]
            } else {
              item.showImg = item.images.split(',')[0]
            }
            if (typeof item.showImg == 'string') {
              if (!item.showImg.startsWith('https:')) {
                item.showImg = this.$store.state.imageHost + item.showImg
              } else {
                item.showImg = item.showImg
              }
            } else {
              item.showImg = this.$store.state.imageHost + item.showImg.tempFilePath
            }
          }
        })
        console.log(list)
        this.mainMenuList = list
        if ((this.leftList.length + this.rightList.length) >= res.data.data.total) {
          this.hasMore = false
        }
        for (const item of _that.mainMenuList) {
          console.log("左边", _that.leftHeight, "右边", _that.rightHeight);
          if (_that.leftHeight <= _that.rightHeight) {
            _that.leftList.push(item);
          } else {
            _that.rightList.push(item);
          }
          await _that.getBoxHeight(_that.leftList, _that.rightList, _that);
        }
        this.loadLock = false
      }).catch(err => {
        console.log(err)
      })
    }, 500),

    goList(item) {
      this.$router.push({
        path:'/idle_work_list',
        query:{item:JSON.stringify(item)},
      })
    },
    goShopList(item) {
      console.log(item)
      this.$router.push({
        path: '/idle_shop_list',
        query: {
          id: item.id,
          cate_name: item.cate_name,
        },
      })
    },

    tabsChange(e) {
      e
      // console.log(e)
    },
    beforeChange(index) {
      console.log(index)
      if(this.KongLi[index].id == 2){
        this.$router.push({
          path: '/Idle_office',
          query: {item: JSON.stringify(this.KongLi[index])},
        })
      }
      if (this.KongLi[index].id == 4) {
        this.$router.push({
          path: '/idle_homeGoodsList',
          query: {item: JSON.stringify(this.KongLi[index])},
        })
        return false
      }
      return true
    },
  },
  watch: {
    tabsActive(n) {
      this.$nextTick(() => {
        if (!this.KongLi[n].swiper.$el) {
          this.KongLi[n].swiper = new Swiper('.' + this.KongLi[n].class, {
            pagination: {
              el: ".swiper-pagination",
              // clickable设置为true时，点击分页器的指示点分页器会控制Swiper切换
              clickable: true,
              //修改分页器样式
              bulletClass: 'swiper-pagination-bullet',
              //激活样式
              bulletActiveClass: 'my-bullet-active',
            },
            loop: false,
            autoplay: false,
          });
        }
      })
    },

    KongLi(n) {
      n.forEach((item) => {
        if (item.children) {
          let list = item.children
          item.swiperList = this.getSplitArray(list)
        }

      })
      console.log(n)
    },
  },
  beforeDestroy() {
    Bus.$off("sendUserInfo");
  }
}
</script>

<template>
  <div class="container" @scroll="containerScroll">
    <div class="area">
      <navigationBar :title="''" class="navigationBar" :particular="'idle'">
        <template #search>
          <div class="searchBox">
<!--            <div class="left"><span class="iconfont icon-weizhi1"></span>{{ location.address }}-->
<!--              <van-icon name="arrow-down" class="icon"/>-->
<!--            </div>-->
            <div class="right">
              <div class="searchOut">
                <div class="leftIcon"><span class="iconfont icon-Group"></span></div>
                <div class="text">搜索你想要的物品...</div>
              </div>
            </div>
          </div>
        </template>
      </navigationBar>
      <!--      <span class="iconfont icon-Frame-19"></span>-->
      <!--      <span class="iconfont icon-Frame28"></span>-->
<!--      <button @click="sendssssss">发送</button>-->

      <div class="inner">
        <div class="publicity">
          <!--          <img src="https://picture.bjbjkj.top/tp2024071803001500257731617.png" alt="" class="img">-->
          <div class="swiper publicitySwiper">
            <div class="swiper-wrapper">
              <div v-for="(item,index) in topSwiper" :key="index"
                   class="swiper-slide"
                   :style="`background-image:url(${$store.state.imageHost}${item.img})`"
              ></div>
            </div>
          </div>


        </div>

        <div class="funBox">
          <div class="every" v-for="(item,index) in funLi" :key="index" @click="goList(item)">
            <div class="title">
              <span>{{ item.title }}</span>
              <span class="iconfont icon-jiantou"></span>
            </div>
            <div class="body">
              <div class="left">
                <div>{{ item.text }}</div>
                <div>{{ item.text2 }}</div>
              </div>
              <img :src="item.icon" alt="" class="image">
            </div>
          </div>
        </div>

        <div class="card idle_home">
          <van-tabs animated class="tabs" v-model="tabsActive" @change="tabsChange" :before-change="beforeChange">
            <van-tab :title="item.cate_name" v-for="(item,index) in KongLi" :key="index">
              <div class="" :class="item.class">
                <div class="swiper-wrapper">
                  <div v-for="(item2,index2) in item.swiperList" :key="index2" class="page swiper-slide">
                    <div class="every" v-for="(item3,index3) in item2" :key="index3" @click="goShopList(item3)">
                      <img :src="item3.pic" alt="" class="iconf">
                      <!--                      <span class="iconfont icon-pengyouquan"></span>-->
                      <div class="text">{{ item3.cate_name }}</div>
                    </div>
                  </div>
                  <!--                <div-->
                  <!--                    class="swiper-slide"-->
                  <!--                    :style="`background-image:url(https://picture.bjbjkj.top/tp2024071803001500257731617.png)`"-->
                  <!--                ></div>-->
                </div>
                <!--                &lt;!&ndash; 如果需要分页器 &ndash;&gt;-->
                <!--                <div class="swiper-pagination">-->
                <!--                </div>-->
              </div>
            </van-tab>
          </van-tabs>
        </div>


        <div class="card pay_flow">
          <div class="title">交易流程</div>
          <div class="body">
            <div class="every" v-for="(item,index) in pay_flow" :key="index">
              <!--            <span class="iconfont icon-pengyouquan"></span>-->
              <img class="iconf"
                   :src="$store.state.imageHost+item.img"
                   alt="">
              <div class="text">{{ item.text }}</div>
              <div class="circle"></div>
              <!--              <van-icon name="arrow" class="icon"/>-->
            </div>
          </div>
        </div>

        <div class="pubu">
          <!--          <div class="title">闲置好物</div>-->
          <div id="box">
            <div id="left-box" ref="left">
              <div class="item" v-for="(item,index) in leftList" :key="index" @click.stop="godetails(item)">
                <div class="imageBox" @click.stop="godetails(item)">
                  <img :src="item.showImg" alt="" class="img">
                  <div class="tag" :class="item.is_sale==2?'yushou':''">{{ item.is_sale_text }}</div>
                </div>
                <div class="bottom">
                  <div class="name">{{ item.product_name }}</div>
                  <div class="sellNums">{{ item.sell_count }}</div>
                  <div class="priceLine">
                    <div class="nowPrice">
                      <div class="leftUnit">￥</div>
                      <div class="rightPrice">{{ item.price }}</div>
                    </div>
                    <div class="lastPrice">
                      {{ item.visit_count }}
                    </div>
                  </div>
                  <div class="userInfo">
                    <div class="left">
                      <img class="avatar"
                           :src="$store.state.imageHost+item.avatar"
                           alt="">
                      <span class="nickname">{{ item.nickname }}</span>
                    </div>
                    <div class="rightAdd">
                      <!--                      <span class="iconfont icon-Frame11"></span>-->
                      <!--                      {{ item.distanceObj.distance_str }}-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="right-box" ref="right">
              <div class="item" v-for="(item,index) in rightList" :key="index" @click.stop="godetails(item)">
                <div class="imageBox">
                  <img :src="item.showImg" alt="" class="img">
                  <div class="tag" :class="item.is_sale==2?'yushou':''">{{ item.is_sale_text }}</div>
                </div>
                <div class="bottom">
                  <div class="name">{{ item.product_name }}</div>
                  <div class="sellNums">{{ item.sell_count }}</div>
                  <div class="priceLine">
                    <div class="nowPrice">
                      <div class="leftUnit">￥</div>
                      <div class="rightPrice">{{ item.price }}</div>
                    </div>
                    <div class="lastPrice">
                      浏览{{ item.visit_count }}
                    </div>
                  </div>
                  <div class="userInfo">
                    <div class="left">
                      <img class="avatar"
                           :src="$store.state.imageHost+item.avatar"
                           alt="">
                      <span class="nickname">{{ item.nickname }}</span>
                    </div>
                    <div class="rightAdd">
                      <!--                      <span class="iconfont icon-Frame11"></span>-->
                      <!--                      {{ item.distanceObj.distance_str }}-->
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="loader-box">
            <div id="loader" v-if="loadLock"></div>
            <div id="nothing" v-if="!loadLock&&!hasMore">没有更多了~</div>
          </div>
        </div>

      </div>

      <div style="width: 100%;height: 100px;"></div>
      <idle_tabbar :active="0" ref="tabbar"></idle_tabbar>
    </div>
  </div>
</template>

<style scoped lang="scss">

.container {
  background-color: #F9F9F9;
  height: 100vh;
}

.navigationBar {
  background: #FFF5E9;

  .searchBox {
    display: flex;
    width: 80%;

    .left {
      display: flex;
      align-items: center;

      .iconfont {
        font-size: 25px;
      }

      .icon {
        margin-left: 5px;
      }
    }

    .right {

      flex: 1;
      margin-left: 10px;

      .searchOut {
        border-radius: 20px;
        padding: 4px 13px;
        background-color: white;
        display: flex;
        font-weight: 400;
        font-size: 12px;
        color: #8F8F8F;
        line-height: 24px;

        .leftIcon {
          margin-right: 10px;

          .iconfont {
            font-size: 15px;
          }
        }
      }
    }
  }
}

.publicity {
  margin: 10px 16px;
  border-radius: 10px;
  overflow: hidden;

  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 10px;
  }
}

.funBox {
  display: flex;
  margin: 10px 16px;
  justify-content: space-between;

  .every {
    width: 41%;
    padding: 12px;

    .title {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: 15px;
      color: #1B1B1B;
      line-height: 21px;
      text-align: left;

      .iconfont {
        font-weight: 400;
        font-size: 16px;
      }
    }

    .body {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        font-weight: 400;
        font-size: 12px;
        color: #1B1B1B;
        line-height: 17px;
        text-align: left;

        div {
          white-space: nowrap;
        }
      }

      .image {
        width: 52px;
        height: 52px;
        object-fit: cover;
      }
    }
  }

  .every:nth-of-type(1) {
    background: linear-gradient(154deg, #FFF1F3 0%, #FFFFFF 100%);
    border-radius: 10px 10px 10px 10px;

    .iconfont {
      color: #FF8686;
    }
  }

  .every:nth-of-type(2) {
    background: linear-gradient(154deg, #E6FFFB 0%, #FFFFFF 100%);
    border-radius: 10px 10px 10px 10px;

    .iconfont {
      color: #57BFBC;
    }
  }
}

.card {
  margin: 10px 16px 0 16px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
}

.swiper-slide {
  // background: red;
  height: 160px;
  background-size: cover;
  background-position: center center;
}

.swiper {
  margin: 10px 0px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;

  .swiper-slide {
    max-height: 180px;
  }


  .img {
    width: 100%;
  }

  // 分页器
  /* 两种都可以 */
  .swiper-pagination-total {
    font-size: 12px;
  }

  .swiper-pagination {
    //background: rgba(51, 51, 51, 0.75);
    width: 100%;
    // right: 10px;
    border-radius: 64px;
    color: white;
    line-height: 24px;
    font-size: 13px;
  }

  //.text{
  //  color: #333333;
  //  font-weight: 400;
  //  font-size: 12px;
  //  line-height: 17px;
  //}

}

.swiper-wrapper {

  .page {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .every {
      width: 20%;
    }
  }
}

.publicity .swiper-slide {
  max-height: 88px;
}

.pubu {
  padding: 10px;

  .title {
    align-items: center;
    text-align: center;
    color: rgba(27, 27, 27, 1);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    display: inline-block;
  }

  .title::after, .title::before {
    content: '';
    display: block;
    width: 116px;
    height: 1px;
    background-color: rgb(221, 221, 221);
    position: absolute;
  }

  .title::after {
    left: -24px;
    top: 50%;
    transform: translate(-100%, -50%);
  }

  .title::before {
    right: -24px;
    top: 50%;
    transform: translate(100%, -50%);
  }
}

.pay_flow {
  padding-left: 0;
  padding-right: 0;
  padding-top: 5px;
  background-image: url("https://picture.bjbjkj.top/image/idle_liuchengBack.png");
  background-size: cover;
  background-position: center center;
  padding-bottom: 20px;
  background-repeat: no-repeat;
  background-size: 100%;

  .title {
    background-image: url("https://picture.bjbjkj.top/image/idle_title.png");
    display: inline-block;
    background-size: cover;
    background-position: center center;
    width: 93px;
    text-align: center;
    color: #333333;
    font-weight: 400;
    font-size: 14px;
    height: 33px;
    line-height: 30px;
    text-indent: 1em;
  }

  .body {
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;

    .every {
      width: 20%;
      position: relative;

      .text {
        text-align: center;
        color: rgba(0, 0, 0, 1);
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
      }

      .icon {
        position: absolute;
        right: -25%;
        right: 0%;
        bottom: 10px;
        transform: translate(50%, 0);
      }

      .iconf {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }

      .circle {
        width: 5px;
        height: 5px;
        border-radius: 20px;
        background: #ffc77e;
        margin: 5px auto 0 auto;
        position: relative;
      }

      .circle:after, .circle:before {
        position: absolute;
        display: block;
        content: '';
        width: 31px;
        height: 1px;
        background: #ffe8cb;
        transform: translate(0 -50%);
        top: 50%;
      }

      .circle:before {
        left: 100%;
      }

      .circle:after {
        right: 100%;
      }

    }

    .every:nth-last-of-type(1) {
      .icon:nth-last-of-type(1) {
        display: none;
      }
    }
  }
}


#box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

#box img {
  object-fit: cover;
  background-color: #eee;
  margin-bottom: 10px;
}

#left-box,
#right-box {
  width: 180px;


  .item {
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    /* box-shadow: 0rpx 0rpx 4rpx 0rpx rgba(102,102,102,0.25); */
    display: flex;
    flex-direction: column;
    text-align: left;

    .img {
      width: 100%;
      background-color: #fff;
    }

    .imageBox {
      background-color: #fff;
      position: relative;
      min-height: 40px;

      .tag {
        position: absolute;
        right: 10px;
        bottom: 10px;
        border-radius: 5px;
        background: #fd2a39;
        color: #ffffff;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        padding: 1px 4px;
      }

      .yushou {
        background: #24b512;
      }
    }

    .bottom {
      padding: 6px 10px;

      .unit {
        font-size: 10px;
      }

      .price {
        color: #FC223B;
        font-size: 18px;
      }

      .priceLine {
        display: flex;
        color: #FC223B;
        align-items: baseline;
        width: 100%;
        margin-top: 6px;
        justify-content: space-between;

        .nowPrice, .lastPrice {
          display: flex;
          align-items: baseline;

          .leftUnit,
          .rightPrice {
            font-size: 12px;
            color: #7f7f7f;
          }
        }

        .leftUnit {
          font-size: 10px;
          line-height: 10px;
        }

        .rightPrice {
          font-weight: 400;
          width: 95%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .nowPrice {
          .rightPrice {
            font-size: 18px;
          }

          .leftUnit,
          .rightPrice {
            color: #FC223B !important;
          }
        }

        .lastPrice {
          text-align: right;
          color: #7f7f7f;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        }
      }

      .nowPrice {
        max-width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }


      .userInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;

        .left {
          width: 50%;

          .avatar {
            width: 18px;
            height: 18px;
            object-fit: cover;
            border-radius: 50%;
            margin-bottom: 0;
            vertical-align: middle;
            margin-right: 4px;
          }

          .nickname {
            display: inline-block;
            color: #999999;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;

          }

        }

        .rightAdd {
          text-align: right;
          color: #999999;
          font-weight: 400;
          font-size: 11px;
          line-height: 18px;
          display: flex;
          align-items: center;
        }
      }

    }

    .name {
      display: -webkit-box;
      /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
      -webkit-box-orient: vertical;
      /*要显示的行数*/
      -webkit-line-clamp: 2;
      /* 溢出部分隐藏 */
      overflow: hidden;
      font-size: 14px;
      color: #1b1b1b;
    }

    .sellNums {
      line-height: 18px;
      font-size: 12px;
      color: #7f7f7f;
    }
  }
}

.loader-box {
  width: 375px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loader {
  width: 25px;
  height: 25px;
  border: 3px solid #ddd;
  border-radius: 50%;
  border-bottom: 3px solid #717171;
  transform: rotate(0deg);
  animation: loading 1s linear infinite;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.idle_home {
  .iconf {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
  }

  .text {
    color: #333333;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
  }
}


</style>
<style>
.idle_home {
  .van-tab {
    width: 20%;
    position: relative;
    z-index: 2;
    color: #8C8C8C;
    font-size: 14px;
    padding: 0;
  }

  .tabs {
    .van-tabs__nav--line {
      padding-bottom: 5px;
    }

    .van-tabs__line {
      background-color: #FFDD7C;
      width: 16%;
      height: 7px;
      border-radius: 0px;
    }
  }

  .van-tab--active {
    color: #1B1B1B;
    font-size: 16px;

  }

  .van-tabs__content {
    .van-tab__pane-wrapper {
      .van-tab__pane {
        height: auto;
      }
    }
  }

  .my-bullet-active {
    background: #ffffff;
    opacity: 1;
    width: 13px !important;
    border-radius: 19px !important;
  }

  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
    background: rgb(252, 34, 59);
  }
}

</style>


