<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {Toast} from "vant";
import {collectList} from "@/api/idle";

export default {
  name: "myCollect",
  components: {navigationBar},
  data() {
    return {
      active: 0,
      tabsLi: [
        {text: '全部', list: [], page: 1, limit: 10, loading: false, noMore: false,},
        {text: '在售', list: [], page: 1, limit: 10, loading: false, noMore: false,},
        {text: '预售', list: [], page: 1, limit: 10, loading: false, noMore: false,},
      ],
    }
  },
  methods: {
    getList() {
      collectList(this.is_sale, this.tabsLi[this.active].page, this.tabsLi[this.active].limit,).then(res => {
        if (res.data.status == 200) {
          res.data.data.total
          console.log(res.data.data.data)
          let list = res.data.data.data
          list.forEach(item => {
            item.showImg = JSON.parse(item.images)[0]
            if (typeof item.showImg == 'string') {
              if (!item.showImg.startsWith('https:')) {
                item.showImg = this.$store.state.imageHost + item.showImg
              } else {
                item.showImg = item.showImg
              }
            } else {
              item.showImg = this.$store.state.imageHost + item.showImg.tempFilePath
            }
          })
          this.tabsLi[this.active].list = list
          // this.tabsLi[this.active].list = this.tabsLi[this.active].list.concat(list);
          console.log(this.tabsLi[this.active].list)
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    tabsChange() {
      this.getList()
    },

    load() {
      console.log('加载=========')
      this.tabsLi[this.active].loading = true
      if (this.tabsLi[this.active].noMore) {
        return
      }
      let page = this.tabsLi[this.active].page
      let limit = this.tabsLi[this.active].limit += 10
      collectList(this.is_sale, page, limit).then(res => {
        if (res.data.status == 200) {
          console.log(res.data.data.data)
          let maxlength = res.data.data.count
          let list = res.data.data.data
          list.forEach(item => {
            item.showImg = JSON.parse(item.images)[0]
            if (typeof item.showImg == 'string') {
              if (!item.showImg.startsWith('https:')) {
                item.showImg = this.$store.state.imageHost + item.showImg
              } else {
                item.showImg = item.showImg
              }
            } else {
              item.showImg = this.$store.state.imageHost + item.showImg.tempFilePath
            }
          })
          this.tabsLi[this.active].list = list
          if (list.length >= maxlength) {
            this.tabsLi[this.active].noMore = true
            this.tabsLi[this.active].loading = false
          }
          this.tabsLi[this.active].loading = false
          this.tabsLi[this.active].maxlength = maxlength
        } else {
          Toast(res.data.msg)
        }
      })


    },


    godetail(item) {
      this.$router.push({
        path:'/idleDetails',
        query:{id:item.product_id}
      })
    },
  },
  computed: {
    is_sale() {
      // 1出价中 2待取货 3已完成 4已取消
      let flag = this.active
      if (this.active == 0) {
        flag = ''
      }
      return flag
    },
    disabled() {
      return (this.active != -1 ? this.tabsLi[this.active].loading : true) || (this.active != -1 ? this.tabsLi[this.active].noMore : false)
    },
    noMore() {
      return this.tabsLi[this.active].list.length >= this.tabsLi[this.active >= 0 ? this.active : 0].maxlength
    },


  },
  mounted() {
    // this.getList()
  },
}
</script>

<template>
  <div class="container myCollect">
    <div class="area">
      <navigationBar :title="'我的收藏'" class="navigationBar"></navigationBar>
      <van-tabs v-model="active" animated class="vanTabs" @change="tabsChange">
        <!--        <van-tab v-for="(item,index) in tabsLi" :key="index" :title="item.text">-->
        <!--          <div class="ul" v-infinite-scroll="load"-->
        <!--               :infinite-scroll-disabled="disabled" :infinite-scroll-immediate="false">-->

        <!--            <p v-if="tabsLi[index].loading">加载中...</p>-->
        <!--            <p v-if="tabsLi[index].noMore">没有更多了</p>-->
        <!--          </div>-->
        <!--        </van-tab>-->


        <van-tab
            v-for="(item, index) in tabsLi"
            :title="item.name"
            :key="index"
        >
          <template #title>{{ item.text }}</template>
          <div class="ul" v-infinite-scroll="load"
               :infinite-scroll-disabled="disabled" :infinite-scroll-immediate="false">
            <div v-for="(itemc,indexcc) in tabsLi[index].list" :key="indexcc+'itemc'" class="li">
              <!--                  {{active}}-->
              <div class="item" @click="godetail(itemc)">
                <!--              <div class="top">-->
                <!--                <img src="https://m.360buyimg.com/babel/jfs/t1/189590/5/43143/7643/660157ebF638a43d9/b2843ee17c84c1b4.png" class="avatar" alt="">-->
                <!--                <span class="nickname">o泡果奶o泡果奶o泡果奶o泡果奶o泡果奶</span>-->
                <!--              </div>-->
                <div class="center">
                  <div class="left">
                    <img :src="itemc.showImg" alt="" class="showimg">
                    <div class="tag ">在售</div>
                    <div class="tag yushou">预售</div>
                  </div>
                  <div class="right">
                    <div class="name">{{ itemc.product_name }}</div>
                    <div class="price">
                      <div class="left">￥<span class="nums">{{ itemc.price }}</span></div>
                      <div class="visit_count">预览{{ itemc.visit_count }}</div>
                    </div>
                    <div class="userInfo">
                      <div class="leftB"><img :src="$store.state.imageHost+itemc.avatar" alt="" class="avatar"><span
                          class="nickname">{{ itemc.nickname }}</span></div>
                      <div class="rightDistance">
                        <span class="iconfont icon-Frame11"></span>
                        123km

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p v-if="tabsLi[index].loading">加载中...</p>
            <p v-if="tabsLi[index].noMore">没有更多了</p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  background: #f4f6fa;
  height: 100vh;
}

.container::-webkit-scrollbar {
  display: none;
}

.area {
  height: 100vh;
}

.navigationBar {
  background: #fff;
}

.vanTabs .van-tabs__wrap {
  background: #fff;
}

.vanTabs {

  .ul {
    height: 100vh;
    overflow: scroll;

    .li {

      .item {

        border-radius: 10px;
        background: #ffffff;
        margin: 10px 16px;
        padding: 10px;

        .top {
          text-align: left;

          .avatar {
            width: 20px;
            height: 20px;
            object-fit: cover;
            vertical-align: middle;
          }

          .nickname {
            color: #999999;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            vertical-align: middle;
            display: inline-block;
            width: 85%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 10px;
          }
        }

        .center {
          display: flex;

          .left {
            width: 104px;
            height: 104px;
            position: relative;
            overflow: hidden;

            .showimg {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              object-fit: cover;
            }

            .tag {
              position: absolute;
              left: 0px;
              top: 0px;
              border-radius: 10px 0px 5px 0px;
              background: #fd2a39;
              color: #ffffff;
              font-weight: 400;
              font-size: 12px;
              line-height: 17px;
              padding: 1px 4px;
            }

            .yushou {
              background: #24b512;
            }
          }

          .right {
            flex: 1;
            text-align: left;
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .name {
              display: -webkit-box;
              /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
              -webkit-box-orient: vertical;
              /*要显示的行数*/
              -webkit-line-clamp: 2;
              /* 溢出部分隐藏 */
              overflow: hidden;
              color: #1b1b1b;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px
            }

            .price {
              font-size: 10px;
              color: #fd2a39;
              font-weight: 400;
              line-height: 24px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .left{
                display: block!important;
                height: auto;
              }

              .nums {
                font-weight: 500;
                font-size: 18px;
              }

              .visit_count {
                display: flex;
                align-items: center;
                text-align: right;
                color: #7f7f7f;
                font-family: "PingFang SC";
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
              }
            }

            .userInfo {
              justify-content: space-between;
              display: flex;

              .leftB {
                width: 70%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                .avatar {
                  width: 20px;
                  height: 20px;
                  object-fit: cover;
                  vertical-align: middle;
                }

                .nickname {
                  vertical-align: middle;
                  padding-left: 10px;
                  color: #999999;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                }
              }

              .rightDistance {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                text-align: right;
                color: #999999;
                font-weight: 400;
                font-size: 11px;
                line-height: 18px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }

        .footer {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;

          .left {
            width: 25%;
            text-align: left;

            .btn {

            }
          }

          .right {
            width: 75%;
            display: flex;
            justify-content: flex-end;

            .btn {
              margin-left: 10px;
            }
          }

          .btn {
            display: inline-flex;
            place-content: center;
            place-items: center;
            gap: 10px;
            padding: 6px 10px;
            border-radius: 25px;
            border: 0.5px solid #999999;
            color: #1b1b1b;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .ul::-webkit-scrollbar {
    display: none;
  }
}
</style>
<style>

.myCollect .van-tab {
  flex: none;
  width: 20%;
  color: #1b1b1b;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

.myCollect .van-tab--active {
  text-align: center;
  color: #1b1b1b;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.myCollect .van-tabs--line .van-tabs__wrap {
  padding-bottom: 10px;
}
</style>